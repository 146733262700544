import clx from "classnames";
import React, { forwardRef } from "react";
import SectionTitle from "../SectionTitle";
import { ReactComponent as Condominium } from "../../../../assets/svg/condominium.svg";
import { ReactComponent as Money } from "../../../../assets/svg/money.svg";
import PlusImage2 from "../../../../assets/svg/plus_icon.svg";
import { ReactComponent as Room } from "../../../../assets/svg/room.svg";
import { ReactComponent as Sketch } from "../../../../assets/svg/sketch.svg";
import UpIcon from "../../../../assets/svg/up-arrow.svg";
import formatToEuro from "../../../../utils/formatToEuro";
import ReactToolTip from "../../../Common/ToolTip/ReactToolTip";
import Gallery from "../Gallery";
import styles from "./PropertyCharacteristics.module.scss";

const PropertyCharacteristics = forwardRef(
  (
    {
      data,
      isMobile,
      reportId,
      showGallery = true,
      marginTop = "",
      title = "Riepilogo della tua valutazione",
      clickProperty = null,
      padding,
      maxHeight,
      minPrice,
      exactPrice,
      maxPrice,
      completeSurface,
    },
    ref
  ) => {
    // console.log(data, "data property characteristics");
    // console.log(exactPrice, "exactPrice");

    // Object mapping to extract field names in an array
    const filteredItems = [];
    [
      "Immobile Di Lusso",
      "Portineria",
      "Immobile A Zero Emissioni",
      "Campo Tennis",
      "Piscina",
      "Jacuzzi",
      "Pompa Di Calore",
      // "Aria Condizionata",
      "Esposizione Interna",
      "Esposizione Esterna",
      "Camino",
    ].forEach((key) => {
      if (clickProperty == null) {
        if (data?.[key.replace(/\s+/g, "")] != "No") {
          filteredItems.push(key);
        }
      } else {
        if (data?.CampiAddizionali?.value?.includes(key.replace(/\s+/g, ""))) {
          filteredItems.push(key);
        }
      }
    });

    return (
      <div
        className={clx(styles.property__characteristics__wrapper, {
          [styles.property__characteristics__mbl]: isMobile,
        })}
        style={{
          marginTop,
        }}
        ref={ref}
      >
        {/* SECTION TITLE */}
        {clickProperty == null ? (
          <div className={styles.title__wrapper}>
            <SectionTitle
              title={title}
              section={"property_characteristics"}
              isDisabled={true}
              toolText={"Non è possibile omettere questo campo dal PDF."}
            />
          </div>
        ) : (
          <p
            className={clx(styles.property__characteristics__header, {
              [styles.property__characteristics__header_create]:
                clickProperty != null,
            })}
          >
            {title}
          </p>
        )}
        {/* CHARACTERISTICS WRAPPER */}
        <div
          className={clx(styles.property__characteristics__main, {
            [styles.property__characteristics__main_create]:
              clickProperty != null,
          })}
          style={{
            padding,
            overflowY: clickProperty != null && "auto",
            maxHeight: clickProperty != null ? maxHeight : "null",
            overflowX: clickProperty != null && "hidden",
          }}
        >
          {/* STRUTTURA INTERNI */}
          <div
            className={clx(
              styles.characteristichs__structure,
              styles.characteristichs__wrapper
            )}
          >
            <div className={styles.characteristichs__title}>
              <Room /> <b>Caratteristiche dell’immobile</b>
            </div>
            <div className={styles.characteristichs__column}>
              {/* PROPERTY TYPE */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Tipologia?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Tipo di proprieta&apos;
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Tipologia?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.Tipologia?.value || data?.Tipologia || "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* PIANI DELL'IMMOBILE */}
              {((data?.Tipologia?.value || data?.Tipologia) == "Appartamento" ||
                (data?.Tipologia?.value || data?.Tipologia) == "Attico") && (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.PianiCasa?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Piani dell&apos;immobile
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.PianiCasa?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.PianiCasa?.value !== undefined &&
                        data?.PianiCasa?.value !== null
                          ? +data?.PianiCasa?.value
                          : data?.PianiCasa !== undefined &&
                            data?.PianiCasa !== null
                          ? +data?.PianiCasa
                          : "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              )}
              {/* CLASSE ENERGETICA */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.ClasseEnergetica?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Classe energetica
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.ClasseEnergetica?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.ClasseEnergetica?.value ||
                      data?.ClasseEnergetica ||
                      "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* RISCALDAMENTO */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Riscaldamento?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Riscaldamento
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Riscaldamento?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.Riscaldamento?.value || data?.Riscaldamento || "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* TIPOLOGIA RISCALDAMENTO */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.TipologiaRiscaldamento?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Tipologia Riscaldamento
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.TipologiaRiscaldamento?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.TipologiaRiscaldamento?.value ||
                      data?.TipologiaRiscaldamento ||
                      "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* ALIMENTAZIONE RISCALDAMENTO */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.AlimentazioneRiscaldamento?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Alimentazione Riscaldamento
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.AlimentazioneRiscaldamento?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.AlimentazioneRiscaldamento?.value ||
                      data?.AlimentazioneRiscaldamento ||
                      "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* ARIA CONDIZIONATA */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.SistemaAriaCondizionata?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Sistema aria condizionata
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.SistemaAriaCondizionata?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.SistemaAriaCondizionata?.value ||
                      data?.SistemaAriaCondizionata ||
                      "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* STATO DEGLI INTERNI */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.StatoInterni?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Stato degli interni
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.StatoInterni?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.StatoInterni?.value || data?.StatoInterni || "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* ARREDAMENTO */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Arredamento?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Arredamento
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Arredamento?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.Arredamento?.value || data?.Arredamento || "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* NUMERO STANZE */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Locali?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Numero stanze
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Locali?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.Locali?.value || data?.Locali || "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* NUMERO BAGNI */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Bagni?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Numero bagni
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Bagni?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.Bagni?.value || data?.Bagni || "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* CUCINA */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Cucina?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Cucina
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Cucina?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.Cucina?.value || data?.Cucina || "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* TERRAZZI */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Terrazzo?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Terrazzo
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Terrazzo?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.Terrazzo?.value || data?.Terrazzo || "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* BALCONI */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Balconi?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Balconi
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Balconi?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.Balconi?.value || data?.Balconi || "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* GIARDINO */}
              {/*{((data?.Tipologia?.value || data?.Tipologia) == "Appartamento" ||
                (data?.Tipologia?.value || data?.Tipologia) == "Villa" ||
                (data?.Tipologia?.value || data?.Tipologia) == "Villetta") && (
                <>*/}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.GiardinoPrivato?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Giardino privato
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.GiardinoPrivato?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.GiardinoPrivato?.value ||
                      data?.GiardinoPrivato ||
                      "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/*</>
              )}*/}
              {/* CANTINA */}
              {((data?.Tipologia?.value || data?.Tipologia) == "Appartamento" ||
                (data?.Tipologia?.value || data?.Tipologia) == "Attico") && (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.Cantina?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Cantina
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.Cantina?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.Cantina?.value || data?.Cantina || "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              )}
              {/* SOFFITTA */}
              {((data?.Tipologia?.value || data?.Tipologia) == "Appartamento" ||
                (data?.Tipologia?.value || data?.Tipologia) == "Villa" ||
                (data?.Tipologia?.value || data?.Tipologia) == "Villetta") && (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.Soffitta?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Soffitta
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.Soffitta?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.Soffitta?.value || data?.Soffitta || "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              )}
              {/* SEMINTERRATO */}
              {((data?.Tipologia?.value || data?.Tipologia) == "Appartamento" ||
                (data?.Tipologia?.value || data?.Tipologia) == "Villa" ||
                (data?.Tipologia?.value || data?.Tipologia) == "Villetta") && (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.Seminterrato?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Seminterrato
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.Seminterrato?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.Seminterrato?.value || data?.Seminterrato || "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              )}
              {/* ALTRE CARATTERISTICHE */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.CampiAddizionali?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Altre caratteristiche
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.CampiAddizionali?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {filteredItems && filteredItems?.length
                      ? filteredItems?.map((item, index) => (
                          <React.Fragment key={index}>
                            {item}
                            {index === filteredItems?.length - 1 ? "" : ", "}
                          </React.Fragment>
                        ))
                      : "-"}
                  </div>
                </ReactToolTip>
              </div>
            </div>
          </div>

          {/* ESTERNI */}
          <div
            className={clx(
              styles.characteristichs__external,
              styles.characteristichs__wrapper
            )}
          >
            <div className={styles.characteristichs__title}>
              <Condominium /> <b>Esterni / Condominio</b>
            </div>
            <div className={styles.characteristichs__column}>
              {/* PIANI DEL PALAZZO */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.TotalePiani?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Piani{" "}
                    {(data?.Tipologia?.value || data?.Tipologia) ==
                      "Appartamento" ||
                    (data?.Tipologia?.value || data?.Tipologia) == "Attico"
                      ? "del palazzo"
                      : "della villa"}
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.TotalePiani?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.TotalePiani?.value !== undefined &&
                    data?.TotalePiani?.value !== null
                      ? +data?.TotalePiani?.value
                      : data?.TotalePiani !== undefined &&
                        data?.TotalePiani !== null
                      ? +data?.TotalePiani
                      : "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* PIANO DELL IMMOBILE */}
              {(data.Tipologia?.value || data.Tipologia) == "Appartamento" && (
                <div className={styles.column__item}>
                  <ReactToolTip
                    contenet={
                      <div style={{ color: "gray", paddingTop: 12 }}>
                        Clicca per modificare questo campo
                      </div>
                    }
                    containerClass="tooltipStyleChange"
                    x={-87}
                    y={70}
                    width={289}
                    height={77}
                    // displayPosition={
                    //   clickProperty != null ? "absolute" : "relative"
                    // }
                    arrow={{
                      x: -3,
                      y: 74,
                      rotate: "206deg",
                    }}
                    display={clickProperty != null ? "block" : "none"}
                  >
                    <div
                      className={styles.second__column__header}
                      onClick={() =>
                        clickProperty != null &&
                        clickProperty(data?.Piano?.stepId)
                      }
                      style={{
                        cursor: clickProperty != null ? "pointer" : "default",
                      }}
                    >
                      Piano dell&apos;immobile
                    </div>
                    <div
                      className={styles.second__column__value}
                      onClick={() =>
                        clickProperty != null &&
                        clickProperty(data?.Piano?.stepId)
                      }
                      style={{
                        cursor: clickProperty != null ? "pointer" : "default",
                      }}
                    >
                      {data?.Piano?.value !== undefined &&
                      data?.Piano?.value !== null
                        ? +data?.Piano?.value
                        : data?.Piano !== undefined && data?.Piano !== null
                        ? +data?.Piano
                        : "-"}
                    </div>
                  </ReactToolTip>
                </div>
              )}
              {/* ASCENSORE */}
              {((data?.Tipologia?.value || data?.Tipologia) == "Appartamento" ||
                (data?.Tipologia?.value || data?.Tipologia) == "Attico") && (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.Ascensore?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Ascensore
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.Ascensore?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.Ascensore?.value || data?.Ascensore || "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              )}
              {/* GIARDINO CONDOMINIALE */}
              {((data?.Tipologia?.value || data?.Tipologia) == "Appartamento" ||
                (data?.Tipologia?.value || data?.Tipologia) == "Attico") && (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.GiardinoCondominiale?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Giardino condominiale
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.GiardinoCondominiale?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.GiardinoCondominiale?.value ||
                          data?.GiardinoCondominiale ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              )}
              {/* POSTO AUTO */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.PostoAuto?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Posto Auto
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.PostoAuto?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.PostoAuto?.value?.join(", ") ||
                      data?.PostoAuto?.join(", ") ||
                      "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* ANNO DI COSTRUZIONE */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.AnnoCostruzione?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Anno di costruzione
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.AnnoCostruzione?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.AnnoCostruzione?.value ||
                      data?.AnnoCostruzione ||
                      "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* COSTI PROPRIETA */}
              {data?.CostiProprieta?.value ||
              (typeof data?.CostiProprieta === "number" &&
                data?.CostiProprieta !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.CostiProprieta?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Costi della proprietà
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.CostiProprieta?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {(data?.CostiProprieta?.value ||
                          data?.CostiProprieta) &&
                          "\u20AC\u00A0"}{" "}
                        {clickProperty != null
                          ? data?.CostiProprieta?.value || "-"
                          : formatToEuro(data?.CostiProprieta) || "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          {/* ALTRO */}
          <div
            className={clx(
              styles.characteristichs__surfaces,
              styles.characteristichs__wrapper
            )}
          >
            <div className={styles.characteristichs__title}>
              <Sketch /> <b>Analisi delle superfici in m²</b>
            </div>
            <div className={styles.characteristichs__column}>
              {/* SURFACE CALPESTABILE */}
              <div className={styles.column__item}>
                <ReactToolTip
                  contenet={
                    <div style={{ color: "gray", paddingTop: 12 }}>
                      Clicca per modificare questo campo
                    </div>
                  }
                  containerClass="tooltipStyleChange"
                  x={-87}
                  y={70}
                  width={289}
                  height={77}
                  arrow={{
                    x: -3,
                    y: 74,
                    rotate: "206deg",
                  }}
                  display={clickProperty != null ? "block" : "none"}
                >
                  <div
                    className={styles.second__column__header}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Superficie?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    Superficie calpestabile
                  </div>
                  <div
                    className={styles.second__column__value}
                    onClick={() =>
                      clickProperty != null &&
                      clickProperty(data?.Superficie?.stepId)
                    }
                    style={{
                      cursor: clickProperty != null ? "pointer" : "default",
                    }}
                  >
                    {data?.Superficie?.value || data?.Superficie || "-"}
                  </div>
                </ReactToolTip>
              </div>
              {/* SUPERFICIE GIARDINO PRIVATO */}
              {data?.SuperficieGiardinoPrivato?.value ||
              (typeof data?.SuperficieGiardinoPrivato === "number" &&
                data?.SuperficieGiardinoPrivato !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieGiardinoPrivato?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Superficie giardino privato
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieGiardinoPrivato?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.SuperficieGiardinoPrivato?.value ||
                          data?.SuperficieGiardinoPrivato ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
              {/* SUPERFICIE GIARDINO CONDOMINIALE */}
              {data?.SuperficieGiardinoCondominiale?.value ||
              (typeof data?.SuperficieGiardinoCondominiale === "number" &&
                data?.SuperficieGiardinoCondominiale !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(
                            data?.SuperficieGiardinoCondominiale?.stepId
                          )
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Superficie giardino condominiale
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(
                            data?.SuperficieGiardinoCondominiale?.stepId
                          )
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.SuperficieGiardinoCondominiale?.value ||
                          data?.SuperficieGiardinoCondominiale ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
              {/* SUPERFICIE BALCONI */}
              {data?.SuperficieBalconi?.value ||
              (typeof data?.SuperficieBalconi === "number" &&
                data?.SuperficieBalconi !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieBalconi?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Superficie balconi
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieBalconi?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.SuperficieBalconi?.value ||
                          data?.SuperficieBalconi ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
              {/* SUPERFICIE TERRAZZO */}
              {data?.SuperficieTerrazzo?.value ||
              (typeof data?.SuperficieTerrazzo === "number" &&
                data?.SuperficieTerrazzo !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieTerrazzo?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Superficie terrazzo
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieTerrazzo?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.SuperficieTerrazzo?.value ||
                          data?.SuperficieTerrazzo ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
              {/* SUPERFICIE SOFFITTA */}
              {data?.SuperficieSoffitta?.value ||
              (typeof data?.SuperficieSoffitta === "number" &&
                data?.SuperficieSoffitta !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieSoffitta?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Superficie soffitta
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieSoffitta?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.SuperficieSoffitta?.value ||
                          data?.SuperficieSoffitta ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
              {/* SUPERFICIE CANTINA */}
              {data?.SuperficieCantina?.value ||
              (typeof data?.SuperficieCantina === "number" &&
                data?.SuperficieCantina !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieCantina?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Superficie cantina
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieCantina?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.SuperficieCantina?.value ||
                          data?.SuperficieCantina ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
              {/* SUPERFICIE SEMINTERRATO */}
              {data?.SuperficieSeminterrato?.value ||
              (typeof data?.SuperficieSeminterrato === "number" &&
                data?.SuperficieSeminterrato !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieSeminterrato?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Superficie seminterrato
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficieSeminterrato?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.SuperficieSeminterrato?.value ||
                          data?.SuperficieSeminterrato ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
              {/* SUPERFICIE POSTO AUTO */}
              {data?.SuperficiePostoAuto?.value ||
              (typeof data?.SuperficiePostoAuto === "number" &&
                data?.SuperficiePostoAuto !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficiePostoAuto?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Superficie posto auto privato
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficiePostoAuto?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.SuperficiePostoAuto?.value ||
                          data?.SuperficiePostoAuto ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
              {/* SUPERFICIE POSTO AUTO CONDOMINIALE */}
              {data?.SuperficiePostoAutoCondominiale?.value ||
              (typeof data?.SuperficiePostoAutoCondominiale === "number" &&
                data?.SuperficiePostoAutoCondominiale !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(
                            data?.SuperficiePostoAutoCondominiale?.stepId
                          )
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Superficie posto auto condominiale
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(
                            data?.SuperficiePostoAutoCondominiale?.stepId
                          )
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.SuperficiePostoAutoCondominiale?.value ||
                          data?.SuperficiePostoAutoCondominiale ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
              {/* SUPERFICIE POSTO AUTO GARAGE */}
              {data?.SuperficiePostoAutoGarage?.value ||
              (typeof data?.SuperficiePostoAutoGarage === "number" &&
                data?.SuperficiePostoAutoGarage !== 0) ? (
                <>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__header}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficiePostoAutoGarage?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        Superficie posto auto garage
                      </div>
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.SuperficiePostoAutoGarage?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {data?.SuperficiePostoAutoGarage?.value ||
                          data?.SuperficiePostoAutoGarage ||
                          "-"}
                      </div>
                    </ReactToolTip>
                  </div>
                </>
              ) : null}
              {/* TOTAL SURFACE */}
              {completeSurface && (
                <div className={styles.total_surface}>
                  Viene valutata una superficie totale commerciale di{" "}
                  <b>{completeSurface.toFixed(0)} m²</b>
                </div>
              )}
            </div>
          </div>

          {/* VALUTAZIONE */}
          <div
            className={clx(
              styles.characteristichs__price,
              styles.characteristichs__wrapper,
              {
                [styles.three_prices]: !clickProperty,
              }
            )}
            style={{ gap: clickProperty !== null && 15 }}
          >
            {clickProperty !== null ? (
              <>
                <div className={styles.characteristichs__title}>
                  <Money /> <b>LA TUA VALUTAZIONE</b>
                </div>
                <div className={styles.characteristichs__column}>
                  <div className={styles.column__item}>
                    <ReactToolTip
                      contenet={
                        <div style={{ color: "gray", paddingTop: 12 }}>
                          Clicca per modificare questo campo
                        </div>
                      }
                      containerClass="tooltipStyleChange"
                      x={-87}
                      y={70}
                      width={289}
                      height={77}
                      arrow={{
                        x: -3,
                        y: 74,
                        rotate: "206deg",
                      }}
                      display={clickProperty != null ? "block" : "none"}
                    >
                      <div
                        className={styles.second__column__value}
                        onClick={() =>
                          clickProperty != null &&
                          clickProperty(data?.Prezzo?.stepId)
                        }
                        style={{
                          cursor: clickProperty != null ? "pointer" : "default",
                        }}
                      >
                        {(data?.Prezzo?.value || data?.Prezzo) &&
                          "\u20AC\u00A0"}{" "}
                        {clickProperty != null
                          ? data?.Prezzo?.value || "Valutazione non inserita"
                          : data?.prezzo !== 0
                          ? formatToEuro(data?.Prezzo)
                          : "Valutazione non inserita"}
                      </div>
                    </ReactToolTip>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.characteristichs__title}>
                  <Money /> <b>STIME ECONOMICHE DELL&apos;IMMOBILE</b>
                </div>
                <div className={styles.characteristichs__column}>
                  <div className={clx(styles.column__item, styles.estimation)}>
                    <div className={styles.second__column__header}>
                      Stima minima
                    </div>
                    <div className={styles.second__column__value}>
                      € {formatToEuro(minPrice?.toFixed(0))}
                    </div>
                  </div>
                  <div className={clx(styles.column__item, styles.estimation)}>
                    <div className={styles.second__column__header}>
                      Stima reale
                    </div>
                    <div className={styles.second__column__value}>
                      € {formatToEuro(exactPrice?.toFixed(0))}
                    </div>
                  </div>
                  <div className={clx(styles.column__item, styles.estimation)}>
                    <div className={styles.second__column__header}>
                      Stima massima
                    </div>
                    <div className={styles.second__column__value}>
                      € {formatToEuro(maxPrice?.toFixed(0))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* </div> */}
        </div>
        {/* FOOTER */}
        {showGallery && (
          <div id="footer">
            {/* GALLERY */}
            <Gallery reportId={reportId} />
            <div className={styles.property__characteristics__footer}>
              {/* BACK TO TOP BUTTON */}
              {/* <div className={styles.to_top} onClick={callback}>
                <img src={UpIcon} alt="up-arrow-icon" />
                <span>torna su</span>
              </div> */}
              {/* PLUS IMAGE */}
              <div className={styles.plus__wrapper}>
                {process.env.REACT_APP_PLATFORM == "HOUSEPLUS" && (
                  <img style={{ width: 76 }} src={PlusImage2} alt="plus-img" />
                )}
              </div>
              {/* FOOTER */}
              <p className={styles.footer__text}>
                Report di valutazione immobiliare {/* <br />  */}
                evoluta
              </p>
              {/* <div className={styles.footer__actions}>
              <div className={styles.actions__item} onClick={goToDownload}>
                <div>
                  <img
                    src={DownloadIcon}
                    alt="download-icon"
                    style={{ width: "24px" }}
                  />
                </div>
                <p className={styles.actions__title}>Scarica</p>
              </div>
              <div
                className={styles.actions__item}
                onClick={() => location.reload()}
              >
                <div>
                  <img src={PrintIcon} alt="print-icon" />
                </div>
                <p className={styles.actions__title}>Ricarica</p>
              </div>
            </div> */}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default PropertyCharacteristics;
