import { Navigate, useLocation } from "react-router-dom";

export const AdminRoute = ({ component: RouteComponent, isAdmin }) => {
  let location = useLocation();

  if (!isAdmin) {
    return <Navigate to={"/"} state={{ from: location }} />;
  }

  return <RouteComponent />;
};
