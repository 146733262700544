import * as yup from "yup";

export const INITIAL_VALUES = {
  Tipologia: "",
  Prezzo: "",
  PrezzoMq: "",
  ClasseEnergetica: "",
  Superficie: "",
  Distanza: "",
};

export const VALIDATION_SCHEMA = yup.object().shape({
  Tipologia: yup
    .string("Inserisci un valore")
    .required("Tipologia proprietà richiesta"),
  Prezzo: yup
    .number("Inserisci un valore numerico")
    .transform((_value, originalValue) =>
      Number(originalValue.replace(/\D/g, ""))
    ) // transform value to be only numbers for "when" validation below
    .typeError("Devi specificare un numero")
    // .min(1, "La tua valutazione non può essere negativa")
    .max(1000000000, "La tua valutazione non puo' superare 1.000.000.000")
    .required("Prezzo immobile richiesto"),
  PrezzoMq: yup
    .number("Inserisci un valore numerico")
    .transform((_value, originalValue) =>
      Number(originalValue.replace(/\D/g, ""))
    ) // transform value to be only numbers for "when" validation below
    .typeError("Devi specificare un numero")
    // .min(1, "La tua valutazione non può essere negativa")
    .max(1000000000, "La tua valutazione non puo' superare 1.000.000.000")
    .required("Prezzo immobile richiesto"),
  ClasseEnergetica: yup
    .string("Inserisci un valore")
    .required("Classe energetica richiesta"),
  Superficie: yup
    .number()
    .typeError(
      "Inserisci la superficie commerciale dell'immobile in metri quadri"
    )
    .min(1, "La superficie commerciale non può essere negativa")
    .max(1000000, "La superficie commerciale non può superare 1.000.000 m²")
    .required("Superficie richiesta"),
  Distanza: yup
    .number()
    .typeError("Inserisci la distanza dall'immobile analizzato")
    .min(1, "La distanza non può essere negativa")
    .max(1000000, "La distanza non può superare 1.000.000 m²")
    .required("Distanza richiesta"),
});

export const FORM_FIELDS = [
  {
    fieldName: "Tipologia",
    labelProperty: "Tipo di proprietà",
    isSelectField: true,
    acceptValues: [
      {
        id: "sold_select_type_8",
        value: "Appartamento",
        label: "Appartamento",
      },
      {
        id: "sold_select_type_7",
        value: "Attico",
        label: "Attico",
      },
      {
        id: "sold_select_type_7",
        value: "Villa",
        label: "Villa",
      },
      {
        id: "sold_select_type_6",
        value: "Villetta",
        label: "Villetta",
      },
    ],
  },
  {
    fieldName: "Prezzo",
    labelProperty: "Prezzo in €",
    isTextField: true,
  },
  {
    fieldName: "PrezzoMq",
    labelProperty: "Prezzo in €/m²",
    isTextField: true,
  },
  {
    fieldName: "ClasseEnergetica",
    labelProperty: "Classe energetica",
    isSelectField: true,
    acceptValues: [
      {
        id: "sold_Select_8",
        value: "Sconosciuta",
        label: "Sconosciuta",
      },
      {
        id: "sold_Select_7",
        value: "G",
        label: "G",
      },
      {
        id: "sold_Select_7",
        value: "F",
        label: "F",
      },
      {
        id: "sold_Select_6",
        value: "E",
        label: "E",
      },
      {
        id: "sold_Select_5",
        value: "D",
        label: "D",
      },
      {
        id: "sold_Select_4",
        value: "C",
        label: "C",
      },
      {
        id: "sold_Select_3",
        value: "B",
        label: "B",
      },
      {
        id: "sold_Select_1",
        value: "A1",
        label: "A1",
      },
      {
        id: "sold_Select_9",
        value: "A2",
        label: "A2",
      },
      {
        id: "sold_Select_10",
        value: "A3",
        label: "A3",
      },
      {
        id: "sold_Select_11",
        value: "A4",
        label: "A4",
      },
    ],
  },
  {
    fieldName: "Superficie",
    labelProperty: "Superficie in m²",
    isTextField: true,
  },
  {
    fieldName: "Distanza",
    labelProperty: "Distanza in m",
    isTextField: true,
  },
];
