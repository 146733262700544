import { ReactComponent as ApartmentImage } from "../assets/svg/apartment-big.svg";
import { ReactComponent as AtticMansardImage } from "../assets/svg/attic-mansard-bid.svg";
import { ReactComponent as ChaletImage } from "../assets/svg/chalet-new.svg";
import { ReactComponent as TownHouseImage } from "../assets/svg/townhouse-big.svg";

export const getImg = (type) => {
  switch (type) {
    case "Appartamento":
      return ApartmentImage;
    case "Villetta":
      return TownHouseImage;
    case "Attico":
      return AtticMansardImage;
    case "Villa":
      return ChaletImage;
    default:
      return ApartmentImage;
  }
};
