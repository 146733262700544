import MUIDataTable from 'mui-datatables';

import useWindowSize from '../../../../../utils/useWindowSize';
import DataTableFooter from '../DataTableFooter';

const DataTableDefault = ({
	title,
	tableData,
	tableColumns,
	rowsPerPageNum = 10,
	uniqueKey,
	filterOption = true,
	selectableRowsOption = false,
	paginationOption = true,
}) => {
	const { isMobile } = useWindowSize();

	const columns = [...tableColumns?.map(column => (
		{
			name: column?.name,
			label: column?.label,
			options: {
				filter: column?.filter,
				sort: column?.sort,
				setCellHeaderProps: () => column?.centered && !isMobile && ({ className: 'centeredHeader' }),
				setCellProps: () => column?.centered && !isMobile && ({ className: 'centeredColumn' }),
				customBodyRender: data => column?.customBodyRender ? column?.customBodyRender(data) : data,
			}
		}
	))];

	const options = {
		filterType: 'checkbox',
		filter: filterOption,
		sort: true,
		responsive: 'vertical',
		selectableRows: selectableRowsOption && 'multiple',
		tableBodyMaxHeight: 'auto',
		rowsPerPage: rowsPerPageNum,
		rowsPerPageOptions: [],
		pagination: paginationOption,
		textLabels: {
			body: {
				noMatch: 'Nessun oggetto trovato',
			}
		},
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => paginationOption ?
			<DataTableFooter
				count={count}
				page={page}
				rowsPerPage={rowsPerPage}
				changeRowsPerPage={changeRowsPerPage}
				changePage={changePage}
				textLabels={textLabels}
			/> :
			false,
	};
	return (
		<div>
			{tableData && <MUIDataTable
				title={title}
				key={uniqueKey}
				data={tableData?.length > 0 ? tableData : []}
				columns={columns}
				options={options}
			/>}
		</div>
	);
};

export default DataTableDefault;
