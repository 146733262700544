export const ROUTES = {
  SIGN_IN: "/login",
  SIGN_UP: "/registration",
  ACCOUNT: "/account",
  FORGOT_PASSWORD: "/forgotPassword",
  RESET_PASSWORD: "/reset",
  REPORT: "/report",
  PAYMENTS: "/payments",
  FAQ: "/faq",
  CREATE_REPORT: "/createReport",
  ADMIN: "/admin",
};
