import clx from "classnames";
import { useEffect, useState } from "react";

import { ReactComponent as EyeIcon } from "../../../assets/svg/eye.svg";
import styles from "./TextField.module.scss";

const TextField = ({
  field,
  form: { touched, errors, values },
  className,
  label,
  fontSizelabel,
  optional = false,
  desc = "",
  type = "text",
  inputColor = "#fff",
  borderRadius = "5px",
  border,
  readonly,
  textarea = false,
  height = "50px",
  showPassword = false,
  iconComponent,
  colorFontInput = "",
  colorFontTextarea = "",
  labelStyle = {},
  labelHeight = null,
  value,
  classNameInherit,
  marginBottomInputGroup,
  key,
  placeholder,
  width,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  const isError = touched[field.name] && errors[field.name];
  // console.log("isError", isError);

  useEffect(() => {
    setInputType(type);
  }, [type]);

  return (
    <div
      className={clx(
        styles.field__wrapper,
        styles[`${className}`],
        classNameInherit,
        {
          [styles["full-height"]]: textarea && height === "100%",
        }
      )}
      key={key}
      style={{ width }}
    >
      {label && (
        <div
          className={styles.label_wrapper}
          style={{ height: labelHeight || 35, ...labelStyle }}
        >
          <label
            className={styles["field__label"]}
            htmlFor={field.name}
            style={{ fontSize: `${fontSizelabel}` }}
          >
            {label}
          </label>
          {optional && (
            <p
              style={{
                color: "#a3a3a3",
                fontSize: 14,
              }}
            >
              Opzionale
            </p>
          )}
        </div>
      )}
      {textarea ? (
        <textarea
          className={clx(styles["field__textarea"], {
            [styles["error-textarea"]]: isError,
          })}
          {...field}
          {...props}
          style={{
            borderRadius,
            border,
            height,
            background: `${inputColor}`,
            color: `${colorFontTextarea}`,
          }}
        />
      ) : (
        <div
          className={clx(styles["input-group"], {
            [styles["error-field"]]: isError,
          })}
          style={{
            borderRadius,
            border,
            height,
            background: `${inputColor}`,
            marginBottom: marginBottomInputGroup,
          }}
        >
          <input
            className={styles["field__input"]}
            type={inputType}
            placeholder={placeholder}
            {...field}
            {...props}
            style={{
              background: `${inputColor}`,
              color: `${colorFontInput}`,
            }}
          />
          {showPassword && (
            <EyeIcon
              className={styles["show-pwd-icon"]}
              onClick={() => {
                if (inputType === "password") {
                  setInputType("text");
                } else {
                  setInputType("password");
                }
              }}
            />
          )}
          {iconComponent}
        </div>
      )}
      {desc != "" && <p className={styles.descNumber}>{desc}</p>}
      {isError && <div className={styles.error}>{errors[field.name]}</div>}
    </div>
  );
};

export default TextField;
