import clx from "classnames";
import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  memo,
} from "react";
import { useSelector } from "react-redux";
import styles from "./ProbabilitySale.module.scss";

import SectionTitle from "../SectionTitle";
import { ReactComponent as InfoIcon } from "../../../../assets/svg/info.svg";
import SingleSelect from "../../../Common/SingleSelect";
import ReactToolTip from "../../../Common/ToolTip/ReactToolTip";
import { options2, options3, options4 } from "../../constants";
import BestSellingCard from "./containers/BestSellingCard";
import ProbabilitySaleChart from "./containers/ProbabilitySaleChart";
import BuyCta from "../BuyCta";
import { getProbabilityNew } from "../../../Report/store/selectors";

const defaultConfiguredData = {}; // Default data for the chart set ourside compinent to prevent infinite oop on use effect when no data

const ProbabilitySale = forwardRef(
  (
    {
      isMobile,
      houseInfo,
      defPrice,
      privateUserHasReport,
      userIsPrivate,
      agencyUserHasSubscription,
    },
    ref
  ) => {
    const [configuredData, setConfiguredData] = useState({});
    useImperativeHandle(ref?.[0], () => ({ configuredData }));

    // SET FILTERS INITIALLY TO A DEFAULT VALUE
    const [filters, setFilters] = useState({
      ClasseEnergetica: "",
      Riscaldamento: "",
      StatoImmobile: "",
    });

    const [isDefault, setIsDefault] = useState(false);

    // THEN ONCE THE HOUSE INFO IS LOADED, SET THE FILTERS TO THE HOUSE INFO
    useEffect(() => {
      const sel2 = options2.find(
        (item) => item.value === houseInfo?.ClasseEnergetica
      );
      const sel3 = options3.find(
        (item) => item.value === houseInfo?.Riscaldamento
      );
      const sel4 = options4.find(
        (item) => item.value === houseInfo?.StatoInterni
      );
      setFilters({
        ...filters,
        ClasseEnergetica: sel2,
        Riscaldamento: sel3,
        StatoImmobile: sel4,
      });
    }, [houseInfo]);

    // CHANGE THE FILTERS ON SELECT
    const changeFilters = (field, value) => {
      setFilters({
        ...filters,
        [field]: value,
      });
    };

    const configuratore = useSelector(getProbabilityNew());
    // console.log(configuratore, "configuratore");

    // GET THE PRICE PROBABILITY ONCE THE HOUSE INFO IS LOADED
    useEffect(() => {
      if (
        houseInfo?.ClasseEnergetica == filters?.ClasseEnergetica?.value &&
        houseInfo?.Riscaldamento == filters?.Riscaldamento?.value &&
        houseInfo?.StatoInterni == filters?.StatoImmobile?.value
      ) {
        setIsDefault(true);
      } else {
        setIsDefault(false);
      }

      setConfiguredData(
        configuratore?.prices?.find(
          (item) =>
            item?.classe_energetica == filters?.ClasseEnergetica?.value &&
            item?.riscaldamento == filters?.Riscaldamento?.value &&
            item?.stato == filters?.StatoImmobile?.value
        ) || defaultConfiguredData
      );
    }, [filters, configuratore, houseInfo]);

    // CHECK IF THE HOUSE IS A VILLA OR A Villetta
    const checkingSelect = () => {
      if (houseInfo?.Tipologia == "Villetta") return true;
      if (houseInfo?.Tipologia == "Villa") return true;
      return false;
    };

    // BEST SELLING CARDS
    const bestSelling = [
      {
        id: "best_selling_1",
        period: 2,
        stima_text: "Stima minima di vendita consigliata",
        price: isDefault
          ? Math.round(defPrice?.price_min) || 0
          : Math.round(configuredData?.price_min) || 0,
        defaultPrice: Math.round(defPrice?.price_min) || 0,
      },
      {
        id: "best_selling_2",
        period: 6,
        stima_text: "Stima reale di vendita consigliata",
        price: isDefault
          ? Math.round(defPrice?.price) || 0
          : Math.round(configuredData?.price) || 0,
        defaultPrice: Math.round(defPrice?.price) || 0,
      },
      {
        id: "best_selling_3",
        period: 12,
        stima_text: "Stima massima di vendita consigliata",
        price: isDefault
          ? Math.round(defPrice?.price_max) || 0
          : Math.round(configuredData?.price_max) || 0,
        defaultPrice: Math.round(defPrice?.price_max) || 0,
      },
    ];

    const availableOptions = (options, field) => {
      return options?.slice(
        options?.indexOf(
          options?.find((item) => item.value === houseInfo?.[field])
        )
      );
    };

    // console.log(configuredData?.curves, "configuredData?.curves");

    return (
      <div
        className={clx(styles.probability__sale__wrapper, {
          [styles.probability__sale__mbl]: isMobile,
        })}
        ref={ref?.[1]}
      >
        {/* HEADER */}
        <SectionTitle
          title={"Pricing simulator"}
          subtitle={
            "Seleziona nuove caratteristiche dell'immobile e scopri come cambia il rapporto probabilità e prezzo di vendita. Poi confronta i nuovi prezzi consigliati con quelli della precedente configurazione dell'immobile per approfondire la tua analisi."
          }
          section={"pricing_simulator"}
          isOpposite={true}
          isDisabled={!userIsPrivate && !agencyUserHasSubscription}
        />
        {(userIsPrivate && !privateUserHasReport) ||
        (!userIsPrivate && !agencyUserHasSubscription) ? (
          <BuyCta />
        ) : (
          <>
            <div className={styles.characteristics__info}>
              <ReactToolTip
                contenet={
                  <div style={{ color: "gray" }}>
                    Confronta le caratteristiche dell’immobile con altre{" "}
                  </div>
                }
                containerClass="tooltipStyleChange"
                x={-87}
                y={70}
                width={289}
                height={77}
                arrow={{
                  x: -3,
                  y: 74,
                  rotate: "206deg",
                }}
              >
                <InfoIcon />
              </ReactToolTip>
              <p className={styles.characteristics__info__text}>
                Seleziona le nuove caratteristiche del tuo Immobile
              </p>
            </div>
            {/* SELECTS */}
            <div
              className={styles.characteristics__selectors}
              style={{ zIndex: 1000 }}
            >
              {/* CLASSE ENERGETICA */}
              <SingleSelect
                label="Classe energetica"
                options={availableOptions(options2, "ClasseEnergetica")}
                defOption={
                  options2.find(
                    (item) => item.value === houseInfo?.ClasseEnergetica
                  )?.label
                }
                value={filters.ClasseEnergetica}
                width={"calc(100% / 3)"}
                onChange={(data) => changeFilters("ClasseEnergetica", data)}
                isDisabled={
                  availableOptions(options2, "ClasseEnergetica").length === 1
                }
              />
              {/* RISCALDAMENTO */}
              {!checkingSelect() && (
                <SingleSelect
                  label="Riscaldamento"
                  display="none"
                  options={availableOptions(options3, "Riscaldamento")}
                  defOption={
                    options3.find(
                      (item) => item.value === houseInfo?.Riscaldamento
                    )?.label
                  }
                  value={filters.Riscaldamento}
                  width={"calc(100% / 3)"}
                  onChange={(data) => changeFilters("Riscaldamento", data)}
                  isDisabled={
                    availableOptions(options3, "Riscaldamento").length === 1
                  }
                />
              )}
              {/* STATO INTERNI */}
              <SingleSelect
                label="Stato dell'immobile"
                options={availableOptions(options4, "StatoInterni")}
                defOption={
                  options4.find(
                    (item) => item.value === houseInfo?.StatoInterni
                  )?.label
                }
                value={filters.StatoImmobile}
                onChange={(data) => changeFilters("StatoImmobile", data)}
                width={"calc(100% / 3)"}
                isDisabled={
                  availableOptions(options4, "StatoInterni").length === 1
                }
              />
            </div>
            {/* BEST SELLING CARDS */}
            <div className={styles.best__selling__cards}>
              {bestSelling.map((item) => (
                <BestSellingCard
                  key={item.id}
                  stima_text={item.stima_text}
                  period={item.period}
                  price={item.price}
                  defaultPrice={item.defaultPrice}
                />
              ))}
            </div>
            {/* CHART */}
            <ProbabilitySaleChart
              selected_curves={configuredData?.curves}
              base_curve={configuratore?.base_curve}
              isMobile={isMobile}
            />
          </>
        )}
      </div>
    );
  }
);

export default memo(ProbabilitySale);
