import { call, put } from "redux-saga/effects";

import { setError } from "../shared/Error/store/actions";
import {
  startLoader,
  stopLoader,
  stopReportLoader,
} from "../shared/Loader/store/actions";

export default function* (successAction, errorAction) {
  try {
    yield put(startLoader());
    yield call(successAction());
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          error?.message ||
          null
      )
    );
    yield put(
      errorAction(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.message ||
          null
      )
    );
    yield put(stopReportLoader());
  } finally {
    yield put(stopLoader());
  }
}
