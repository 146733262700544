import React from "react";

import { formatNumber } from "../../../../../../utils/formatNumber";
import styles from "./OmiTable.module.scss";

const OmiTable = ({ omiPrices }) => (
  // console.log("omiPrices", omiPrices),
  <div style={{ width: "100%", overflowX: "auto" }}>
    <table className={styles.table}>
      <thead>
        <tr className={styles.head}>
          <th rowSpan="2" style={{ borderTopLeftRadius: 30 }}>
            Tipologia
          </th>
          <th rowSpan="2">Stato conservativo</th>
          <th
            colSpan="2"
            scope="colgroup"
            style={{ borderBottom: "1px solid #DAEBEE" }}
          >
            Valore Mercato (€/m<sup>2</sup>)
          </th>
          <th
            colSpan="2"
            scope="colgroup"
            style={{
              borderTopRightRadius: 30,
              borderBottom: "1px solid #DAEBEE",
            }}
          >
            Valore Locazione (€/mq x mese)
          </th>
        </tr>
        <tr className={styles.head}>
          <th scope="col">Min</th>
          <th scope="col">Max</th>
          <th scope="col">Min</th>
          <th scope="col">Max</th>
        </tr>
      </thead>
      <tbody>
        {omiPrices?.map((item, index) => (
          <tr key={index}>
            <td>{item.Descr_Tipologia}</td>
            <td>{item.Stato}</td>
            <td>€ {formatNumber(item.Compr_min)}</td>
            <td>€ {formatNumber(item.Compr_max)}</td>
            <td>€ {formatNumber(item.Loc_min)}</td>
            <td>€ {formatNumber(item.Loc_max)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default OmiTable;
