import React from "react";
import { components, default as Select } from "react-select";
import selectFieldStyles from "./MultiSelect.module.scss";
import selectConditionals from "../../../utils/selectConditionals";

// REACT-SELECT STYLES
const colourStyles = {
  control: (styles) => ({
    ...styles,
    outline: `1px solid ${selectConditionals()?.mainColor}`,
    minHeight: "50px",
    borderRadius: "5px",
    cursor: "pointer",
    boxShadow: "none",
    ":hover": {
      borderColor: selectConditionals()?.mainColor,
      outline: `1.5px solid ${selectConditionals()?.mainColor}`,
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "6px 8px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: selectConditionals()?.secondaryColor,
    ":hover": {
      color: "#9d9d9d",
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: selectConditionals()?.secondaryColor,
    ":hover": {
      color: "#9d9d9d",
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "14px",
    color: selectConditionals()?.placeholderText,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? selectConditionals()?.actionColor
      : isFocused
      ? selectConditionals()?.actionColor
      : undefined,
    color: isDisabled
      ? "#ccc"
      : isSelected
      ? selectConditionals()?.secondaryColor
      : data.color,
    fontSize: "85%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    ":active": {
      backgroundColor: "inherit",
    },
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#fff7fa",
    backgroundColor: selectConditionals()?.mainColor,
    borderRadius: "0",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    padding: "6px",
    paddingLeft: "9px",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#fff7fa",
    backgroundColor: selectConditionals()?.mainColor,
    borderRadius: "0",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    ":hover": {
      cursor: "pointer",
    },
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: "5px",
    marginTop: "5px",
    borderBottom: `1px solid ${selectConditionals()?.mainColor}`,
    borderLeft: `1px solid ${selectConditionals()?.mainColor}`,
    borderRight: `1px solid ${selectConditionals()?.mainColor}`,
    borderTop: `1px solid ${selectConditionals()?.mainColor}`,
    boxShadow: "none",
  }),
  menuList: (styles) => ({
    ...styles,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px",
    padding: "10px",
    "@media only screen and (max-width: 500px)": {
      ...styles,
      gridTemplateColumns: "1fr",
    },
  }),
};

// Custom react-select option component
const Option = (props) => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      <label
        style={{
          cursor: "pointer",
          fontSize: "18px",
          marginBottom: "0",
          marginLeft: "10px",
        }}
      >
        {props.label}
      </label>
    </components.Option>
  </div>
);

// MULTI SELECT COMPONENT
const SelectField = ({
  field,
  form: { touched, errors, setFieldValue, setTouched, values },
  options,
  placeholder = "",
  className,
  label,
  border = "",
  height = "50px",
  optional,
  ...props
}) => {
  const isError = touched[field.name] && errors[field.name];

  // Multi select values Formik functions
  function onChange(option) {
    setFieldValue(field.name, option ? option.map((item) => item.value) : []);
  }

  const getValue = () => {
    if (options) {
      return options.filter((option) => field.value.indexOf(option.value) >= 0);
    } else {
      return [];
    }
  };

  return (
    <div className={selectFieldStyles.multiselect}>
      {/* Label */}
      {label && (
        <div className={selectFieldStyles.label_wrapper}>
          <label
            className={selectFieldStyles.field__label}
            htmlFor={field.name}
          >
            {label}
          </label>
          {optional && (
            <p
              style={{
                color: "#a3a3a3",
                fontSize: 14,
              }}
            >
              Opzionale
            </p>
          )}
        </div>
      )}
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        {...field}
        {...props}
        //name={field.name}
        value={getValue()}
        onChange={onChange}
        onBlur={() => setTouched({ ...touched, [field.name]: true })}
        options={options}
        isMulti={true}
        placeholder={placeholder}
        styles={colourStyles}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
      />

      {/* Validation Error div */}
      {isError && (
        <div className={selectFieldStyles.error}>{errors[field.name]}</div>
      )}
    </div>
  );
};

export default SelectField;
