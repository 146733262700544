import React from "react";
import styles from "./SearchBar.module.scss";
import Filters from "./Filters";
import Form from "./Form";

const SearchBar = () => {
  return (
    <div className={styles.admin_search_bar}>
      {/* FILTERS */}
      <Filters />
      {/* FORM */}
      <Form />
    </div>
  );
};

export default SearchBar;
