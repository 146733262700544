import React from "react";
import styles from "../../Download.module.scss";
import platformConditionals from "../../../../utils/platformConditionals";

// Higher Order Component wrapper for the pages
const Index = React.forwardRef(
  ({ children, date, height, visibleClass }, ref) => (
    <div
      ref={ref}
      className={`${styles.page} ${visibleClass || ""}`}
      style={{ height }}
    >
      <div className={styles.page__logo}>
        <img
          src={platformConditionals()?.bannerPdfHoc || ""}
          alt="header-logo"
          style={{ width: platformConditionals()?.pdfLogoSize }}
        />
      </div>
      {children}
      <div className={styles.page__footer}>
        <p className={styles.footer__date}>
          Stima realizzata il <b>{date}</b>
        </p>
        <p className={styles.footer__number}></p>
      </div>
    </div>
  )
);

export default Index;
