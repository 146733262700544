import clx from "classnames";
import React from "react";
import "./SecondPgLeaflet.scss";
import styles from "./SecondPg.module.scss";

import { ReactComponent as Condominium } from "../../../../assets/svg/condominium.svg";
import { ReactComponent as MarkerCurrent } from "../../../../assets/svg/pin.svg";
import { ReactComponent as Room } from "../../../../assets/svg/room.svg";
import { ReactComponent as Sketch } from "../../../../assets/svg/sketch.svg";
import buildAddressString from "../../../../utils/buildAddress";
import formatToEuro from "../../../../utils/formatToEuro";
import LeafletMap from "../../../Common/LeafletMap";
import platformConditionals from "../../../../utils/platformConditionals";

const SecondPg = ({ houseInfo, completeSurface }) => {
  // GOOGLE MAP STATIC MAP IMAGE
  // const [staticMap, setStaticMap] = useState("");
  // const getStaticMap = (lat, lng) =>
  //   `https://maps.googleapis.com/maps/api/staticmap?zoom=14&size=450x450&markers=color:0x${
  //     platformConditionals()?.pdfHomeIconColor
  //   }%7C${lat},${lng}&key=AIzaSyDhU_uaWxC4hWjLecwUvBW7RzqauLXfpVw&style=saturation:-100&scale=2&maptype=roadmap`;

  // useEffect(() => {
  //   const lat = houseInfo.Lat,
  //     lng = houseInfo.Lng;
  //   if (lat && lng) {
  //     const map = getStaticMap(lat, lng);
  //     setStaticMap(map);
  //   }
  // }, [houseInfo]);

  // Object mapping to extract field names in an array if on report page
  const filteredItems = [];
  [
    "Immobile Di Lusso",
    "Portineria",
    "Immobile A Zero Emissioni",
    "Campo Tennis",
    "Piscina",
    "Jacuzzi",
    "Pompa Di Calore",
    // "Aria Condizionata",
    "Esposizione Interna",
    "Esposizione Esterna",
    "Camino",
  ].forEach((key) => {
    if (houseInfo?.[key.replace(/\s+/g, "")] != "No") {
      filteredItems.push(key);
    }
  });

  // Check how many surfaces are present to determine map size, to avoid page to small
  const mappingForMapSize =
    [
      "SuperficieGiardinoPrivato",
      "SuperficieGiardinoCondominiale",
      "SuperficieBalconi",
      "SuperficieTerrazzo",
      "SuperficieSoffitta",
      "SuperficieCantina",
      "SuperficieSeminterrato",
      "SuperficiePostoAuto",
    ].filter((item) => houseInfo?.[item] != 0).length > 6;

  // console.log(mappingForMapSize, "mappingForMapSize");

  return (
    <div
      className={styles.second_page}
      style={{ paddingTop: mappingForMapSize && 30 }}
      id="second_page"
    >
      {/* CARATTERISTICHE IMMOBILE */}
      <div className={styles.second_page__characteristichs}>
        {/* STRUTTURA INTERNI */}
        <div
          className={clx(
            styles.characteristichs__structure,
            styles.characteristichs__wrapper
          )}
        >
          <div className={styles.characteristichs__title}>
            <Room /> <b>Caratteristiche Immobile</b>
          </div>
          <div className={styles.second_page__grid}>
            {/* TIPO DI PROPRIETA */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Tipo di proprietà
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.Tipologia || ""}
              </div>
            </div>
            {/* PIANI DELL'IMMOBILE */}
            {houseInfo.Tipologia != "Villa" &&
              houseInfo.Tipologia != "Villetta" && (
                <>
                  <div className={styles.property_card}>
                    <div className={styles.property_card_header}>
                      Piani dell&apos;immobile
                    </div>
                    <div className={styles.property_card_content}>
                      {houseInfo?.PianiCasa !== undefined &&
                      houseInfo?.PianiCasa !== null
                        ? +houseInfo?.PianiCasa
                        : "-"}
                    </div>
                  </div>
                </>
              )}
            {/* CLASSE EFFICIENZA ENERGETICA */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Classe energetica
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.ClasseEnergetica || ""}
              </div>
            </div>
            {/* SISTEMA DI RISCALDAMENTO */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>Riscaldamento</div>
              <div className={styles.property_card_content}>
                {houseInfo?.Riscaldamento || ""}
              </div>
            </div>
            {/* TIPOLOGIA RISCALDAMENTO */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Tipologia riscaldamento
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.TipologiaRiscaldamento || ""}
              </div>
            </div>
            {/* ALIMENTAZIONE RISCALDAMENTO */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Alimentazione riscaldamento
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.AlimentazioneRiscaldamento || ""}
              </div>
            </div>
            {/* SISTEMA ARIA CONDIZIONATA */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Sistema aria condizionata
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.SistemaAriaCondizionata || ""}
              </div>
            </div>
            {/* CONDIZIONI */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Stato degli interni
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.StatoInterni || ""}
              </div>
            </div>
            {/* ARREDAMENTO */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>Arredamento</div>
              <div className={styles.property_card_content}>
                {houseInfo?.Arredamento || ""}
              </div>
            </div>
            {/* NUMERO DI STANZE */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Numero di stanze
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.Locali !== undefined && houseInfo?.Locali !== null
                  ? +houseInfo?.Locali
                  : "-"}
              </div>
            </div>
            {/* BAGNI */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>Numero di bagni</div>
              <div className={styles.property_card_content}>
                {houseInfo?.Bagni !== undefined && houseInfo?.Bagni !== null
                  ? +houseInfo?.Bagni
                  : "-"}
              </div>
            </div>
            {/* CUCINA */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>Cucina</div>
              <div className={styles.property_card_content}>
                {houseInfo?.Cucina || ""}
              </div>
            </div>
            {/* BALCONE */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>Balconi</div>
              <div className={styles.property_card_content}>
                {houseInfo?.Balconi || ""}
              </div>
            </div>
            {/* TERRAZZI */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>Terrazzo</div>
              <div className={styles.property_card_content}>
                {houseInfo?.Terrazzo || ""}
              </div>
            </div>
            {/* GIARDINO */}
            {/* {houseInfo.Tipologia != "Attico" && (
              <> */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Giardino privato
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.GiardinoPrivato || ""}
              </div>
            </div>
            {/* </>
            )} */}
            {/* CANTINA */}
            {(houseInfo.Tipologia == "Appartamento" ||
              houseInfo.Tipologia == "Attico") && (
              <>
                <div className={styles.property_card}>
                  <div className={styles.property_card_header}>Cantina</div>
                  <div className={styles.property_card_content}>
                    {houseInfo?.Cantina || ""}
                  </div>
                </div>
              </>
            )}
            {/* SOFFITTA */}
            {houseInfo.Tipologia != "Attico" && (
              <>
                <div className={styles.property_card}>
                  <div className={styles.property_card_header}>Soffitta</div>
                  <div className={styles.property_card_content}>
                    {houseInfo?.Soffitta || ""}
                  </div>
                </div>
              </>
            )}
            {/* SEMINTERRATO */}
            {houseInfo.Tipologia != "Attico" && (
              <>
                <div className={styles.property_card}>
                  <div className={styles.property_card_header}>
                    Seminterrato
                  </div>
                  <div className={styles.property_card_content}>
                    {houseInfo?.Seminterrato || ""}
                  </div>
                </div>
              </>
            )}
            {/* ALTRE CARATTERISTICHE */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Altre caratteristiche
              </div>
              <div className={styles.property_card_content}>
                {filteredItems.length
                  ? filteredItems.map((item, index) => (
                      <React.Fragment key={index}>
                        {item}
                        {index === filteredItems.length - 1 ? "" : ", "}
                      </React.Fragment>
                    ))
                  : "-"}
              </div>
            </div>
          </div>
        </div>

        {/* ESTERNI */}
        <div
          className={clx(
            styles.characteristichs__external,
            styles.characteristichs__wrapper
          )}
        >
          <div className={styles.characteristichs__title}>
            <Condominium /> <b>Esterni / Condominio</b>
          </div>
          <div className={styles.second_page__grid}>
            {/* PIANI DEL PALAZZO */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Piani{" "}
                {houseInfo?.Tipologia == "Appartamento" ||
                houseInfo?.Tipologia == "Attico"
                  ? "del palazzo"
                  : "della villa"}
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.TotalePiani !== undefined &&
                houseInfo?.TotalePiani !== null
                  ? +houseInfo?.TotalePiani
                  : "-"}
              </div>
            </div>
            {/* PIANO IMMOBILE */}
            {houseInfo?.Tipologia == "Appartamento" && (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Piano dell&apos;immobile
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.Piano !== undefined && houseInfo?.Piano !== null
                    ? +houseInfo?.Piano
                    : "-"}
                </div>
              </div>
            )}
            {/* ASCENSORE */}
            {houseInfo?.Tipologia != "Villa" &&
              houseInfo?.Tipologia != "Villetta" && (
                <div className={styles.property_card}>
                  <div className={styles.property_card_header}>Ascensore</div>
                  <div className={styles.property_card_content}>
                    {houseInfo?.Ascensore || ""}
                  </div>
                </div>
              )}
            {/* GIARDINO CONDOMINIALE */}
            {houseInfo?.Tipologia != "Villa" &&
              houseInfo?.Tipologia != "Villetta" && (
                <div className={styles.property_card}>
                  <div className={styles.property_card_header}>
                    Giardino condominiale
                  </div>
                  <div className={styles.property_card_content}>
                    {houseInfo?.GiardinoCondominiale || ""}
                  </div>
                </div>
              )}
            {/* POSTO AUTO */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>Posto auto</div>
              <div className={styles.property_card_content}>
                {houseInfo?.PostoAuto?.join(", ") || ""}
              </div>
            </div>
            {/* ANNO DI COSTRUZIONE */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Anno di costruzione
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.AnnoCostruzione || ""}
              </div>
            </div>
            {/* COSTI PROPRIETA */}
            {houseInfo?.CostiProprieta !== 0 && (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Costi della proprietà
                </div>
                <div className={styles.property_card_content}>
                  € {formatToEuro(houseInfo?.CostiProprieta) || 0}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* ALTRO */}
        <div
          className={clx(
            styles.characteristichs__surfaces,
            styles.characteristichs__wrapper
          )}
        >
          <div className={styles.characteristichs__title}>
            <Sketch /> <b>Analisi delle superfici in m²</b>
          </div>
          <div className={styles.second_page__grid}>
            {/* SURFACE CALPESTABILE */}
            <div className={styles.property_card}>
              <div className={styles.property_card_header}>
                Superficie calpestabile
              </div>
              <div className={styles.property_card_content}>
                {houseInfo?.Superficie || ""}
              </div>
            </div>
            {/* SUPERFICIE GIARDINO PRIVATO */}
            {houseInfo?.SuperficieGiardinoPrivato !== 0 ? (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Superficie giardino privato
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.SuperficieGiardinoPrivato || ""}
                </div>
              </div>
            ) : null}
            {/* SUPERFICIE GIARDINO CONDOMINIALE */}
            {houseInfo?.SuperficieGiardinoCondominiale !== 0 ? (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Superficie giardino condominiale
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.SuperficieGiardinoCondominiale || ""}
                </div>
              </div>
            ) : null}
            {/* SUPERFICIE BALCONI */}
            {houseInfo?.SuperficieBalconi !== 0 ? (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Superficie balconi
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.SuperficieBalconi || ""}
                </div>
              </div>
            ) : null}
            {/* SUPERFICIE TERRAZZO */}
            {houseInfo?.SuperficieTerrazzo !== 0 ? (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Superficie terrazzo
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.SuperficieTerrazzo || ""}
                </div>
              </div>
            ) : null}
            {/* SUPERFICIE SOFFITTA */}
            {houseInfo?.SuperficieSoffitta !== 0 ? (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Superficie soffitta
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.SuperficieSoffitta || ""}
                </div>
              </div>
            ) : null}
            {/* SUPERFICIE CANTINA */}
            {houseInfo?.SuperficieCantina !== 0 ? (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Superficie cantina
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.SuperficieCantina || ""}
                </div>
              </div>
            ) : null}
            {/* SUPERFICIE SEMINTERRATO */}
            {houseInfo?.SuperficieSeminterrato !== 0 ? (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Superficie seminterrato
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.SuperficieSeminterrato || ""}
                </div>
              </div>
            ) : null}
            {/* SUPERFICIE POSTO AUTO */}
            {houseInfo?.SuperficiePostoAuto !== 0 ? (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Superficie posto auto privato
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.SuperficiePostoAuto || ""}
                </div>
              </div>
            ) : null}
            {/* SUPERFICIE POSTO AUTO CONDOMINIALE */}
            {houseInfo?.SuperficiePostoAutoCondominiale !== 0 ? (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Superficie posto auto condominiale
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.SuperficiePostoAutoCondominiale || ""}
                </div>
              </div>
            ) : null}
            {/* SUPERFICIE GARAGE */}
            {houseInfo?.SuperficiePostoAutoGarage !== 0 ? (
              <div className={styles.property_card}>
                <div className={styles.property_card_header}>
                  Superficie posto auto garage
                </div>
                <div className={styles.property_card_content}>
                  {houseInfo?.SuperficiePostoAutoGarage || ""}
                </div>
              </div>
            ) : null}
            {/* TOTAL SURFACE */}
            {completeSurface && (
              <div className={styles.total_surface}>
                Viene valutata una superficie totale commerciale di{" "}
                <b>{completeSurface.toFixed(0)} m²</b>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* ZONA DI RIFERIMENTO */}
      <div
        className={clx(
          styles.second_page__location,
          styles.second_page__section
        )}
      >
        <div className={styles.second_page__location_details}>
          <h3 className={styles.second_page__title}>Zona di riferimento</h3>
          <div className={styles.description}>
            La mappa mostra la posizione dell&apos;immobile e l&apos;area che
            compone la sua zona di riferimento.
          </div>
          <div className={styles.address}>
            <div className={styles.address_title}>
              <MarkerCurrent /> <b>Indirizzo:</b>
            </div>
            <p>{buildAddressString(houseInfo)}</p>
          </div>
        </div>
        <LeafletMap
          lat={houseInfo?.Lat}
          lng={houseInfo?.Lng}
          //           svgIconHome={`<svg version="1.1" id="Layer_1" x="0px" y="0px"
          //     viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          //  <g>
          //    <g>
          //      <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5
          //        c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
          //        C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333
          //        s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z" fill="currentcolor"/>
          //    </g>
          //  </g>
          //  </svg>

          //     `}
          imgIconHome={platformConditionals()?.staticMapPin}
          iconSize={[36, 36]}
          className="svg-icon-leaflet"
          zoom={15}
        />
        {/* <img
          className={styles.map}
          src={staticMap}
          alt="static-map"
          style={{ height: mappingForMapSize && 185 }}
        /> */}
      </div>
    </div>
  );
};

export default SecondPg;
