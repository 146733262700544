import { useEffect, useState } from "react";

const useShowNav = (isHovering) => {
  const [showNav, setShowNav] = useState(false);
  let currentScrollPos = 0;
  let timeoutId;

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset != 0 && window.pageYOffset != currentScrollPos) {
        setShowNav(true);
        clearTimeout(timeoutId); // Clear the timeout
        timeoutId = setTimeout(() => {
          setShowNav(false);
        }, 2000);
      } else {
        setShowNav(false);
      }
      currentScrollPos = window.pageYOffset;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId); // Clear the timeout on component unmount
    };
  }, [isHovering]);

  // Clear timeout if user mouses out
  useEffect(() => {
    if (!isHovering) {
      timeoutId = setTimeout(() => {
        setShowNav(false);
      }, 2000);
    } else {
      clearTimeout(timeoutId);
    }
    return () => clearTimeout(timeoutId);
  }, [isHovering]);

  return { showNav };
};

export default useShowNav;
