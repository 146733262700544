import * as yup from "yup";

export const STEPS = [
  {
    step: 1,
    requiredFields: ["agency_name", "user_name", "piva"],
  },
  {
    step: 2,
    requiredFields: ["email", "pwd", "pwdConfirmation", "acceptPolicy"],
  },
];

export const INITIAL_VALUES = {
  is_private: false,
  agency_name: "",
  user_name: "",
  piva: "",
  email: "",
  pwd: "",
  pwdConfirmation: "",
  acceptPolicy: false,
};

export const VALIDATION_SCHEMA = yup.object().shape({
  is_private: yup
    .string("Inserisci un valore")
    .required("Tipo di registrazione richiesta"),
  agency_name: yup
    .string("Inserisci il nome della tua azienda")
    .when("$values", (anyName, schema) =>
      !anyName.is_private ? schema.required("Nome azienda richiesto") : schema
    ),
  user_name: yup.string("Inserisci il tuo nome").required("Nome richiesto"),
  piva: yup
    .string("Inserisci la P.IVA ")
    .when("$values", (anyName, schema) =>
      !anyName.is_private ? schema.required("P.IVA richiesta") : schema
    ),
  email: yup
    .string("Enter your email")
    .email("Attenzione! La email è errata")
    .required("Email richiesta"),
  pwd: yup
    .string()
    .min(3, "Password è troppo corta")
    .max(100, "Password troppo lunga")
    .required("Attenzione! La password è errata"),
  pwdConfirmation: yup
    .string()
    .oneOf([yup.ref("pwd"), null], "Le password devono coincidere")
    .min(3, "La password è troppe breve")
    .max(100, "La password è troppo lunga!")
    .required("Attenzione! La password è errata"),
  acceptPolicy: yup
    .boolean()
    .required(
      "Bisogna accettare l 'informativa sulla privacy percontinuare con la registrazione"
    )
    .oneOf(
      [true],
      "Bisogna accettare l 'informativa sulla privacy percontinuare con la registrazione"
    ),
});
