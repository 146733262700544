import clx from "classnames";

import MaleFemaleIcon from "../../../../../../assets/svg/male-female.svg";
import chartStyles from "../../../../../Common/Charts/Charts.module.scss";
import CircleProgressBar from "../../../../../Common/Charts/CircleProgressBar";
import PdfCheckbox from "../../../PdfCheckbox";
import styles from "./GenderDistributionArea.module.scss";

const GenderDistributionArea = ({
  data,
  marginTop,
  minHeight,
  isPdf = false,
}) => {
  // console.log(data, "data gender distribution area");
  const roundFemale = Math.round(data?.data?.cat_area[1] * 100);
  // const roundMale = Math.round(male * 100);

  return (
    <div
      className={`${styles.gender__distribution__area__wrapper} ${chartStyles.chart__wrapper}`}
      style={{ minHeight }}
    >
      {!isPdf && <PdfCheckbox section={"gender_distribution"} />}
      <b>{data?.title}</b>
      <div className={styles.circle__wrapper} style={{ marginTop }}>
        <CircleProgressBar
          percentage={roundFemale}
          radius={100}
          icon={MaleFemaleIcon}
          label={["Uomo", "Donna"]}
        />
      </div>
      <p
        className={clx(chartStyles.chart__source, styles.chart__source)}
        style={{
          position: "absolute",
          bottom: "20px",
          right: "30px",
        }}
      >
        {data?.description}
      </p>
    </div>
  );
};

export default GenderDistributionArea;
