import React from "react";
import ArrowNegative from "../../../../assets/svg/arrow-negative.svg";
import ArrowPositive from "../../../../assets/svg/arrow-positive.svg";
import { ReactComponent as TagIcon2mesi } from "../../../../assets/svg/tag/tag2mesi.svg";
import { ReactComponent as TagIcon6mesi } from "../../../../assets/svg/tag/tag6mesi.svg";
import { ReactComponent as TagIcon12mesi } from "../../../../assets/svg/tag/tag12mesi.svg";
import formatToEuro from "../../../../utils/formatToEuro";
import styles from "./AgentInputs.module.scss";
import clx from "classnames";

const AgentInputs = ({
  pdfItems,
  soldProperties,
  prezzoMq,
  prezzo,
  pdfSimulator,
  defPriceMin,
  defPrice,
  defPriceMax,
  agentCharacteristics,
  isPrivate,
}) => {
  // BEST SELLING CARDS
  const bestSelling = [
    {
      period: 2,
      stima_text: "Stima minima di vendita consigliata",
      price: Math.round(pdfSimulator?.price_min) || 0,
      defaultPrice: Math.round(defPriceMin) || 0,
    },
    {
      period: 6,
      stima_text: "Stima reale di vendita consigliata",
      price: Math.round(pdfSimulator?.price) || 0,
      defaultPrice: Math.round(defPrice) || 0,
    },
    {
      period: 12,
      stima_text: "Stima massima di vendita consigliata",
      price: Math.round(pdfSimulator?.price_max) || 0,
      defaultPrice: Math.round(defPriceMax) || 0,
    },
  ];

  const activeSections = Object.keys(pdfItems || {})
    ?.filter((key) =>
      [
        "sold_properties",
        "pricing_simulator",
        "agent_characteristics",
      ].includes(key)
    )
    .filter((key) =>
      key == "pricing_simulator" ? pdfItems[key] === 1 : pdfItems[key] === 0
    );
  // console.log("activeSections", activeSections);

  return (
    <div
      className={styles.agent_inputs}
      style={{
        gap:
          (activeSections?.length == 2 ||
            (!soldProperties.length && !agentCharacteristics.length)) &&
          60,
        // paddingTop: activeSections?.length == 2 && 60,
      }}
    >
      {/* SOLD PROPERTIES */}
      {soldProperties.length > 0 &&
        !pdfItems?.["sold_properties"] &&
        isPrivate != 1 && (
          <div className={styles.agent_inputs__sold_properties}>
            <h3 className={styles.header__text}>Immobili similari venduti</h3>
            <p className={styles.secondary__text}>
              Di seguito una selezione di alcuni immobili simili a quello
              analizzato venduti in precedenza
            </p>

            <div className={styles.table_container}>
              <table className={styles.table}>
                <thead>
                  <tr className={styles.head}>
                    <th rowSpan="2" style={{ borderTopLeftRadius: 10 }}>
                      Tipologia
                    </th>
                    <th
                      colSpan="1"
                      style={{ borderBottom: "1px solid #DAEBEE" }}
                    >
                      Prezzo <br /> (€)
                    </th>
                    <th
                      colSpan="1"
                      style={{ borderBottom: "1px solid #DAEBEE" }}
                    >
                      Prezzo unitario <br /> (€/m<sup>2</sup>)
                    </th>
                    <th
                      rowSpan="2"
                      style={{ borderBottom: "1px solid #DAEBEE" }}
                    >
                      Classe <br /> energetica
                    </th>
                    <th
                      colSpan="1"
                      style={{ borderBottom: "1px solid #DAEBEE" }}
                    >
                      Superficie <br /> (m<sup>2</sup>)
                    </th>
                    <th
                      colSpan="1"
                      style={{
                        borderTopRightRadius: 10,
                        borderBottom: "1px solid #DAEBEE",
                      }}
                    >
                      Distanza <br /> (m)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {soldProperties?.map((item, index) => (
                    <tr key={`sold_prop-${index}`}>
                      {/* TIPOLOGIA */}
                      <td colSpan="1">{item?.Tipologia}</td>
                      {/* PREZZO */}
                      <td colSpan="1">
                        <div className={styles.price_column}>
                          <b>€ {formatToEuro(item?.Prezzo)}</b>{" "}
                          <img
                            src={
                              item?.Prezzo <= prezzo
                                ? ArrowPositive
                                : ArrowNegative
                            }
                            alt="sold-icon"
                            className={styles.arrow_img}
                          />
                        </div>
                      </td>
                      {/* PREZZO MQ */}
                      <td colSpan="1">
                        <div className={styles.price_column}>
                          <b>
                            €/m<sup>2</sup> {formatToEuro(item?.PrezzoMq)}
                          </b>{" "}
                          <img
                            src={
                              item?.PrezzoMq <= prezzoMq
                                ? ArrowPositive
                                : ArrowNegative
                            }
                            alt="sold-icon"
                            className={styles.arrow_img}
                          />
                        </div>
                      </td>
                      {/* CLASSE ENERGETICA */}
                      <td colSpan="1">{item?.ClasseEnergetica}</td>
                      {/* SUPERFICIE */}
                      <td colSpan="1">
                        {Number(item?.Superficie)?.toFixed(0)} m<sup>2</sup>
                      </td>
                      {/* DISTANZA */}
                      <td colSpan="1">
                        {Number(item?.Distanza)?.toFixed(0)} m
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className={styles.legend}>
                <div className={styles.legend__text}>
                  *{" "}
                  <img
                    src={ArrowPositive}
                    alt="comparison-icon"
                    className={styles.arrow_img}
                  />{" "}
                  immobile simile è più economico al m<sup>2</sup>
                </div>
                <div className={styles.legend__text}>
                  *{" "}
                  <img
                    src={ArrowNegative}
                    alt="comparison-icon"
                    className={styles.arrow_img}
                  />{" "}
                  immobile simile è più costoso al m<sup>2</sup>
                </div>
              </div>
            </div>
          </div>
        )}

      {/* AGENT CHARACTERISTICS */}
      {agentCharacteristics?.length > 0 &&
        !pdfItems?.["agent_characteristics"] && (
          <div className={styles.agent_inputs__characteristics}>
            <h3 className={styles.header__text}>
              Caratteristiche della proprietà in vendita
            </h3>
            <p className={styles.secondary__text}>
              Elementi che incidono sul valore dell'immobile
            </p>
            <div className={styles.agent_inputs__characteristics__cards}>
              {agentCharacteristics?.map((item, index) => (
                <div
                  key={`agent-char-${index}`}
                  className={clx(
                    styles.agent_inputs__characteristics__cards__item,
                    styles[item.type]
                  )}
                >
                  {item.characteristic}
                </div>
              ))}
            </div>
          </div>
        )}

      {/* PRICING SIMULATOR */}
      {Object.keys(pdfSimulator || {}).length > 0 &&
        pdfItems?.["pricing_simulator"] == 1 && (
          <div className={styles.agent_inputs__pricing_simulator}>
            <h3 className={styles.header__text}>Pricing simulator</h3>
            <p className={styles.secondary__text}>
              Di seguito come cambia il rapporto probabilità e prezzo di
              vendita, in base alle caratteristiche selezionate nel Pricing
              simulator.
            </p>
            <div className={styles.pricing_simulator__content}>
              <div className={styles.pricing_simulator__content__options}>
                <div className={styles.section_header}>
                  Caratteristiche selezionate
                </div>
                <div
                  className={styles.pricing_simulator__content__options__list}
                >
                  <div
                    className={
                      styles.pricing_simulator__content__options__list__item
                    }
                  >
                    <div className={styles.title}>Classe energetica</div>
                    <div className={styles.content}>
                      {pdfSimulator?.classe_energetica}
                    </div>
                  </div>
                  <div
                    className={
                      styles.pricing_simulator__content__options__list__item
                    }
                  >
                    <div className={styles.title}>Riscaldamento</div>
                    <div className={styles.content}>
                      {pdfSimulator?.riscaldamento}
                    </div>
                  </div>
                  <div
                    className={
                      styles.pricing_simulator__content__options__list__item
                    }
                  >
                    <div className={styles.title}>Stato immobile</div>
                    <div className={styles.content}>{pdfSimulator?.stato}</div>
                  </div>
                </div>
              </div>
              <div className={styles.pricing_simulator__content__cards}>
                <div className={styles.section_header}>Stime reali</div>
                <div
                  className={
                    styles.pricing_simulator__content__cards__container
                  }
                  // style={{
                  //   flexDirection: activeSections?.length == 2 && "columt",
                  // }}
                >
                  {bestSelling.map((card, index) => (
                    <div
                      className={clx(styles.best__selling__card, {
                        [styles.medium_card]: card.period === 6,
                      })}
                      key={`${index}-pricing-sim-card`}
                    >
                      <div className={styles.first__row}>
                        {card.period === 2 && (
                          <div
                            className={clx(
                              styles.icon__wrapper,
                              styles.icon_two_months
                            )}
                          >
                            <TagIcon2mesi />
                          </div>
                        )}
                        {card.period === 6 && (
                          <div
                            className={clx(
                              styles.icon__wrapper,
                              styles.icon_six_months
                            )}
                          >
                            <TagIcon6mesi />
                          </div>
                        )}
                        {card.period === 12 && (
                          <div
                            className={clx(
                              styles.icon__wrapper,
                              styles.icon_twelve_months
                            )}
                          >
                            <TagIcon12mesi />
                          </div>
                        )}
                        <p className={styles.first__row__text}>
                          {card.stima_text}
                        </p>
                        {/* If prices and default prices are different show tooltip with difference in percentage */}
                        {100 -
                          ((Math.round(card.price / 1000) * 1000) /
                            (Math.round(card.defaultPrice / 1000) * 1000)) *
                            100 !=
                        0.0 ? (
                          <div
                            className={
                              100 -
                                ((Math.round(card.price / 1000) * 1000) /
                                  (Math.round(card.defaultPrice / 1000) *
                                    1000)) *
                                  100 <
                              0
                                ? styles.percent_box_positive
                                : styles.percent_box_negative
                            }
                          >
                            {100 -
                              ((Math.round(card.price / 1000) * 1000) /
                                (Math.round(card.defaultPrice / 1000) * 1000)) *
                                100 <
                            0
                              ? `+${(
                                  ((Math.round(card.price / 1000) * 1000) /
                                    (Math.round(card.defaultPrice / 1000) *
                                      1000)) *
                                    100 -
                                  100
                                ).toFixed(2)}%`
                              : `${(
                                  ((Math.round(card.price / 1000) * 1000) /
                                    (Math.round(card.defaultPrice / 1000) *
                                      1000)) *
                                    100 -
                                  100
                                ).toFixed(2)}%`}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <p className={styles.price}>
                        {card.price != 0 ? (
                          <>
                            €{" "}
                            {`${Math.round(card.price / 1000) * 1000}`.replace(
                              /(?!^)(?=(?:\d{3})+(?:\.|$))/gm,
                              "."
                            )}
                          </>
                        ) : (
                          <span>Nessun dato disponibile</span>
                        )}
                      </p>

                      <p className={styles.second_row_test}>
                        Configurazione base
                      </p>
                      <p className={styles.second_row_price}>
                        €{" "}
                        {`${
                          Math.round(card.defaultPrice / 1000) * 1000
                        }`.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ".")}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default AgentInputs;
