import react from "react";
import { useDispatch, useSelector } from "react-redux";

import MenuIcon from "../../../assets/svg/menu.svg";
import { actions } from "../../Auth/store";
import { getUser } from "../../Auth/store/selectors";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import Modal from "../../Common/Modal";
import styles from "./UploadAvatarModal.module.scss";

function UploadAvatarModal({
  isUploadFileModalOpen,
  setIsUploadFileModalOpen,
}) {
  const dispatch = useDispatch();
  const user = useSelector(getUser());
  const [selectedFile, setSelectedFile] = react.useState();
  const [selectedFileError, setSelectedFileError] = react.useState(false);

  const uploadFile = async () => {
    const getBase64 = (file) =>
      new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          //('Error: ', error);
        };
      });
    if (!selectedFile) {
      setSelectedFileError(true);
      return;
    }
    const base64File = await getBase64(selectedFile);
    await dispatch(
      actions.ADD_LOGO_AGENCY.REQUEST({ base64: [base64File] }, (data) => {
        window.location.reload();
        setIsUploadFileModalOpen(false);
        uploadFile();
      })
    );
  };

  //upload log after selected
  react.useEffect(() => {
    // dispatch(actions.CHECK_USER_CREDITS.REQUEST());
    if (selectedFile) uploadFile();
  }, [selectedFile]);

  return (
    <Modal
      padding="40px"
      showCloseBtn={() => setIsUploadFileModalOpen(false)}
      isModalOpen={isUploadFileModalOpen}
      setIsModalOpen={() => setIsUploadFileModalOpen(false)}
    >
      <div className={styles["report-modal__content"]}>
        <h1>Carica il tuo logo</h1>
        <p>Carica un immagine come logo della tua agenzia</p>
        <div className={styles["carica_file"]}>
          {selectedFile && (
            <p className={styles["carica_file_name"]}>{selectedFile.name}</p>
          )}

          <label htmlFor="files" className={styles["carica_file_button"]}>
            Seleziona il logo
          </label>
          <input
            type="file"
            id="files"
            style={{ display: "none" }}
            onChange={({
              target: {
                files: [file],
              },
            }) => setSelectedFile(file)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default UploadAvatarModal;
