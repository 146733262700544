import { call, put, takeLatest } from "redux-saga/effects";
import { Api } from "../../../entries/ApiTransport";
import sagaAssessor from "../../../utils/sagaAssessor";
import { actions, types } from "./actions";
import platformConditionals from "../../../utils/platformConditionals";
import platformCheck from "../../../utils/platformCheck";

const api = Api.getInstance();

const signUp = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post(`/auth/agencySignUp?owner=${payload.owner}`, payload)
        );
        yield put(actions.SIGN_UP.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.SIGN_IN.FAILED(err)
  );

const signIn = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post(
            `/auth/login?owner=${platformConditionals()?.owner || ""}`,
            payload
          )
        );

        if (
          platformCheck(data?.user?.owner) == process.env.REACT_APP_PLATFORM
        ) {
          yield put(actions.SIGN_IN.SUCCESS());
          callback && typeof callback === "function" && callback();
        } else {
          throw new Error("wrongPlatform");
        }
      },
    (err) => actions.SIGN_IN.FAILED(err)
  );

const forgotPassword = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post("/auth/forgotPassword", payload)
        );
        yield put(actions.FORGOT_PASSWORD.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.FORGOT_PASSWORD.FAILED(err)
  );

const changePassword = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post("/user/changePassword", payload)
        );
        yield put(actions.CHANGE_PASSWORD.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.CHANGE_PASSWORD.FAILED(err)
  );

const resetPassword = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.put("/auth/forgotPassword", payload)
        );
        yield put(actions.RESET_PASSWORD.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.RESET_PASSWORD.FAILED(err)
  );

const getUser = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get("/auth/user"));
        yield put(actions.FETCH_USER.SUCCESS(data.data));
        callback && typeof callback === "function" && callback(data);
      },
    (err) => actions.FETCH_USER.FAILED(err)
  );

const editAccount = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.put("/auth/user", payload));
        yield put(actions.EDIT_ACCOUNT.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.EDIT_ACCOUNT.FAILED(err)
  );

const addLogoAgency = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.put("/user/changePhoto", payload)
        );
        yield put(actions.ADD_LOGO_AGENCY.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.ADD_LOGO_AGENCY.FAILED(err)
  );

const logOut = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        // localStorage.removeItem("jwtToken");
        Object.keys(localStorage)
          .filter(
            (item) =>
              item.includes("searchText") ||
              item.includes("filterList") ||
              item.includes("sortingOptions")
          )
          .forEach((item) => {
            localStorage.removeItem(`${item}`);
          });

        const { data } = yield call(() => api.get("/auth/logout"));
        yield put(actions.LOG_OUT.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.LOG_OUT.FAILED(err)
  );

export default function* () {
  yield takeLatest(types.SIGN_UP.REQUEST, signUp);
  yield takeLatest(types.SIGN_IN.REQUEST, signIn);
  yield takeLatest(types.FETCH_USER.REQUEST, getUser);
  yield takeLatest(types.EDIT_ACCOUNT.REQUEST, editAccount);
  yield takeLatest(types.LOG_OUT.REQUEST, logOut);
  yield takeLatest(types.FORGOT_PASSWORD.REQUEST, forgotPassword);
  yield takeLatest(types.CHANGE_PASSWORD.REQUEST, changePassword);
  yield takeLatest(types.RESET_PASSWORD.REQUEST, resetPassword);
  yield takeLatest(types.ADD_LOGO_AGENCY.REQUEST, addLogoAgency);
}
