import "./styles/index.scss";
import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { getReportLoader } from "./shared/Loader/store/selectors";

import Account from "./Containers/Account";
import AlgorithmReport from "./Containers/AlgorithmReport";
import {
  ForgotPassword,
  ResetPassword,
  SignIn,
  SignUp,
} from "./Containers/Auth/containers";
import { getAuth, getUser } from "./Containers/Auth/store/selectors";
import Footer from "./Containers/Common/Footer";
import PageWrapper from "./Containers/Common/PageWrapper";
import Spinner from "./Containers/Common/Spinner";
import CreateReport from "./Containers/CreateReport";
import Download from "./Containers/Download";
import DownloadTest from "./Containers/Download/DownloadPdfTest";
import PaymentsAndCredits from "./Containers/PaymentsAndCredits";
import PaymentError from "./Containers/PaymentsAndCredits/containers/PaymentError";
import PaymentSuccess from "./Containers/PaymentsAndCredits/containers/PaymentSuccess";
import Report from "./Containers/Report";
import Admin from "./Containers/Admin";
import { ROUTES } from "./routes/constants";
import { PrivateRoute, PublicRoute, AdminRoute } from "./routes/containers";
import Error from "./shared/Error/Error";
import Loader from "./shared/Loader/Loader";
import CustomLoader from "./shared/CustomLoader";
import muiTheme from "./themes/muiTheme";
import useAuth from "./utils/useAuth";
import useRemoveScroll from "./utils/useRemoveScroll";

export default () => {
  const { isLoading } = useAuth();
  const { type, is_private } = useSelector(getUser());
  // console.log(type, "type");
  const isAuthenticated = useSelector(getAuth());
  const isReportLoading = useSelector(getReportLoader());
  // console.log(isReportLoading, "isReportLoading");
  const { pathname } = useLocation();

  // REMOVE SCROLL FROM BODY IF REPORT LOADER IS ACTIVE
  useRemoveScroll(document.body, isReportLoading);

  // Comment to test commits

  return (
    <>
      {!isLoading ? (
        <MuiThemeProvider theme={muiTheme}>
          {pathname !== ROUTES.CREATE_REPORT && <Loader />}
          {isReportLoading != 0 && <CustomLoader />}
          <Error />
          <main id="main">
            <Routes>
              <Route
                path={ROUTES.SIGN_IN}
                element={
                  <PublicRoute
                    component={SignIn}
                    isAuthenticated={isAuthenticated}
                    restricted={true}
                    route={ROUTES.SIGN_IN}
                    title="Accedi"
                  />
                }
              />
              {process.env.REACT_APP_PLATFORM != "BAKECA" && (
                <>
                  <Route
                    path="/errorPayment"
                    element={
                      <PrivateRoute
                        component={PaymentError}
                        isAuthenticated={isAuthenticated}
                        restricted={true}
                        title="Risultato pagamento"
                        route="/errorPayment"
                      />
                    }
                  ></Route>
                  <Route
                    path="/successPayment"
                    element={
                      <PrivateRoute
                        component={PaymentSuccess}
                        isAuthenticated={isAuthenticated}
                        restricted={true}
                        title="Risultato pagamento"
                        route="/successPayment"
                      />
                    }
                  ></Route>
                </>
              )}
              <Route
                path={ROUTES.FORGOT_PASSWORD}
                element={
                  <PublicRoute
                    component={ForgotPassword}
                    isAuthenticated={isAuthenticated}
                    restricted={true}
                    route={ROUTES.FORGOT_PASSWORD}
                    title="Recupera password"
                  />
                }
              />
              <Route
                path={`${ROUTES.RESET_PASSWORD}/:token`}
                element={
                  <PublicRoute
                    component={ResetPassword}
                    isAuthenticated={isAuthenticated}
                    restricted={true}
                    route={ROUTES.RESET_PASSWORD}
                    title="Reimposta password"
                  />
                }
              />
              <Route
                path={ROUTES.SIGN_UP}
                element={
                  <PublicRoute
                    component={SignUp}
                    isAuthenticated={isAuthenticated}
                    restricted={true}
                    route={ROUTES.SIGN_UP}
                    title="Registrati"
                  />
                }
              />
              <Route
                path={ROUTES.CREATE_REPORT}
                element={
                  <PrivateRoute
                    component={CreateReport}
                    isAuthenticated={isAuthenticated}
                    title="Crea report"
                    route={ROUTES.CREATE_REPORT}
                  />
                }
              />
              <Route
                path={`${ROUTES.REPORT}/demo`}
                element={
                  <PrivateRoute
                    component={AlgorithmReport}
                    isAuthenticated={isAuthenticated}
                  />
                }
              />

              <Route path={ROUTES.REPORT}>
                <Route
                  index
                  element={
                    <PageWrapper>
                      <Outlet />
                      <PrivateRoute
                        component={Report}
                        isAuthenticated={isAuthenticated}
                        title="Report"
                        route={ROUTES.REPORT}
                      />
                    </PageWrapper>
                  }
                />
                <Route path=":reportId">
                  <Route
                    index
                    element={
                      <PrivateRoute
                        component={AlgorithmReport}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path={`download${process.env.REACT_APP_SECRET_DOWNLOAD}`}
                    element={
                      <PrivateRoute
                        component={Download}
                        isAuthenticated={isAuthenticated}
                        title="Scarica Report"
                        route={ROUTES.REPORT}
                      />
                    }
                  />
                  <Route
                    path="download_test"
                    element={
                      <AdminRoute
                        component={DownloadTest}
                        isAdmin={type == 3}
                      />
                    }
                  />
                </Route>
              </Route>

              <Route
                element={
                  <PageWrapper>
                    <Outlet />
                  </PageWrapper>
                }
              >
                {process.env.REACT_APP_PLATFORM != "BAKECA" &&
                  is_private != 1 && (
                    <Route
                      path={ROUTES.PAYMENTS}
                      element={
                        <PrivateRoute
                          component={PaymentsAndCredits}
                          isAuthenticated={isAuthenticated}
                          title="Pagamenti e crediti"
                          route={ROUTES.PAYMENTS}
                        />
                      }
                    ></Route>
                  )}
                <Route
                  path={ROUTES.ACCOUNT}
                  element={
                    <PrivateRoute
                      component={Account}
                      isAuthenticated={isAuthenticated}
                      title="Account"
                      route={ROUTES.ACCOUNT}
                    />
                  }
                />
                <Route
                  path={ROUTES.FAQ}
                  element={
                    <PrivateRoute
                      component={() => <div>FAQ</div>}
                      isAuthenticated={isAuthenticated}
                      title="FAQ"
                      route={ROUTES.FAQ}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    isAuthenticated ? (
                      <Navigate to={ROUTES.REPORT} />
                    ) : (
                      <Navigate to={ROUTES.SIGN_IN} />
                    )
                  }
                />
              </Route>
              <Route
                path={ROUTES.ADMIN}
                element={
                  type == 3 ? <Admin /> : <Navigate to={ROUTES.REPORT} />
                }
              />
            </Routes>
          </main>
          {isAuthenticated && <Footer />}
        </MuiThemeProvider>
      ) : (
        <Spinner />
      )}
    </>
  );
};
