import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import alertToast from "../../../../../shared/Error/AlertToast";
import { actions } from "../../../../Admin/store/actions";
import { actions as reportActions } from "../../../../Report/store";
import { getPlatform, getFormValue } from "../../../store/selectors";
// import styles from "../ResultTable.module.scss";
// import Modal from "../../../../Common/Modal";
// import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";

const useQueryCallback = () => {
  const dispatch = useDispatch();
  const value = useSelector(getFormValue());
  const platform = useSelector(getPlatform());

  const endSubscription = (queryObj) =>
    dispatch(
      actions.END_SUBSCRIPTION.REQUEST(queryObj, () =>
        dispatch(
          actions.GET_USERS_BY_EMAIL_OR_NAME.REQUEST(
            {
              value,
              platform,
            },
            (data) => {
              if (!data.length) {
                alertToast("warning", "Attenzione", "Nessun risultato trovato");
              }
            }
          )
        )
      )
    );

  const deleteReport = (queryObj) =>
    dispatch(
      reportActions.DELETE_HOUSE.REQUEST(queryObj, () =>
        dispatch(
          actions.GET_REPORTS_BY_ADDRESS.REQUEST(
            {
              value,
              platform,
            },
            (data) => {
              if (!data.length) {
                alertToast("warning", "Attenzione", "Nessun risultato trovato");
              }
            }
          )
        )
      )
    );

  const deleteReportFromUserReports = (queryObj, userId) => {
    // console.log(userId, "userId");
    dispatch(
      reportActions.DELETE_HOUSE.REQUEST(queryObj, () =>
        dispatch(
          actions.GET_USER_REPORTS.REQUEST(userId, (data) => {
            if (!data.length) {
              alertToast("warning", "Attenzione", "Nessun risultato trovato");
            }
          })
        )
      )
    );
  };

  const modifySubscription = (subscriptionEnd, userId) => {
    const handleChange = (e) => {
      dispatch(
        actions.MODIFY_SUBSCRIPTION_DATE.REQUEST(
          {
            date: e.target.value,
            userId: userId,
          },
          () =>
            dispatch(
              actions.GET_USERS_BY_EMAIL_OR_NAME.REQUEST(
                {
                  value,
                  platform,
                },
                (data) => {
                  if (!data.length) {
                    alertToast(
                      "warning",
                      "Attenzione",
                      "Nessun risultato trovato"
                    );
                  }
                }
              )
            )
        )
      );
    };

    return (
      <input
        type="date"
        value={
          subscriptionEnd
            ? new Date(subscriptionEnd).toISOString().slice(0, 10)
            : ""
        }
        onChange={handleChange}
      />
    );
  };

  const modifySubscriptionType = (subscriptionType, userId) => {
    const handleChange = (e) => {
      dispatch(
        actions.MODIFY_SUBSCRIPTION_TYPE.REQUEST(
          {
            type: e.target.value,
            userId: userId,
          },
          () =>
            dispatch(
              actions.GET_USERS_BY_EMAIL_OR_NAME.REQUEST(
                {
                  value,
                  platform,
                },
                (data) => {
                  if (!data.length) {
                    alertToast(
                      "warning",
                      "Attenzione",
                      "Nessun risultato trovato"
                    );
                  }
                }
              )
            )
        )
      );
    };

    return (
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <label>
          <input
            type="radio"
            value="yearly"
            checked={subscriptionType === "yearly"}
            onChange={handleChange}
          />
          Annuale
        </label>
        <label>
          <input
            type="radio"
            value="monthly"
            checked={subscriptionType === "monthly"}
            onChange={handleChange}
          />
          Mensile
        </label>
      </div>
    );
  };

  return {
    endSubscription,
    deleteReport,
    modifySubscription,
    modifySubscriptionType,
    deleteReportFromUserReports,
  };
};

export default useQueryCallback;

// const ModifyCreditsForm = ({ credits, userId }) => {
//   const [numberInputValue, setNumberInputValue] = useState(0);
//   const [isModifyCreditsModalOpen, setIsModifyCreditsModalOpen] =
//     useState(false);

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     dispatch(
//       actions.MODIFY_USER_CREDITS.REQUEST(
//         { credits: numberInputValue, userId },
//         () =>
//           dispatch(
//             actions.GET_USERS_BY_EMAIL_OR_NAME.REQUEST({
//               value,
//               platform,
//             })
//           )
//       )
//     );
//   };

//   return (
//     <form onSubmit={handleSubmit} className={styles.credits_input}>
//       <input
//         type="number"
//         value={credits}
//         onClick={() => setIsModifyCreditsModalOpen(true)}
//         readOnly
//         className={styles.credits_input__input}
//       />
//       <Modal
//         padding="80px"
//         showCloseBtn={() => {
//           setIsModifyCreditsModalOpen(false);
//           setNumberInputValue(0);
//         }}
//         isModalOpen={isModifyCreditsModalOpen}
//         setIsModalOpen={() => setIsModifyCreditsModalOpen(false)}
//       >
//         <div className={styles["report-modal__content"]}>
//           <input
//             type="number"
//             id="numberInput"
//             name="numberInput"
//             value={numberInputValue}
//             onChange={(e) => setNumberInputValue(e.target.value)}
//           />
//           <PrimaryButton
//             width="180px"
//             height="45px"
//             fontSize="17px"
//             text={"Modifica crediti"}
//             onClick={handleSubmit}
//           />
//         </div>
//       </Modal>
//     </form>
//   );
// };

// const modifyCredits = (credits, userId) => {
//   return <ModifyCreditsForm credits={credits} userId={userId} />;
// };
