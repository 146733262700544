import GaugeChart from '../../../../../Common/Charts/GaugeChart';
import PdfCheckbox from '../../../PdfCheckbox';

const PopulationDensityArea = ({
	data, marginTopValue, minHeight, isPdf = false,
}) => {
	const translateValue = value => {
		if (value < 1) {
			return { label: 'bassa', value: 25 };
		}
		if (value < 2) {
			return { label: 'media', value: 50 };
		}
		return { label: 'alta', value: 100 };
	};

	const translateDisplayValue = displayVal => {
		// const numericVal = displayVal?.split(' ')[0];
		// const stringVal = displayVal?.split(' ')[1];
		// console.log(numericVal?.toLocaleString('it-IT'));

		// console.log(displayVal);
		const str = displayVal;
		// console.log(str);
		const regex = /(\d+(\.\d+)?)/;
		const match = str?.match(regex);
		// console.log(match);
		const number = match ? Number(match[0])?.toLocaleString('it-IT') : null;
		// console.log(number, 'NUMBER');

		return <>Comune con <b>{number} ab./km²</b></>;
	};

	 return (
		<div style={{ position: 'relative', height: '100%' }}>
			{!isPdf && <PdfCheckbox zIndex={1} section={'population_density'} />}
			<GaugeChart title={data?.title} description={data?.description}
				displayValue={translateDisplayValue(data?.data?.displayed_value)} levelLabel={translateValue(data?.data?.level)?.label}
				value={translateValue(data?.data?.level)?.value} minHeight={minHeight} marginTopValue={marginTopValue} />
		</div>
	);
};

export default PopulationDensityArea;
