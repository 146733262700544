const maxPiani = (fieldname, TotalePiani, Piano, PianiCasa) => {
  if (fieldname == "Piano") {
    return TotalePiani;
  }

  if (fieldname == "PianiCasa") {
    if (
      TotalePiani - Piano <= 0 ||
      (TotalePiani == 1 && Piano == 1 && PianiCasa == 1)
    ) {
      return 1;
    } else {
      return TotalePiani - Piano;
    }
  }
};

export default maxPiani;
