import { ReactComponent as HospitalsIcon } from "../../../../../../assets/svg/1-01.svg";
import styles from "./HospitalsSection.module.scss";

const HospitalsSection = ({ data }) => {
  return (
    <div className={styles.hospitals__wrapper}>
      <div className={styles.first__row}>
        <p className={styles.title}>Ospedali</p>
        <div className={styles.icon__wrapper}>
          <HospitalsIcon />
        </div>
      </div>
      <div>
        <p className={styles.column__header}>Entro 20 km</p>
        <ul className={styles.column__list}>
          {data?.length ? (
            data?.map((item, index) => (
              <li key={`${item.name}-${index}`} className={styles.list__item}>
                {item.name}
              </li>
            ))
          ) : (
            <p className={styles.list__item}>
              Non ci sono ospedali disponibili
            </p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default HospitalsSection;
