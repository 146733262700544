import React from 'react';

import BarChart from '../../../../../Common/Charts/BarChart';
import PdfCheckbox from '../../../PdfCheckbox';

const GenderStatus = ({
	data, minHeight, wrapperHeight, chartMarginTop, isPdf = false,
}) => {
	const chartData = data?.data?.x?.map((item, index) => ({
		'x': item,
		'Uomini': data?.data?.y[0][index],
		'Donne': data?.data?.y[1][index],
		'percentUomini': `${data?.data?.displayed_values[0][index].toFixed(1)}%`,
		'percentDonne': `${data?.data?.displayed_values[1][index].toFixed(1)}%`
	}));

	return (
		<div>
			{!isPdf && <PdfCheckbox section={'gender_status'} />}
			<BarChart title={data?.title} description={data?.description} label={data?.data?.ytitle}
				legend={data?.data?.legend} dx={36} marginLeft={0}
				minHeight={minHeight} wrapperHeight={wrapperHeight} chartMarginTop={chartMarginTop}
				chartData={chartData} />
		</div>
	);
};

export default GenderStatus;