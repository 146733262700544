import Modal from "../../Common/Modal";
import PropertyCharacteristics from "../../AlgorithmReport/containers/PropertyCharacteristics";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import styles from "./PreviewModal.module.scss";

const PreviewModal = ({
  isPreviewModalOpen,
  setIsPreviewModalOpen,
  reportData,
  backStep,
  submitFormRef,
}) => {
  return (
    <Modal
      width={"100%"}
      showCloseButton={true}
      padding={"30px 30px 40px"}
      borderRadius={"0px"}
      borderTopLeftRadius={"40px"}
      borderTopRightRadius={"40px"}
      borderBottomLeftRadius={"0px"}
      borderBottomRightRadius={"0px"}
      position={"absolute"}
      bottom={"0"}
      top={"auto"}
      left={"auto"}
      transform={"none"}
      className={styles.preview_modal}
      isModalOpen={isPreviewModalOpen}
      setIsModalOpen={() => setIsPreviewModalOpen(false)}
      overflow={"hidden"}
    >
      <PropertyCharacteristics
        data={reportData}
        showGallery={false}
        clickProperty={(idstep) => {
          backStep(idstep);
        }}
        marginTop={"0px"}
        title={"Confermi la creazione del report con queste caratteristiche?"}
      />
      <div
        style={{
          display: "flex",
          columnGap: "20px",
          justifyContent: window.innerWidth < 580 ? "center" : "flex-end",
          // marginRight: window.innerWidth < 580 ? "0px" : "50px",
        }}
      >
        <SecondaryButton
          width="100px"
          height="60px"
          fontSize="24px"
          text="Annulla"
          onClick={() => {
            setIsPreviewModalOpen(false);
          }}
        />
        <PrimaryButton
          width="200px"
          height="60px"
          fontSize="24px"
          text="Calcola"
          onClick={() => {
            // console.log(submitFormRef?.current, "submitFormRef");
            submitFormRef.current?.submitForm();
            // console.log("submit");
          }}
        />
      </div>
    </Modal>
  );
};

export default PreviewModal;
