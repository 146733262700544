import React, { useState } from "react";
import styles from "./ReportNav.module.scss";
import clx from "classnames";
import scrollInToView from "../../../../utils/scrollIntoView";
import useIntersectionObserver from "../../../../utils/useIntersectionObserver";
import useShowNav from "../../../../utils/useShowNav";

const ReportNav = ({ algoComponentRefs, hasAgentAssessment, isPrivate }) => {
  // console.log(algoComponentRefs, "algoComponentRefs");
  const [isHovering, setIsHovering] = useState(false);
  const { activeSection } = useIntersectionObserver(algoComponentRefs);
  const { showNav } = useShowNav(isHovering);
  // console.log(activeSection, "activeSection");
  // console.log(showNav, "showNav");

  const sectionTitles = [
    "Perizia",
    "Immobili comparabili",
    "Immobili similari venduti",
    "Caratteristiche proprietà in vendita",
    "Pricing simulator",
    "Analisi Potenziali clienti",
    "Dati OMI",
    "Informazioni socio-demografiche",
    "Informazioni economiche",
    "Informazioni sulla sicurezza",
    "Servizi di zona",
    "Dante AI",
    "Riepilogo valutazione",
  ];

  if (hasAgentAssessment) {
    sectionTitles.splice(1, 0, "Analisi della tua valutazione");
  }

  if (isPrivate == 1) {
    sectionTitles.splice(sectionTitles.indexOf("Immobili similari venduti"), 1);
  }

  return (
    <div
      className={clx(styles.report_nav, { [styles.show_nav]: showNav })}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        gridTemplateColumns: `repeat(auto-fill, minmax(${
          100 / sectionTitles.length
        }%, 1fr))`,
      }}
    >
      {sectionTitles.map((title, index) => (
        <div
          className={clx(styles.report_nav_item, {
            [styles.active]: activeSection == algoComponentRefs[index],
          })}
          onClick={() => scrollInToView(algoComponentRefs[index])}
          key={`report-nav-${index}`}
        >
          {title}
        </div>
      ))}
    </div>
  );
};

export default ReportNav;
