import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TABLE_KEYS } from "../Common/DataTables/constants";
import PaymentsTable from "./containers/PaymentsTable";
import styles from "./PaymentsAndCredits.module.scss";
import { actions } from "./store";
import {
  // getTransactions,
  getUserPortal,
  getUserCredits,
  getTransactionsNew,
  // getUserSubscriptionHouseplus,
} from "./store/selectors";
import Houseplus from "./containers/Houseplus";
import Mondored from "./containers/Mondored";

const PaymentsAndCredits = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userPortal = useSelector(getUserPortal());
  // console.log("userPortal", userPortal);
  // const transactions = useSelector(getTransactions());
  const transactionsNew = useSelector(getTransactionsNew());
  console.log("transactionsNew", transactionsNew);
  const userCredits = useSelector(getUserCredits());
  // console.log("userCredits", userCredits);
  // const isSubscribed = useSelector(getUserSubscriptionHouseplus());
  // console.log("subscribed", subscribed);

  useEffect(() => {
    dispatch(actions.GET_USER_PORTAL.REQUEST()); // Get user subscription url
    // dispatch(actions.GET_TRANSACTIONS.REQUEST()); // Get user transactions
    dispatch(actions.GET_TRANSACTIONS_NEW.REQUEST()); // Get user transactions
    dispatch(actions.CHECK_USER_CREDITS.REQUEST()); // Get user credits
  }, [dispatch]);

  return (
    <section className={styles["payments-credits__section"]}>
      {/* PRODUCTS */}
      <div className={styles.head_box}>
        {/* HOUSEPLUS */}
        {process.env.REACT_APP_PLATFORM === "HOUSEPLUS" && (
          <Houseplus userPortal={userPortal} />
        )}

        {/* MONDORED */}
        {process.env.REACT_APP_PLATFORM === "MONDORED" && (
          <Mondored userPortal={userPortal} userCredits={userCredits} />
        )}
      </div>

      {/* TRANSACTIONS */}
      <PaymentsTable
        // transactions={transactions}
        transactions={transactionsNew}
        uniqueKey={`${pathname}/${TABLE_KEYS.PAYMENTS}`}
      />
    </section>
  );
};

export default PaymentsAndCredits;
