import clx from "classnames";
import React from "react";

import translateDisplayValue from "../../../../utils/translateDisplayValue";
import NoChartsData from "../../../AlgorithmReport/containers/NoChartsData";
import CommonCard from "../../../AlgorithmReport/containers/SocioDemographicArea/containers/CommonCard";
import Education from "../../../AlgorithmReport/containers/SocioDemographicArea/containers/Education";
import ForeignersChart from "../../../AlgorithmReport/containers/SocioDemographicArea/containers/ForeignersChart";
import styles from "./EightPg.module.scss";

const EightPg = ({
  residents,
  surface,
  family,
  education,
  foreigners,
  pdfItems,
}) => (
  <div className={clx(styles.area__services__wrapper)}>
    <h3 className={styles.header__text}>Informazioni socio-demografiche</h3>
    <div className={styles.area__services__main}>
      {!pdfItems?.["education"] && (
        <div className={clx(styles.education, styles.general__card)}>
          {education?.graph_status == "OK" ? (
            <Education
              data={education?.graph_data}
              minHeight={300}
              wrapperHeight={"auto"}
              marginTop={"0px"}
              isPdf={true}
            />
          ) : (
            <NoChartsData title={"Livello di istruzione"} />
          )}
        </div>
      )}
      {!pdfItems?.["residents"] && (
        <div className={clx(styles.residents, styles.general__card)}>
          {residents?.graph_status == "OK" ? (
            <CommonCard
              header={residents?.graph_data?.title}
              description={residents?.graph_data?.description}
              content={translateDisplayValue(
                residents?.graph_data?.data?.displayed_value
              )}
              marginTop={"0px"}
              fontSize={"20px"}
              titleSize={"16px"}
              isPdf={true}
            />
          ) : (
            <NoChartsData
              title={"Popolazione residente"}
              fontSize={"12px"}
              padding={"15px 25px"}
              minHeight={"105px"}
              marginTop={"5px"}
            />
          )}
        </div>
      )}
      {!pdfItems?.["surface"] && (
        <div className={clx(styles.surface__area, styles.general__card)}>
          {surface?.graph_status == "OK" ? (
            <CommonCard
              header={surface?.graph_data?.title}
              description={surface?.graph_data?.description}
              content={translateDisplayValue(
                surface?.graph_data?.data?.displayed_value
              )}
              marginTop={"0px"}
              fontSize={"20px"}
              titleSize={"16px"}
              isPdf={true}
            />
          ) : (
            <NoChartsData
              title={"Superficie"}
              fontSize={"12px"}
              padding={"15px 25px"}
              minHeight={"105px"}
              marginTop={"5px"}
            />
          )}
        </div>
      )}
      {!pdfItems?.["family"] && (
        <div className={clx(styles.family, styles.general__card)}>
          {family?.graph_status == "OK" ? (
            <CommonCard
              header={family?.graph_data?.title}
              description={family?.graph_data?.description}
              content={translateDisplayValue(
                family?.graph_data?.data?.displayed_value
              )}
              marginTop={"0px"}
              fontSize={"20px"}
              titleSize={"16px"}
              isPdf={true}
            />
          ) : (
            <NoChartsData
              title={"Nucleo familiare"}
              fontSize={"12px"}
              padding={"15px 25px"}
              minHeight={"105px"}
              marginTop={"5px"}
            />
          )}
        </div>
      )}
      {!pdfItems?.["foreigners"] && (
        <div className={clx(styles.foreigners, styles.general__card)}>
          {foreigners?.graph_status == "OK" ? (
            <ForeignersChart
              data={foreigners?.graph_data}
              viewBox={"-150 0 900 250"}
              minHeight={"auto"}
              justifyContent={"normal"}
              marginBottom={"0px"}
              marginTop={"25px"}
              marginTopMedium={"-20px"}
              isPdf={true}
            />
          ) : (
            <NoChartsData
              title={"Percentuale di popolazione residenti stranieri"}
            />
          )}
        </div>
      )}
    </div>
  </div>
);

export default EightPg;
