// import PrimaryButton from "../../Containers/Common/Buttons/PrimaryButton";
// import Modal from "../../Containers/Common/Modal";
// import { AlgoErrorModal } from "../../Containers/CreateReport/containers/CreateReportModals";
// import styles from "./Error.module.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import alertToast from "./AlertToast";
import { actions } from "./store";
import { getError } from "./store/selectors";
import { ToastContainer } from "react-toastify";

export default () => {
  const error = useSelector(getError());
  const dispatch = useDispatch();
  const clear = () => {
    dispatch(actions.clearError());
  };
  const selectError = (error) => {
    switch (error) {
      case "dbErrorHousesRetrieval":
        return {
          title: "Errore interno del server",
          subtitle: "Si è verificato un errore nel recupero degli immobili",
          type: "error",
        };
      case "houseNotFound":
        return {
          title: "Attenzione",
          subtitle:
            "Questo immobile potrebbe non esistere piu` o appartenere ad un altro utente",
          type: "warning",
        };
      case "imageNotFound":
        return {
          title: "Attenzione",
          subtitle:
            "Questa immagine potrebbe non esistere piu` o appartenere ad un altro utente",
          type: "warning",
        };
      case "emptyFields":
        return {
          subtitle:
            "Mancano dei campi necessari per poter effettuare la registrazione",
          title: "Errore",
          type: "error",
        };
      case "dbErrorUserCreation":
        return {
          subtitle:
            "Si è verificato un errore durante la registrazione dell'utente",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorLogin":
        return {
          subtitle:
            "Si è verificato un errore durante l'autenticazione dell'utente",
          title: "Errore interno del server",
          type: "error",
        };
      case "incorrectPassword":
        return {
          subtitle: "La password inserita non è corretta",
          title: "Errore",
          type: "error",
        };
      case "dbErrorPasswordEmailSender":
        return {
          subtitle:
            "Si è verificato un errore durante l'invio della mail di recupero password",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorPasswordUpdate":
        return {
          subtitle:
            "Si è verificato un errore durante l'aggiornamento della password",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorUserPortal":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero del portale Stripe dell'utente",
          title: "Errore interno del server",
          type: "error",
        };
      case "cantUpdatePswOrEmail":
        return {
          subtitle: "Non è possibile aggiornare la password o l'email",
          title: "Errore",
          type: "error",
        };
      case "dbErrorCreditCheck":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero dei crediti dell'utente",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorUpdateAddress":
        return {
          subtitle:
            "Si è verificato un errore durante l'aggiornamento dell'indirizzo dell'utente",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorAddAddress":
        return {
          subtitle:
            "Si è verificato un errore durante l'inserimento dell'indirizzo dell'utente nel database",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorPasswordChange":
        return {
          subtitle:
            "Si è verificato un errore durante l'aggiornamento della password",
          title: "Errore interno del server",
          type: "error",
        };
      case "mailAlreadyRegistered":
        return {
          subtitle: "Email gia registrata",
          title: "Attenzione",
          type: "warning",
        };
      case "emailNotFound":
        return {
          subtitle: "Questo indirizzo email non e` registrato",
          title: "Attenzione",
          type: "warning",
        };
      case "userNotFound":
        return {
          subtitle: "Questo utente non esiste",
          title: "Attenzione",
          type: "warning",
        };
      case "houseNotOfThisUser":
        return {
          subtitle: "Questo immobile non appartiene a questo utente",
          title: "Attenzione",
          type: "warning",
        };
      case "unauthorized":
        return {
          subtitle: "Credenziali errate",
          title: "Attenzione",
          type: "warning",
        };
      case "dbError":
        return {
          subtitle: "",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorUpdatingUser":
        return {
          subtitle:
            "Si è verificato un errore nell'aggiornamento dei dati dell'utente",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorUserCreation":
        return {
          subtitle: "Si è verificato un errore nella registrazione dell'utente",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorUserRetrieval":
        return {
          subtitle: "Si è verificato un errore nel recupero dell'utente",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorProfilePicUpload":
        return {
          subtitle:
            "Si è verificato un errore nel caricamento dell'immagine profilo",
          title: "Errore interno del server",
          type: "error",
        };
      case "Invalid request S3":
        return {
          subtitle:
            "Si è verificato un errore nel caricamento dell'immagine profilo",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorHouseRetrieval":
        return {
          subtitle: "Si è verificato un errore nel recupero dell'immobile",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorHouseUpdate":
        return {
          subtitle:
            "Si è verificato un errore nell'aggiornamento dell'immobile",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorHouseRemoval":
        return {
          subtitle: "Si è verificato un errore nel cancellamento dell'immobile",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorGalleryAdd":
        return {
          subtitle:
            "Si è verificato un errore durante il salvataggio delle immagini",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorGalleryUpdate":
        return {
          subtitle:
            "Si è verificato un errore nell'aggiornamento della galleria",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorGalleryRemoval":
        return {
          subtitle:
            "Si è verificato un errore durante la cancellazione delle immagini",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorImageRemoval":
        return {
          subtitle:
            "Si è verificato un errore durante la cancellazione dell'immagine",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorGalleryFetch":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero della galleria",
          title: "Errore interno del server",
          type: "error",
        };
      case "noFilesUploaded":
        return {
          subtitle: "Non e` stato caricato alcun file",
          title: "Attenzione",
          type: "warning",
        };
      case "house not linked with this user":
        return {
          subtitle: "Questo immobile non e` collegato a questo utente",
          title: "Attenzione",
          type: "warning",
        };
      case "moreCreditsNeeded":
        return {
          subtitle: "Sono necessari piu` crediti per poter creare un report",
          title: "Attenzione",
          type: "warning",
        };
      case "dbErrorHouseCreation":
        return {
          subtitle: "Si è verificato un errore nella creazione dell'immobile",
          title: "Errore interno del server",
          type: "error",
        };
      case "PdfItemsError":
        return {
          subtitle:
            "Si è verificato un errore nel recupero delle sezioni che avevi selezionato per essere incluse nel report PDF.",
          title: "Errore interno del server",
          type: "error",
        };
      case "pdfItemsNotFound":
        return {
          subtitle:
            "Si è verificato un errore nel recupero delle sezioni che avevi selezionato per essere incluse nel report PDF.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorPdfItemsUpdate":
        return {
          subtitle:
            "Si è verificato un errore nell'aggiornamento delle sezioni selezionate per essere incluse nel report PDF.",
          title: "Errore interno del server",
          type: "error",
        };
      case "creditsAddedUnsuccesfully":
        return {
          subtitle: "Si è verificato un errore durante l'acquisto dei crediti",
          title: "Errore interno del server",
          type: "error",
        };
      case "transactionsRetrievedUnsuccesfully":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero delle transazioni",
          title: "Errore interno del server",
          type: "error",
        };
      case "subscriptionAddedUnsuccesfully":
        return {
          subtitle:
            "Si è verificato un errore durante l'acquisto dell'abbonamento",
          title: "Errore interno del server",
          type: "error",
        };
      case "productsRetrievedUnsuccesfully":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero dei prodotti Stripe",
          title: "Errore interno del server",
          type: "error",
        };
      case "paymentsLinkRetrievedUnsuccesfully":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero del link Stripe per l'acquisto dei crediti",
          title: "Errore interno del server",
          type: "error",
        };
      case "paymentsLinkSubsrciptionRetrievedUnsuccesfully":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero del link Stripe per l'acquisto dell'abbonamento",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorDownloadPdf":
        return {
          subtitle:
            "Si è verificato un errore durante lo scaricamento del report PDF",
          title: "Errore interno del server",
          type: "error",
        };
      case "algoError":
        return {
          subtitle:
            'Ops! Sembra che i dati inseriti abbiano creato una singolarità nell\'universo. Non preoccuparti, stiamo già lavorando per risolverlo al più presto! Ti invitiamo a riprovare o scrivere al team <a href="mailto:hello@houseplus.it" className="error-link">Houseplus</a>.',
          title: "Errore Algoritmico",
          type: "error",
        };
      case "wrongPlatform":
        return {
          subtitle:
            "Si prega di accertarsi di effettuare l'accesso alla piattaforma corretta. Controlla l'URL e assicurati di essere sul sito web giusto.",
          title: "Attenzione",
          type: "warning",
        };
      case "missingReportId":
        return {
          subtitle:
            "L'id del report, necessario per modificarne i contenuti, non è stato trovato.",
          title: "Attenzione",
          type: "warning",
        };
      case "stripeIdNotFound":
        return {
          subtitle: "L'utente non sembra essere registrato su Stripe.",
          title: "Attenzione",
          type: "warning",
        };
      case "mondoSubscriptionRetrievalError":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero dell'abbonamento Mondored.",
          title: "Errore interno del server",
          type: "error",
        };
      case "missingDataEmailAdmin":
        return {
          subtitle: "Manca il valore necessario per la tua ricerca.",
          title: "Attenzione",
          type: "warning",
        };
      case "noUserFoundAdmin":
        return {
          subtitle: "Nessun utente trovato.",
          title: "Attenzione",
          type: "warning",
        };
      case "noReportsFoundAdmin":
        return {
          subtitle: "Nessun report trovato.",
          title: "Attenzione",
          type: "warning",
        };
      case "noUserReportsFoundAdmin":
        return {
          subtitle: "Nessun report trovato per questo utente.",
          title: "Attenzione",
          type: "warning",
        };
      case "noUserTransactionsFoundAdmin":
        return {
          subtitle: "Nessuna transazione trovata per questo utente.",
          title: "Attenzione",
          type: "warning",
        };
      case "missingCreditsData":
        return {
          subtitle: "Mancano i dati necessari per modificare i crediti.",
          title: "Attenzione",
          type: "warning",
        };
      case "invalidCreditsData":
        return {
          subtitle: "I crediti da modificare non possono essere 0.",
          title: "Attenzione",
          type: "warning",
        };
      case "dbErrorUserByEmailAdmin":
        return {
          subtitle:
            "Si è verificato un errore durante la ricerca degli utenti.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorReportsByAddressAdmin":
        return {
          subtitle: "Si è verificato un errore durante la ricerca dei reports.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorAllUserReportsAdmin":
        return {
          subtitle:
            "Si è verificato un errore durante la ricerca dei reports dell'utente.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorAllUserTransactionsAdmin":
        return {
          subtitle:
            "Si è verificato un errore durante la ricerca delle transazioni dell'utente.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorAllReportsAdmin":
        return {
          subtitle:
            "Si è verificato un errore durante la ricerca di tutti i reports.",
          title: "Errore interno del server",
          type: "error",
        };
      case "creditsAddedUnsuccesfullyAdmin":
        return {
          subtitle:
            "Si è verificato un errore durante la modifica dei crediti.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorModifyDateAdmin":
        return {
          subtitle:
            "Si è verificato un errore durante la modifica della data dell'utente.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorEndSubscriptionAdmin":
        return {
          subtitle:
            "Si è verificato un errore durante la terminazione dell'utente.",
          title: "Errore interno del server",
          type: "error",
        };
      case "AgentCharacteristicsNotFound":
        return {
          subtitle: "Nessun caratteristica dell'agente trovata.",
          title: "Errore interno del server",
          type: "error",
        };
      case "AgentCharacteristicsError":
        return {
          subtitle: "Nessun caratteristica dell'agente trovata.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErroragentCharacteristicAdd":
        return {
          subtitle:
            "Si è verificato un errore durante l'aggiornamento delle caratteristiche dell'immobile.",
          title: "Errore interno del server",
          type: "error",
        };
      case "hiddenSimilarPropertiesNotFound":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero degli immobili simili da nascondere.",
          title: "Errore interno del server",
          type: "error",
        };
      case "hiddenSimilarPropertiesError":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero degli immobili simili da nascondere.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorhiddenSimilarPropertiesUpdate":
        return {
          subtitle:
            "Si è verificato un errore durante l'aggiornamento degli immobili simili da nascondere.",
          title: "Errore interno del server",
          type: "error",
        };
      case "PdfSimulatorNotFound":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero dei dati del pricing simulator per il PDF.",
          title: "Errore interno del server",
          type: "error",
        };
      case "PdfSimulatorError":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero dei dati del pricing simulator per il PDF.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorPdfSimulatorset":
        return {
          subtitle:
            "Si è verificato un errore durante l'aggiornamento dei dati del pricing simulator per il PDF.",
          title: "Errore interno del server",
          type: "error",
        };
      case "soldPropertiesNotFound":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero degli immobili simili venduti inseriti dall'utente.",
          title: "Errore interno del server",
          type: "error",
        };
      case "soldPropertiesError":
        return {
          subtitle:
            "Si è verificato un errore durante il recupero degli immobili simili venduti inseriti dall'utente.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorsoldPropertyAdd":
        return {
          subtitle:
            "Si è verificato un errore durante l'aggiornamento degli immobili simili venduti.",
          title: "Errore interno del server",
          type: "error",
        };
      case "dbErrorsoldPropertyDelete":
        return {
          subtitle:
            "Si è verificato un errore durante la rimozione dell'immobile simile venduto.",
          title: "Errore interno del server",
          type: "error",
        };
      default:
        return {
          subtitle:
            "Ti invitiamo a scrivere al team Houseplus il quale si impegnerà a trovare una soluzione nel più breve tempo possibile.",
          title: "Errore interno del server",
          type: "error",
        };
    }
  };

  useEffect(() => {
    if (error) {
      alertToast(
        selectError(error).type,
        selectError(error).title,
        selectError(error).subtitle,
        clear
      );
    }
  }, [error]);

  return <ToastContainer />;
};

// return error !== "algoError" ? (
//   <Modal
//     width="330px"
//     height="300px"
//     isModalOpen={error}
//     setIsModalOpen={clear}
//   >
//     <div className={styles.modal__content}>
//       <h3>{error === "wrongPlatform" ? "Attenzione" : "Errore"}</h3>
//       <p>{selectError(error)}</p>
//       <div className={styles.modal__actions}>
//         <PrimaryButton
//           text="Chiudi"
//           background="transparent"
//           padding="8px"
//           height="auto"
//           fontSize="15px"
//           width="180px"
//           color="#AEAEB2"
//           onClick={clear}
//         />
//       </div>
//     </div>
//   </Modal>
// ) : (
//   <AlgoErrorModal error={error} selectError={selectError} clear={clear} />
// );
