import DeleteIcon from "../../../../assets/svg/trash-can-white.svg";
import { useDispatch } from "react-redux";

import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import Modal from "../../../Common/Modal";
import { actions } from "../../../Report/store";
import styles from "./CreateReportModal.module.scss";

export const DeleteReportModal = ({
  isDeleteReportModalOpen,
  setIsDeleteReportModalOpen,
  itemId,
  flag,
  callback,
}) => {
  const dispatch = useDispatch();
  // console.log(flag, "flag");
  // console.log(itemId, "itemId");

  return (
    <Modal
      padding="80px"
      showCloseBtn={() => setIsDeleteReportModalOpen(false)}
      isModalOpen={isDeleteReportModalOpen}
      setIsModalOpen={() => setIsDeleteReportModalOpen(false)}
    >
      <div className={styles["report-modal__content"]}>
        <h1 style={{ textAlign: "center" }}>
          Sicuro di voler eliminare {flag == "users" ? "l'utente" : "il report"}{" "}
          #{itemId}?
        </h1>
        <div className={styles["modal__actions"]}>
          <PrimaryButton
            leftIconSrc={DeleteIcon}
            imgHeight={20}
            // isReactIcon={true}
            // viewBox="0 0 26 26"
            background={"#c70000"}
            width="180px"
            height="45px"
            fontSize="17px"
            text={`Elimina ${flag == "users" ? "utente" : "Report"}`}
            onClick={() => {
              callback
                ? callback()
                : dispatch(
                    actions.DELETE_HOUSE.REQUEST(itemId, () =>
                      dispatch(actions.GET_ALL_USER_HOUSES.REQUEST())
                    )
                  );
              setIsDeleteReportModalOpen(false);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
