import React from "react";
import { Helmet as HelmetAsync } from "react-helmet-async";

const Helmet = ({ title, route }) => {
  return (
    <HelmetAsync>
      <title>
        {process.env.REACT_APP_PLATFORM.charAt(0) +
          process.env.REACT_APP_PLATFORM.slice(1).toLowerCase()}{" "}
        - {title}
      </title>
      <link rel="canonical" href={route} />
      <link
        rel="icon"
        type="image/x-icon"
        id="favicon"
        href={`/favicon/favicon${process.env.REACT_APP_PLATFORM}.ico`}
      />
    </HelmetAsync>
  );
};

export default Helmet;
