import clx from "classnames";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { getReportOmi } from "../../../Report/store/selectors";
import SectionTitle from "../SectionTitle";
import LeftSide from "./containers/LeftSide";
import OmiTable from "./containers/OmiTable";
import RightSide from "./containers/RightSide";
import styles from "./OMIData.module.scss";
import BuyCta from "../BuyCta";

const OMIData = forwardRef(
  (
    {
      homePricem2,
      isMobile,
      privateUserHasReport,
      userIsPrivate,
      agencyUserHasSubscription,
    },
    ref
  ) => {
    const omi = useSelector(getReportOmi());
    // console.log("omi", omi);

    return (
      <div
        className={clx(styles.omi__data__wrapper, {
          [styles.omi__data__mbl]: isMobile,
        })}
        ref={ref}
      >
        <SectionTitle
          title={"Dati OMI"}
          subtitle={
            "Nella tabella sottostante vengono riportate le Quotazioni Immobiliari relative all’ultimo semestre disponibile. Le quotazioni sono suddivise per tipologia edilizia e stato manutentivo, sono rappresentative, inoltre, della zona OMI nella quale è ubicato l’immobile oggetto di valutazione."
          }
          section={"omi"}
          isDisabled={!userIsPrivate && !agencyUserHasSubscription}
        />

        {(userIsPrivate && !privateUserHasReport) ||
        (!userIsPrivate && !agencyUserHasSubscription) ? (
          <BuyCta />
        ) : (
          <>
            <div className={styles.omi__data__main}>
              <LeftSide omi={omi} />
              <RightSide
                min={omi?.Compr_min}
                max={omi?.Compr_max}
                minRent={omi?.prices?.[3]?.Loc_min}
                maxRent={omi?.prices?.[3]?.Loc_max}
                homePricem2={homePricem2}
                isMobile={isMobile}
              />
            </div>
            {omi?.prices?.length > 0 && <OmiTable omiPrices={omi?.prices} />}
          </>
        )}
      </div>
    );
  }
);

export default OMIData;
