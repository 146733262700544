import { ReactComponent as HealthIcon } from "../../../../../../assets/svg/1-07.svg";
import styles from "./HealthSection.module.scss";

const HealthSection = ({ data, values }) => {
  const services = values?.map((value) => data?.[value]).filter(Boolean);

  const formatBackendText = (text) => {
    const regex1 = /^(.*?):/;
    const regex2 = /(\d+(?:\.\d+)? km)$/;

    const match1 = regex1.exec(text);
    const match2 = regex2.exec(text);

    const result1 = match1[1].trim() || "";
    const result2 = match2[1].trim() || "";
    const remainingText =
      text.replace(result1, "").replace(result2, "").trim() || "";

    return (
      <>
        <b>{result1}</b> {remainingText} <b>{result2}</b>
      </>
    );
  };

  return (
    <div className={styles.health__wrapper}>
      <div className={styles.first__row}>
        <p className={styles.title}>Salute</p>
        <div className={styles.icon__wrapper}>
          <HealthIcon />
        </div>
      </div>
      <div className={styles.content}>
        {services?.length ? (
          <ul className={styles.column__list}>
            {services?.map((service, index) => (
              <li key={index} className={styles.list__item}>
                {formatBackendText(service?.text)}
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.list__item}>
            Non ci sono servizi di salute disponibili
          </p>
        )}
      </div>
    </div>
  );
};

export default HealthSection;
