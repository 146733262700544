import clx from "classnames";
import React from "react";
import { useSearchParams } from "react-router-dom";
import homepal from "../../../../assets/images/partner/homepal.png";
import lazioInnova from "../../../../assets/images/partner/lazioInnova.png";
import luiss from "../../../../assets/images/partner/luiss.png";
import lventure from "../../../../assets/images/partner/lventure.png";
import Sagoma from "../../../../assets/svg/sagoma.svg";
import useWindowSize from "../../../../utils/useWindowSize";
import RegistrationForm from "./containers/RegistrationForm";
import styles from "./SignUp.module.scss";
import platformConditionals from "../../../../utils/platformConditionals";

export default () => {
  const { isMobile, windowHeight } = useWindowSize();

  //prendo i prarametri fonti da cui viene la lead
  const [searchParams] = useSearchParams();

  return (
    <section
      className={clx(styles.signup__wrapper, {
        [styles["signup__wrapper-mobile"]]: isMobile,
      })}
    >
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <RegistrationForm
          source={
            searchParams.get("source") != null ? searchParams.get("source") : ""
          }
          owner={platformConditionals()?.owner || ""}
        />
      </div>
      {!isMobile && (
        <div
          className={styles.right_div}
          style={{
            backgroundImage: "url(" + Sagoma + ")",
          }}
        >
          {process.env.REACT_APP_PLATFORM == "HOUSEPLUS" && (
            <div style={{ width: "100%" }}>
              <div className={styles.partner}>
                <img src={lventure} style={{ marginLeft: 20 }} />
                <img src={lazioInnova} style={{ marginLeft: 20 }} />
                <img src={homepal} style={{ marginLeft: 20 }} />
                <img src={luiss} style={{ marginLeft: 20 }} />
              </div>
              <div className={styles.trust_copy}>
                Trusted by Top-tier product campanies
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};
