import clx from "classnames";

import styles from "./RadioButton.module.scss";

const RadioButton = ({
  field: { name, value, onBlur },
  form: { setFieldValue },
  id,
  label,
  _display = "",
  option,
  type = "radio",
  ...props
}) => {
  // Checked condition depends on if radio or checkbox field
  const checked = type == "radio" ? id === value : value.includes(id);

  // onChange function depending on if radio or checkbox field
  const onFieldChange = (event) => {
    // If checkbox
    if (type == "checkbox") {
      // If 'Nessuno' or 'No' is selected, remove all other values
      if (label == "Nessuno" || label == "No") {
        if (value.includes(id)) {
          setFieldValue(name, []);
        } else {
          setFieldValue(name, [event.target.value]);
        }
      } else {
        // console.log(value, "value inside checkbox");
        const updatedValue = value.includes(id)
          ? // Remove the id from selected values if already checked
            value.filter((val) => val !== event.target.value)
          : // Add the id to selected values if not checked and remove the 'Nessuno' or 'No' value
            [
              event.target.value,
              ...value.filter(
                (val) =>
                  val !=
                  option.acceptValues.find(
                    (opt) =>
                      opt.nameOption == "Nessuno" || opt.nameOption == "No"
                  ).nameOption
              ),
            ];

        // Update the form field's value with the new array of selected ids
        setFieldValue(name, updatedValue);
      }
    }

    // If radio
    if (type == "radio") {
      setFieldValue(name, event.target.value);
    }
  };

  return (
    <label
      className={clx(styles["radio-btn"], {
        [styles["radio-btn__checked"]]: checked,
      })}
      style={{ display: _display }}
    >
      <span>{label}</span>
      <input
        name={name}
        id={id}
        type={type}
        value={id}
        checked={checked}
        onChange={onFieldChange}
        onBlur={onBlur}
        className={styles["radio-btn__input"]}
        {...props}
      />
    </label>
  );
};

export default RadioButton;
