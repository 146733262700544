export const tableColumnsReports = [
  {
    name: "id",
    label: "ID",
    sort: true,
  },
  {
    name: "title",
    label: "Titolo",
    sort: true,
  },
  {
    name: "city",
    label: "Città",
    sort: true,
  },
  {
    name: "address",
    label: "Indirizzo",
    sort: true,
    filter: false,
  },
  {
    name: "type",
    label: "Tipologia",
    sort: true,
    filter: true,
  },
  {
    name: "size",
    label: "M²",
    sort: true,
    filter: true,
  },
  {
    name: "condition",
    label: "Stato Interni",
    filter: false,
    sort: true,
    centered: true,
  },
  {
    name: "heating",
    label: "Riscaldamento",
    filter: false,
    sort: true,
    centered: true,
  },
  {
    name: "price",
    label: "Stima economica",
    centered: true,
    filter: false,
    sort: false,
  },
  {
    name: "daysOnMarket",
    label: "Giorni sul mercato",
    centered: true,
    filter: true,
    sort: true,
  },
  {
    name: "created_at",
    label: "Data di creazione",
    centered: true,
    filter: false,
    sort: true,
  },
];

export const tableColumnsUser = [
  {
    name: "id",
    label: "ID",
    sort: true,
  },
  {
    name: "email",
    label: "Email",
    sort: false,
  },
  {
    name: "owner",
    label: "Piattaforma",
    sort: true,
    filter: true,
  },
  {
    name: "lastLogin",
    label: "Ultimo accesso",
    sort: true,
    filter: false,
  },
  {
    name: "created_at",
    label: "Data di registrazione",
    sort: true,
    filter: true,
  },
  {
    name: "subscriptionType",
    label: "Tipo di abbonamento",
    filter: false,
    sort: false,
    centered: true,
  },
  {
    name: "subscriptionExpiry",
    label: "Scadenza abbonamento",
    filter: false,
    sort: false,
    centered: true,
  },
];

export const reportData = (data) =>
  data?.map((report) => ({
    id: report.id,
    title: report.Titolo,
    city: `${report.city ? report.city + "" : ""}`,
    address: `${
      report.street
        ? report.street +
          (report.buildingNumber ? ", " + report.buildingNumber : "")
        : ""
    }`,
    type: `${report.Tipologia || ""} `,
    size: ` ${report.Superficie + "m²" || ""}`,
    condition: `${report.StatoInterni || ""} `,
    heating: `${report.Riscaldamento || ""}`,
    price: (
      <div>
        <b>
          €{" "}
          {parseInt(report.proba_180)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") || "-"}
        </b>
      </div>
    ),
    daysOnMarket: report?.GiorniMercato || 0,
    created_at: report?.created_at?.slice(0, 10) || "-", // 2021-09-30T00:00:00.000Z
    user_id: report?.user_id,
  }));

export const userData = (data, modifySubscription, modifySubscriptionType) => {
  const mappedUserData = data?.map((user) => {
    return {
      id: user.id,
      email: user.email,
      owner:
        user.owner == 1
          ? "Houseplus"
          : user.owner == 2
          ? "Mondored"
          : "Non specificata",
      lastLogin: user.lastLogin
        ? new Date(user.lastLogin).toISOString().slice(0, 10)
        : "",
      created_at: user.created_at
        ? new Date(user.created_at).toISOString().slice(0, 10)
        : "",
      subscriptionType: modifySubscriptionType(user.subscriptionType, user.id),
      subscriptionExpiry: modifySubscription(user.subscriptionExpiry, user.id),
    };
  });

  return mappedUserData;
};
