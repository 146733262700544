import clx from "classnames";
import { forwardRef } from "react";
import { formatNumber } from "../../../../utils/formatNumber";
import translateDisplayValue from "../../../../utils/translateDisplayValue";
import Skeleton from "../../../Common/Skeleton";
import { useSelector } from "react-redux";
import { getReportCharts } from "../../../Report/store/selectors";
import NoChartsData from "../NoChartsData";
import CommonCard from "./containers/CommonCard";
import Education from "./containers/Education";
import ForeignersChart from "./containers/ForeignersChart";
import GenderDistributionArea from "./containers/GenderDistributionArea";
import GenderStatus from "./containers/GenderStatus";
import PopulationChange from "./containers/PopulationChange";
import PopulationDensityArea from "./containers/PopulationDensityArea";
import PopulationPyramid from "./containers/PopulationPyramid";
import styles from "./SocioDemographicArea.module.scss";
import SectionTitle from "../SectionTitle";
import BuyCta from "../BuyCta";

const SocioDemographicArea = forwardRef(
  ({ isMobile, town, privateUserHasReport, userIsPrivate }, ref) => {
    const charts = useSelector(getReportCharts());

    // POPULATION CHANGE DYNAMIC STRING DATA
    const popChange = charts?.[2];
    const firstYear = popChange?.graph_data?.data?.x[0];
    const lastYear =
      popChange?.graph_data?.data?.x[popChange?.graph_data?.data?.x.length - 1];
    const firstYearAmount = popChange?.graph_data?.data?.y[0];
    const lastYearAmount =
      popChange?.graph_data?.data?.y[popChange?.graph_data?.data?.y.length - 1];
    const difference = lastYearAmount - firstYearAmount;
    const differencePercent = (difference / firstYearAmount) * 100;
    const changeType =
      (difference / firstYearAmount) * 100 >= 0 ? "incremento" : "decremento";

    // CIVIL STATUS DYNAMIC STRING DATA
    const genderStatus = charts?.[4];
    const highestCategoryAmount =
      genderStatus?.graph_data?.data?.displayed_values[0]?.map(
        (item, index) =>
          item + genderStatus?.graph_data?.data?.displayed_values[1][index]
      );
    const highestCategoryAmountIndex = highestCategoryAmount?.indexOf(
      Math.max(...highestCategoryAmount)
    );
    const highestCategory =
      genderStatus?.graph_data?.data?.x[highestCategoryAmountIndex];
    const highestCategoryMen =
      genderStatus?.graph_data?.data?.displayed_values[0]?.[
        highestCategoryAmountIndex
      ];
    const highestCategoryWomen =
      genderStatus?.graph_data?.data?.displayed_values[1]?.[
        highestCategoryAmountIndex
      ];

    // POPULATION PYRAMID DYNAMIC STRING DATA
    const populationPyramid = charts?.[0];
    const convertMenPyramid =
      populationPyramid?.graph_data?.data?.displayed_values[0]?.map((item) =>
        Number(item.replace("%", ""))
      );
    const convertWomenPyramid =
      populationPyramid?.graph_data?.data?.displayed_values[1]?.map((item) =>
        Number(item.replace("%", ""))
      );
    const highestPyramidMenIndex = convertMenPyramid?.indexOf(
      Math.max(...convertMenPyramid)
    );
    const highestPyramidWomenIndex = convertWomenPyramid?.indexOf(
      Math.max(...convertWomenPyramid)
    );
    const highestPyramidGender =
      convertMenPyramid?.[highestPyramidMenIndex] >
      convertWomenPyramid?.[highestPyramidWomenIndex]
        ? "uomini"
        : "donne";
    const highestGenderAge =
      populationPyramid?.graph_data?.data?.y?.[
        convertMenPyramid?.[highestPyramidMenIndex] >
        convertWomenPyramid?.[highestPyramidWomenIndex]
          ? highestPyramidMenIndex
          : highestPyramidWomenIndex
      ];

    return charts?.loading ? (
      <Skeleton />
    ) : (
      <div className={clx(styles.area__services__wrapper)} ref={ref}>
        <SectionTitle
          title={"Informazioni socio-demografiche"}
          section={"all_socio_charts"}
          className={styles.header__text}
          isGeneral={true}
        />

        {userIsPrivate && !privateUserHasReport ? (
          <BuyCta />
        ) : (
          <div className={styles.area__services__main}>
            {/* Population change */}
            <div className={clx(styles.pop__change, styles.general__card)}>
              {charts?.[2]?.graph_status == "OK" ? (
                <div className={styles.general__card_container}>
                  <div className={styles.chart_info}>
                    Nel <b>{firstYear}</b> i residenti a <b>{town}</b> erano{" "}
                    <b>{formatNumber(firstYearAmount)}</b> mentre nel{" "}
                    <b>{lastYear}</b> sono <b>{formatNumber(lastYearAmount)}</b>{" "}
                    con un {changeType} del{" "}
                    <b>{differencePercent.toFixed(2)}%</b>
                  </div>
                  <PopulationChange
                    data={charts?.[2]?.graph_data}
                    locale={false}
                    chartMarginTop={"100px"}
                  />
                </div>
              ) : (
                <NoChartsData title={"Andamento demografico"} />
              )}
            </div>
            {/* Gender distribution */}
            <div
              className={clx(
                styles.gender__distribution__area,
                styles.general__card
              )}
            >
              {charts?.[3]?.graph_status == "OK" ? (
                <GenderDistributionArea
                  data={charts?.[3]?.graph_data}
                  isMobile={isMobile}
                />
              ) : (
                <NoChartsData title={"Distribuzione genere"} />
              )}
            </div>
            {/* Population density */}
            <div
              className={clx(
                styles.population__density__area,
                styles.general__card
              )}
            >
              {charts?.[7]?.graph_status == "OK" ? (
                <PopulationDensityArea
                  data={charts?.[7]?.graph_data}
                  minHeight={"450px"}
                />
              ) : (
                <NoChartsData title={"Densità popolazione"} />
              )}
            </div>
            {/* Civil status */}
            <div className={clx(styles.gender__status, styles.general__card)}>
              {charts?.[4]?.graph_status == "OK" ? (
                <div className={styles.general__card_container}>
                  <div className={styles.chart_info}>
                    Lo stato civile maggiormente presente è quello dei{" "}
                    <b>{highestCategory}</b>, con rispettivamente il{" "}
                    <b>{highestCategoryMen}%</b> di uomini e{" "}
                    <b>{highestCategoryWomen}%</b> di donne
                  </div>
                  <GenderStatus
                    data={charts?.[4]?.graph_data}
                    chartMarginTop={"57px"}
                  />
                </div>
              ) : (
                <NoChartsData title={"Stato civile"} />
              )}
            </div>
            {/* Population pyramid */}
            <div className={clx(styles.pop__pyramid, styles.general__card)}>
              {charts?.[0]?.graph_status == "OK" ? (
                <div className={styles.general__card_container}>
                  <div className={styles.chart_info}>
                    Il gruppo di popolazione più numeroso a <b>{town}</b> sono{" "}
                    <b>{highestPyramidGender}</b> di <b>{highestGenderAge}</b>{" "}
                    anni
                  </div>
                  <PopulationPyramid
                    data={charts?.[0]?.graph_data}
                    chartMarginTop={"30px"}
                  />
                </div>
              ) : (
                <NoChartsData title={"Piramide della popolazione residente"} />
              )}
            </div>
            <div className={clx(styles.residents, styles.general__card)}>
              {charts?.[5]?.graph_status == "OK" ? (
                <CommonCard
                  header={charts?.[5]?.graph_data?.title}
                  description={charts?.[5]?.graph_data?.description}
                  content={translateDisplayValue(
                    charts?.[5]?.graph_data?.data?.displayed_value
                  )}
                  section={"residents"}
                />
              ) : (
                <NoChartsData
                  title={"Popolazione residente"}
                  fontSize={"12px"}
                  padding={"15px 25px"}
                  minHeight={"105px"}
                  marginTop={"5px"}
                />
              )}
            </div>
            <div className={clx(styles.surface__area, styles.general__card)}>
              {charts?.[6]?.graph_status == "OK" ? (
                <CommonCard
                  header={charts?.[6]?.graph_data?.title}
                  description={charts?.[6]?.graph_data?.description}
                  content={translateDisplayValue(
                    charts?.[6]?.graph_data?.data?.displayed_value
                  )}
                  section={"surface"}
                />
              ) : (
                <NoChartsData
                  title={"Superficie"}
                  fontSize={"12px"}
                  padding={"15px 25px"}
                  minHeight={"105px"}
                  marginTop={"5px"}
                />
              )}
            </div>
            <div className={clx(styles.family, styles.general__card)}>
              {charts?.[9]?.graph_status == "OK" ? (
                <CommonCard
                  header={charts?.[9]?.graph_data?.title}
                  description={charts?.[9]?.graph_data?.description}
                  content={translateDisplayValue(
                    charts?.[9]?.graph_data?.data?.displayed_value
                  )}
                  section={"family"}
                />
              ) : (
                <NoChartsData
                  title={"Nucleo familiare"}
                  fontSize={"12px"}
                  padding={"15px 25px"}
                  minHeight={"105px"}
                  marginTop={"5px"}
                />
              )}
            </div>
            {/* Education */}
            <div className={clx(styles.education, styles.general__card)}>
              {charts?.[8]?.graph_status == "OK" ? (
                <Education data={charts?.[8]?.graph_data} />
              ) : (
                <NoChartsData title={"Livello di istruzione"} />
              )}
            </div>
            {/* Foreigners */}
            <div className={clx(styles.foreigners, styles.general__card)}>
              {charts?.[1]?.graph_status == "OK" ? (
                <ForeignersChart data={charts?.[1]?.graph_data} />
              ) : (
                <NoChartsData
                  title={"Percentuale di popolazione residenti stranieri"}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);
export default SocioDemographicArea;
