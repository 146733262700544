import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import LoaderImg1 from "../../assets/images/loader-img-1.jpg";
import LoaderImg2 from "../../assets/images/loader-img-2.jpg";
import LoaderImg3 from "../../assets/images/loader-img-3.jpg";
import LoaderImg4 from "../../assets/images/loader-img-4.jpg";
import LoaderImg5 from "../../assets/images/loader-img-5.jpg";
import LoaderImg6 from "../../assets/images/loader-img-6.jpg";
import LoaderImg7 from "../../assets/images/loader-img-7.jpg";
import LoaderImg8 from "../../assets/images/loader-img-8.jpg";
import LoaderImg9 from "../../assets/images/loader-img-9.jpg";
import LoaderImg10 from "../../assets/images/loader-img-10.jpg";
import LoaderImg11 from "../../assets/images/loader-img-11.jpg";
import styles from "./CustomLoader.module.scss";

const CustomLoader = () => {
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    variableWidth: true,
  };

  const sliderImagesRow1 = [
    {
      src: LoaderImg11,
      width: 360,
    },
    {
      src: LoaderImg1,
      width: 220,
    },
    {
      src: LoaderImg2,
      width: 580,
    },
    {
      src: LoaderImg6,
      width: 290,
    },
    {
      src: LoaderImg7,
      width: 180,
    },
    {
      src: LoaderImg8,
      width: 360,
    },
  ];

  const sliderImagesRow2 = [
    {
      src: LoaderImg3,
      width: 450,
    },
    {
      src: LoaderImg9,
      width: 180,
    },
    {
      src: LoaderImg5,
      width: 760,
    },
    {
      src: LoaderImg10,
      width: 295,
    },
    {
      src: LoaderImg4,
      width: 180,
    },
  ];

  return (
    <div className={styles.loader}>
      <div className={styles.slider__wrapper}>
        <Slider {...settings}>
          {sliderImagesRow1.map((slideImg, idx) => (
            <div key={idx} style={{ width: slideImg.width }}>
              <img src={slideImg.src} alt="loader" />
            </div>
          ))}
        </Slider>
      </div>
      <div className={styles.loader__text}>
        <h3>L’algoritmo sta calcolando la vendibilità dell’immobile</h3>
        <p>otterrai il tuo report tra pochi secondi</p>
      </div>
      <div className={styles.slider__wrapper}>
        <Slider {...settings} rtl={true}>
          {sliderImagesRow2.map((slideImg, idx) => (
            <div key={idx} style={{ width: slideImg.width }}>
              <img src={slideImg.src} alt="loader" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CustomLoader;
