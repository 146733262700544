import { ReactComponent as TagIcon2mesi } from "../../../../../../assets/svg/tag/tag2mesi.svg";
import { ReactComponent as TagIcon6mesi } from "../../../../../../assets/svg/tag/tag6mesi.svg";
import { ReactComponent as TagIcon12mesi } from "../../../../../../assets/svg/tag/tag12mesi.svg";
import styles from "./BestSellingCard.module.scss";
import clx from "classnames";

const BestSellingCard = ({ period, stima_text, price, defaultPrice }) => {
  const priceAndDefPriceDiff =
    ((Math.round(price / 1000) * 1000) /
      (Math.round(defaultPrice / 1000) * 1000)) *
    100;

  return (
    <div className={styles.best__selling__card}>
      <div className={styles.first__row}>
        {period === 2 && (
          <div className={clx(styles.icon__wrapper, styles.icon_two_months)}>
            <TagIcon2mesi />
          </div>
        )}
        {period === 6 && (
          <div className={clx(styles.icon__wrapper, styles.icon_six_months)}>
            <TagIcon6mesi />
          </div>
        )}
        {period === 12 && (
          <div className={clx(styles.icon__wrapper, styles.icon_twelve_months)}>
            <TagIcon12mesi />
          </div>
        )}
        <p className={styles.first__row__text}>
          {/* Prezzo di vendita consigliato per vendere l&apos;immobile <br />
          in {period} mesi.{" "} */}
          {stima_text}
        </p>
        {/* If prices and default prices are different show tooltip with difference in percentage */}
        {100 - priceAndDefPriceDiff != 0.0 ? (
          <div
            className={
              100 - priceAndDefPriceDiff < 0
                ? styles.percent_box_positive
                : styles.percent_box_negative
            }
          >
            {100 - priceAndDefPriceDiff < 0
              ? `+${(priceAndDefPriceDiff - 100).toFixed(2)}%`
              : `${(priceAndDefPriceDiff - 100).toFixed(2)}%`}
          </div>
        ) : (
          ""
        )}
      </div>

      <p className={styles.price}>
        {price != 0 ? (
          <>
            €{" "}
            {`${Math.round(price / 1000) * 1000}`.replace(
              /(?!^)(?=(?:\d{3})+(?:\.|$))/gm,
              "."
            )}
          </>
        ) : (
          <span>Nessun dato disponibile</span>
        )}
      </p>

      <p className={styles.second_row_test}>Configurazione base</p>
      <p className={styles.second_row_price}>
        €{" "}
        {`${Math.round(defaultPrice / 1000) * 1000}`.replace(
          /(?!^)(?=(?:\d{3})+(?:\.|$))/gm,
          "."
        )}
      </p>
    </div>
  );
};

export default BestSellingCard;
