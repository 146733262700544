import { call, put, takeLatest } from "redux-saga/effects";
import { Api } from "../../../entries/ApiTransport";
import { setError } from "../../../shared/Error/store/actions";
import sagaAssessor from "../../../utils/sagaAssessor";
import { actions, types } from "./actions";
import {
  startLoader,
  stopLoader,
  stopReportLoader,
} from "../../../shared/Loader/store/actions";
import platformConditionals from "../../../utils/platformConditionals";

const api = Api.getInstance();

const getUserHouses = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get("/housesNew"));
        // console.log(data, "user houses data");
        yield put(actions.GET_ALL_USER_HOUSES.SUCCESS(data.data || []));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_ALL_USER_HOUSES.FAILED(err)
  );

function* getHouse({ payload, callback, failureCallback }) {
  try {
    yield put(startLoader());
    const { data } = yield call(() => api.get(`/houseNew/${payload}`));
    yield put(actions.GET_HOUSE_BY_ID.SUCCESS(data.data));
    callback && typeof callback === "function" && callback(data.data);
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          error?.message ||
          null
      )
    );
    yield put(
      actions.GET_HOUSE_BY_ID.FAILED(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.message ||
          null
      )
    );
    yield put(stopReportLoader());
    if (error?.response?.data?.message === "houseNotOfThisUser") {
      failureCallback &&
        typeof failureCallback === "function" &&
        failureCallback();
    }
  } finally {
    yield put(stopLoader());
  }
}

function* algoReportNew({ payload, callback }) {
  try {
    yield put(startLoader());
    const { data } = yield call(() => api.post(`/houseNewAlgo`, payload));
    yield put(actions.GET_REPORT_NEW.SUCCESS(data.data));
    callback && typeof callback === "function" && callback();
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          error?.message ||
          null
      )
    );
    yield put(
      actions.GET_REPORT_NEW.FAILED(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.message ||
          null
      )
    );
  } finally {
    yield put(stopLoader());
    yield put(stopReportLoader());
  }
}

// const algoReportNew = ({ payload, callback }) =>
//   sagaAssessor(
//     () =>
//       function* () {
//         const { data } = yield call(() => api.post(`/houseNewAlgo`, payload));
//         yield put(actions.GET_REPORT_NEW.SUCCESS(data.data));
//         callback && typeof callback === "function" && callback();
//       },
//     (err) => actions.GET_REPORT_NEW.FAILED(err)
//   );

const addHouse = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post("/houseNew/add", payload));
        yield put(actions.ADD_HOUSE.SUCCESS(data));
        callback && typeof callback === "function" && callback(data.data);
      },
    (err) => actions.ADD_HOUSE.FAILED(err)
  );

const getPdfItems = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`/pdfItemsNew/${payload}`));
        yield put(actions.GET_PDF_ITEMS.SUCCESS(data.data));
      },
    (err) => actions.GET_PDF_ITEMS.FAILED(err)
  );

const updatePdfItems = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { reportId, chartItem } = payload;
        // console.log(chartItem, "chartItem");
        const { data } = yield call(() =>
          api.put(`/pdfItemsNew/${reportId}`, chartItem)
        );
        yield put(actions.UPDATE_PDF_ITEMS.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.UPDATE_PDF_ITEMS.FAILED(err)
  );

const getHiddenComparedItems = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get(`/hiddenSimilarProperties/${payload}`)
        );
        yield put(actions.GET_HIDDEN_COMPARED_ITEMS.SUCCESS(data.data));
      },
    (err) => actions.GET_HIDDEN_COMPARED_ITEMS.FAILED(err)
  );

const updateHiddenComparedItems = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { reportId, property_id } = payload;
        // console.log(propertyId, "propertyId");
        const { data } = yield call(() =>
          api.put(`/hiddenSimilarProperties/${reportId}`, { property_id })
        );
        yield put(actions.UPDATE_HIDDEN_COMPARED_ITEMS.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.UPDATE_HIDDEN_COMPARED_ITEMS.FAILED(err)
  );

const addSoldProperty = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post(`/addSoldProperty`, payload)
        );
        yield put(actions.ADD_SOLD_PROPERTY.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.ADD_SOLD_PROPERTY.FAILED(err)
  );

const getSoldProperties = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get(`/getSoldProperties/${payload}`)
        );
        yield put(actions.GET_SOLD_PROPERTIES.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_SOLD_PROPERTIES.FAILED(err)
  );

const deleteSoldProperty = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { reportId, property_id } = payload;
        const { data } = yield call(() =>
          api.delete(`/deleteSoldProperty/${reportId}/${property_id}`)
        );
        yield put(actions.DELETE_SOLD_PROPERTY.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.DELETE_SOLD_PROPERTY.FAILED(err)
  );

const getPdfSimulator = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get(`/getPdfSimulator/${payload}`)
        );
        yield put(actions.GET_PDF_SIMULATOR.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_PDF_SIMULATOR.FAILED(err)
  );

const setPdfSimulator = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post(`/setPdfSimulator`, payload)
        );
        yield put(actions.SET_PDF_SIMULATOR.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.SET_PDF_SIMULATOR.FAILED(err)
  );

const getAgentCharacteristics = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get(`/getAgentCharacteristics/${payload}`)
        );
        yield put(actions.GET_AGENT_CHARACTERISTICS.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_AGENT_CHARACTERISTICS.FAILED(err)
  );

const setAgentCharacteristics = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post(`/setAgentCharacteristics`, payload)
        );
        yield put(actions.SET_AGENT_CHARACTERISTICS.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.SET_AGENT_CHARACTERISTICS.FAILED(err)
  );

function* getGallery({ payload, callback }) {
  try {
    yield put(actions.GET_GALLERY.LOADING());
    const { data } = yield call(() => api.get(`/gallery/${payload}`));
    yield put(actions.GET_GALLERY.SUCCESS(data.data || []));
    callback && typeof callback === "function" && callback();
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          null
      )
    );
    yield put(actions.GET_GALLERY.FAILED(error));
  }
}

function* deleteImage({ payload, callback }) {
  try {
    const { reportId, idImage } = payload;
    yield put(actions.DELETE_IMAGE.LOADING());
    const { data } = yield call(() =>
      api.delete(`/gallery/${reportId}/${idImage}`)
    );

    callback && typeof callback === "function" && callback();
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          null
      )
    );
    yield put(actions.DELETE_IMAGE.FAILED(error));
  }
}

function* editImageFields({ payload, callback }) {
  try {
    const { reportId, idImage, ...rest } = payload;
    yield put(actions.EDIT_IMAGE_FIELDS.LOADING());
    const { data } = yield call(() =>
      api.put(`/gallery/${reportId}/${idImage}`, { data: rest })
    );
    yield put(actions.EDIT_IMAGE_FIELDS.SUCCESS(data.data));
    callback && typeof callback === "function" && callback();
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          null
      )
    );
    yield put(actions.EDIT_IMAGE_FIELDS.FAILED(error));
  }
}

function* featuredImage({ payload, callback }) {
  try {
    const { reportId, idImage } = payload;
    yield put(actions.FEATURED_IMAGE.LOADING());
    const { data } = yield call(() =>
      api.patch(`/gallery/${reportId}/${idImage}`, null)
    );
    yield put(actions.FEATURED_IMAGE.SUCCESS(data.image));
    callback && typeof callback === "function" && callback();
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          null
      )
    );
    yield put(actions.FEATURED_IMAGE.FAILED(error));
  }
}

function* uploadImage({ payload, callback }) {
  try {
    const { reportId, ...rest } = payload;
    yield put(actions.UPLOAD_IMAGE.LOADING());
    const { data } = yield call(() =>
      api.post(`/gallery/${reportId}`, { gallery: rest })
    );
    yield put(actions.UPLOAD_IMAGE.SUCCESS(data.data));
    callback && typeof callback === "function" && callback();
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          null
      )
    );
    yield put(actions.UPLOAD_IMAGE.FAILED(error));
  }
}

function* changeOrder({ payload, callback }) {
  try {
    const { reportId, values } = payload;
    yield put(actions.CHANGE_ORDER.LOADING());
    const { data } = yield call(() =>
      api.put(`/gallery/${reportId}`, { data: values })
    );
    yield put(actions.CHANGE_ORDER.SUCCESS(data.data));
    callback && typeof callback === "function" && callback();
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          null
      )
    );
    yield put(actions.CHANGE_ORDER.FAILED(error));
  }
}

const getReportDownload = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get(`/houseNew/download/${payload}/`, {
            responseType: "blob",
          })
        );
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          platformConditionals()?.pdfTitle ||
            "Report_houseplus_" + payload + ".pdf"
        );
        document.body.appendChild(link);
        link.click();

        // Clean up the object URL
        URL.revokeObjectURL(href);

        yield put(actions.GET_REPORT_DOWNLOAD.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_REPORT_DOWNLOAD.FAILED(err)
  );

const deleteHouse = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.delete(`/houseNew/${payload}`));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.DELETE_HOUSE.FAILED(err)
  );

const checkBoughtReport = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`/boughtReports/${payload}`));
        yield put(actions.CHECK_BOUGHT_REPORT.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.CHECK_BOUGHT_REPORT.FAILED(err)
  );

const demoContact = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post("/demo_contact", payload));
        yield put(actions.DEMO_CONTACT.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.DEMO_CONTACT.FAILED(err)
  );

export default function* () {
  yield takeLatest(types.GET_ALL_USER_HOUSES.REQUEST, getUserHouses);
  yield takeLatest(types.GET_HOUSE_BY_ID.REQUEST, getHouse);
  yield takeLatest(types.GET_REPORT_NEW.REQUEST, algoReportNew);
  yield takeLatest(types.ADD_HOUSE.REQUEST, addHouse);
  yield takeLatest(types.GET_PDF_ITEMS.REQUEST, getPdfItems);
  yield takeLatest(types.UPDATE_PDF_ITEMS.REQUEST, updatePdfItems);
  yield takeLatest(
    types.GET_HIDDEN_COMPARED_ITEMS.REQUEST,
    getHiddenComparedItems
  );
  yield takeLatest(
    types.UPDATE_HIDDEN_COMPARED_ITEMS.REQUEST,
    updateHiddenComparedItems
  );
  yield takeLatest(types.ADD_SOLD_PROPERTY.REQUEST, addSoldProperty);
  yield takeLatest(types.GET_SOLD_PROPERTIES.REQUEST, getSoldProperties);
  yield takeLatest(types.DELETE_SOLD_PROPERTY.REQUEST, deleteSoldProperty);
  yield takeLatest(types.GET_PDF_SIMULATOR.REQUEST, getPdfSimulator);
  yield takeLatest(types.SET_PDF_SIMULATOR.REQUEST, setPdfSimulator);
  yield takeLatest(
    types.GET_AGENT_CHARACTERISTICS.REQUEST,
    getAgentCharacteristics
  );
  yield takeLatest(
    types.SET_AGENT_CHARACTERISTICS.REQUEST,
    setAgentCharacteristics
  );
  yield takeLatest(types.GET_GALLERY.REQUEST, getGallery);
  yield takeLatest(types.DELETE_IMAGE.REQUEST, deleteImage);
  yield takeLatest(types.EDIT_IMAGE_FIELDS.REQUEST, editImageFields);
  yield takeLatest(types.FEATURED_IMAGE.REQUEST, featuredImage);
  yield takeLatest(types.UPLOAD_IMAGE.REQUEST, uploadImage);
  yield takeLatest(types.CHANGE_ORDER.REQUEST, changeOrder);
  yield takeLatest(types.GET_REPORT_DOWNLOAD.REQUEST, getReportDownload);
  yield takeLatest(types.DELETE_HOUSE.REQUEST, deleteHouse);
  yield takeLatest(types.CHECK_BOUGHT_REPORT.REQUEST, checkBoughtReport);
  yield takeLatest(types.DEMO_CONTACT.REQUEST, demoContact);
}
