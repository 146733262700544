import PdfCheckbox from "../../../PdfCheckbox";
import styles from "./CommonCard.module.scss";

const CommonCard = ({
  header,
  description,
  content,
  icon: Icon,
  marginTop,
  fontSize,
  titleSize,
  isPdf = false,
  section,
}) => (
  <div style={{ position: "relative", height: "100%" }}>
    {!isPdf && <PdfCheckbox top={"14px"} section={section} />}
    <div className={styles.common__card__wrapper}>
      <div className={styles.first__row}>
        <b className={styles.header} style={{ fontSize: titleSize }}>
          {header}
        </b>
      </div>
      <p className={styles.content} style={{ marginTop, fontSize }}>
        {content}
      </p>
      <p className={styles.chart__source}>{description}</p>
    </div>
  </div>
);

export default CommonCard;
