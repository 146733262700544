import * as yup from "yup";

export const INITIAL_VALUES = {
  Titolo: "",
  Indirizzo: {
    country: "",
    regione: "",
    provincia: "",
    city: "",
    buildingNumber: "",
    cap: "",
    street: "",
    Lat: "",
    Lng: "",
  },
  Tipologia: "",
  TotalePiani: 1,
  Piano: 0,
  PianiCasa: 1,
  Superficie: "",
  Bagni: 1,
  Cucina: "",
  Locali: 1,
  GiardinoPrivato: "",
  SuperficieGiardinoPrivato: "",
  GiardinoCondominiale: "",
  SuperficieGiardinoCondominiale: "",
  Balconi: "",
  SuperficieBalconi: "",
  Terrazzo: "",
  SuperficieTerrazzo: "",
  Soffitta: "",
  SuperficieSoffitta: "",
  Cantina: "",
  SuperficieCantina: "",
  Seminterrato: "",
  SuperficieSeminterrato: "",
  Ascensore: "",
  PostoAuto: [],
  SuperficiePostoAuto: "",
  SuperficiePostoAutoCondominiale: "",
  SuperficiePostoAutoGarage: "",
  Riscaldamento: "",
  TipologiaRiscaldamento: "",
  AlimentazioneRiscaldamento: "",
  SistemaAriaCondizionata: "",
  StatoInterni: "",
  ClasseEnergetica: "",
  Arredamento: "",
  AnnoCostruzione: "",
  CostiProprieta: "",
  CampiAddizionali: [],
  Prezzo: "",
};

export const VALIDATION_SCHEMA = yup.object().shape({
  Titolo: yup.string("Inserisci un valore").required("Titolo richiesto"),
  Indirizzo: yup.object().shape({
    Lat: yup.string("Inserisci un valore").required("Indirizzo richiesto"),
    Lng: yup.string("Inserisci un valore").required("Indirizzo richiesto"),
  }),
  Tipologia: yup
    .string("Inserisci un valore")
    .required("Tipologia proprietà richiesta"),
  TotalePiani: yup
    .string("Inserisci un valore")
    .required("Totale piani condominio richiesti"),
  Piano: yup
    .string("Inserisci un valore")
    .when("$hideElement.Piano", (anyName, schema) =>
      !anyName ? schema.required("Piano richiesto") : schema
    ),
  PianiCasa: yup
    .string("Inserisci un valore")
    .required("Piani dell'immobile richiesto"),
  Superficie: yup
    .number()
    .typeError(
      "Inserisci la superficie commerciale dell'immobile in metri quadri"
    )
    .min(1, "La superficie commerciale non può essere negativa")
    .max(1000000, "La superficie commerciale non può superare 1.000.000 m²")
    .required("Superficie richiesta"),
  Bagni: yup.string("Inserisci un valore").required("Quantità bagni richiesta"),
  Cucina: yup
    .string("Inserisci un valore")
    .required("Tipologia cucina richiesta"),
  Locali: yup
    .string("Inserisci un valore")
    .required("Quantità locali richiesta"),
  GiardinoPrivato: yup
    .string("Inserisci un valore")
    .when("$hideElement.GiardinoPrivato", (anyName, schema) =>
      !anyName ? schema.required("Giardino privato richiesto") : schema
    ),
  SuperficieGiardinoPrivato: yup
    .number()
    .typeError("Devi specificare un numero")
    .max(
      100000,
      "La superficie del giardino privato non può superare i 100.000 m²"
    )
    .when("$values", (anyName, schema) =>
      anyName.GiardinoPrivato != "No"
        ? schema
            .required("Superficie giardino privato richiesta")
            .min(
              1,
              "La superficie del giardino privato non può essere negativa"
            )
        : schema
    ),
  GiardinoCondominiale: yup
    .string("Inserisci un valore")
    .when("$hideElement.GiardinoCondominiale", (anyName, schema) =>
      !anyName ? schema.required("Giardino condominiale richiesto") : schema
    ),
  SuperficieGiardinoCondominiale: yup
    .number()
    .typeError("Devi specificare un numero")
    .max(
      100000,
      "La superficie del giardino condominiale non può superare i 100.000 m²"
    )
    .when("$values", (anyName, schema) =>
      anyName.GiardinoCondominiale != "No"
        ? schema
            .required("Superficie giardino condominiale richiesta")
            .min(
              1,
              "La superficie del giardino condominiale non può essere negativa"
            )
        : schema
    ),
  Balconi: yup.string("Inserisci un valore").required("Balconi richiesti"),
  SuperficieBalconi: yup
    .number()
    .typeError("Devi specificare un numero")
    .max(100000, "La superficie dei balconi non può superare i 100.000 m²")
    .when("$values", (anyName, schema) =>
      anyName.Balconi != "No"
        ? schema
            .required("Superficie balconi richiesta")
            .min(1, "La superficie dei balconi non può essere negativa")
        : schema
    ),
  Terrazzo: yup.string("Inserisci un valore").required("Terrazzo richiesto"),
  SuperficieTerrazzo: yup
    .number()
    .typeError("Devi specificare un numero")
    .max(100000, "La superficie del terrazzo non può superare i 100.000 m²")
    .when("$values", (anyName, schema) =>
      anyName.Terrazzo != "No"
        ? schema
            .required("Superficie terrazzo richiesta")
            .min(1, "La superficie del terrazzo non può essere negativa")
        : schema
    ),
  Soffitta: yup
    .string("Inserisci un valore")
    .when("$hideElement.Soffitta", (anyName, schema) =>
      !anyName ? schema.required("Soffitta richiesta") : schema
    ),
  SuperficieSoffitta: yup
    .number()
    .typeError("Devi specificare un numero")
    .max(100000, "La superficie della soffitta non può superare i 100.000 m²")
    .when("$values", (anyName, schema) =>
      anyName.Soffitta != "No"
        ? schema
            .required("Superficie soffitta richiesta")
            .min(1, "La superficie della soffitta non può essere negativa")
        : schema
    ),
  Cantina: yup
    .string("Inserisci un valore")
    .when("$hideElement.Cantina", (anyName, schema) =>
      !anyName ? schema.required("Cantina richiesta") : schema
    ),
  SuperficieCantina: yup
    .number()
    .typeError("Devi specificare un numero")
    .max(100000, "La superficie della cantina non può superare i 100.000 m²")
    .when("$values", (anyName, schema) =>
      anyName.Cantina != "No"
        ? schema
            .required("Superficie cantina richiesta")
            .min(1, "La superficie della cantina non può essere negativa")
        : schema
    ),
  Seminterrato: yup
    .string("Inserisci un valore")
    .when("$hideElement.Seminterrato", (anyName, schema) =>
      !anyName ? schema.required("Seminterrato richiesto") : schema
    ),
  SuperficieSeminterrato: yup
    .number()
    .typeError("Devi specificare un numero")
    .max(100000, "La superficie del seminterrato non può superare i 100.000 m²")
    .when("$values", (anyName, schema) =>
      anyName.Seminterrato != "No"
        ? schema
            .required("Superficie seminterrato richiesta")
            .min(1, "La superficie del seminterrato non può essere negativa")
        : schema
    ),
  Ascensore: yup
    .string("Inserisci un valore")
    .when("$hideElement.Ascensore", (anyName, schema) =>
      !anyName ? schema.required("Ascensore richiesto") : schema
    ),
  PostoAuto: yup.string("Inserisci un valore").required("Posto auto richiesto"),
  SuperficiePostoAuto: yup
    .number()
    .typeError("Devi specificare un numero")
    .max(100000, "La superficie dei posti auto non può superare i 100.000 m²")
    .when("$values", (anyName, schema) =>
      anyName.PostoAuto.includes("Privato")
        ? schema
            .required("Superficie posto auto richiesta")
            .min(1, "La superficie del posto auto non può essere negativa")
        : schema
    ),
  SuperficiePostoAutoCondominiale: yup
    .number()
    .typeError("Devi specificare un numero")
    .max(100000, "La superficie dei posti auto non può superare i 100.000 m²")
    .when("$values", (anyName, schema) =>
      anyName.PostoAuto.includes("Condominiale")
        ? schema
            .required("Superficie posto auto richiesta")
            .min(1, "La superficie del posto auto non può essere negativa")
        : schema
    ),
  SuperficiePostoAutoGarage: yup
    .number()
    .typeError("Devi specificare un numero")
    .max(100000, "La superficie dei posti auto non può superare i 100.000 m²")
    .when("$values", (anyName, schema) =>
      anyName.PostoAuto.includes("Garage")
        ? schema
            .required("Superficie posto auto richiesta")
            .min(1, "La superficie del posto auto non può essere negativa")
        : schema
    ),
  Riscaldamento: yup
    .string("Inserisci un valore")
    .required("Riscaldamento richiesto"),
  TipologiaRiscaldamento: yup
    .string("Inserisci un valore")
    .required("Tipologia riscaldamento richiesta"),
  AlimentazioneRiscaldamento: yup
    .string("Inserisci un valore")
    .required("Alimentazione riscaldamento richiesta"),
  SistemaAriaCondizionata: yup
    .string("Inserisci un valore")
    .required("Sistema aria condizionata richiesto"),
  StatoInterni: yup
    .string("Inserisci un valore")
    .required("Stato interni richiesto"),
  ClasseEnergetica: yup
    .string("Inserisci un valore")
    .required("Classe energetica richiesta"),
  Arredamento: yup
    .string("Inserisci un valore")
    .required("Arredamento richiesto"),
  AnnoCostruzione: yup
    .number()
    .typeError("Inserisci l'anno di costruzione dell'immobile")
    .min(1, "L'Anno di costruzione non può essere negativo")
    .max(2200, "L'Anno di costruzione non può superare il 2200")
    .integer("L'anno di costruzione deve essere un numero intero")
    .required("Anno di costruzione richiesto"),
  // .test(
  //   "len",
  //   "L'anno di costruzione deve avere al massimo 4 cifre",
  //   (value) => value && value.toString().length <= 4
  // ),
  CostiProprieta: yup
    .number("Inserisci un valore numerico")
    .transform((_value, originalValue) =>
      Number(originalValue.replace(/\D/g, ""))
    ) // transform value to be only numbers for "when" validation below
    .typeError("Inserisci un valore numerico")
    // .min(1, "I costi della proprietà non possono essere negativi")
    .max(
      1000000,
      "I costi della proprietà non possono superare 1.000.000 mensili"
    ),
  // .required("Costi della proprietà richiesti"),
  Prezzo: yup
    .number("Inserisci un valore numerico")
    .transform((_value, originalValue) =>
      Number(originalValue.replace(/\D/g, ""))
    ) // transform value to be only numbers for "when" validation below
    .typeError("Devi specificare un numero")
    // .min(1, "La tua valutazione non può essere negativa")
    .max(1000000000, "La tua valutazione non puo' superare 1.000.000.000"),
  // .when(
  //   ["prezzoPerMetroQuadro", "size"],
  //   (prezzoPerMetroQuadro, size, schema) =>
  //     schema.min(
  //       Number(prezzoPerMetroQuadro) > 0
  //         ? (prezzoPerMetroQuadro / 2) * size
  //         : 200 * size,
  //       "L'offerta inserita è troppo bassa. Inserisci un'offerta più in linea con i valori economici standard"
  //     )
  // ),
});

export const FORM_OPTIONS_STEP_2 = [
  {
    fieldName: "Tipologia",
    labelProperty: "Tipo di proprietà",
    stepId: 2,
    acceptValues: [
      {
        nameOption: "Appartamento",
      },
      {
        nameOption: "Attico",
      },
      {
        nameOption: "Villa",
      },
      {
        nameOption: "Villetta",
      },
    ],
  },
  {
    fieldName: "TotalePiani",
    labelProperty: "Piani del palazzo",
    min: 1,
    stepId: 2,
    isNumberField: true,
  },
  {
    fieldName: "Piano",
    labelProperty: "A che piano è situato l'immobile",
    stepId: 2,
    isNumberField: true,
  },
  {
    fieldName: "PianiCasa",
    labelProperty: "Immobile su più piani? indica il numero",
    min: 1,
    stepId: 2,
    isNumberField: true,
  },
  {
    fieldName: "Superficie",
    labelProperty: "Superficie calpestabile immobile in m²",
    isTextField: true,
    stepId: 2,
  },
  {
    fieldName: "Bagni",
    labelProperty: "Numero di bagni",
    min: 1,
    isNumberField: true,
    stepId: 2,
  },
  {
    fieldName: "Cucina",
    labelProperty: "Tipologia cucina",
    stepId: 2,
    acceptValues: [
      {
        nameOption: "Angolo cottura",
      },
      {
        nameOption: "A vista",
      },
      {
        nameOption: "Cucinotto",
      },
      {
        nameOption: "Semiabitabile",
      },
      {
        nameOption: "Abitabile",
      },
    ],
  },
  {
    fieldName: "Locali",
    labelProperty: "Numero di stanze",
    isNumberField: true,
    stepId: 2,
    min: 1,
  },
];

export const FORM_OPTIONS_STEP_3 = [
  {
    fieldName: "GiardinoPrivato",
    labelProperty: "Giardino di proprietà",
    stepId: 3,
    acceptValues: [
      {
        nameOption: "Si",
      },
      {
        nameOption: "No",
      },
    ],
  },
  {
    fieldName: "SuperficieGiardinoPrivato",
    labelProperty: "Superficie giardino di proprietà in m²",
    isTextField: true,
    stepId: 3,
  },
  {
    fieldName: "GiardinoCondominiale",
    labelProperty: "Giardino condominiale",
    stepId: 3,
    acceptValues: [
      {
        nameOption: "Si",
      },
      {
        nameOption: "No",
      },
    ],
  },
  {
    fieldName: "SuperficieGiardinoCondominiale",
    labelProperty: "Superficie giardino condominiale in m²",
    isTextField: true,
    stepId: 3,
  },
  {
    fieldName: "Balconi",
    labelProperty: "Balconi",
    stepId: 3,
    acceptValues: [
      {
        nameOption: "Si",
      },
      {
        nameOption: "No",
      },
    ],
  },
  {
    fieldName: "SuperficieBalconi",
    labelProperty: "Superficie balconi in m²",
    isTextField: true,
    stepId: 3,
  },
  {
    fieldName: "Terrazzo",
    labelProperty: "Terrazzo",
    stepId: 3,
    acceptValues: [
      {
        nameOption: "Si",
      },
      {
        nameOption: "No",
      },
    ],
  },
  {
    fieldName: "SuperficieTerrazzo",
    labelProperty: "Superficie terrazzo in m²",
    isTextField: true,
    stepId: 3,
  },
  {
    fieldName: "Soffitta",
    labelProperty: "Soffitta",
    stepId: 3,
    acceptValues: [
      {
        nameOption: "Si",
      },
      {
        nameOption: "No",
      },
    ],
  },
  {
    fieldName: "SuperficieSoffitta",
    labelProperty: "Superficie soffitta in m²",
    isTextField: true,
    stepId: 3,
  },
  {
    fieldName: "Cantina",
    labelProperty: "Cantina",
    stepId: 3,
    acceptValues: [
      {
        nameOption: "Si",
      },
      {
        nameOption: "No",
      },
    ],
  },
  {
    fieldName: "SuperficieCantina",
    labelProperty: "Superficie cantina in m²",
    isTextField: true,
    stepId: 3,
  },
  {
    fieldName: "Seminterrato",
    labelProperty: "Seminterrato",
    stepId: 3,
    acceptValues: [
      {
        nameOption: "Si",
      },
      {
        nameOption: "No",
      },
    ],
  },
  {
    fieldName: "SuperficieSeminterrato",
    labelProperty: "Superficie seminterrato in m²",
    isTextField: true,
    stepId: 3,
  },
  {
    fieldName: "Ascensore",
    labelProperty: "Ascensore",
    stepId: 3,
    acceptValues: [
      {
        nameOption: "Si",
      },
      {
        nameOption: "No",
      },
    ],
  },
];

export const FORM_OPTIONS_STEP_4 = [
  {
    fieldName: "PostoAuto",
    labelProperty: "Posto Auto",
    isMulti: true,
    stepId: 4,
    acceptValues: [
      {
        nameOption: "Nessuno",
      },
      {
        nameOption: "Condominiale",
      },
      {
        nameOption: "Privato",
      },
      {
        nameOption: "Garage",
      },
    ],
  },
  {
    fieldName: "SuperficiePostoAuto",
    labelProperty: "Superficie posto auto privato in m²",
    isTextField: true,
    stepId: 4,
  },
  {
    fieldName: "SuperficiePostoAutoCondominiale",
    labelProperty: "Superficie posto auto condominiale in m²",
    isTextField: true,
    stepId: 4,
  },
  {
    fieldName: "SuperficiePostoAutoGarage",
    labelProperty: "Superficie garage in m²",
    isTextField: true,
    stepId: 4,
  },
  {
    fieldName: "Riscaldamento",
    labelProperty: "Riscaldamento",
    stepId: 4,
    acceptValues: [
      {
        nameOption: "Centralizzato",
      },
      {
        nameOption: "Autonomo",
      },
    ],
  },
  {
    fieldName: "TipologiaRiscaldamento",
    labelProperty: "Tipologia Riscaldamento",
    stepId: 4,
    acceptValues: [
      {
        nameOption: "Sconosciuto",
      },
      {
        nameOption: "Aria",
      },
      {
        nameOption: "Pavimento",
      },
      {
        nameOption: "Radiatori",
      },
      {
        nameOption: "Stufa",
      },
    ],
  },
  {
    fieldName: "AlimentazioneRiscaldamento",
    labelProperty: "Alimentazione Riscaldamento",
    isSelectField: true,
    stepId: 4,
    acceptValues: [
      {
        id: "alim_risca_1",
        value: "Sconosciuto",
        label: "Sconosciuto",
      },
      {
        id: "alim_risca_2",
        value: "Calore",
        label: "Calore",
      },
      {
        id: "alim_risca_3",
        value: "Elettrica",
        label: "Elettrica",
      },
      {
        id: "alim_risca_4",
        value: "Fotovoltaico",
        label: "Fotovoltaico",
      },
      {
        id: "alim_risca_5",
        value: "Gas",
        label: "Gas",
      },
      {
        id: "alim_risca_6",
        value: "Gasolio",
        label: "Gasolio",
      },
      {
        id: "alim_risca_7",
        value: "Gpl",
        label: "Gpl",
      },
      {
        id: "alim_risca_8",
        value: "Legna",
        label: "Legna",
      },
      {
        id: "alim_risca_9",
        value: "Metano",
        label: "Metano",
      },
      {
        id: "alim_risca_10",
        value: "Pellet",
        label: "Pellet",
      },
      {
        id: "alim_risca_11",
        value: "Solare",
        label: "Solare",
      },
      {
        id: "alim_risca_12",
        value: "TeleRiscaldamento",
        label: "TeleRiscaldamento",
      },
    ],
  },
  {
    fieldName: "SistemaAriaCondizionata",
    labelProperty: "Sistema Aria Condizionata",
    stepId: 4,
    acceptValues: [
      {
        nameOption: "Nessuno",
      },
      {
        nameOption: "Autonomo",
      },
      {
        nameOption: "Centralizzato",
      },
      {
        nameOption: "Predisposizione",
      },
    ],
  },
  {
    fieldName: "StatoInterni",
    labelProperty: "Stato degli interni",
    stepId: 4,
    acceptValues: [
      {
        nameOption: "Da ristrutturare",
      },
      {
        nameOption: "Ristrutturato",
      },
      {
        nameOption: "Nuovo",
      },
      {
        nameOption: "Buono, abitabile",
      },
    ],
  },
  {
    fieldName: "ClasseEnergetica",
    labelProperty: "Classe di efficienza energetica",
    isSelectField: true,
    stepId: 4,
    acceptValues: [
      {
        id: "clenergetica_1",
        value: "A4",
        label: "A4",
      },
      {
        id: "clenergetica_2",
        value: "A3",
        label: "A3",
      },
      {
        id: "clenergetica_3",
        value: "A2",
        label: "A2",
      },
      {
        id: "clenergetica_4",
        value: "A1",
        label: "A1",
      },
      {
        id: "clenergetica_5",
        value: "B",
        label: "B",
      },
      {
        id: "clenergetica_6",
        value: "C",
        label: "C",
      },
      {
        id: "clenergetica_7",
        value: "D",
        label: "D",
      },
      {
        id: "clenergetica_8",
        value: "E",
        label: "E",
      },
      {
        id: "clenergetica_9",
        value: "F",
        label: "F",
      },
      {
        id: "clenergetica_10",
        value: "G",
        label: "G",
      },
      {
        id: "clenergetica_11",
        value: "Sconosciuta",
        label: "Sconosciuta",
      },
    ],
  },
  {
    fieldName: "Arredamento",
    labelProperty: "L'immobile viene venduto",
    stepId: 4,
    acceptValues: [
      {
        nameOption: "Nessuno",
      },
      {
        nameOption: "Solo cucina arredata",
      },
      {
        nameOption: "Parzialmente arredata",
      },
      {
        nameOption: "Completamente arredata",
      },
    ],
  },
  {
    fieldName: "AnnoCostruzione",
    labelProperty: "Anno costruzione palazzo",
    stepId: 4,
    isTextField: true,
  },
  {
    fieldName: "CostiProprieta",
    labelProperty:
      "Costi mensili della proprietà in € (es. spese di condominio)",
    stepId: 4,
    isTextField: true,
    optionalField: true,
  },
  {
    fieldName: "CampiAddizionali",
    labelProperty: "Altre caratteristiche",
    optionalField: true,
    stepId: 4,
    isMultiSelect: true,
    acceptValues: [
      {
        label: "Immobile di lusso",
        value: "ImmobileDiLusso",
      },
      {
        label: "Portineria",
        value: "Portineria",
      },
      {
        label: "Immobile a zero emissioni",
        value: "ImmobileAZeroEmissioni",
      },
      {
        label: "Campo tennis",
        value: "CampoTennis",
      },
      {
        label: "Piscina",
        value: "Piscina",
      },
      {
        label: "Jacuzzi",
        value: "Jacuzzi",
      },
      {
        label: "Pompa di calore",
        value: "PompaDiCalore",
      },
      // {
      //   label: "Aria condizionata",
      //   value: "AriaCondizionata",
      // },
      {
        label: "Esposizione interna",
        value: "EsposizioneInterna",
      },
      {
        label: "Esposizione esterna",
        value: "EsposizioneEsterna",
      },
      {
        label: "Camino",
        value: "Camino",
      },
    ],
  },
  {
    fieldName: "Prezzo",
    labelProperty: "Inserisci la tua valutazione in €",
    optionalField: true,
    stepId: 4,
    isTextField: true,
  },
];

export const FORM_OPTIONS_ADDRESS = [
  {
    fieldName: "country",
    stepId: 1,
  },
  {
    fieldName: "regione",
    stepId: 1,
  },
  {
    fieldName: "provincia",
    stepId: 1,
  },
  {
    fieldName: "city",
    stepId: 1,
  },
  {
    fieldName: "buildingNumber",
    stepId: 1,
  },
  {
    fieldName: "cap",
    stepId: 1,
  },
  {
    fieldName: "street",
    stepId: 1,
  },
  {
    fieldName: "Lat",
    stepId: 1,
  },
  {
    fieldName: "Lng",
    stepId: 1,
  },
];
