import clx from "classnames";

import styles from "./PrimaryButton.module.scss";

const PrimaryButton = ({
  background,
  color = "#FFFFFF",
  height = "40px",
  width = "auto",
  minWidth,
  fontSize = "20px",
  padding = "15px",
  margin,
  marginRight,
  marginTop,
  alignItems,
  borderRadius = "10px",
  border,
  text,
  leftIconSrc: LeftIconSrc,
  rightIconSrc,
  isReactIcon,
  viewBox,
  onClick,
  className,
  imgHeight,
  type,
  iconHeight,
  disabled,
}) => (
  <button
    style={{
      height,
      width,
      minWidth,
      fontSize,
      borderRadius,
      border,
      padding,
      margin,
      marginTop,
      color,
      background,
      alignItems,
    }}
    className={clx(styles.primary__btn, styles[`${className}`])}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    {LeftIconSrc &&
      (!isReactIcon ? (
        <img
          className={styles["left-icon"]}
          style={{ marginRight, height: imgHeight }}
          src={LeftIconSrc}
          alt="icon"
        />
      ) : (
        <LeftIconSrc viewBox={viewBox} />
      ))}
    {text && <span>{text}</span>}
    {rightIconSrc && (
      <img
        className={styles["right-icon"]}
        src={rightIconSrc}
        alt="icon"
        style={{ height: iconHeight }}
      />
    )}
  </button>
);

export default PrimaryButton;
