import React from "react";
import styles from "./MapSection.module.scss";
import Price from "./containers/Price";
import Map from "./containers/Map";

const MapSection = React.forwardRef(({ houseInfo, model }, ref) => {
  return (
    <div className={styles.map_section} ref={ref}>
      {/* PRICES */}
      <Price model={model} />

      {/* MAP */}
      <Map houseInfo={houseInfo} />
    </div>
  );
});

export default MapSection;
