const ChartsAxisLabel = ({
	x, y, stroke, payload, dy, textAnchor, dx, locale = true
}) => {
	const value = (typeof payload.value == 'number' && locale) ? payload.value.toLocaleString('it-IT') : payload.value;

	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={dy}
				dx={dx}
				textAnchor={textAnchor} fill="#666" style={{ fontSize: 14 }}>
				{value}
			</text>
		</g>
	);
};

export default ChartsAxisLabel;