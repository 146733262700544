import clx from "classnames";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import styles from "./NumberField.module.scss";

const NumberField = ({
  field,
  form: { touched, errors, setFieldValue, values },
  className,
  desc = "",
  label,
  min = 0,
  max = 10000,
  inputColor = "#ffff",
  borderRadius = "5px",
  border,
  height = "50px",
  handleSelect,
  ...props
}) => {
  const isError = touched[field.name] && errors[field.name];

  return (
    <div className={clx(styles.field__wrapper, styles[`${className}`])}>
      {label && (
        <label className={styles["field__label"]} htmlFor={field.name}>
          {label}
        </label>
      )}
      <div
        className={clx(styles["input-group"], {
          [styles["error-field"]]: isError,
        })}
        style={{
          borderRadius,
          border,
          height,
          background: `${inputColor}`,
        }}
      >
        <input
          className={styles["field__input"]}
          type="number"
          min={min}
          onKeyDown={(e) => {
            e.preventDefault(); //can't add text
          }}
          {...field}
          {...props}
          style={{
            background: `${inputColor}`,
          }}
        />
        <div className={styles.number_btns}>
          <PrimaryButton
            width="40px"
            height="25px"
            text="+"
            borderRadius="0px 0px 3px 3px"
            type="button"
            disabled={+field.value === max}
            onClick={() => {
              // Piano increasing Logic
              if (field.name == "Piano") {
                // Logic to auto decrease PianiCasa if the subtraction of TotalePiani and Piano is less than PianiCasa
                if (
                  +values.TotalePiani - (+field.value + 1) <=
                    +values.PianiCasa &&
                  +values.PianiCasa > 1
                ) {
                  setFieldValue(
                    "PianiCasa",
                    +values.TotalePiani - (+field.value + 1)
                  );
                }

                // To hide fields based on Piano
                handleSelect(+field.value + 1);
              }

              setFieldValue(field.name, +field.value + 1);
            }}
          />
          <PrimaryButton
            width="40px"
            height="25px"
            text="-"
            borderRadius="3px 3px 0px 0px"
            disabled={+field.value === min}
            type="button"
            onClick={() => {
              // TotalePiani decreasing Logic
              if (field.name == "TotalePiani") {
                setFieldValue(field.name, +field.value - 1);

                // Logic to auto decrease Piano if TotalePiani is decreased
                if (+field.value - 1 < +values.Piano) {
                  setFieldValue("Piano", +field.value - 1);
                }

                // Logic to auto decrease PianiCasa if the subtraction of TotalePiani and Piano is less than PianiCasa
                if (
                  +field.value - 1 - +values.Piano < +values.PianiCasa &&
                  +values.PianiCasa > 1
                ) {
                  setFieldValue("PianiCasa", +field.value - 1 - +values.Piano);
                }
              } else {
                setFieldValue(field.name, +field.value - 1);
              }

              // To hide fields based on Piano
              if (
                (field.name == "Piano" || field.name == "TotalePiani") &&
                +field.value - 1 < +values.Piano
              )
                handleSelect(+values.Piano - 1);
            }}
          />
        </div>
      </div>
      {isError && <div className={styles.error}>{errors[field.name]}</div>}
    </div>
  );
};

export default NumberField;
