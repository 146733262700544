import React from "react";
import ReactToolTip from "../../../../../../../Common/ToolTip/ReactToolTip";

const PinMap = ({
  setActivePointGroup,
  resetActivePointGroup,
  service,
  key,
  customClass,
  data,
}) => (
  <>
    <ReactToolTip
      contenet={
        <div
          style={{
            color: "gray",
            minHeight: "max-content",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <h4>{data?.name} </h4>
          <h6>Servizio: {service?.name}</h6>
          <p>
            distanza:{" "}
            {data?.distance > 0.5
              ? `${data?.distance.toFixed(2)}m`
              : `${Math.round(data?.distance * 100)}m`}
          </p>
        </div>
      }
      containerClass="tooltipStyleChange"
      x={-87}
      y={70}
      width={289}
      height={"auto"}
      arrow={{
        x: -3,
        y: 74,
        rotate: "206deg",
      }}
    >
      <img
        onMouseEnter={() => setActivePointGroup(service.id)}
        onMouseLeave={resetActivePointGroup}
        className={customClass}
        src={service.img}
        key={key}
        alt={"point"}
      />
    </ReactToolTip>
  </>
);

export default PinMap;
