import EmailIcon from "@material-ui/icons/Email";
import React from "react";

import { ReactComponent as Marker } from "../../../../assets/svg/markercurrentcolor.svg";
import styles from "./SixteenthPg.module.scss";

const SixteenthPage = ({
  addresses,
  agency_name,
  email,
  piva,
  business_name,
  pec,
  profileImg,
}) => {
  // console.log(addresses, "addresses");
  const getAddressAgenzy = () => {
    let address = "";
    if (addresses != undefined) {
      return (
        <span>
          {addresses[0]?.street || ""},&nbsp;{addresses[0]?.number || ""}{" "}
          ,&nbsp;
          {addresses[0]?.city || ""}&nbsp;({addresses[0]?.province || ""})
        </span>
      );
    }
    return address;
  };

  return (
    <div className={styles.area__services__wrapper}>
      <h3 className={styles.header__text}>La tua agenzia immobiliare</h3>
      <div className={styles.agency_info}>
        {profileImg && <img src={profileImg} />}
        <div className={styles.agency_info_text}>
          <p>{agency_name}</p>
          <p style={{ marginLeft: "-3px" }}>
            <EmailIcon /> {email}
          </p>
          <p>
            <Marker /> {getAddressAgenzy()}
          </p>
          <p>{"P.IVA " + piva}</p>
        </div>
      </div>
    </div>
  );
};

export default SixteenthPage;
