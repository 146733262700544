import { types } from "./actions";

const initialState = {
  error: null,
  user: {
    type: null, // explicitly set isAdmin to null initially
  },
  // token: null,
  isLogin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SIGN_IN.REQUEST:
      return {
        ...state,
        error: null,
      };
    case types.ADD_LOGO_AGENCY.REQUEST:
      return {
        ...state,
      };
    case types.SIGN_IN.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.FETCH_USER.SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case types.SIGN_IN.SUCCESS:
      return {
        ...state,
        // token: action.payload,
        error: null,
        loading: false,
        isLogin: true,
      };
    case types.LOG_OUT.SUCCESS:
      return {
        ...state,
        error: null,
        isLogin: false,
        user: {},
      };
    case types.CHANGE_USER_IMAGE:
      return {
        ...state,
        isLogin: true,
      };
    default:
      return { ...state };
  }
};
