import React, { useEffect, useMemo, useState } from "react";

import centriEsteticiMaker from "../../../../assets/svg/map/centriEstetici.svg";
import DentistMarker from "../../../../assets/svg/map/dent.svg";
import dottoreMaker from "../../../../assets/svg/map/dottore.svg";
import farmaciayMaker from "../../../../assets/svg/map/farmacia.svg";
import GreenSpace from "../../../../assets/svg/map/green.svg";
import HospitalMarker from "../../../../assets/svg/map/hospital.svg";
import ParkingMarker from "../../../../assets/svg/map/parching.svg";
import SchoolMarker from "../../../../assets/svg/map/school.svg";
import GroceryStoreMarker from "../../../../assets/svg/map/shop-food.svg";
import TransportMarker from "../../../../assets/svg/map/transport.svg";
import TransportMarkerTrain from "../../../../assets/svg/map/transportTrain.svg";
import UniversityMaker from "../../../../assets/svg/map/univerity.svg";
import Bus from "../../../../assets/svg/map/bus.svg";
import styles from "./TwelfthPg.module.scss";
import "./TwelfthPgLeaflet.scss";
import LeafletMap from "../../../Common/LeafletMap";
import platformConditionals from "../../../../utils/platformConditionals";

const TwelfthPg = ({ data, lat, lng }) => {
  const values = useMemo(
    () => [
      {
        data_name: "beauty",
        display_name: "Centri estetici",
        icon: centriEsteticiMaker,
        color: "#BA44E4",
      },
      {
        data_name: "parking",
        display_name: "Parcheggi",
        icon: ParkingMarker,
        color: "#568C92",
      },
      {
        data_name: "supermarket",
        display_name: "Supermercati",
        icon: GroceryStoreMarker,
        color: "#E69C37",
      },
      {
        data_name: "university",
        display_name: "Universita'",
        icon: UniversityMaker,
        color: "#27A4FF",
      },
      {
        data_name: "school",
        display_name: "Scuole",
        icon: SchoolMarker,
        color: "#29A5B3",
      },
      {
        data_name: "hospital",
        display_name: "Ospedali",
        icon: HospitalMarker,
        color: "#4581E2",
      },
      {
        data_name: "dentist",
        display_name: "Dentisti",
        icon: DentistMarker,
        color: "#779D4F",
      },
      {
        data_name: "medical_centers",
        display_name: "Centri medici",
        icon: dottoreMaker,
        color: "#C4EBCA",
      },
      {
        data_name: "green_outdoors",
        display_name: "Spazi verdi",
        icon: GreenSpace,
        color: "#4E7425",
      },
      {
        data_name: "pharmacy",
        display_name: "Farmacie",
        icon: farmaciayMaker,
        color: "#008A15",
      },
      {
        data_name: "bus",
        display_name: "Bus",
        icon: Bus,
        color: "#D95040",
      },
      {
        data_name: "metro",
        display_name: "Metro",
        icon: TransportMarker,
        color: "#D95040",
      },
      {
        data_name: "train",
        display_name: "Treni",
        icon: TransportMarkerTrain,
        color: "#B3663B",
      },
    ],
    []
  );

  const [activeServices, setActiveServices] = useState();
  const [markers, setMarkers] = useState();

  useEffect(() => {
    // const tempData = {...data};
    const activeData = values
      ?.map((value) => {
        const obj = data?.[value.data_name];
        if (obj) {
          obj.serviceName = value.display_name;
          obj.icon = value.icon;
          const updatedServices = obj.services_found.map((service) => ({
            ...service,
            color: value.color,
          }));
          obj.services_found = updatedServices;
        }
        return obj;
      })
      .filter((item) => item?.services_found?.length);
    // console.log(activeData);
    setActiveServices(activeData);
    setMarkers(
      activeData
        .flatMap((item) => item.services_found)
        .filter((item) => item.distance <= 500)
    );
  }, [values, data]);

  // const generateMapUrl = (lat, lng, markers) => {
  //   const markerParams = markers
  //     ?.map(
  //       (marker) =>
  //         `&markers=size:tiny%7Ccolor:${marker.color}|${marker.lat},${marker.lng}`
  //     )
  //     .join("&");
  //   const baseUrl = "https://maps.googleapis.com/maps/api/staticmap?";
  //   const params = `style=feature:poi|visibility:off&center=${lat},${lng}&markers=${markerParams}&markers=color:0x${
  //     platformConditionals()?.pdfHomeIconColor
  //   }%7C${lat},${lng}&size=770x400&key=AIzaSyDhU_uaWxC4hWjLecwUvBW7RzqauLXfpVw&style=saturation:-100&scale=2&maptype=roadmap&zoom=15`;
  //   return `${baseUrl}${params}`;
  // };

  // const [staticMap, setStaticMap] = useState("");

  // useEffect(() => {
  //   if (lat && lng) {
  //     const map = generateMapUrl(lat, lng, markers);
  //     setStaticMap(map);
  //   }
  // }, [lat, lng, markers]);

  //console.log(markers, "markers");

  return (
    <div className={styles.area__services__wrapper} id="twelfth_pg">
      <h3 className={styles.header__text}>Servizi di zona</h3>
      <div className={styles.map}>
        {/* <img src={staticMap} alt="Map" /> */}
        <LeafletMap
          lat={lat}
          lng={lng}
          // svgIconHome={`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          //     viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          //  <g>
          //    <g>
          //      <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5
          //        c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
          //        C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333
          //        s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z" fill="currentcolor"/>
          //    </g>
          //  </g>
          //  </svg>

          //     `}
          imgIconHome={platformConditionals()?.staticMapPin}
          svgIconMarkers={markers}
          isSvgElement={false}
          iconSize={[36, 36]}
          className="svg-icon-leaflet"
          zoom={16}
          radius={500}
        />
        <div className={styles.map__legend}>Raggio di 500m</div>
      </div>
      <div className={styles.services}>
        {activeServices?.slice(0, 6).map((item, index) => (
          <div key={index} className={styles.service}>
            <div className={styles.service_title}>
              <img src={item.icon} alt="service-logo" /> {item.serviceName}
            </div>
            <div className={styles.service_content}>
              <div className={styles.service_content_distance}>
                <p className={styles.service_content_distance_title}>Km.</p>
                {item.services_found.map((serviceItem, index) => (
                  <p
                    key={index}
                    className={styles.service_content_distance_content}
                  >
                    {(serviceItem.distance / 1000).toFixed(2)}
                  </p>
                ))}
              </div>
              <div className={styles.service_content_name}>
                <p className={styles.service_content_name_title}>Nome</p>
                {item.services_found.map((serviceItem, index) => (
                  <p
                    key={index}
                    className={styles.service_content_name_content}
                  >
                    {serviceItem.name.substring(0, 25)}
                    {serviceItem.name.length > 25 && "..."}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TwelfthPg;
