import clx from "classnames";
import React from "react";

import { formatNumber } from "../../../../utils/formatNumber";
import NoChartsData from "../../../AlgorithmReport/containers/NoChartsData";
import GenderDistributionArea from "../../../AlgorithmReport/containers/SocioDemographicArea/containers/GenderDistributionArea";
import PopulationChange from "../../../AlgorithmReport/containers/SocioDemographicArea/containers/PopulationChange";
import PopulationDensityArea from "../../../AlgorithmReport/containers/SocioDemographicArea/containers/PopulationDensityArea";
import styles from "./SixthPg.module.scss";

const SixthPg = ({
  populationChange,
  genderDistributionArea,
  populationDensityArea,
  town,
  pdfItems,
}) => {
  // console.log(populationChange, 'POP CHANGE');
  const firstYear = populationChange?.graph_data?.data?.x[0];
  const lastYear =
    populationChange?.graph_data?.data?.x[
      populationChange?.graph_data?.data?.x.length - 1
    ];
  const firstYearAmount = populationChange?.graph_data?.data?.y[0];
  const lastYearAmount =
    populationChange?.graph_data?.data?.y[
      populationChange?.graph_data?.data?.y.length - 1
    ];
  const difference = lastYearAmount - firstYearAmount;
  const differencePercent = (difference / firstYearAmount) * 100;
  const changeType =
    (difference / firstYearAmount) * 100 >= 0 ? "incremento" : "decremento";
  // console.log(changeType);

  return (
    <div className={clx(styles.area__services__wrapper)}>
      <h3 className={styles.header__text}>Informazioni socio-demografiche</h3>
      <p className={styles.secondary__text}>
        Approfondisci le informazioni sociali e demografiche essenziali relative
        comune di appartenenza dell’immobile.
      </p>
      <div className={styles.area__services__main}>
        {!pdfItems?.["population_change"] && (
          <div
            className={clx(styles.pop__change, styles.general__card)}
            style={{ position: "relative" }}
          >
            <div className={styles.chart_info}>
              Nel <b>{firstYear}</b> i residenti a <b>{town}</b> erano{" "}
              <b>{formatNumber(firstYearAmount)}</b> mentre nel{" "}
              <b>{lastYear}</b> sono <b>{formatNumber(lastYearAmount)}</b> con
              un {changeType} del <b>{differencePercent.toFixed(2)}%</b>
            </div>
            <div>
              {populationChange?.graph_status == "OK" ? (
                <PopulationChange
                  data={populationChange?.graph_data}
                  minHeight={300}
                  wrapperHeight={"450px"}
                  locale={false}
                  paddingTop={"30px"}
                  chartMarginTop={"50px"}
                  isPdf={true}
                />
              ) : (
                <NoChartsData title={"Andamento demografico"} />
              )}
            </div>
          </div>
        )}
        {!pdfItems?.["gender_distribution"] && (
          <div
            className={clx(
              styles.gender__distribution__area,
              styles.general__card
            )}
          >
            {genderDistributionArea?.graph_status == "OK" ? (
              <GenderDistributionArea
                data={genderDistributionArea?.graph_data}
                marginTop="35px"
                minHeight={"400px"}
                isPdf={true}
              />
            ) : (
              <NoChartsData title={"Distribuzione genere"} />
            )}
          </div>
        )}
        {!pdfItems?.["population_density"] && (
          <div
            className={clx(
              styles.population__density__area,
              styles.general__card
            )}
          >
            {populationDensityArea?.graph_status == "OK" ? (
              <PopulationDensityArea
                data={populationDensityArea?.graph_data}
                marginTopValue={"70px"}
                minHeight={"400px"}
                isPdf={true}
              />
            ) : (
              <NoChartsData title={"Densità popolazione"} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SixthPg;
