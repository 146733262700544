import styles from "./Report.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../routes/constants";
import { TABLE_KEYS } from "../Common/DataTables/constants";
import { actions } from "./store";
import { getUserHouses } from "./store/selectors";

import ConnectIcon from "../../assets/svg/connection.svg";
import PrimaryButton from "../Common/Buttons/PrimaryButton";
import DataTableCustomized from "../Common/DataTables/containers/DataTableCustomized";
import Modal from "../Common/Modal";
import { CreateReportModal } from "../CreateReport/containers/CreateReportModals";
// import probVenditaCopy from "./utils/probVenditaCopy";
import tableColumns from "./utils/tableColumns";

const Report = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const userHouses = useSelector(getUserHouses());
  // console.log(userHouses, "userHouses");

  // Modal states
  const [isCreateReportModalOpen, setIsCreateReportModalOpen] = useState(false);
  const [confirmCopiedModal, setConfirmCopiedModal] = useState(false);

  // Fetch all user houses
  useEffect(() => {
    dispatch(actions.GET_ALL_USER_HOUSES.REQUEST());
  }, [dispatch]);

  // Function firing on user click on copy button, opens modal and fetch selected house by id
  const copyHandler = (id) => {
    dispatch(actions.GET_HOUSE_BY_ID.REQUEST(id));
    setConfirmCopiedModal(true);
  };

  return (
    <div className={styles["report__wrapper"]}>
      {/* HEADER */}
      <div className={styles["report__header"]}>
        <h2>Immobili e Report</h2>
        <PrimaryButton
          leftIconSrc={ConnectIcon}
          width="180px"
          height="45px"
          fontSize="17px"
          margin="0 5px 0 auto"
          text="Crea Report"
          onClick={() => setIsCreateReportModalOpen(true)}
        />
        <CreateReportModal
          isCreateReportModalOpen={isCreateReportModalOpen}
          setIsCreateReportModalOpen={setIsCreateReportModalOpen}
        />
      </div>

      {/* TABLE */}
      <DataTableCustomized
        rowsPerPageNum={8}
        tableData={
          userHouses?.map((house) => ({
            id: house.id,
            // title: `#${house.id} ${house.Titolo}`,
            title: house.Titolo,
            city: `${house.city ? house.city + "" : ""}`,
            address: `${
              house.street
                ? house.street +
                  (house.buildingNumber ? ", " + house.buildingNumber : "")
                : ""
            }`,
            type: `${house.Tipologia || ""} `,
            size: ` ${house.Superficie + "m²" || ""}`,
            condition: `${house.StatoInterni || ""} `,
            heating: `${house.Riscaldamento || ""}`,
            price: (
              <div>
                <b style={{ fontSize: 14, fontWeight: 700 }}>
                  €{" "}
                  {parseInt(house.proba_180)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".") || "-"}
                </b>
              </div>
            ),
            // probability: probVenditaCopy(house?.proba_180),
            daysOnMarket: house?.GiorniMercato || 0,
          }))
          // .reverse()
        }
        uniqueKey={`${pathname}/ ${TABLE_KEYS.REPORT}`}
        tableColumns={tableColumns}
        copyHandler={copyHandler}
      />

      {/* COPY MODAL */}
      <Modal
        padding="60px 40px"
        maxWidth="500px"
        showCloseBtn={() => setConfirmCopiedModal(false)}
        isModalOpen={confirmCopiedModal}
        setIsModalOpen={() => setConfirmCopiedModal(false)}
      >
        <div
          style={{
            fontSize: "20px",
            fontWeight: "700",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          Vuoi analizzare un nuovo immobile partendo dalle stesse
          caratteristiche di questo selezionato?
        </div>
        <div
          className={styles.copy_btn}
          onClick={() =>
            navigate(ROUTES.CREATE_REPORT, { state: { copy: true } })
          }
        >
          <PrimaryButton
            height="40px"
            text="continua"
            padding="5px 15px"
            fontSize="14px"
            borderRadius="10px"
          />
        </div>
      </Modal>
    </div>
  );
};

export default Report;
