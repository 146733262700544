import actionCreator from "../../../utils/actionCreator";

const PaymentsAndCreditsActionTypes = [
  "GET_USER_PORTAL",
  "GET_TRANSACTIONS",
  "GET_TRANSACTIONS_NEW",
  "CHECK_USER_CREDITS",
  "GET_PAYMENT_URL_PACKAGE",
  "GET_PAYMENT_URL_PACKAGE_PRIVATE",
  "GET_PAYMENT_URL_SUBSCRIPTION_HOUSEPLUS",
  "GET_PAYMENT_URL_SUBSCRIPTION_MONDO",
  "CHECK_USER_SUBSCRIPTION",
  // "GET_PRODUCTS",
];

export const { types, actions } = actionCreator(PaymentsAndCreditsActionTypes);
