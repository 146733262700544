const translateDisplayValue = displayVal => {
	if (typeof displayVal !== 'string') {
		return '';
	}
	const numericVal = Number(displayVal?.split(' ')[0]).toLocaleString('it-IT');
	const stringVal = displayVal?.split(' ')[1];
	// console.log(numericVal?.toLocaleString('it-IT'));

	// // console.log(displayVal);
	// const str = displayVal;
	// // console.log(str);
	// const regex = /(\d+(\.\d+)?)/;
	// const match = str?.match(regex);
	// // console.log(match);
	// const number = match ? Number(match[0])?.toLocaleString('it-IT') : null;
	// // console.log(number, 'NUMBER');


	return (numericVal && stringVal) ? `${numericVal}  ${stringVal}` : null;
};

export default translateDisplayValue;