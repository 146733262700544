import React from "react";
import clx from "classnames";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSoldProperties } from "../../../../Report/store/selectors";
import { actions } from "../../../../Report/store";
import styles from "./Table.module.scss";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import formatToEuro from "../../../../../utils/formatToEuro";
import ArrowNegative from "../../../../../assets/svg/arrow-negative.svg";
import ArrowPositive from "../../../../../assets/svg/arrow-positive.svg";

const Table = ({ prezzoMq, prezzo }) => {
  const dispatch = useDispatch();
  const { reportId } = useParams();
  const sold_properties = useSelector(getSoldProperties());
  if (!sold_properties.length) return null;

  return (
    <>
      <div className={clx(styles.sold_table)}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.head}>
              <th rowSpan="2" style={{ borderTopLeftRadius: 30 }}>
                Tipologia
              </th>
              <th
                colSpan="1"
                style={{ borderBottom: "1px solid #DAEBEE", width: 200 }}
              >
                Prezzo <br /> (€)
              </th>
              <th colSpan="1" style={{ borderBottom: "1px solid #DAEBEE" }}>
                Prezzo unitario <br /> (€/m<sup>2</sup>)
              </th>
              <th rowSpan="2" style={{ borderBottom: "1px solid #DAEBEE" }}>
                Classe <br /> energetica
              </th>
              <th colSpan="1" style={{ borderBottom: "1px solid #DAEBEE" }}>
                Superficie <br /> (m<sup>2</sup>)
              </th>
              <th
                colSpan="1"
                style={{
                  borderBottom: "1px solid #DAEBEE",
                }}
              >
                Distanza <br /> (m)
              </th>
              <th
                rowSpan="2"
                style={{
                  borderTopRightRadius: 30,
                  borderBottom: "1px solid #DAEBEE",
                }}
              >
                Rimuovi
              </th>
            </tr>
          </thead>
          <tbody>
            {sold_properties?.map((item, index) => (
              <tr key={`sold_prop-${index}`}>
                {/* TIPOLOGIA */}
                <td colSpan="1">{item?.Tipologia}</td>
                {/* PREZZO */}
                <td colSpan="1">
                  <div className={styles.price_column}>
                    <b>€ {formatToEuro(item?.Prezzo)}</b>{" "}
                    <img
                      src={
                        item?.Prezzo <= prezzo ? ArrowPositive : ArrowNegative
                      }
                      alt="sold-icon"
                      className={styles.arrow_img}
                    />
                  </div>
                </td>
                {/* PREZZO MQ */}
                <td colSpan="1">
                  <div className={styles.price_column}>
                    <b>
                      €/m<sup>2</sup> {formatToEuro(item?.PrezzoMq)}
                    </b>{" "}
                    <img
                      src={
                        item?.PrezzoMq <= prezzoMq
                          ? ArrowPositive
                          : ArrowNegative
                      }
                      alt="sold-icon"
                      className={styles.arrow_img}
                    />
                  </div>
                </td>
                {/* CLASSE ENERGETICA */}
                <td colSpan="1">{item?.ClasseEnergetica}</td>
                {/* SUPERFICIE */}
                <td colSpan="1">
                  {Number(item?.Superficie)?.toFixed(0)} m<sup>2</sup>
                </td>
                {/* DISTANZA */}
                <td colSpan="1">{Number(item?.Distanza)?.toFixed(0)} m</td>
                {/* RIMUOVI */}
                <td colSpan="1">
                  <div className={styles.flex_column}>
                    <DeleteForeverIcon
                      onClick={() => {
                        if (reportId && item.id) {
                          dispatch(
                            actions.DELETE_SOLD_PROPERTY.REQUEST(
                              { reportId, property_id: item.id },
                              () =>
                                dispatch(
                                  actions.GET_SOLD_PROPERTIES.REQUEST(reportId)
                                )
                            )
                          );
                        } else {
                          throw new Error("missingReportId");
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.legend}>
        <div className={styles.legend__text}>
          *{" "}
          <img
            src={ArrowPositive}
            alt="comparison-icon"
            className={styles.arrow_img}
          />{" "}
          Immobile con caratteristiche simili ma più economico
        </div>
        <div className={styles.legend__text}>
          *{" "}
          <img
            src={ArrowNegative}
            alt="comparison-icon"
            className={styles.arrow_img}
          />{" "}
          Immobile con caratteristiche simili ma più costoso
        </div>
      </div>
    </>
  );
};

export default Table;
