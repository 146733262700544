import { Cell, RadialBar, RadialBarChart } from "recharts";
import chartStyles from "../Charts.module.scss";
import styles from "./GaugeChart.module.scss";
import "./GaugeChart.scss";
import clx from "classnames";

const GaugeChart = ({
  title,
  description,
  displayValue,
  levelLabel,
  value,
  inverted = false,
  minHeight,
  marginTop,
  marginTopValue,
}) => {
  const data = [
    {
      name: "Example",
      value: 100,
    },
    {
      name: "Social demographic",
      value,
    },
  ];

  return (
    <div
      className={clx(styles.gauge__wrapper, "gauge-chart")}
      style={{ position: "relative", minHeight }}
    >
      <b
        style={{
          position: "absolute",
          top: "35px",
          marginRight: "30px",
        }}
      >
        {title}
      </b>
      <div
        className={`${styles.chart__wrapper} ${
          marginTop ? styles.gauge_margin_top : ""
        }`}
        style={{ marginTop: marginTopValue }}
      >
        <RadialBarChart
          width={270}
          height={220}
          innerRadius="50%"
          outerRadius="100%"
          data={[
            {
              name: "Example",
              value: 100,
            },
            {
              name: "Social demographic",
              value,
            },
          ]}
          startAngle={180}
          barCategoryGap={-15}
          endAngle={0}
        >
          <RadialBar minAngle={15} background clockWise={true} dataKey="value">
            {data.map((entry, value) => (
              <Cell
                key={`cell-${value}`}
                style={entry.name === "Example" ? { display: "none" } : {}}
              />
            ))}
          </RadialBar>
        </RadialBarChart>
        <p className={styles.medium}>{levelLabel}</p>
        <p className={styles.caption}>{displayValue && displayValue}</p>
      </div>
      <p
        className={`${chartStyles.chart__source} ${styles.source}`}
        style={{
          position: "absolute",
          bottom: "20px",
          right: "30px",
        }}
      >
        {description}
      </p>
    </div>
  );
};

export default GaugeChart;
