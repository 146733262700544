import formatToEuro from "../../../utils/formatToEuro";

// Format textfield input
const handleInput = (e, property, setFieldValue) => {
  // console.log(e.target.value, "e.target.value");

  // If price input format it to match euro currency format
  if (
    property.fieldName == "Prezzo" ||
    property.fieldName == "CostiProprieta" ||
    property.fieldName == "PrezzoMq"
  ) {
    // Allow backspace and tab keys
    // if (e.key === "Backspace" || e.key === "Tab") {
    //   return;
    // }

    // const isDigit = /^\d+$/.test(e.key);
    // console.log(isDigit, "isDigit");

    // if (!isDigit) {
    //   e.preventDefault();
    //   return;
    // }

    if (e.target.value == 0) {
      setFieldValue(property.fieldName, "");
      return;
    }

    setFieldValue(property.fieldName, formatToEuro(e.target.value));
  } else {
    setFieldValue(
      property.fieldName,
      e.target.value.replace(".", "").replace(",", "")
    );
  }
};

export default handleInput;
