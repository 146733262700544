import React from "react";
import styles from "./StepperMobile.module.scss";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const StepperMobile = ({ setIsPreviewModalOpen, step, setStep }) => {
  // Back step function
  const backStepNew = (step) => {
    setIsPreviewModalOpen(false);
    if (step > 1) setStep(step - 1);
  };

  return (
    <div className={styles["header__progress-bar"]}>
      <div
        className={styles["header__progress-icon"]}
        onClick={() => backStepNew(step)}
      >
        <KeyboardBackspaceIcon />
      </div>
      <span
        className={styles["header__progress"]}
        style={{ width: `${(step - 1) * 33.33}%` }}
      />
      <span className={styles["header__progress-number"]}>{step}</span>
    </div>
  );
};

export default StepperMobile;
