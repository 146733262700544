import  './tooltip.scss';

import PropTypes from 'prop-types';
import React from 'react';

const ReactToolTip =  ({
	contenet,
	children,
	position,
	displayPosition = 'relative',
	containerClass,
	x,
	y,
	width,
	tooltipWidth,
	height,
	theme,
	display = '',
	arrow= {
		x: 0,
		y: 0,
		rotate: '0deg'
	},
	top,
	zIndex
}) => (
	<div className={`tooltip ${containerClass}`}
		style={{
			position: displayPosition,
			width: tooltipWidth,
			top,
			zIndex
		}}
	>
		{children}
		<div  className={`tooltiptext ${theme === 'dark' ? 'dark' :'light'} tooltip-${position}`} style={{
			top: x,
			left: y,
			width,
			height,
			display
		}}>
			{contenet}
			<span className="arrow" style={{
				left: arrow.x, top: arrow.y, transform: `rotate(${arrow.rotate})`
			}}></span>
		</div>
	</div>
);

export default ReactToolTip;

ReactToolTip.defaultProps = {
	title: 'sample',
	children: React.createElement('div'),
	position: 'bottom',
	containerClass: '',
	theme: 'light'
};

ReactToolTip.propTypes = {
	title: PropTypes.string,
	children: PropTypes.element,
	position: PropTypes.string,
	containerClass: PropTypes.string,
	theme: PropTypes.string
};