const formatToEuro = (value) => {
  // Remove all non digits
  var removeChar = String(value)?.replace(/\D/g, "");

  // Format number to Euro
  let euro = Intl.NumberFormat("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return euro.format(removeChar);
};

export default formatToEuro;

// var removeChar = String(value)?.replace(/[^0-9.]/g, ""); // This is to remove alphabets and special characters.
// var removeDot = removeChar?.replace(/\./g, ""); // This is to remove "DOT"
// var formattedNumber = removeDot?.replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Adds a dot every 3 digits
// value = formattedNumber;
