import { ReactComponent as TransportationIcon } from "../../../../../../assets/svg/1-03.svg";
import styles from "./TransporationSection.module.scss";

const TransportationSection = ({ data, valuesTrain }) => {
  const services = valuesTrain
    ?.map((value) => data?.[value])
    ?.filter(Boolean)
    ?.reduce((acc, curr) => [...acc, ...curr?.services_found], []);

  // console.log(services, "services");

  return (
    <div className={styles.transportation__wrapper}>
      <div className={styles.first__row}>
        <p className={styles.title}>Trasporti</p>
        <div className={styles.icon__wrapper}>
          <TransportationIcon />
        </div>
      </div>
      {/* METRO E TRENI */}
      <div>
        <p className={styles.column__header}>Fermate treni / metro</p>
        <ul className={styles.column__list}>
          {services?.length ? (
            services?.map((item) => (
              <li
                key={`${item.name} ${item.distance}`}
                className={styles.list__item}
              >
                {item.name}{" "}
                <b style={{ color: "#fff7fa" }}>
                  {parseFloat((item.distance / 1000).toFixed(1))} km
                </b>
              </li>
            ))
          ) : (
            <p className={styles.list__item}>
              Non ci sono fermate treno o metro disponibili
            </p>
          )}
        </ul>
      </div>

      {/* BUS */}
      <div>
        <p className={styles.column__header}>Fermate bus</p>
        <ul className={styles.column__list}>
          {data?.bus?.services_found?.length ? (
            data?.bus?.services_found?.map((item) => (
              <li
                key={`${item.name} ${item.distance}`}
                className={styles.list__item}
              >
                {item.name}{" "}
                <b style={{ color: "#fff7fa" }}>
                  {parseFloat((item.distance / 1000).toFixed(1))} km
                </b>
              </li>
            ))
          ) : (
            <p className={styles.list__item}>
              Non ci sono fermate bus disponibili
            </p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TransportationSection;
