import clx from "classnames";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { getReportServices } from "../../../Report/store/selectors";
import styles from "./AreaServices.module.scss";
import SectionTitle from "../SectionTitle";
import HealthSection from "./containers/HealthSection";
import HospitalsSection from "./containers/HospitalsSection";
import ServicesMapModal from "./containers/ServicesMapModal";
import ServicesSection from "./containers/ServicesSection";
import TransportationSection from "./containers/TransporationSection";
import BuyCta from "../BuyCta";

const AreaServices = forwardRef(
  (
    {
      isMobile,
      home,
      privateUserHasReport,
      userIsPrivate,
      agencyUserHasSubscription,
    },
    ref
  ) => {
    const report = useSelector(getReportServices());
    // console.log(report, "report services");

    return (
      <div
        className={clx(styles.area__services__wrapper, {
          [styles.area__services__mbl]: isMobile,
        })}
        ref={ref}
      >
        <SectionTitle
          title={"Servizi di zona"}
          section={"area_services"}
          marginBottom={"40px"}
          isDisabled={!userIsPrivate && !agencyUserHasSubscription}
        />

        {(userIsPrivate && !privateUserHasReport) ||
        (!userIsPrivate && !agencyUserHasSubscription) ? (
          <BuyCta />
        ) : (
          <>
            <ServicesMapModal isMobile={isMobile} data={report} home={home} />
            <div className={styles.area__services__content}>
              <div className={clx(styles.general__card, styles.services__area)}>
                <ServicesSection
                  data={report}
                  values={[
                    "beauty",
                    "cinema",
                    "nightlife",
                    "parking",
                    "restaurant",
                    "supermarket",
                    "university",
                    "school",
                  ]}
                />
              </div>
              <div
                className={clx(
                  styles.general__card,
                  styles.transportation__area
                )}
              >
                <TransportationSection
                  data={report}
                  valuesTrain={["metro", "train"]}
                />
              </div>
              <div className={clx(styles.general__card, styles.health__area)}>
                <HealthSection
                  data={report}
                  values={[
                    "dentist",
                    "doctor",
                    "green_outdoors",
                    "medical_centers",
                    "pharmacy",
                    "sport",
                  ]}
                />
              </div>
              <div
                className={clx(styles.general__card, styles.hospitals__area)}
              >
                <HospitalsSection data={report?.hospital?.services_found} />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default AreaServices;
