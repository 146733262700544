const ChartsLabel = ({ x, y, offset, dy, textAnchor, dx, label }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={x}
      y={y}
      dy={dy}
      dx={dx}
      offset={offset}
      textAnchor={textAnchor}
      style={{ fontSize: 14 }}
    >
      {label}
    </text>
  </g>
);
export default ChartsLabel;
