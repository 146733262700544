import {useDispatch} from 'react-redux';

import BinImageWhite from '../../../../../../assets/svg/bin-white.svg';
import ConfirmModal from '../../../../../Common/ConfirmModal';
import Modal from '../../../../../Common/Modal';
import {actions} from '../../../../../Report/store';

const ConfirmRemoveImageModal = ( {
	confirmModalOpen, setConfirmModalOpen, reportId, idImage, setIsChangingOrder
} ) => {
	const dispatch = useDispatch();

	const confirmModal = () => {
		dispatch(actions.DELETE_IMAGE.REQUEST({reportId, idImage}, () => {
			dispatch(actions.GET_GALLERY.REQUEST(reportId));
			setConfirmModalOpen(false);
		}));
	};

	return <Modal
		isModalOpen={confirmModalOpen}
		setIsModalOpen={ () => setConfirmModalOpen(false) }
		modalJustifyContent="center"
		padding='40px 0px'
		gap={'20px'}
	>
		<ConfirmModal
			confirmModal={confirmModal}
			setConfirmModalOpen={() => setConfirmModalOpen(false)}
			text="Sicuro di voler eliminare la foto?"
			textAlign="center"
			width="60%"
			margin="0 auto"
			rightIconSrc={ BinImageWhite }
			iconHeight="14px"
		/>
	</Modal>;
};

export default ConfirmRemoveImageModal;
