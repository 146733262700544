const selectConditionals = () => {
  const housePlusStyles = {
    mainColor: "#078ea6",
    secondaryColor: "#034349",
    placeholderText: "#034349",
    actionColor: "#d8e9eb",
    singleMainColor: "#00A5B5",
    singleSecondaryColor: "#F3FEFF",
    spanSecondaryColor: "#097e89",
  };

  const mondoStyles = {
    mainColor: "#b02832",
    secondaryColor: "#370a0e",
    placeholderText: "#370a0e",
    actionColor: "#e1e1e1",
    singleMainColor: "#370A0E",
    singleSecondaryColor: "#fff3f3",
    spanSecondaryColor: "#71030d",
  };

  const bakecaStyles = {
    mainColor: "#65a30d",
    secondaryColor: "#65a30d",
    placeholderText: "#0f172a",
    actionColor: "#fcfff7",
    singleMainColor: "#65a30d",
    singleSecondaryColor: "#fcfff7",
    spanSecondaryColor: "#65a30d",
  };

  switch (process.env.REACT_APP_PLATFORM) {
    case "HOUSEPLUS":
      return housePlusStyles;
    case "MONDORED":
      return mondoStyles;
    case "BAKECA":
      return bakecaStyles;
    default:
      return housePlusStyles;
  }
};

export default selectConditionals;
