import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./Nav.module.scss";
import clx from "classnames";

import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import HpLogo from "../../../../assets/svg/cropped-logo.svg";
import HpLogoSmall from "../../../../assets/images/hp-logo-small.png";
import LogOut from "../../../../assets/svg/logout.svg";
import Homepage from "../../../../assets/svg/homepage.svg";
import { actions } from "../../../Auth/store";
import { ROUTES } from "../../../../routes/constants";

const Nav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={styles.admin_header}>
      {/* Logo large screens */}
      <div className={styles.admin_header__logo}>
        <img src={HpLogo} alt="logo" />
      </div>
      {/* Logo small screens */}
      <div className={styles.admin_header__logo_small}>
        <img src={HpLogoSmall} alt="logo" />
      </div>

      {/* Links */}
      <div className={styles.admin_header__links}>
        {/* Links large */}
        <div
          className={styles.admin_header__links__link}
          onClick={() => navigate(ROUTES.REPORT)}
        >
          <PrimaryButton
            text="Homepage"
            leftIconSrc={Homepage}
            width={150}
            height={50}
            background={"#078ea6"}
          />
        </div>
        <div
          className={clx(styles.admin_header__links__link, styles.logout)}
          onClick={() => {
            dispatch(actions.LOG_OUT.REQUEST());
          }}
        >
          <SecondaryButton text="Esci" leftIconSrc={LogOut} height={50} />
        </div>
        {/* Links small */}
        <div
          className={styles.admin_header__links__link_mobile}
          onClick={() => navigate(ROUTES.REPORT)}
        >
          <PrimaryButton
            leftIconSrc={Homepage}
            width={50}
            height={50}
            background={"#078ea6"}
          />
          <p>Home</p>
        </div>
        <div
          className={clx(
            styles.admin_header__links__link_mobile,
            styles.logout
          )}
          onClick={() => {
            dispatch(actions.LOG_OUT.REQUEST());
          }}
        >
          <SecondaryButton leftIconSrc={LogOut} width={50} height={50} />
          <p>Esci</p>
        </div>
      </div>
    </div>
  );
};

export default Nav;
