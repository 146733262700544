import clx from "classnames";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { getReportCharts } from "../../../Report/store/selectors";
import NoChartsData from "../NoChartsData";
import CommonCard from "../SocioDemographicArea/containers/CommonCard";
import AverageEarnings from "./containers/AverageEarnings";
import BceChange from "./containers/BceChange";
import Employment from "./containers/Employment";
import InflationChange from "./containers/InflationChange";
import styles from "./Economics.module.scss";
import SectionTitle from "../SectionTitle";
import BuyCta from "../BuyCta";

const Economics = forwardRef(({ privateUserHasReport, userIsPrivate }, ref) => {
  const charts = useSelector(getReportCharts());

  // INFLATION CHANGE DYNAMIC STRING DATA
  const inflationChange = charts?.[14];
  // console.log(inflationChange, "inflationChange");
  const firstYear = inflationChange?.graph_data?.data?.y[0];
  // console.log(firstYear, "firstYear");
  const lastYear =
    inflationChange?.graph_data?.data?.y[
      inflationChange?.graph_data?.data?.y.length - 1
    ];
  const firstYearAmount = inflationChange?.graph_data?.data?.x?.[0];
  const lastYearAmount =
    inflationChange?.graph_data?.data?.x?.[
      inflationChange?.graph_data?.data?.y.length - 1
    ];
  const difference = lastYearAmount - firstYearAmount;
  const differencePercent = (difference / firstYearAmount) * 100;
  const changeType =
    (difference / firstYearAmount) * 100 >= 0 ? "incremento" : "decremento";

  const extractMonthName = (dateString) => {
    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];
    const numericMonth = Number(dateString?.split("-")?.[1]);
    const year = dateString?.split("-")?.[0];

    return `${months[numericMonth - 1]} ${year}`;
  };

  // BCE CHANGE DYNAMIC STRING DATA
  const bceChange = charts?.[16];
  const firstYearBce = bceChange?.graph_data?.data?.x[0];
  const lastYearBce =
    bceChange?.graph_data?.data?.x?.[
      bceChange?.graph_data?.data?.x?.length - 1
    ];
  const firstYearAmountBce = bceChange?.graph_data?.data?.y?.[0];
  const lastYearAmountBce =
    bceChange?.graph_data?.data?.y?.[
      bceChange?.graph_data?.data?.y?.length - 1
    ];
  const differenceBce = lastYearAmountBce - firstYearAmountBce;
  const differencePercentBce = (differenceBce / firstYearAmountBce) * 100;
  const changeTypeBce =
    (differenceBce / firstYearAmountBce) * 100 >= 0
      ? "incremento"
      : "decremento";

  const extractMonthNameBce = (dateString) => {
    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];
    const numericMonth = Number(dateString?.split("-")?.[1]);
    const year = dateString?.split("-")?.[0];
    const day = dateString?.split("-")?.[2];

    return `${months[numericMonth - 1]} ${year}`;
  };

  return (
    <div className={clx(styles.area__services__wrapper)} ref={ref}>
      <SectionTitle
        title={"Informazioni economiche"}
        section={"all_eco_charts"}
        className={styles.header__text}
        isGeneral={true}
      />

      {userIsPrivate && !privateUserHasReport ? (
        <BuyCta />
      ) : (
        <div className={styles.area__services__main}>
          <div className={clx(styles.inflation, styles.general__card)}>
            {charts?.[15]?.graph_status == "OK" ? (
              <CommonCard
                header={charts?.[15]?.graph_data?.title}
                description={charts?.[15]?.graph_data?.description}
                content={charts?.[15]?.graph_data?.data?.displayed_value}
                section={"inflation"}
              />
            ) : (
              <NoChartsData
                title={"Inflazione"}
                fontSize={"12px"}
                padding={"15px 25px"}
                minHeight={"105px"}
                marginTop={"5px"}
              />
            )}
          </div>
          {/* Inflation change */}
          <div className={clx(styles.inflation_change, styles.general__card)}>
            {charts?.[14]?.graph_status == "OK" ? (
              <div className={styles.general__card_container}>
                <div className={styles.chart_info}>
                  L’inflazione a <b>{firstYear}</b> era del{" "}
                  <b>{firstYearAmount}%</b>, rispetto al{" "}
                  <b>{lastYearAmount}%</b> di <b>{lastYear}</b>, con un{" "}
                  <b>{changeType}</b> del <b>{difference.toFixed(2)}%</b>
                </div>
                <InflationChange
                  data={charts?.[14]?.graph_data}
                  chartMarginTop={"100px"}
                  sourcePosition={"absolute"}
                  sourceRight="30px"
                  sourceBottom="20px"
                />
              </div>
            ) : (
              <NoChartsData title={"Andamento Inflazione"} />
            )}
          </div>
          <div className={clx(styles.avg_earnings, styles.general__card)}>
            {charts?.[10]?.graph_status == "OK" ? (
              <AverageEarnings data={charts?.[10]?.graph_data} />
            ) : (
              <NoChartsData title={"Reddito medio"} />
            )}
          </div>
          <div className={clx(styles.bce, styles.general__card)}>
            {charts?.[17]?.graph_status == "OK" ? (
              <CommonCard
                header={charts?.[17]?.graph_data?.title}
                description={charts?.[17]?.graph_data?.description}
                content={charts?.[17]?.graph_data?.data?.displayed_value}
                section={"bce"}
              />
            ) : (
              <NoChartsData
                title={"Tasso BCE"}
                fontSize={"12px"}
                padding={"15px 25px"}
                minHeight={"105px"}
                marginTop={"5px"}
              />
            )}
          </div>
          <div className={clx(styles.bce_change, styles.general__card)}>
            {charts?.[16]?.graph_status == "OK" ? (
              <div className={styles.general__card_container}>
                <div className={styles.chart_info}>
                  Il tasso della Banca Centrale Europea a{" "}
                  <b>{extractMonthName(lastYearBce)}</b> è del{" "}
                  <b>{lastYearAmountBce}%</b> con un <b>{changeTypeBce}</b> del{" "}
                  <b>{differenceBce.toFixed(2)}%</b> rispetto a{" "}
                  <b>{extractMonthName(firstYearBce)}</b>
                </div>
                <BceChange
                  data={charts?.[16]?.graph_data}
                  chartMarginTop={"100px"}
                  sourcePosition={"absolute"}
                  sourceRight="30px"
                  sourceBottom="20px"
                />
              </div>
            ) : (
              <NoChartsData title={"Andamento del tasso BCE"} />
            )}
          </div>
          <div className={clx(styles.employment, styles.general__card)}>
            {charts?.[11]?.graph_status == "OK" ? (
              <Employment data={charts?.[11]?.graph_data} />
            ) : (
              <NoChartsData title={"Tasso di occupazione"} />
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default Economics;
