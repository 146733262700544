import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ConnectIcon from "../../../../../assets/svg/connection.svg";
import { ReactComponent as CopyIcon } from "../../../../../assets/svg/copy-new.svg";
import { ReactComponent as EyeIcon } from "../../../../../assets/svg/eye.svg";
import { ReactComponent as FocusIcon } from "../../../../../assets/svg/focus.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/svg/trash-can.svg";
import { appendFilterListToColumns } from "../../../../../helpers";
import { getLoader } from "../../../../../shared/Loader/store/selectors";
import useWindowSize from "../../../../../utils/useWindowSize";
import {
  CreateReportModal,
  DeleteReportModal,
} from "../../../../CreateReport/containers/CreateReportModals";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import DataTableFooter from "../DataTableFooter";
import OptionsTableHeadCell from "../OptionsTableHeadCell";
import styles from "./DataTableCustomized.module.scss";

const DataTableCustomized = ({
  tableData,
  tableColumns,
  rowsPerPageNum = 10,
  // detailsLink = false,
  uniqueKey,
  filterOption = true,
  selectableRowsOption = false,
  paginationOption = true,
  noMatchOption = true,
  copyHandler,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector(getLoader());
  const [isCreateReportModalOpen, setIsCreateReportModalOpen] = useState(false);
  const [isDeleteReportModalOpen, setIsDeleteReportModalOpen] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [isFiltersOpen, setIsFilterOpen] = useState(false);
  const { pathname } = useLocation();
  const [filterList, setFilterList] = useState(
    JSON.parse(localStorage.getItem(`filterList${uniqueKey}`))
  );
  const [sortingOptions, setSortingOptions] = useState(
    JSON.parse(localStorage.getItem(`sortingOptions${uniqueKey}`))
  );
  const [isReset, setReset] = useState(false);
  const { isMobile } = useWindowSize();

  const columns = [
    ...tableColumns?.map((column) => ({
      name: column?.name,
      label: column?.label,
      options: {
        filter: column?.filter,
        sort: column?.sort,
        setCellHeaderProps: () =>
          column?.centered && { className: "centeredHeader" },
        setCellProps: () =>
          column?.centered && !isMobile && { className: "centeredColumn" },
        customBodyRender: (data) =>
          column?.customBodyRender ? column?.customBodyRender(data) : data,
      },
    })),
    {
      name: "options",
      label: "",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: "centeredHeader" }),
        setCellProps: () => !isMobile && { className: "centeredColumn" },
        customHeadRender: () => (
          <OptionsTableHeadCell
            key={tableColumns?.length}
            filterOption={filterOption}
            setIsFilterOpen={setIsFilterOpen}
            resetOptions={() => setReset(true)}
          />
        ),
      },
    },
  ];

  useEffect(() => {
    if (JSON.parse(localStorage.getItem(`searchText${uniqueKey}`))) {
      setIsFilterOpen(true);
    }

    if (uniqueKey) {
      setFilterList(JSON.parse(localStorage.getItem(`filterList${uniqueKey}`)));
      setSortingOptions(
        JSON.parse(localStorage.getItem(`sortingOptions${uniqueKey}`))
      );
    }
  }, [uniqueKey]);

  const options = {
    filterType: "checkbox",
    filter: filterOption,
    sort: true,
    sortOrder: sortingOptions || {},
    searchOpen: true,
    responsive: "vertical",
    selectableRows: selectableRowsOption ? "multiple" : "none",
    tableBodyMaxHeight: "auto",
    rowsPerPage: rowsPerPageNum,
    rowsPerPageOptions: [],
    searchText: JSON.parse(localStorage.getItem(`searchText${uniqueKey}`))
      ? JSON.parse(localStorage.getItem(`searchText${uniqueKey}`))
      : "",
    pagination: paginationOption,
    onRowClick: (e) => {
      // console.log(e, "e[9].props.to");
      window.open(e[10].props.to, "_self");
    },
    onTableChange: (action, tableState) => {
      if (action === "propsUpdate") {
        if (isReset) {
          setFilterList(false);
          setSortingOptions(false);
          localStorage.removeItem(`searchText${uniqueKey}`);
          localStorage.removeItem(`filterList${uniqueKey}`);
          localStorage.removeItem(`sortingOptions${uniqueKey}`);
          tableState.filterList = tableState.filterList.map((list) => []);
          tableState.sortOrder = {};
          setReset(false);
        }
      }
      if (action === "filterChange") {
        setFilterList(tableState.filterList);
        localStorage.setItem(
          `filterList${uniqueKey}`,
          JSON.stringify(tableState.filterList)
        );
        return;
      }
      if (action === "search") {
        localStorage.setItem(
          `searchText${uniqueKey}`,
          JSON.stringify(tableState.searchText)
        );
        return;
      }
      if (action === "sort") {
        const fieldName = columns[tableState.activeColumn].name;
        // console.log(fieldName, "column");
        const direction = tableState.announceText.includes("descending")
          ? "desc"
          : "asc";
        const option = {
          name: fieldName,
          direction,
        };

        localStorage.setItem(
          `sortingOptions${uniqueKey}`,
          JSON.stringify(option)
        );
        setSortingOptions(option);
        return;
      }
    },
    customSort: (data, colIndex, client) =>
      data.sort((a, b) => {
        const aVal = a.data[colIndex];
        const bVal = b.data[colIndex];

        if (aVal < bVal) {
          return -1 * (client === "desc" ? -1 : 1);
        } else if (aVal > bVal) {
          return 1 * (client === "desc" ? -1 : 1);
        } else {
          return 0;
        }
      }),
    textLabels: {
      body: {
        noMatch:
          !loader && noMatchOption ? (
            <div className={styles.empty_table}>
              <h2 key="empty-table-1">
                Crea il primo report di vendibilità caricando i dati degli
                immobili.
              </h2>
              <p key="empty-table-2">
                In questa sezione potrai consultare e visualizzare tutti gli
                immobili che carichi insieme ai tuo report.
              </p>
              <div key="empty-table-3" className={styles.create__btn__wrapper}>
                <PrimaryButton
                  key="empty-table-4"
                  leftIconSrc={ConnectIcon}
                  width="180px"
                  height="45px"
                  fontSize="17px"
                  text="Crea Report"
                  onClick={() => setIsCreateReportModalOpen(true)}
                />
                <div className={styles.create__btn__focusIcon__wrapper}>
                  <FocusIcon
                    key="empty-table-5"
                    className={styles.create__btn__focusIcon}
                  />
                </div>
              </div>
            </div>
          ) : (
            "Nessun oggetto trovato"
          ),
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) =>
      paginationOption ? (
        <DataTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      ) : (
        false
      ),
  };

  const handleDeleteButtonClick = (item) => {
    setIsDeleteReportModalOpen(true);
    setItemId(item.id);
  };

  return (
    <div>
      {tableData && (
        <MUIDataTable
          className={`muitable ${isFiltersOpen ? "withFilters" : "noFilters"}`}
          key={uniqueKey}
          data={
            tableData?.length > 0
              ? tableData.map((item) => ({
                  ...item,
                  options: (
                    <div className={styles.table_data_options}>
                      <div className={styles.table_data_options__option}>
                        <Link
                          to={
                            // detailsLink
                            //   ? `${detailsLink}/${item?.id}`
                            //   :
                            `${pathname}/${item?.id}`
                          }
                        >
                          <EyeIcon className="eye-icon" />
                        </Link>
                      </div>
                      <div className={styles.table_data_options__option}>
                        <CopyIcon
                          onClick={() => copyHandler(item?.id)}
                          className={styles.copy}
                        />
                      </div>
                      <div className={styles.table_data_options__option}>
                        <DeleteIcon
                          onClick={() => handleDeleteButtonClick(item)}
                          className={styles.copy}
                        />
                      </div>
                    </div>
                  ),
                }))
              : []
          }
          columns={appendFilterListToColumns(columns, filterList)}
          options={options}
        />
      )}
      <CreateReportModal
        isCreateReportModalOpen={isCreateReportModalOpen}
        setIsCreateReportModalOpen={setIsCreateReportModalOpen}
      />
      <DeleteReportModal
        isDeleteReportModalOpen={isDeleteReportModalOpen}
        setIsDeleteReportModalOpen={setIsDeleteReportModalOpen}
        itemId={itemId}
      />
    </div>
  );
};

export default DataTableCustomized;
