import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CreditIcon } from "../../../../assets/svg/credit.svg";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import styles from "./Mondored.module.scss";
import { actions } from "../../store";
import { getUserSubscriptionMondo } from "../../store/selectors";
import clx from "classnames";

const PaymentsAndCredits = ({ userPortal, userCredits }) => {
  const dispatch = useDispatch();
  const isSubscribedMondo = useSelector(getUserSubscriptionMondo());
  // console.log("isSubscribedMondo", isSubscribedMondo);

  useEffect(() => {
    dispatch(actions.GET_PAYMENT_URL_SUBSCRIPTION_MONDO.REQUEST());
  }, [dispatch]);

  // Mondored packages purchasing info
  const mondoPackagesInfo = [
    {
      reportsQuantity: "100",
      reportsPrice: "300",
      reportsId: "price_1Mo5BUDY10W53u6wAXEQfvyb",
    },
    {
      reportsQuantity: "500",
      reportsPrice: "1200",
      reportsId: "price_1Mo5ETDY10W53u6wy1aARVM5",
    },
  ];
  return (
    <>
      {/* UNSUBSCRIBED */}
      {!isSubscribedMondo ? (
        <div className={styles.subcription}>
          <span className={styles.head_sub}>
            Accedi all&apos; abbonamento <br /> ottieni 500 report
          </span>
          <p className={styles.product__price}>
            <br />
            <span className={styles.subcription__offer}>
              € 1200 + iva /anno
            </span>
          </p>
          <div>
            <PrimaryButton
              padding="10px"
              width="100%"
              text="Acquista il tuo abbonamento"
              background="white"
              color="black"
              fontSize="20px"
              onClick={() => {
                dispatch(
                  actions.GET_PAYMENT_URL_SUBSCRIPTION_MONDO.REQUEST(
                    "",
                    (url) => window.open(url)
                  )
                );
              }}
            />
          </div>
        </div>
      ) : (
        <>
          {/* SUBSCRIBED */}
          {/* Mondored packages boxes */}
          {mondoPackagesInfo.map((item, index) => (
            <div key={index} className={styles.packages}>
              Vuoi analizzare un immobile? <br />
              acquista {item.reportsQuantity} report
              <p className={styles.product__price}>
                <br />
                {item.reportsQuantity} Report <br />€ {item.reportsPrice} + IVA
              </p>
              <div>
                <PrimaryButton
                  padding="10px"
                  width="200px"
                  text="Acquista"
                  fontSize="20px"
                  onClick={() => {
                    dispatch(
                      actions.GET_PAYMENT_URL_PACKAGE.REQUEST(
                        { idPrice: item.reportsId },
                        (url) => window.open(url)
                      )
                    );
                  }}
                />
              </div>
            </div>
          ))}
          {/* Subscribed box */}
          <div className={styles.subscribed}>
            <h3>Il tuo abbonamento annuale è attivo</h3>
            <p>Con il tuo abbonamento puoi generare 1000 report ogni anno</p>
            <PrimaryButton
              padding="10px"
              width="50%"
              height="auto"
              text="Gestisci abbonamento"
              background="white"
              color="black"
              fontSize="20px"
              onClick={() => window.open(userPortal, "_blank")}
            />
          </div>
        </>
      )}
      {/* CREDITS */}
      <div
        className={clx(styles.credits, {
          [styles.credits_unsubscribed]: !isSubscribedMondo,
          [styles.credits_subscribed]: isSubscribedMondo,
        })}
      >
        <CreditIcon style={{ marginRight: 10 }} />
        <div>
          <p>{userCredits}</p>
          <span>Report disponibili</span>
        </div>
      </div>
    </>
  );
};

export default PaymentsAndCredits;
