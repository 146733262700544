import clx from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactSortable } from "react-sortablejs";

import CheckIcon from "../../../../../../assets/svg/check-icon.svg";
import { ReactComponent as GridIcon } from "../../../../../../assets/svg/grid.svg";
import StarIcon from "../../../../../../assets/svg/star.svg";
import PrimaryButton from "../../../../../Common/Buttons/PrimaryButton";
import { actions } from "../../../../../Report/store";
import styles from "./GalleryList.module.scss";
import SortableItem from "./SortableItem";

const GalleryList = ({
  gallery,
  activeImage,
  setActiveImage,
  reportId,
  isChangingOrder,
  setIsChangingOrder,
}) => {
  // console.log(gallery, "gallery");
  const dispatch = useDispatch();
  // const [isChangingOrder, setIsChangingOrder] = useState(false);
  const [sortedGallery, setSortedGallery] = useState([]);
  useEffect(() => {
    if (gallery) {
      setSortedGallery(gallery);
    }

    return () => {
      // Cleanup function
      setSortedGallery([]);
    };
  }, [gallery]);

  const changeOrder = () => {
    if (sortedGallery.length) {
      dispatch(
        actions.CHANGE_ORDER.REQUEST({ reportId, values: sortedGallery }, () =>
          setIsChangingOrder(false)
        )
      );
    }
  };

  return (
    <>
      {isChangingOrder ? (
        <div className={styles.actions__wrapper}>
          {/* <IconButton padding="0" onClick={changeOrder} iconSrc={CloseIcon} /> */}
          <PrimaryButton
            text="Salva ordine foto"
            leftIconSrc={CheckIcon}
            onClick={changeOrder}
            fontSize="10px"
            height="25px"
            padding="6px 10px"
            borderRadius="5px"
          />
        </div>
      ) : null}
      {isChangingOrder ? (
        <ReactSortable
          className={styles.gallery__list}
          group="groupName"
          preventOnFilter={true}
          setList={setSortedGallery}
          list={sortedGallery}
          filter={".static"}
          animation={200}
        >
          {sortedGallery?.map((image, index) => (
            <div key={index} style={{ maxWidth: 300 }}>
              <SortableItem index={index} image={image} reportId={reportId} />
            </div>
          ))}
        </ReactSortable>
      ) : (
        <>
          <div className={styles.gallery__list}>
            {gallery?.map((image, idx) => (
              <div
                key={idx}
                // className={styles['list-item__wrapper']}
                className={clx(styles["list-item__wrapper"], {
                  [styles["active-image"]]: activeImage?.id === image?.id,
                })}
                onClick={() => setActiveImage(image)}
              >
                {image.id === reportId ? (
                  <div
                    className={clx(styles.cover__image, "cover-image-tooltip")}
                  >
                    <img src={StarIcon} alt="cover" />
                  </div>
                ) : null}
                <img
                  src={image.path}
                  alt="match"
                  // className={clx(styles.list__img, {
                  // 	[styles['active-image']]: activeImage?.id === image?.id,
                  // })}
                  className={styles.list__img}
                />
                {gallery?.length > 3 && idx >= 0 && idx <= 2 && (
                  <span
                    className={clx(styles["img_span"], {
                      [styles["active-span"]]: activeImage?.id === image?.id,
                    })}
                  >
                    PDF
                  </span>
                )}
              </div>
            ))}
            {/* {gallery?.length ?
							(
								<div className={styles['change-order-btn__wrapper']}>
									<IconButton
										padding="25px"
										height="75px"
										width="75px"
										borderRadius="50%"
										onClick={() => setIsChangingOrder(true)}
										// iconSrc={GridIcon}
										iconSrc={DeleteIcon}
										backgroundColor="#FFF7FA"
									/>
								</div>
							) :
							null} */}
          </div>
          {gallery?.length > 3 && (
            <div className={styles.sort_warning}>
              <span>
                Le prime tre foto da sinistra verranno caricate nel tuo PDF di
                questo immobile, per cambiare l&apos;ordine premi il bottone{" "}
                <span className={styles.sort_warning__img_color}>
                  <GridIcon />
                </span>{" "}
                qui sotto
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GalleryList;
