import clx from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowImg } from "../../assets/svg/arrow-nero.svg";
import { ReactComponent as AvatarIcon } from "../../assets/svg/avatar.svg";
import AvatarImg from "../../assets/svg/avatar-placeholder.svg";
import { ReactComponent as CreditIcon } from "../../assets/svg/euro-coin.svg";
import { ReactComponent as Abbonamento } from "../../assets/svg/debit-card.svg";
import { ReactComponent as Admin } from "../../assets/svg/settings.svg";
import { ReactComponent as LogoutIcon } from "../../assets/svg/logout-new.svg";
import { ROUTES } from "../../routes/constants";
import useOutsideClick from "../../utils/useOutsideClick";
import { actions } from "../Auth/store";
import styles from "./SideBar.module.scss";
import platformConditionals from "../../utils/platformConditionals";
import PrimaryButton from "../Common/Buttons/PrimaryButton";
import ContactUsModal from "./containers/ContactUsModal";

const SideBar = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const dropdownRef = useRef();
  const [activeItem, setActiveItem] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(null);
  const [contactUsModal, setContactUsModal] = useState(false);
  console.log(contactUsModal, "CONTACT US MODAL");
  console.log("user", user);

  useEffect(() => {
    if (location) {
      setActiveItem(location.pathname);
    }
  }, [location]);

  useOutsideClick(dropdownRef, () => {
    if (isDropdownOpen) setIsDropdownOpen(false);
  });

  return (
    <>
      <nav className={styles.navbar}>
        <Link className={styles.logo__wrapper} to={ROUTES.REPORT}>
          <img
            src={platformConditionals()?.logo || ""}
            alt="logo"
            className={styles.logo}
          />
        </Link>
        <div className={styles.navbar__actions}>
          <PrimaryButton
            height={50}
            text="Richiedi una demo"
            width="max-content"
            onClick={() => setContactUsModal(true)}
          />
          {/* Profile dropdown */}
          <div
            onClick={() => setIsDropdownOpen(true)}
            className={styles["user-info"]}
          >
            <div className={styles["user__details"]}>
              <img
                alt="profile"
                src={user?.profileImg || AvatarImg}
                className={styles.user__image}
              />
              {/* <AvatarIcon
            className={styles.user__image}
            style={{ color: "#d3d3d3" }}
          /> */}
              <p>{user?.agency_name}</p>
              <ArrowImg
                className={clx(styles.menu__arrow, {
                  [styles.arrow__up]: isDropdownOpen,
                })}
              />
            </div>
            {isDropdownOpen && (
              <div ref={dropdownRef} className={styles.dropdown__menu}>
                <div
                  className={clx(styles.dropdown_item, {
                    [styles.active_item]: activeItem?.includes(ROUTES.ACCOUNT),
                  })}
                  onClick={() => {
                    navigate(ROUTES.ACCOUNT);
                    setActiveItem(ROUTES.ACCOUNT);
                  }}
                >
                  <AvatarIcon className={styles.user__image} />
                  Il mio profilo
                </div>
                {process.env.REACT_APP_PLATFORM != "BAKECA" &&
                  user?.is_private != 1 && (
                    <div
                      className={clx(styles.dropdown_item, {
                        [styles.active_item]: activeItem?.includes(
                          ROUTES.PAYMENTS
                        ),
                      })}
                      onClick={() => {
                        navigate(ROUTES.PAYMENTS);
                        setActiveItem(ROUTES.PAYMENTS);
                      }}
                    >
                      <Abbonamento style={{ width: 32, height: 32 }} />
                      Abbonamento
                    </div>
                  )}
                {user?.type == "3" && (
                  <div
                    className={clx(styles.dropdown_item, {
                      [styles.active_item]: activeItem?.includes(ROUTES.ADMIN),
                    })}
                    onClick={() => {
                      navigate(ROUTES.ADMIN);
                      setActiveItem(ROUTES.ADMIN);
                    }}
                  >
                    <Admin style={{ width: 26, height: 26 }} />
                    Admin
                  </div>
                )}
                <div
                  className={clx(styles.dropdown_item, styles.logout)}
                  onClick={() => dispatch(actions.LOG_OUT.REQUEST())}
                >
                  <LogoutIcon style={{ width: 27, height: 27 }} />
                  Esci
                </div>
                {process.env.REACT_APP_PLATFORM == "BAKECA" && (
                  <div className={styles.credits}>
                    <CreditIcon style={{ width: 25, height: 25 }} />
                    <p>{user?.nCredits} crediti</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </nav>
      <div className={styles.demo_mbl_btn}>
        <PrimaryButton
          height={50}
          text="Richiedi una demo"
          onClick={() => setContactUsModal(true)}
        />
      </div>
      {contactUsModal && user?.email != "" && (
        <ContactUsModal
          isModalOpen={contactUsModal}
          setIsModalOpen={setContactUsModal}
          userEmail={user?.email}
        />
      )}
    </>
  );
};

export default SideBar;
