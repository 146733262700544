import styles from './TextFieldMockup.module.scss';

const TextFieldMockup = ({
	label,
	value,
	height = 'auto',
	bgColor = 'transparent',
	color = '#034349',
	labelSize = '18px',
	borderRadius,
}) => (
	<div className={styles.field__wrapper}>
		<span
			style={{fontSize: labelSize}}
			className={styles['field__label']}>{label}</span>
		<div
			className={styles['field__value']}
			style={{
				borderRadius: `${borderRadius}`,
				background: bgColor,
				color,
				height
			}}>
			{value}
		</div>
	</div>
);

export default TextFieldMockup;
