import "./BarChart.scss";
import clx from "classnames";

import React from "react";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import chartStyles from "../Charts.module.scss";
import ChartsAxisLabel from "../ChartsAxisLabel";
import ChartsLabel from "../ChartsLabel";
import ChartsTooltip from "../ChartsTooltip";
import ChartsLegend from "../ChartsLegend";

const Index = ({
  title,
  description,
  label,
  legend,
  dx,
  marginLeft,
  chartData,
  wrapperHeight,
  minHeight,
  chartMarginTop,
}) => (
  <div
    className={clx(chartStyles.chart__wrapper, "bar")}
    style={{ minHeight: wrapperHeight }}
  >
    <b>{title}</b>
    <div
      className={chartStyles.chart__container}
      style={{ marginTop: chartMarginTop }}
    >
      <ResponsiveContainer
        minHeight={minHeight || 410}
        minWidth={420}
        height="80%"
      >
        <BarChart data={chartData} margin={{ top: 40, left: marginLeft }}>
          <XAxis
            dataKey={chartData && Object.keys(chartData[0])[0]}
            tick={<ChartsAxisLabel dy={10} textAnchor={"middle"} />}
          />
          <YAxis
            label={<ChartsLabel label={label} dy={60} dx={0} />}
            tick={<ChartsAxisLabel dy={3.5} textAnchor={"end"} />}
            tickFormatter={(tick) => tick.toLocaleString("it-IT")}
          />
          <Tooltip
            wrapperStyle={{ outline: "none" }}
            content={<ChartsTooltip />}
          />
          <Legend
            verticalAlign="top"
            align="right"
            height={50}
            width={"fit-content"}
            content={
              <ChartsLegend
                typeFirst={"Uomini"}
                colorFirst={"light"}
                typeSecond={"Donne"}
                colorSecond={"dark"}
                position="10px"
              />
            }
          />
          <Bar
            isAnimationActive={false}
            dataKey={chartData && Object.keys(chartData[0])[1]}
            radius={[5, 5, 0, 0]}
            className="strong-bar"
          >
            <LabelList
              dataKey={chartData && Object.keys(chartData[0])[3]}
              position="top"
              fill="#9D9D9D"
            />
          </Bar>
          <Bar
            isAnimationActive={false}
            dataKey={chartData && Object.keys(chartData[0])[2]}
            radius={[5, 5, 0, 0]}
            className="weak-bar"
          >
            <LabelList
              dataKey={chartData && Object.keys(chartData[0])[4]}
              position="top"
              fill="#9D9D9D"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
    <p className={chartStyles.chart__source}>{description}</p>
  </div>
);

export default Index;

// tick={{ stroke: '#9D9D9D', strokeWidth: .5 }}
