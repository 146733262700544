import GaugeChart from '../../../../../Common/Charts/GaugeChart';
import PdfCheckbox from '../../../PdfCheckbox';

const Employment = ({
	data, marginTopValue, minHeight, isPdf = false,
}) => {
	// console.log(data);
	const translateValue = value => {
		if (value < 1) {
			return { label: 'basso', value: 25 };
		}
		if (value < 2) {
			return { label: 'medio', value: 50 };
		}
		return { label: 'alto', value: 100 };
	};

	const translateDisplayValue = displayVal => {
		// console.log(displayVal);
		const str = displayVal;
		// console.log(str);
		const regex = /(\d+(\.\d+)?)/;
		const match = str?.match(regex);
		// console.log(match);
		const number = match ? Number(match[0])?.toFixed(1) : null;
		// console.log(number, 'NUMBER');

		return <>Comune con <b>{number}%</b> di occupati</>;
	};

	return (
		<div style={{ position: 'relative', height: '100%' }}>
			{!isPdf && <PdfCheckbox zIndex={1} section={'employment'} />}
			<GaugeChart title={data?.title} description={data?.description}
				displayValue={translateDisplayValue(data?.data?.displayed_value)} levelLabel={translateValue(data?.data?.level)?.label}
				value={translateValue(data?.data?.level)?.value} minHeight={minHeight} marginTopValue={marginTopValue} />
		</div>
	);
};

export default Employment;