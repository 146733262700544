import clx from "classnames";
import { useRef } from "react";

import CloseIcon from "../../../assets/svg/cross.svg";
import useOutsideClick from "../../../utils/useOutsideClick";
import styles from "./Modal.module.scss";

const Modal = ({
  isModalOpen,
  setIsModalOpen,
  children,
  height = "",
  width = "",
  maxWidth,
  padding = "",
  borderRadius = "",
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  position = "",
  top,
  left,
  bottom,
  transform,
  wrapperDisplay = "",
  wrapperJustifyContent = "",
  modalJustifyContent = "",
  wrapperFlexDirection = "",
  background = "",
  className,
  showCloseBtn,
  overflow,
  gap,
  zIndex,
}) => {
  const modalRef = useRef();

  useOutsideClick(modalRef, () => {
    if (isModalOpen && setIsModalOpen) setIsModalOpen();
  });

  return (
    <>
      {isModalOpen && (
        <div
          className={styles.modal__wrapper}
          style={{
            display: wrapperDisplay,
            justifyContent: wrapperJustifyContent,
            flexDirection: wrapperFlexDirection,
            zIndex,
          }}
        >
          <div
            ref={modalRef}
            className={clx(styles.modal, className)}
            style={{
              width,
              maxWidth,
              height,
              padding,
              borderRadius,
              borderTopLeftRadius,
              borderTopRightRadius,
              borderBottomLeftRadius,
              borderBottomRightRadius,
              position,
              top,
              left,
              bottom,
              transform,
              background,
              justifyContent: modalJustifyContent,
              gap,
              overflow,
            }}
          >
            {showCloseBtn && (
              <div className={styles.modal__header} onClick={showCloseBtn}>
                <img src={CloseIcon} alt="close" />
              </div>
            )}
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
