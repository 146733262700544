const tableColumns = [
  {
    name: "id",
    label: "ID",
    sort: true,
  },
  {
    name: "title",
    label: "Titolo",
    sort: true,
  },
  {
    name: "city",
    label: "Città",
    sort: true,
  },
  {
    name: "address",
    label: "Indirizzo",
    sort: true,
    filter: false,
  },
  {
    name: "type",
    label: "Tipologia",
    sort: true,
    filter: true,
  },
  {
    name: "size",
    label: "M²",
    sort: true,
    filter: true,
  },
  {
    name: "condition",
    label: "Stato Interni",
    filter: false,
    sort: true,
    centered: true,
  },
  {
    name: "heating",
    label: "Riscaldamento",
    filter: false,
    sort: true,
    centered: true,
  },
  {
    name: "price",
    label: "Stima economica",
    centered: true,
    filter: false,
    sort: false,
  },
  {
    name: "daysOnMarket",
    label: "Giorni sul mercato",
    centered: true,
    filter: true,
    sort: false,
  },

  // {
  //   name: "probability",
  //   label: "Vendibilità entro 6 mesi",
  //   centered: true,
  //   filter: true,
  //   sort: false,
  // },
];

export default tableColumns;
