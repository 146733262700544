import styles from "./List.module.scss";
import React from "react";
import clx from "classnames";

const List = ({
  cardsValues,
  setCharacteristicsValues,
  characteristicsValues,
  isChangingCharacteristics,
  setIsChangingCharacteristics,
}) => {
  return (
    <div className={styles.selects_list}>
      <div className={styles.selects_list__items}>
        {cardsValues?.map((item, index) => (
          <div
            key={`cards-values-${index}`}
            className={clx(styles.selects_list__items__item, styles[item.type])}
            onClick={() => {
              setCharacteristicsValues([...characteristicsValues, item]);
              if (!isChangingCharacteristics) {
                setIsChangingCharacteristics(true);
              }
            }}
          >
            <div className={styles.selects_list__items__item__title}>
              {item.label}
            </div>
            <div className={styles.selects_list__items__item__btn}>x</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default List;
