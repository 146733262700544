import "./PdfCheckbox.scss";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actions } from "../../../Report/store";
import { getPdfItems, getBoughtReport } from "../../../Report/store/selectors";
import { getUser } from "../../../Auth/store/selectors";

import ActionCheckboxButton from "../../../Common/Buttons/ActionCheckboxButton";
import ReactToolTip from "../../../Common/ToolTip/ReactToolTip";
import { useCheckboxTooltipText } from "../../constants";

const PdfCheckbox = ({
  section,
  className,
  top,
  zIndex,
  isDisabled,
  isChecked,
  isOpposite,
  isGeneral,
  toolText,
}) => {
  const tooltiptext = useCheckboxTooltipText(section, isOpposite, isGeneral);
  const dispatch = useDispatch();
  const pdfItems = useSelector(getPdfItems());
  const { reportId } = useParams();
  const toggledItem = pdfItems?.[0]?.[section] == 0 ? 1 : 0;

  const user = useSelector(getUser());
  // console.log("user", user);
  const boughtReport = useSelector(getBoughtReport());
  // console.log("boughtReport", boughtReport);

  return (
    <ReactToolTip
      contenet={
        <div
          style={{
            color: "gray",
            fontSize: "14px",
            lineHeight: "15px",
          }}
        >
          {toolText || tooltiptext}
        </div>
      }
      displayPosition="absolute"
      containerClass={className || "tooltipStyleCharts"}
      x={-110}
      y={-200}
      width={289}
      height={100}
      arrow={{
        x: 260,
        y: 96,
        rotate: "206deg",
      }}
      top={top}
      zIndex={zIndex}
    >
      <div
        className={`tooltipBtn ${
          (user?.is_private == 1 && boughtReport?.house_id != reportId) ||
          isDisabled
            ? "disabled"
            : ""
        }`}
        onClick={() => {
          if (
            (user?.is_private == 1 && boughtReport?.house_id != reportId) ||
            isDisabled
          )
            return;
          if (isDisabled) return;
          if (reportId) {
            dispatch(
              actions.UPDATE_PDF_ITEMS.REQUEST(
                { reportId, chartItem: { [section]: toggledItem } },
                () => dispatch(actions.GET_PDF_ITEMS.REQUEST(reportId))
              )
            );
          } else {
            throw new Error("missingReportId");
          }
        }}
      >
        <ActionCheckboxButton
          checked={
            isChecked == false
              ? false
              : isOpposite
              ? pdfItems?.[0]?.[section] == 1
              : pdfItems?.[0]?.[section] == 0
          }
        />
        <span>{`${className == "tooltipStyle" ? "Includi nel" : ""} PDF`}</span>
      </div>
    </ReactToolTip>
  );
};

export default PdfCheckbox;
