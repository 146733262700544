import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import FolderIcon from "../../../../../../assets/svg/folder.svg";
import { fileReaderToBase64 } from "../../../../../../helpers";
import PrimaryButton from "../../../../../Common/Buttons/PrimaryButton";
import Modal from "../../../../../Common/Modal";
import SingleSelect from "../../../../../Common/SingleSelect";
import TextField from "../../../../../Common/TextField";
import WarningTooltip from "../../../../../Common/WarningTooltip";
import { actions } from "../../../../../Report/store";
import { TYPE_ROOM } from "../../constants";
import DragAndDropContainer from "./DragAndDropContainer";
import styles from "./UploadImageModal.module.scss";

const UploadImageModal = ({
  reportId,
  isModalOpen,
  setUploadImageModalOpen,
  gallery,
}) => {
  const dispatch = useDispatch();

  const [uploadImages, setUploadImages] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [error, setError] = useState(false);
  const [picturesPopup, setPicturesPopup] = useState(false);

  const initialValues = {
    title: "",
    description: "",
    typeRoom: "",
  };

  const validationSchema = yup.object().shape({
    typeRoom: yup.string("Scegli il tipo di camera").required("Obbligatorio"),
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 3000);
    }
  }, [error]);

  const closeModal = () => {
    setUploadImages([]);
    setUploadedFile([]);
    setUploadImageModalOpen(false);
  };

  return (
    <Modal
      height="510px"
      width="80%"
      maxWidth={"510px"}
      padding="40px"
      zIndex="10000000000"
      isModalOpen={isModalOpen}
      showCloseBtn={closeModal}
    >
      <div className={styles["modal__upload-image"]}>
        <DragAndDropContainer
          setUploadedFile={setUploadedFile}
          setUploadImages={setUploadImages}
          setError={setError}
        >
          <div className={styles["upload-img__btn"]}>
            <label htmlFor="img" className={styles["btn__label"]}>
              <img src={FolderIcon} alt="upload" />
              <span>
                Trascina o clicca per{" "}
                {!uploadImages?.length
                  ? "caricare una foto del tuo immobile"
                  : "cambiare la foto caricata"}
              </span>
              {error && <p className={styles.error}>{error}</p>}
            </label>
            <input
              style={{
                width: "0.1px",
                height: "0.1px",
                opacity: "0",
                overflow: "hidden",
                position: "absolute",
                zIndex: "-1",
              }}
              onChange={async (e) => {
                e.persist();
                const img = Object.values(e.target.files);
                if (img) {
                  const base64ImageUrl = await fileReaderToBase64(img);
                  // FOR MULTIPLY
                  // setUploadImages(prevState => [...prevState, base64ImageUrl]);
                  setUploadImages([base64ImageUrl]);
                  setUploadedFile((images) => [...images, img[0]]);
                }
              }}
              name="img"
              id="img"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
            />
          </div>
        </DragAndDropContainer>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(value) => {
            // if (gallery?.data?.length < 4) {
            if (uploadImages.length) {
              dispatch(
                actions.UPLOAD_IMAGE.REQUEST(
                  {
                    reportId,
                    base64: uploadImages[0],
                    ...value,
                  },
                  closeModal
                )
              );
            }
            // } else {
            // 	setPicturesPopup(true);
            // }
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form className={styles.form}>
              <Field
                name="title"
                id="title"
                component={TextField}
                type="text"
                borderRadius="5px"
                placeholder="Titolo"
              />
              <Field
                name="description"
                id="description"
                component={TextField}
                type="text"
                borderRadius="5px"
                placeholder="Descrizione"
                textarea={true}
              />
              <SingleSelect
                label="Tipologia stanza"
                options={TYPE_ROOM}
                hideBaseConfiguration={true}
                defOption={
                  TYPE_ROOM.find((i) => i.value === values.type_room)?.label
                }
                value={TYPE_ROOM.find((i) => i.value === values.type_room)}
                onChange={(data) => setFieldValue("typeRoom", data.value)}
              />
              {touched.typeRoom && errors.typeRoom && (
                <div className={styles.select_error}>{errors.typeRoom}</div>
              )}
              <div className={styles.btn_wrapper}>
                <PrimaryButton type="submit" text="Carica" width="100%" />
              </div>
            </Form>
          )}
        </Formik>
        {uploadImages?.length ? (
          <div className={styles.preview__wrapper}>
            {uploadImages?.map((image, idx) => (
              <div
                key={idx}
                className={styles["uploaded-image__wrapper"]}
                onClick={() => {
                  setUploadImages((images) =>
                    images?.filter((img, i) => i !== idx)
                  );
                  setUploadedFile((files) =>
                    files?.filter((img, i) => i !== idx)
                  );
                }}
              >
                <img src={image} alt="" className={styles["uploaded-image"]} />
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <WarningTooltip
        picturesPopup={picturesPopup}
        setPicturesPopup={setPicturesPopup}
        text={
          "E' possibile caricare massimo 4 foto. Per assistenza o feedback contatta il team Houseplus :)"
        }
      />
    </Modal>
  );
};

export default UploadImageModal;
