import clx from "classnames";
import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useLocation } from "react-router-dom";

import { Api } from "../../../entries/ApiTransport";
import { ROUTES } from "../../../routes/constants";
import styles from "../TextField/TextField.module.scss";

const api = Api.getInstance();

function GoogleAutocomplete({
  field: { id, name },
  form: { touched, errors, setFieldValue, values },
  defaultValue = "",
  border,
  label,
  inputColor = "#ffff",
  borderRadius,
  height = "50px",
  disabled,
  placeholder,
  restrictLocations,
  types,
  country,
  coords,
}) {
  const { pathname } = useLocation();
  const isError = touched[name] && errors[name];
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyDhU_uaWxC4hWjLecwUvBW7RzqauLXfpVw",
    onPlaceSelected: (place) => {
      // console.log(place, "place");
      if (Object.keys(place || {}).includes("geometry")) {
        handleChangeIndirizzo(place);
      } else {
        return;
      }
    },
    options: {
      // Check also if user passed props regarding a strict location he wants to target
      types: ["geocode", "establishment"],
      componentRestrictions: restrictLocations && { country },
    },
  });

  function handleChangeIndirizzo(place) {
    const Lat = place.geometry.location.lat();
    const Lng = place.geometry.location.lng();
    let cap = 0;
    let numero = 0;
    let citta = "";
    let provincia = "";
    let regione = "";
    let nazione = "";
    let via = "";
    for (var i = 0; i < place.address_components.length; i++) {
      for (var j = 0; j < place.address_components[i].types.length; j++) {
        if (place.address_components[i].types[j] === "postal_code") {
          cap = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] === "street_number") {
          numero = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] === "locality") {
          citta = place.address_components[i].long_name;
        } else if (
          !citta &&
          place.address_components[i].types[j] === "administrative_area_level_3"
        ) {
          citta = place.address_components[i].long_name;
        } else if (
          place.address_components[i].types[j] === "administrative_area_level_2"
        ) {
          provincia = place.address_components[i].short_name;
        } else if (
          place.address_components[i].types[j] === "administrative_area_level_1"
        ) {
          regione = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] === "country") {
          nazione = place.address_components[i].short_name;
        } else if (place.address_components[i].types[j] === "route") {
          via = place.address_components[i].long_name;
        }
      }
    }

    // if  (pathname != ROUTES.ACCOUNT )
    // {
    // 	const resOmi = api.get('/house/omi/'+lat+'/'+ lng +'/' + provincia).then(res => {
    // 		//console.log(res?.data);
    // 		setFieldValue('prezzoPerMetroQuadro', res?.data?.prezzoPerMetroQuadro);
    // 	 });
    // }

    setFieldValue(
      name,
      pathname === ROUTES.ACCOUNT
        ? {
            city: citta,
            street: via,
            number: numero,
            province: provincia,
            postal_code: cap,
            region: regione,
            country: nazione,
            lat: Lat,
            lng: Lng,
          }
        : {
            country: nazione,
            regione,
            provincia,
            city: citta,
            buildingNumber: numero,
            cap,
            street: via,
            Lat,
            Lng,
          }
    );
  }

  const handleKeyDown = (event) => {
    // Prevent default behavior for arrow keys and Enter key
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div
      className={clx(styles.field__wrapper, {
        [styles["error-field"]]: isError,
      })}
    >
      {label && (
        <label
          className={styles["field__label"]}
          htmlFor={"autocomplete"}
          style={{ marginBottom: "10px" }}
        >
          {label}
        </label>
      )}
      <div
        className={clx(styles["input-group"], {
          [styles["error-field"]]: isError,
        })}
        style={{
          borderRadius,
          border,
          height,
          background: `${inputColor}`,
        }}
      >
        <input
          id={id}
          name={name}
          ref={ref}
          className={clx(styles["field__input"], styles[`${inputColor}`])}
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="off"
          defaultValue={defaultValue}
          style={{
            background: `${inputColor}`,
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
      {isError && (
        <div className={styles.error}>
          E&apos; richiesto l&apos;inserimento di un indirizzo
        </div>
      )}
    </div>
  );
}

export default GoogleAutocomplete;
