import { combineReducers } from "redux";

import { authReducer } from "../Containers/Auth/store";
import { paymentsAndCreditsReducer } from "../Containers/PaymentsAndCredits/store";
import { reportReducer } from "../Containers/Report/store";
import { errorReducer } from "../shared/Error/store/reducer";
import { LoaderReducer } from "../shared/Loader/store/reducer";
import { adminReducer } from "../Containers/Admin/store";

export default () =>
  combineReducers({
    loader: LoaderReducer,
    errorReducer,
    authReducer,
    reportReducer,
    paymentsAndCreditsReducer,
    adminReducer,
  });
