// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import ConnectIcon from "../../../../assets/svg/connection.svg";
// import CreditIcon from "../../../../assets/svg/credit.svg";
import { ROUTES } from "../../../../routes/constants";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import Modal from "../../../Common/Modal";
// import { actions } from "../../../PaymentsAndCredits/store";
// import { getUserCredits } from "../../../PaymentsAndCredits/store/selectors";
import styles from "./CreateReportModal.module.scss";

export const CreateReportModal = ({
  isCreateReportModalOpen,
  setIsCreateReportModalOpen,
}) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const userCredits = useSelector(getUserCredits());

  // useEffect(() => {
  //   dispatch(actions.CHECK_USER_CREDITS.REQUEST());
  // }, [dispatch]);

  return (
    <Modal
      padding="80px"
      showCloseBtn={() => setIsCreateReportModalOpen(false)}
      isModalOpen={isCreateReportModalOpen}
      setIsModalOpen={() => setIsCreateReportModalOpen(false)}
    >
      <div className={styles["report-modal__content"]}>
        <h1 style={{ textAlign: "center" }}>
          Inizia ad analizzare il tuo immobile
        </h1>
        {/* <p>Vuoi creare un report di vendibilità di un immobile usando i tuoi crediti?</p>
				<br/>
				<p> 1 credito corrisponde a 1 report</p> */}
        {/* <div className={styles['user-credits']}>
					<img src={CreditIcon} alt='credits' />
					<div className={styles['credits__text']}>
						<div className={styles['text__line']}>
							<b>Crediti richiesti</b>
							<b className={styles['credits__value']}>1</b>
						</div>
						<div>
							<b>I miei crediti </b>
							<b className={styles['credits__value']}>{userCredits}</b>
						</div>
					</div>
				</div> */}
        <div className={styles["modal__actions"]}>
          {/* {userCredits > 0 ? ( */}
          <PrimaryButton
            leftIconSrc={ConnectIcon}
            width="180px"
            height="45px"
            fontSize="17px"
            text="Crea Report"
            onClick={() => {
              navigate(ROUTES.CREATE_REPORT);
              setIsCreateReportModalOpen(false);
            }}
          />
          {/* ) : (
            <>
              <PrimaryButton
                leftIconSrc={CreditIcon}
                width="180px"
                height="45px"
                fontSize="17px"
                text="Acquista Report"
                onClick={() => {
                  navigate(ROUTES.PAYMENTS);
                  setIsCreateReportModalOpen(false);
                }}
              />
            </>
          )} */}
          {/* <SecondaryButton
						leftIconSrc={CreditIcon}
						width='180px'
						height='45px'
						fontSize='17px'
						text='Acquista Crediti'
						onClick={() => {
							navigate(ROUTES.PAYMENTS);
							setIsCreateReportModalOpen(false);
						}}
					/> */}
        </div>
      </div>
    </Modal>
  );
};
