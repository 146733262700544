import clx from "classnames";
import GoogleMapReact from "google-map-react";
import React, { useState } from "react";
import CloseIcon from "../../../../../../assets/svg/cross-white.svg";
import MapIcon from "../../../../../../assets/svg/empty-map-violet.svg";
import { ReactComponent as MarkerIcon } from "../../../../../../assets/svg/marker.svg";
import PrimaryButton from "../../../../../Common/Buttons/PrimaryButton";
import Modal from "../../../../../Common/Modal";
import PinMap from "./containers/PinMap";
import styles from "./ServicesMapModal.module.scss";

const distanceFiltering = [
  {
    id: 1,
    name: "Tutto",
    value: 1000000000,
    is_active: true,
  },
  {
    id: 2,
    name: "Entro 500 m",
    value: 500,
    is_active: false,
  },
  {
    id: 3,
    name: "Entro 1 km",
    value: 1000,
    is_active: false,
  },
  {
    id: 4,
    name: "Entro 2 km",
    value: 2000,
    is_active: false,
  },
];

const HomePin = (home) => (
  <img
    lat={home?.lat}
    lng={home?.lng}
    src={MarkerIcon}
    className={styles.home}
    alt="point"
  />
);

const MapOptions = {
  minZoom: 12,
  fullscreenControl: false,
  zoomControl: true,
};

const ServicesMapModal = ({ isMobile, data, home }) => {
  const [isServicesMapModalOpen, setIsServicesMapModalOpen] = useState(false);
  const [activeDistanceFilter, setActiveDistanceFilter] =
    useState(distanceFiltering);
  let activeFilter = activeDistanceFilter.find((item) => item.is_active);
  return (
    <>
      <div
        className={clx(styles.area__services__action, {
          [styles["area__services__action-mobile"]]: isMobile,
        })}
      >
        <PrimaryButton
          height="60px"
          text="Visualizza sulla Mappa"
          onClick={() => setIsServicesMapModalOpen(true)}
          color={"#ffffff"}
          leftIconSrc={MapIcon}
        />
      </div>
      <Modal
        padding="0px"
        width={isMobile ? "100%" : "80%"}
        height={isMobile ? "100vh" : "80vh"}
        isModalOpen={isServicesMapModalOpen}
        zIndex="10000000000"
      >
        <span className={clx(styles["corner-background"])} />
        <span
          onClick={() => setIsServicesMapModalOpen(false)}
          className={styles["corner-value"]}
        >
          <img style={{ width: 20 }} src={CloseIcon} alt="" />
        </span>

        <div className={styles["distance-filter"]}>
          {activeDistanceFilter.map((filter) => (
            <span
              className={clx(styles["filter-item"], {
                [styles["active-filter"]]: filter.is_active,
              })}
              onClick={() =>
                setActiveDistanceFilter(
                  activeDistanceFilter.map((i) => ({
                    ...i,
                    is_active: i.id === filter.id,
                  }))
                )
              }
              key={filter.id}
            >
              {filter.name}
            </span>
          ))}
        </div>
        <div className={styles["map-modal__content"]}>
          <GoogleMapReact
            options={MapOptions}
            bootstrapURLKeys={{
              key: "AIzaSyDhU_uaWxC4hWjLecwUvBW7RzqauLXfpVw",
            }}
            center={{ lat: +home?.lat, lng: +home?.lng }}
            defaultZoom={17}
            key={"servicesMapId"}
            yesIWantToUseGoogleMapApiInternals
          >
            {data
              ?.filter((item) => item?.dist <= activeFilter.value)
              .map((property, index) => (
                // console.log(property, "simila property"),
                <PinMap
                  key={`similar_properties-${index}`}
                  customClass={styles.pins}
                  data={property}
                  lat={property?.loc?.coordinates[0]}
                  lng={property?.loc?.coordinates[1]}
                />
              ))}
            {/* <HomePin home={home} /> */}
            <span lat={home?.lat} lng={home?.lng} className={styles.home}>
              <MarkerIcon />
            </span>
          </GoogleMapReact>
        </div>
      </Modal>
    </>
  );
};

export default ServicesMapModal;
