import React, { useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./AgentCharacteristics.module.scss";
import SectionTitle from "../SectionTitle";
import useSelectsSetter from "./Selects/utils/useSelectsSetter";
import Selects from "./Selects";
import List from "./List";
import BuyCta from "../BuyCta";

import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import CheckIcon from "../../../../assets/svg/check-icon.svg";
import { actions } from "../../../Report/store";

const AgentCharacteristics = forwardRef(
  ({ privateUserHasReport, userIsPrivate }, ref) => {
    const dispatch = useDispatch();
    const { reportId } = useParams();

    // Custom hooks for setting available characteristic options based on house info and options saves in db
    const { characteristicsValues, setCharacteristicsValues, cardsValues } =
      useSelectsSetter();

    const [isChangingCharacteristics, setIsChangingCharacteristics] =
      useState(false);
    // console.log(isChangingCharacteristics, "isChangingCharacteristics");

    return (
      <div className={styles.agent_characteristics} ref={ref}>
        <SectionTitle
          title={"Caratteristiche della proprietà in vendita"}
          subtitle={"Elementi che incidono sul valore dell'immobile"}
          section={"agent_characteristics"}
        />

        {userIsPrivate && !privateUserHasReport ? (
          <BuyCta />
        ) : (
          <>
            <Selects
              characteristicsValues={characteristicsValues}
              setCharacteristicsValues={setCharacteristicsValues}
              isChangingCharacteristics={isChangingCharacteristics}
              setIsChangingCharacteristics={setIsChangingCharacteristics}
            />

            {cardsValues.length > 0 && (
              <List
                cardsValues={cardsValues}
                characteristicsValues={characteristicsValues}
                setCharacteristicsValues={setCharacteristicsValues}
                isChangingCharacteristics={isChangingCharacteristics}
                setIsChangingCharacteristics={setIsChangingCharacteristics}
              />
            )}

            {isChangingCharacteristics && (
              <PrimaryButton
                text="Salva opzioni selezionate"
                leftIconSrc={CheckIcon}
                onClick={() => {
                  dispatch(
                    actions.SET_AGENT_CHARACTERISTICS.REQUEST(
                      {
                        reportId,
                        charValues: cardsValues?.map((item) => ({
                          value: item.value,
                          type: item.type,
                        })),
                      },
                      () => setIsChangingCharacteristics(false)
                    )
                  );
                }}
                fontSize="14px"
                height="50px"
                // padding="6px 10px"
                borderRadius="10px"
                width="fit-content"
                marginTop={"30px"}
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default AgentCharacteristics;
