import React from "react";
import "./ChartsLegend.scss";

const ChartsLegend = ({
  typeFirst = "",
  typeSecond = "",
  colorFirst,
  colorSecond,
  position,
  isSingle = false,
}) => {
  return (
    <div className="charts_legend" style={{ right: position }}>
      <div className="charts_legend__first">
        <span className={colorFirst} /> {typeFirst}
      </div>
      {!isSingle && (
        <div className="charts_legend__second">
          <span className={colorSecond} /> {typeSecond}
        </div>
      )}
    </div>
  );
};

export default ChartsLegend;
