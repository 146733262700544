import styles from './Label.module.scss';

export const Label = ({
	label, value, disabled, radius
}) => {
	const r = 0;
	const angle = ((value / 200) * 360);
	const radians = Math.PI * (angle / 180);
	const cos = Math.cos(radians);
	const sen = Math.sin(radians) * (disabled ? -1 : 1);
	const x = radius * cos + 20;
	const y = radius * sen - 30;
	return <div className={styles.label__wrapper} style={{
		transform: `translate(${y}px, ${-x}px)`,
	}}>
		<p className={styles.label__text}>{label}</p>
		<span className={styles.label__value}>{value}%</span>
	</div>;
};