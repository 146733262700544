function buildAddressString(houseInfo) {
  const fields = ["street", "building_number", "cap", "city", "provincia"];
  let addressString = "";
  let previousFieldAdded = false;

  for (let i = 0; i < fields.length; i++) {
    if (houseInfo?.[fields[i]]) {
      // if (
      //   fields[i] === "provincia" &&
      //   houseInfo?.city !== "" &&
      //   houseInfo?.[fields[i]] === houseInfo?.city
      // ) {
      //   continue;
      // }

      if (previousFieldAdded) {
        addressString += ", ";
      }

      addressString += houseInfo?.[fields[i]];
      previousFieldAdded = true;
    }
  }

  return addressString;
}

export default buildAddressString;
