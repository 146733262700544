import Select from "react-select";
import styles from "./SelectField.module.scss";
import "./SelectField.scss";
import selectConditionals from "../../../utils/selectConditionals";
import clx from "classnames";

// REACT-SELECT STYLES
const colourStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: "#fff",
    border: `1px solid ${selectConditionals()?.singleMainColor}`,
    boxShadow: isFocused
      ? `0 0 0 1px ${selectConditionals()?.mainColor}`
      : "none",
    // "&:hover": {
    //   boxShadow: `0 0 0 1px ${selectConditionals()?.mainColor}`,
    // },
    width: "100%",
    height: "50px",
    borderRadius: "5px",
    cursor: "pointer",
  }),
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      borderRadius: "0px 5px 5px 0px",
      width: "5px",
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: "0px 5px 5px 0px",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? selectConditionals()?.singleMainColor
      : isFocused
      ? selectConditionals()?.singleSecondaryColor
      : undefined,
    color: isDisabled ? "#ccc" : isSelected ? "white" : data.color,
    ":active": {
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? selectConditionals()?.singleMainColor
        : isFocused
        ? selectConditionals()?.singleSecondaryColor
        : undefined,
    },
    fontSize: "15px",
  }),
  input: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
  container: (styles) => ({ width: "100%", ...styles }),
};

// SINGLE AND MULTI SELECT CUSTOM COMPONENT
const SelectField = ({
  label,
  options,
  borderRadius = "10px",
  labelHeight,
  labelStyle,
  marginBottomInputGroup,
  classNameInherit,
  key,
  field,
  width,
  placeholder = "",
  form: { touched, errors, setFieldValue, setTouched, values },
  ...props
}) => {
  const isError = touched[field.name] && errors[field.name];
  // console.log("isError", isError);

  return (
    // Wrapper
    <div
      className={clx(styles.field__wrapper, "select-field", {
        ["select-field-error"]: isError,
      })}
      style={{ width }}
    >
      {/* Label */}
      {label && (
        <div
          className={styles.label_wrapper}
          style={{ height: labelHeight || 35, ...labelStyle }}
        >
          <label className={styles["field__label"]} htmlFor={field.name}>
            {label}
          </label>
        </div>
      )}
      <Select
        {...field}
        {...props}
        options={options}
        defOption={options[1].label}
        styles={colourStyles}
        onChange={(option) => setFieldValue(field.name, option.value)}
        onBlur={() => setTouched({ ...touched, [field.name]: true })}
        placeholder={placeholder}
        value={options.find((option) => option.value === field.value)}
        // isClearable={false}
        // isOptionDisabled={(option) => option.disabled}
        blurInputOnSelect={false}
      />
      {/* Validation Error div */}
      {isError && <div className={styles.error}>{errors[field.name]}</div>}
    </div>
  );
};

export default SelectField;
