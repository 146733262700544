import clx from "classnames";

import styles from "./SecondaryButton.module.scss";

const SecondaryButton = ({
  height = "50px",
  width = "auto",
  color,
  fontSize = "24px",
  text,
  leftIconSrc: LeftIconSrc,
  isReactIcon,
  viewBox,
  border = "none",
  onClick,
  margin = "0",
  className,
  classNameExternal,
}) => (
  <button
    style={{
      height,
      width,
      fontSize,
      color,
      border,
      margin,
    }}
    className={clx(
      styles.secondary__btn,
      styles[`${className}`],
      classNameExternal
    )}
    onClick={onClick}
  >
    {LeftIconSrc &&
      (!isReactIcon ? (
        <img src={LeftIconSrc} alt="icon" />
      ) : (
        <LeftIconSrc viewBox={viewBox} />
      ))}
    <span>{text}</span>
  </button>
);

export default SecondaryButton;
