import React from "react";
import centriEsteticiMaker from "../../../../assets/svg/map/centriEstetici.svg";
import DentistMarker from "../../../../assets/svg/map/dent.svg";
import dottoreMaker from "../../../../assets/svg/map/dottore.svg";
import farmaciayMaker from "../../../../assets/svg/map/farmacia.svg";
import GreenSpace from "../../../../assets/svg/map/green.svg";
import HospitalMarker from "../../../../assets/svg/map/hospital.svg";
import ParkingMarker from "../../../../assets/svg/map/parching.svg";
import SchoolMarker from "../../../../assets/svg/map/school.svg";
import GroceryStoreMarker from "../../../../assets/svg/map/shop-food.svg";
import TransportMarker from "../../../../assets/svg/map/transport.svg";
import TransportMarkerTrain from "../../../../assets/svg/map/transportTrain.svg";
import UniversityMaker from "../../../../assets/svg/map/univerity.svg";
import Bus from "../../../../assets/svg/map/bus.svg";
import styles from "./ThirteenthPg.module.scss";

const ThirteenthPg = ({ data }) => {
  const values = [
    {
      data_name: "beauty",
      display_name: "Centri estetici",
      icon: centriEsteticiMaker,
    },
    {
      data_name: "parking",
      display_name: "Parcheggi",
      icon: ParkingMarker,
    },
    {
      data_name: "supermarket",
      display_name: "Supermercati",
      icon: GroceryStoreMarker,
    },
    {
      data_name: "university",
      display_name: "Universita'",
      icon: UniversityMaker,
    },
    {
      data_name: "school",
      display_name: "Scuole",
      icon: SchoolMarker,
    },
    {
      data_name: "hospital",
      display_name: "Ospedali",
      icon: HospitalMarker,
    },
    {
      data_name: "dentist",
      display_name: "Dentisti",
      icon: DentistMarker,
    },
    {
      data_name: "medical_centers",
      display_name: "Centri medici",
      icon: dottoreMaker,
    },
    {
      data_name: "green_outdoors",
      display_name: "Spazi verdi",
      icon: GreenSpace,
    },
    {
      data_name: "pharmacy",
      display_name: "Farmacie",
      icon: farmaciayMaker,
    },
    {
      data_name: "bus",
      display_name: "Bus",
      icon: Bus,
    },
    {
      data_name: "metro",
      display_name: "Metro",
      icon: TransportMarker,
    },
    {
      data_name: "train",
      display_name: "Treni",
      icon: TransportMarkerTrain,
    },
  ];

  const services = values
    ?.map((value) => {
      const obj = data?.[value.data_name];
      if (obj) {
        obj.serviceName = value.display_name;
        obj.icon = value.icon;
      }
      return obj;
    })
    .filter((item) => item?.services_found?.length)
    .slice(6);

  return (
    <div className={styles.area__services__wrapper}>
      <h3 className={styles.header__text}>Servizi di zona</h3>
      <div className={styles.services}>
        {services.map((item, index) => (
          <div key={index} className={styles.service}>
            <div className={styles.service_title}>
              <img src={item.icon} alt="service-logo" /> {item.serviceName}
            </div>
            <div className={styles.service_content}>
              <div className={styles.service_content_distance}>
                <p className={styles.service_content_distance_title}>Km.</p>
                {item.services_found.map((serviceItem, index) => (
                  <p
                    key={index}
                    className={styles.service_content_distance_content}
                  >
                    {(serviceItem.distance / 1000).toFixed(2)}
                  </p>
                ))}
              </div>
              <div className={styles.service_content_name}>
                <p className={styles.service_content_name_title}>Nome</p>
                {item.services_found.map((serviceItem, index) => (
                  <p
                    key={index}
                    className={styles.service_content_name_content}
                  >
                    {serviceItem.name.substring(0, 25)}
                    {serviceItem.name.length > 25 && "..."}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThirteenthPg;
