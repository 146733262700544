import React from 'react';

import AreaChart from '../../../../../Common/Charts/AreaChart';
import PdfCheckbox from '../../../PdfCheckbox';

const InflationChange = ({
	data, minHeight, wrapperHeight, paddingTop, chartMarginTop, sourcePosition, sourceBottom, sourceRight, isPdf = false,
}) => {
	// console.log(data);
	const chartData = data?.data?.y?.map((item, index) => ({ 'x': item, 'Inflazione': data?.data?.x[index] }));
	// console.log(chartData);

	return (
		<div style={{ height: '100%' }}>
			{!isPdf && <PdfCheckbox section={'inflation_change'} />}
			<AreaChart title={data?.title} description={data?.description} limit={data?.data?.ylimits}
				label={data?.data?.ytitle} dx={55} marginLeft={-35}
				labelAdditionalContent={'%'} wrapperHeight={wrapperHeight}
				minHeight={minHeight}
				chartData={chartData}
				paddingTop={paddingTop}
				chartMarginTop={chartMarginTop}
				sourcePosition={sourcePosition}
				sourceRight={sourceRight}
				sourceBottom={sourceBottom} />
		</div>
	);
};

export default InflationChange;