import React from "react";
import clx from "classnames";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import chartStyles from "../Charts.module.scss";
import ChartsAxisLabel from "../ChartsAxisLabel";
import ChartsLabel from "../ChartsLabel";
import ChartsTooltip from "../ChartsTooltip";
import ChartsLegend from "../ChartsLegend";
import "./HorizontalBar.scss";

const Index = ({
  title,
  description,
  label,
  marginLeft,
  chartData,
  wrapperHeight,
  minHeight,
  marginTop,
}) => (
  // console.log(chartData)
  <div
    className={clx(chartStyles.chart__wrapper, "horizontal-bar")}
    style={{ minHeight: wrapperHeight }}
  >
    <b>{title}</b>
    <div className={chartStyles.chart__container} style={{ marginTop: "20px" }}>
      <ResponsiveContainer
        minHeight={minHeight || 450}
        minWidth={420}
        height="80%"
      >
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            bottom: 20,
            left: marginLeft,
            right: 30,
          }}
          layout={"vertical"}
          height={minHeight}
        >
          <XAxis
            type="number"
            label={<ChartsLabel label={label} dy={440} dx={95} />}
            tick={<ChartsAxisLabel dy={10} textAnchor={"middle"} />}
            tickFormatter={(tick) => tick.toLocaleString("it-IT")}
          />
          <YAxis
            type="category"
            dataKey={chartData && Object.keys(chartData[0])[0]}
            tick={<ChartsAxisLabel dy={3.5} textAnchor={"end"} />}
          />
          <Tooltip
            wrapperStyle={{ outline: "none" }}
            content={<ChartsTooltip />}
          />
          <Legend
            verticalAlign="top"
            align="right"
            height={20}
            width={"fit-content"}
            content={
              <ChartsLegend
                typeFirst={"Uomini"}
                colorFirst={"light"}
                typeSecond={"Donne"}
                colorSecond={"dark"}
                position="-10px"
              />
            }
          />
          <Bar
            isAnimationActive={false}
            dataKey={chartData && Object.keys(chartData[0])[1]}
            radius={[0, 5, 5, 0]}
            className="strong-bar"
          >
            <LabelList
              dataKey={chartData && Object.keys(chartData[0])[3]}
              position="right"
              fill="#9D9D9D"
            />
          </Bar>
          <Bar
            isAnimationActive={false}
            dataKey={chartData && Object.keys(chartData[0])[2]}
            radius={[0, 5, 5, 0]}
            className="weak-bar"
          >
            <LabelList
              dataKey={chartData && Object.keys(chartData[0])[4]}
              position="right"
              fill="#9D9D9D"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
    <p className={chartStyles.chart__source} style={{ marginTop }}>
      {description}
    </p>
  </div>
);
export default Index;
