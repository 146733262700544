import clx from "classnames";
import { useEffect, useState } from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  Tooltip,
} from "recharts";
import styles from "./FifteenthPg.module.scss";
import "../../../AlgorithmReport/containers/PotentialCustomer/RadarChart.scss";

const House = (
  <g transform="matrix(1 0 0 1 -0 1)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.72664 0.055475C6.62089 0.09447 6.49066 0.189127 6.23275 0.414449C6.04013 0.582772 4.58978 1.85732 3.0098 3.24675C0.568691 5.39346 0.126764 5.79287 0.0683846 5.90527C-0.0688623 6.16951 0.000389462 6.43731 0.27696 6.71188C0.486081 6.91946 0.621498 6.98225 0.860205 6.98225C1.10063 6.98225 1.19015 6.93806 1.50218 6.66539L1.73439 6.46244L1.74805 9.48788L1.76171 12.5133L1.82041 12.6185C1.90335 12.767 2.01265 12.865 2.17604 12.9374L2.3172 13L3.69221 12.9925C5.0393 12.9852 5.06943 12.9839 5.17685 12.9287C5.3162 12.8571 5.42561 12.7522 5.50024 12.6185C5.55876 12.5137 5.55897 12.5061 5.56654 10.371L5.57414 8.22859H7.12991H8.68566L8.69312 10.3992L8.70055 12.5699L8.78321 12.6982C8.83444 12.7776 8.91677 12.8566 8.9996 12.9057L9.1333 12.985L10.5666 12.9925C12.1444 13.0007 12.1512 13.0001 12.3528 12.8407C12.4073 12.7976 12.4837 12.7011 12.5226 12.6261L12.5934 12.4899V9.52067C12.5934 7.88757 12.6006 6.55139 12.6095 6.55139C12.6184 6.55139 12.6945 6.61025 12.7786 6.68216C12.8629 6.75425 12.9691 6.81956 13.0151 6.82773C13.1185 6.846 13.2932 6.78848 13.4513 6.68415C13.6094 6.57982 13.8623 6.30101 13.9295 6.15709L13.9832 6.04215L13.995 6.15918C14.0014 6.22355 14.0017 6.16419 13.9955 6.02726C13.975 5.57057 14.2739 5.85737 10.6302 2.7981C7.16764 -0.109074 7.30655 -0.00207399 6.99895 0.00117559C6.92817 0.00193558 6.80562 0.0263598 6.72664 0.055475ZM0.00814786 6.1714C0.00828445 6.25788 0.013393 6.29009 0.0194849 6.24299C0.0255496 6.1959 0.0254403 6.12514 0.0192118 6.08575C0.0129559 6.04637 0.00798395 6.08492 0.00814786 6.1714Z"
      fill="#7C0AF5"
    />
  </g>
);
const People = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.36273 0.0288601C4.42725 0.174854 3.64306 0.564471 2.98162 1.21193C2.42695 1.75489 2.06781 2.39784 1.89542 3.1565C1.80804 3.541 1.81589 4.32293 1.91119 4.72601C2.09804 5.51629 2.48484 6.16287 3.11212 6.73356C3.69614 7.26481 4.36617 7.59741 5.19396 7.76694C5.60037 7.85019 6.43163 7.8435 6.86186 7.75357C7.70947 7.57639 8.40778 7.20363 9.0197 6.60178C9.5741 6.05646 9.91652 5.44063 10.0959 4.66634C10.176 4.32032 10.176 3.50406 10.0959 3.15804C9.91266 2.36697 9.56566 1.75166 8.98601 1.18989C8.38689 0.609232 7.66814 0.232984 6.83095 0.0617036C6.51477 -0.00299527 5.68565 -0.0215388 5.36273 0.0288601ZM4.63635 8.60169C4.57685 8.60736 4.39603 8.63221 4.23452 8.65689C2.29391 8.95367 0.68932 10.3304 0.155043 12.1571C0.0936254 12.3671 0.000247316 12.8441 3.73758e-08 12.9491C-0.000123602 12.9974 0.306161 13 5.99994 13H12L11.9786 12.8329C11.8252 11.635 11.1591 10.4827 10.1692 9.70256C9.39538 9.09275 8.45795 8.71818 7.44262 8.61314C7.17195 8.58515 4.90743 8.57591 4.63635 8.60169Z"
    fill="#FF7A00"
  />
);

const Cart = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0.226631 0.0474763C0.0738796 0.121558 0 0.247186 0 0.432825C0 0.55094 0.0168255 0.614938 0.0654815 0.682086C0.195211 0.861022 0.229802 0.866194 1.29645 0.866454L2.26102 0.866685L3.32214 4.52448L4.38327 8.18224L4.25662 8.44649C4.09532 8.78293 4.05216 9.00705 4.09556 9.28237C4.16556 9.72632 4.4368 10.0844 4.85027 10.2786L5.07995 10.3865L5.75532 10.3969C6.40603 10.4069 6.42482 10.4088 6.2699 10.4486C5.83878 10.5592 5.45875 10.9371 5.34546 11.3677C5.09179 12.3323 5.98128 13.2074 6.96042 12.9565C7.52773 12.8112 7.94293 12.2806 7.94293 11.7012C7.94293 11.1286 7.5299 10.5945 6.97322 10.4473C6.82746 10.4087 7.00173 10.4048 8.83853 10.4048C10.6753 10.4048 10.8496 10.4087 10.7038 10.4473C9.87799 10.6657 9.47837 11.6399 9.91889 12.361C10.4438 13.2202 11.6903 13.2068 12.2086 12.3364C12.629 11.6303 12.2181 10.6617 11.4072 10.4473C11.255 10.407 11.2909 10.4047 12.0788 10.4029C12.9997 10.4008 13.0505 10.3922 13.1776 10.2168C13.2263 10.1497 13.2431 10.0857 13.2431 9.96758C13.2431 9.84946 13.2263 9.78546 13.1776 9.71831C13.0349 9.52141 13.3168 9.53418 9.11259 9.53418C5.47185 9.53418 5.3097 9.53195 5.21162 9.48067C4.97248 9.35562 4.91469 9.11185 5.0567 8.82693L5.13625 8.66738H9.02373C13.3005 8.66738 13.0363 8.67836 13.1689 8.49518C13.2011 8.45071 13.6151 7.07545 14.1259 5.31635C15.0857 2.01103 15.0664 2.09265 14.9351 1.91129C14.7959 1.71892 15.2611 1.73302 9.05274 1.73302H3.4324L3.23164 1.03236C3.12124 0.64701 3.00302 0.280991 2.96895 0.218986C2.84762 -0.00181544 2.85529 -0.000659664 1.51271 9.15592e-05C0.476958 0.000669423 0.310523 0.00679471 0.226631 0.0474763Z"
    fill="#0056FD"
  />
);

const colorsToIcons = {
  green: "😃 ",
  orange: "😐 ",
  red: "😩 ",
};

const CustomizedIcon = (props) => {
  const {
    icon = House,
    x = 0,
    y = 0,
    width = 30,
    height = 40,
    scale = 1,
  } = props;
  return (
    <svg width={width} height={height}>
      <g
        transform={`
		matrix(${scale} 0 0 ${scale}  -0 1)
		translate(${x}, ${y})
		`}
      >
        {icon}
      </g>
    </svg>
  );
};
const CustomizedLabel = (props) => {
  const { x = 0, y = 0, stroke, icon } = props;
  return (
    <svg
      x={+x - 6}
      y={+y - 8}
      dy={0}
      fill={stroke}
      fontSize={10}
      textAnchor="middle"
    >
      {icon}
    </svg>
  );
};

const translateType = (tipo) => {
  switch (tipo) {
    case "famiglia_grande":
      return "Famiglia grande";
    case "giovane_single":
      return "Giovane single";
    case "investimento_lavoratori":
      return "Investimento per lavoratori";
    case "investimento_studenti":
      return "Investimento per studenti";
    case "maturo_single":
      return "Maturo Single";
    case "neogenitori":
      return "Neogenitori";
    case "neosposi":
      return "Neosposi";
    case "pensionati":
      return "Pensionati";
    default:
      return tipo;
  }
};

const CustomTick = ({
  payload,
  x = 0,
  y = 0,
  textAnchor,
  stroke,
  radius,
  isMobile,
  setCustomerType,
}) => (
  // console.log(payload, "radar payload"),
  <g className="recharts-layer recharts-polar-angle-axis-tick" style={{}}>
    <text
      radius={radius}
      stroke={stroke}
      // x={payload?.value ? x + (_positionRation(payload?.value)?.x || 0) : x}
      // y={payload?.value ? y + (_positionRation(payload?.value)?.y || 0) : y}
      x={x}
      y={y}
      style={{ fontSize: "13px" }}
      className="recharts-text recharts-polar-angle-axis-tick-value"
      textAnchor={textAnchor}
      onMouseEnter={() => {
        setCustomerType(payload?.value);
      }}
    >
      {payload?.value == "investimento_studenti" ? (
        <>
          <tspan x={x} y={y - 20} dy={5} style={{ fontSize: "13px" }}>
            Investimento
          </tspan>
          <tspan x={x} dy={15} style={{ fontSize: "13px" }}>
            per studenti
          </tspan>
        </>
      ) : payload?.value == "investimento_lavoratori" ? (
        <>
          <tspan x={x + 5} y={y - 20} dy={5} style={{ fontSize: "13px" }}>
            Investimento
          </tspan>
          <tspan x={x + 5} dy={15} style={{ fontSize: "13px" }}>
            per lavoratori
          </tspan>
        </>
      ) : payload?.value == "giovane_single" ? (
        <>
          <tspan x={x + 5} dy={0} style={{ fontSize: "13px" }}>
            Giovane
          </tspan>
          <tspan x={x + 5} dy={15} style={{ fontSize: "13px" }}>
            single
          </tspan>
        </>
      ) : payload?.value == "maturo_single" ? (
        <>
          <tspan x={x} dy={5} style={{ fontSize: "13px" }}>
            Maturo
          </tspan>
          <tspan x={x} dy={15} style={{ fontSize: "13px" }}>
            single
          </tspan>
        </>
      ) : payload?.value == "famiglia_grande" ? (
        <>
          <tspan x={x} dy={0} style={{ fontSize: "13px" }}>
            Famiglia
          </tspan>
          <tspan x={x} dy={15} style={{ fontSize: "13px" }}>
            grande
          </tspan>
        </>
      ) : payload?.value == "neosposi" ? (
        <>
          <tspan x={x} dy={5} style={{ fontSize: "13px" }}>
            Neosposi
          </tspan>
        </>
      ) : payload?.value == "neogenitori" ? (
        <>
          <tspan x={x} dy={5} style={{ fontSize: "13px" }}>
            Neogenitori
          </tspan>
        </>
      ) : payload?.value == "pensionati" ? (
        <>
          <tspan x={x} dy={5} style={{ fontSize: "13px" }}>
            Pensionati
          </tspan>
        </>
      ) : (
        // translateType(payload?.value)
        payload?.value
      )}
    </text>
  </g>
);

const CustomTooltip = ({ active, payload, setCustomerType }) => {
  const data = payload?.[0]?.payload;
  if (active && data) {
    setCustomerType(data.subject);
    return (
      <div className={styles.chart_tooltip}>
        <p className={styles.tooltip_title}>{translateType(data.subject)}</p>
        <div
          className={clx(styles.tooltip_string, styles.tooltip_string_total)}
        >
          <p className={styles.string_label}>Compatibilità con:</p>
        </div>
        <div className={styles.tooltip_string}>
          <p className={styles.string_label}>Popolazione</p>
          <p className={styles.string_value}>{parseInt(data.Popolazione)}%</p>
        </div>
        <div className={styles.tooltip_string}>
          <p className={styles.string_label}>
            Caratteristiche dell&apos;immobile
          </p>
          <p className={styles.string_value}>{parseInt(data.Proprietà)}%</p>
        </div>
        <div className={styles.tooltip_string}>
          <p className={styles.string_label}>Servizi in zona</p>
          <p className={styles.string_value}>{parseInt(data.Servizi)}%</p>
        </div>
      </div>
    );
  }

  return null;
};

const StylizedRadarChart = ({ data, setCustomerType }) => (
  <>
    <h5 className={styles.chart_title}>{data?.title}</h5>
    <RadarChart
      outerRadius={115}
      width={475}
      height={294}
      data={data}
      innerRadius={32}
    >
      <svg x={315} y={200} height="100" width="100">
        <circle cx="50" cy="50" r={30} strokeWidth="3" fill="white" />
      </svg>
      <PolarGrid />
      <PolarAngleAxis
        tick={(props) =>
          CustomTick({
            ...props,
            setCustomerType,
          })
        }
        dataKey="subject"
      />
      <Radar dataKey="Main" fillOpacity={0.6} className="radar-fill" />
      <Radar
        dataKey="Popolazione"
        stroke="red"
        fill="#feece444"
        fillOpacity={0}
        strokeOpacity={0}
        label={(props) => CustomizedLabel({ ...props, icon: People })}
      />
      <Radar
        dataKey="Proprietà"
        stroke="blue"
        fill="blue"
        fillOpacity={0}
        strokeOpacity={0}
        label={(props) => CustomizedLabel({ ...props, icon: House })}
      />
      <Radar
        dataKey="Servizi"
        stroke="#eecef0"
        strokeOpacity={0}
        fill="#eecef0"
        fillOpacity={0}
        label={(props) => CustomizedLabel({ ...props, icon: Cart })}
      />
      <Tooltip
        wrapperStyle={{ outline: "none" }}
        content={<CustomTooltip setCustomerType={setCustomerType} />}
      />
    </RadarChart>
  </>
);

const mergeData = (data) =>
  data
    ?.reduce(
      (acc, current, index) =>
        index === 0
          ? current?.data
          : acc?.map((value, index) => ({
              [current?.title || "main"]: current?.data[index]?.Main,
              ...value,
            })),
      []
    )
    ?.reverse();

const formatDataForChart = (chartData, title) => {
  if (!chartData) return null;

  return {
    title,
    data: Object.entries(chartData)?.map(([key, value]) => ({
      subject: key,
      Main: value * 100,
      fullMark: 100,
    })),
  };
};

const FifteenthPg = ({ report }) => {
  const [data, setData] = useState(undefined);
  const [customerType, setCustomerType] = useState();

  const main = formatDataForChart(
    Object.keys(report || {})
      ?.filter((item) => item != "best_category")
      ?.reduce(
        (acc, current) => ({
          ...acc,
          [current]: report?.[current] ? report?.[current]?.final_score : null,
        }),
        {}
      )
  );

  const population = formatDataForChart(
    Object.keys(report || {})
      ?.filter((item) => item != "best_category")
      ?.reduce(
        (acc, current) => ({
          ...acc,
          [current]: report?.[current]
            ? report?.[current].demographic?.score
            : null,
        }),
        {}
      ),
    "Popolazione"
  );

  const property = report
    ? formatDataForChart(
        Object.keys(report)
          ?.filter((item) => item != "best_category")
          .reduce(
            (acc, current) => ({
              ...acc,
              [current]: report?.[current]
                ? report?.[current].property?.score
                : null,
            }),
            {}
          ),
        "Proprietà"
      )
    : null;

  const services = report
    ? formatDataForChart(
        Object.keys(report)
          ?.filter((item) => item != "best_category")
          .reduce(
            (acc, current) => ({
              ...acc,
              [current]: report?.[current]
                ? report?.[current].service?.score
                : null,
            }),
            {}
          ),
        "Servizi"
      )
    : null;

  const best_user =
    main?.data?.filter(
      (el) => !(el.subject === "PII-citta" || el.subject === "PII-university")
    )?.length > 0
      ? main?.data
          ?.filter(
            (el) =>
              !(el.subject === "PII-citta" || el.subject === "PII-university")
          )
          ?.reduce((prev, current) =>
            prev.Main > current.Main ? prev : current
          )
      : null; // or any default value you prefer

  useEffect(() => {
    if (report)
      setData(() => mergeData([main, property, services, population]));
    if (best_user) setCustomerType(best_user.subject);
  }, [setData, report]);

  // Mapping to establish amout of services in order to decide gap between divs
  const mappingForPageGap =
    [
      ...(report?.[report?.best_category]?.property?.text || []),
      ...(report?.[report?.best_category]?.service?.text || []),
      ...(report?.[report?.best_category]?.demographic?.text || []),
    ].length <= 8;
  // console.log(mappingForPageGap, "mappingForPageGap");

  return (
    <div className={styles.potential__customer__wrapper}>
      <h3 className={styles.header__text}>Analisi dei Potenziali clienti</h3>
      <p
        className={styles.secondary__text}
        style={{ marginBottom: mappingForPageGap && "50px" }}
      >
        Nel grafico sottostante si Identificano i clienti ideali per l’immobile
        valutato.
        <br />
        L’ analisi considera la compatibilità con la comunità locale, le
        caratteristiche uniche dell&apos;immobile e i servizi presenti nelle
        vicinanze.
        <br />
        Il prezzo non è considerato in questa valutazione.
      </p>
      <div className={styles.data} style={{ gap: mappingForPageGap && "60px" }}>
        <div className={styles.chart_wrapper}>
          <div className={styles.chart}>
            {data ? (
              <StylizedRadarChart
                data={data}
                setCustomerType={setCustomerType}
              />
            ) : (
              <StylizedRadarChart />
            )}
          </div>
          <div className={styles.labels}>
            <div className={styles.labels__item}>
              <div>
                <CustomizedIcon icon={People} height={30} scale={1.5} y={2} />
              </div>
              <p>Compatibilità con la popolazione residente</p>
            </div>
            <div className={styles.labels__item}>
              <div>
                <CustomizedIcon icon={House} height={30} scale={1.5} y={2} />
              </div>
              <p>Caratteristiche dell&#39;immobile</p>
            </div>
            <div className={styles.labels__item}>
              <div>
                <CustomizedIcon icon={Cart} height={30} scale={1.5} y={2} />
              </div>
              <p>Servizi di zona</p>
            </div>
          </div>
        </div>
        <div className={styles.content_section}>
          <div className={styles.section}>
            <div className={styles.description}>
              <div className={styles.description__title}>
                Questo immobile è ideale per{" "}
                <b style={{ fontSize: "20px" }}>
                  {translateType(report?.best_category || "")}
                </b>
              </div>
              <div
                className={styles.section__description}
                style={{ borderColor: " #653BE3" }}
              >
                <div className={styles.section__description__title}>
                  <CustomizedIcon icon={House} height={30} scale={1.5} y={2} />
                  <p>Caratteristiche {"dell'immobile"}</p>
                </div>
                <div className={styles.section__description__body}>
                  {report?.[customerType]?.property?.text?.map(
                    ({ text, color }, index) => (
                      <p key={index}>
                        {colorsToIcons[color]}
                        {text}
                      </p>
                    )
                  )}
                </div>
              </div>
              <div
                className={styles.section__description}
                style={{ borderColor: "#E18544" }}
              >
                <div className={styles.section__description__title}>
                  <CustomizedIcon icon={People} height={30} scale={1.5} y={2} />
                  <p>Compatibilità con la popolazione</p>
                </div>
                <div className={styles.section__description__body}>
                  {/* {report?.[customerType]?.demographic?.text?.map(
                    ({ text, color }, index) => ( */}
                  <p>{report?.[customerType]?.demographic?.text}</p>
                  {/* )
                  )} */}
                </div>
              </div>
              <div
                className={styles.section__description}
                style={{ borderColor: "#2860EB" }}
              >
                <div className={styles.section__description__title}>
                  <CustomizedIcon icon={Cart} height={30} scale={1.5} y={2} />
                  <p>Servizi di zona</p>
                </div>
                <div className={styles.section__description__body}>
                  {report?.[customerType]?.service?.text?.map(
                    ({ text, color }, index) => (
                      <p key={index}>
                        {colorsToIcons[color]}
                        {text}
                      </p>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifteenthPg;
