import { getIn } from "formik";

// Function scrolling to highest input with error if any
const scrollToErrors = (formOptions, errors, elRef) => {
  // console.log("errors inside scroll to errors", errors);
  // Array to contain matching error keys
  let errorsKeys = [];
  // If formoptions is an array
  if (typeof formOptions == "object") {
    // Get field name of every required field
    let keysToCheck = formOptions
      .filter((item) => !item.optionalField)
      .map((item) => item.fieldName);
    // For each of this key check if the errors object contains them and if so push them into array above
    keysToCheck.forEach((key) => {
      if (Object.getOwnPropertyDescriptor(errors, key)) {
        if (errors[key] !== undefined) {
          errorsKeys.push(key);
        }
      }
    });
  } else {
    // Same logic as above
    if (errors["Titolo"] !== undefined) {
      errorsKeys.push("Titolo");
    }
    if (
      getIn(errors, "Indirizzo.lat") !== "" ||
      getIn(errors, "Indirizzo.lng") !== ""
    ) {
      errorsKeys.push("Indirizzo");
    }
  }

  // Grab dom element of which name matches first item in errorskeys array (so highest error in the form step) and scroll to it
  let errorEl = document.querySelector('[name="' + errorsKeys[0] + '"]');
  elRef.current = errorEl;
  elRef.current.closest("div").scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};

export default scrollToErrors;
