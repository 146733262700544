import React from "react";
import styles from "./BuyCta.module.scss";
import { getUser } from "../../../Auth/store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../routes/constants";
import { actions } from "../../../PaymentsAndCredits/store";

import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import EuroIcon from "../../../../assets/svg/euro-coin-white.svg";
import LogoImage from "../../../../assets/svg/final-logo.svg";

const BuyCta = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser());
  const navigate = useNavigate();
  const { reportId } = useParams();

  return (
    <div className={styles.buy_cta}>
      <img src={LogoImage} className={styles.buy_cta__logo} />

      <div className={styles.buy_cta__text}>
        {user?.is_private == 1
          ? "Acquista il report completo a soli €4.99, per ottenere un accesso esclusivo a tutte le funzionalità avanzate! Sblocca dati approfonditi, analisi dettagliate e la possibilità di scaricare il report per averlo sempre a portata di mano"
          : "Sblocca tutte le funzionalità, dati approfonditi, analisi dettagliate e la possibilità di scaricare il report! Acquista una licenza premium"}
      </div>
      <div className={styles.buy_cta__btn}>
        <PrimaryButton
          leftIconSrc={EuroIcon}
          imgHeight={20}
          width="auto"
          height="45px"
          fontSize="17px"
          margin="0 5px 0 auto"
          text="Acquista Report"
          onClick={() => {
            if (user?.is_private == 1) {
              dispatch(
                actions.GET_PAYMENT_URL_PACKAGE_PRIVATE.REQUEST(
                  {
                    idPrice: "price_1PIR9vLUjZBu6YVsf4f90TTb",
                    payment_url: reportId,
                  },
                  (url) => window.open(url)
                )
              );
            } else {
              navigate(ROUTES.PAYMENTS);
            }
          }}
        />
      </div>
    </div>
  );
};

export default BuyCta;
