import clx from "classnames";
import styles from "./IconButton.module.scss";

const IconButton = ({
  height = "25px",
  width = "25px",
  padding = "5px",
  borderRadius = "5px",
  backgroundColor = "transparent",
  iconSrc: Icon,
  onClick,
  color,
  className = "",
}) => (
  <button
    style={{
      height,
      width,
      borderRadius,
      padding,
      backgroundColor,
      color,
    }}
    className={clx(styles.icon__btn, className)}
    onClick={onClick}
  >
    {/* <img className={styles['btn__icon']} src={Icon} alt='icon'/> */}
    <Icon className={styles["icon__btn"]} />
  </button>
);

export default IconButton;
