import React from "react";

import BarChartHorizontal from "../../../../../Common/Charts/BarChartHorizontal";
import PdfCheckbox from "../../../PdfCheckbox";

const SafetyDetail = ({
  data,
  minHeight,
  wrapperHeight,
  legendHeight,
  marginTop,
  legendTop,
  barSize,
  legendLeft,
  minWidth,
  customLabelTop,
  paddingTop,
  chartMarginTop,
  isPdf = false,
}) => {
  // console.log(data, "SAFETY DETAIL");

  const chartData = data?.data?.y?.map((item, index) => ({
    y: item[0].toUpperCase() + item.substring(1).split("_").join(" "),
    // y: item,
    Delitti: data?.data?.x[index],
  }));

  // console.log(chartData, "CHART DATA");

  const firstRow = chartData
    ?.slice(0, 7)
    ?.sort((a, b) => b.Delitti - a.Delitti);
  const secondRow = chartData
    ?.slice(7, 14)
    ?.sort((a, b) => b.Delitti - a.Delitti);

  const limit = data && Math.max(...data?.data?.x) * 1.2;
  // console.log(limit);

  // console.log(firstRow, 'FIRST ROW');
  // console.log(secondRow, 'SECOND ROW');

  return (
    <div>
      {!isPdf && <PdfCheckbox section={"safety_detail"} />}
      <BarChartHorizontal
        title={data?.title}
        description={data?.description}
        limit={limit}
        legendTitle={"Denunce/100.000 abitanti"}
        firstRow={firstRow}
        secondRow={secondRow}
        minHeight={minHeight}
        wrapperHeight={wrapperHeight}
        legendHeight={legendHeight}
        legendTop={legendTop}
        legendLeft={legendLeft}
        barSize={barSize}
        marginTop={marginTop}
        minWidth={minWidth}
        customLabelTop={customLabelTop}
        paddingTop={paddingTop}
        chartMarginTop={chartMarginTop}
      />
    </div>
  );
};

export default SafetyDetail;
