import clx from "classnames";
import {
  Field,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from "formik";
import React, { createRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../../routes/constants";
import CheckboxField from "../../../../../Common/CheckboxField";
import TextField from "../../../../../Common/TextField";
import { actions } from "../../../../store";
import { INITIAL_VALUES, STEPS, VALIDATION_SCHEMA } from "../../constants";
import SubmitButton from "../SubmitButton";
import styles from "./RegistrationForm.module.scss";
import platformConditionals from "../../../../../../utils/platformConditionals";

const RegistrationForm = (source) => {
  // console.log(owner, "OWNER");
  // console.log(source, "SOURCE");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isPrivateState, setIsPrivateState] = useState(null);
  console.log(isPrivateState, "IS PRIVATE STATE");
  const submitFormRef = createRef();

  return (
    <>
      {step === 3 ? (
        <div className={styles["message-success"]}>
          <h2>Il tuo account è stato creato con successo.</h2>
          <span>
            {" "}
            Per favore <Link to={`${ROUTES.SIGN_IN}`}>accedi</Link> per
            continuare.
          </span>
        </div>
      ) : (
        <div className={styles["registration-form__wrapper"]}>
          <div className={styles["registration-form__image"]}>
            <img
              src={platformConditionals()?.logo || ""}
              alt="logo"
              // onClick={() =>
              //   (window.location.href = "https://www.houseplus.it/")
              // }
            />
          </div>
          {/* <h1>Registra la tua Azienda</h1>
          <div className={styles.steps__wrapper}>
            {STEPS.map((stepInfo) => (
              <span
                key={stepInfo.step}
                className={clx(styles.step, {
                  [styles.step__active]: +step === +stepInfo.step,
                })}
              >
                {stepInfo.step}
              </span>
            ))}
          </div> */}
          <Formik
            innerRef={submitFormRef}
            initialValues={INITIAL_VALUES}
            // validationSchema={VALIDATION_SCHEMA}
            validate={(values) => {
              try {
                validateYupSchema(values, VALIDATION_SCHEMA, true, {
                  values,
                }); // Context
              } catch (err) {
                return yupToFormErrors(err);
              }

              return {};
            }}
            onSubmit={async (values) => {
              dispatch(
                actions.SIGN_UP.REQUEST(
                  {
                    is_private: values.is_private,
                    agency_name: values.agency_name,
                    user_name: values.user_name,
                    piva: values.piva,
                    email: values.email,
                    pwd: values.pwd,
                    accepted_agreement: values.acceptPolicy,
                    source: source.source,
                    owner: source.owner,
                  },
                  () => {
                    dispatch(
                      actions.SIGN_IN.REQUEST(values, () =>
                        navigate(ROUTES.REPORT)
                      )
                    );
                    //setStep(3);
                  }
                )
              );
            }}
          >
            {({ values, setFieldValue, errors }) => (
              <>
                <h1>
                  {!values.is_private
                    ? "Registra la tua Azienda"
                    : "Registrati"}
                </h1>
                <div className={styles.steps__wrapper}>
                  {STEPS.map((stepInfo) => (
                    <span
                      key={stepInfo.step}
                      className={clx(styles.step, {
                        [styles.step__active]: +step === +stepInfo.step,
                      })}
                    >
                      {stepInfo.step}
                    </span>
                  ))}
                </div>
                <Form className={styles["registration__form"]}>
                  {console.log(values)}
                  {console.log(errors, "ERRORS")}
                  {step === 1 ? (
                    <>
                      {source?.owner == 1 && (
                        <Field
                          name="is_private"
                          id="is_private"
                          label={"Sono un'utente privato"}
                          labelStyle={{ fontSize: 18 }}
                          component={CheckboxField}
                          gap="10px"
                          padding="15px 0px 20px 2px"
                          width="fit-content"
                          onClick={(e) => {
                            setFieldValue("agency_name", "");
                            setFieldValue("piva", "");
                            setIsPrivateState(!values.is_private);
                          }}
                        />
                      )}
                      {!values.is_private && (
                        <Field
                          name="agency_name"
                          id="agency_name"
                          label="Nome Azienda"
                          component={TextField}
                        />
                      )}
                      <Field
                        name="user_name"
                        id="user_name"
                        label="Nome e cognome"
                        component={TextField}
                      />
                      {!values.is_private && (
                        <Field
                          name="piva"
                          id="piva"
                          label="P.IVA agenzia"
                          component={TextField}
                        />
                      )}
                    </>
                  ) : step === 2 ? (
                    <>
                      <Field
                        name="email"
                        id="email"
                        label="Email"
                        component={TextField}
                        type="email"
                      />
                      <Field
                        name="pwd"
                        id="pwd"
                        label="Scegli una password"
                        component={TextField}
                        type="password"
                        showPassword={true}
                      />
                      <Field
                        name="pwdConfirmation"
                        id="pwdConfirmation"
                        type="password"
                        label="Conferma la password"
                        component={TextField}
                        showPassword={true}
                      />
                      <Field
                        name="acceptPolicy"
                        id="acceptPolicy"
                        label={
                          <a
                            className={styles.policy}
                            href="https://www.houseplus.it/wp-content/uploads/2022/11/TERMINI-E-CONDIZIONI-HOUSEPLUS-1.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Accetto termini e condizioni
                          </a>
                        }
                        component={CheckboxField}
                        gap="10px"
                        padding="0px 0px 10px 2px"
                        width="fit-content"
                      />
                    </>
                  ) : null}
                </Form>
              </>
            )}
          </Formik>
          <SubmitButton
            style={{ width: "100%" }}
            step={step}
            setStep={setStep}
            ref={submitFormRef}
            isPrivateState={isPrivateState}
          />
          <div className={styles.inner__link}>
            <p>{"Hai già un Account?"}</p>
            <Link className={styles.link} to={`${ROUTES.SIGN_IN}`}>
              {"Accedi"}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationForm;
