import GoogleMapReact from "google-map-react";
import React, { useState } from "react";
import styles from "./Map.module.scss";
import BoxIcon from "../../../../../../assets/svg/box.svg";
import ClasseEnergeticaIcon from "../../../../../../assets/svg/icon_classe.svg";
import StatoImmobileIcon from "../../../../../../assets/svg/icon_stato_immobile.svg";
import LocationIcon from "../../../../../../assets/svg/location.svg";
import { ReactComponent as MarkerIcon } from "../../../../../../assets/svg/marker.svg";
import MetriqIcon from "../../../../../../assets/svg/metro_q.svg";
import { getImg } from "../../../../../../utils/getImg";

const MapOptions = {
  minZoom: 12,
  fullscreenControl: false,
  zoomControl: true,
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.LEFT_BOTTOM,
  },
};

const Map = ({ houseInfo, goToDownload }) => {
  const [openCardIcon, setOpenCardIcon] = useState(true);
  const PropertyImg = getImg(houseInfo?.Tipologia);

  // Map section Pin card
  const PinHome = () => (
    <div
      className={styles.marker__wrapper}
      onClick={() => {
        setOpenCardIcon(!openCardIcon);
      }}
    >
      {openCardIcon ? (
        <div className={styles.marker}>
          <div className={styles.marker__content}>
            <span className={styles.content__image}>
              <PropertyImg />
            </span>
            <div className={styles.content__inner}>
              <p>
                <img src={LocationIcon} alt="location" />
                <span>
                  {houseInfo?.street},&nbsp;
                  {houseInfo?.buildingNumber},&nbsp;
                  {houseInfo?.city}&nbsp;(
                  {houseInfo?.provincia})
                </span>
              </p>
              <p>
                <img src={BoxIcon} alt="box" />
                <span>
                  {houseInfo?.Locali} Stanze - {houseInfo?.Bagni} Bagn
                  {houseInfo?.Bagni > 1 ? "i" : "o"}
                </span>
              </p>
              <p>
                <img src={MetriqIcon} alt="calendar" />
                <span>{houseInfo?.Superficie} m²</span>
              </p>
              <p>
                <img src={ClasseEnergeticaIcon} alt="calendar" />
                <span>{houseInfo?.ClasseEnergetica}</span>
              </p>
              <p>
                <img
                  src={StatoImmobileIcon}
                  style={{ width: 30 }}
                  alt="calendar"
                />
                <span>{houseInfo?.StatoInterni}</span>
              </p>
            </div>
            <div style={{ textAlign: "center", textDecoration: "underline" }}>
              visualizza sulla mappa
            </div>
            <footer>
              {houseInfo?.Tipologia}
              <p>
                Report #{houseInfo?.id} - {houseInfo.Titolo}
              </p>
            </footer>
          </div>
        </div>
      ) : (
        <MarkerIcon style={{ width: 45, height: 45 }} />
      )}
    </div>
  );

  return (
    <div className={styles.map}>
      {/* DOWNLOAD BTN */}
      {/* <div className={styles.download} onClick={goToDownload}>
        <img src={DownloadIcon} alt="print-icon" />
        <p>Scarica</p>
      </div> */}
      {/* MAP */}
      <GoogleMapReact
        options={MapOptions}
        bootstrapURLKeys={{
          key: "AIzaSyDhU_uaWxC4hWjLecwUvBW7RzqauLXfpVw",
        }}
        center={{
          lat: houseInfo?.Lat ? +houseInfo?.Lat : 43.81398039999999,
          lng: houseInfo?.Lng ? +houseInfo?.Lng : 7.758971199999998,
        }}
        defaultZoom={13}
        key={"reportId"}
        yesIWantToUseGoogleMapApiInternals
      >
        <PinHome
          lat={houseInfo?.Lat ? +houseInfo?.Lat : 43.81398039999999}
          lng={houseInfo?.Lng ? +houseInfo?.Lng : 7.758971199999998}
        />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
