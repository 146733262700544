import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SectionTitle from "../SectionTitle";
import Skeleton from "../../../Common/Skeleton";
import Spinner from "../../../Common/Spinner";
import { getGallery } from "../../../Report/store/selectors";
import ActiveImageActions from "./containers/ActiveImageActions";
import ActiveImageInfo from "./containers/ActiveImageInfo";
import ConfirmRemoveImageModal from "./containers/ConfirmRemoveImageModal";
import GalleryList from "./containers/GalleryList";
import UploadImageModal from "./containers/UploadImageModal";
import styles from "./Gallery.module.scss";

const Gallery = ({ reportId }) => {
  const gallery = useSelector(getGallery());
  // console.log(gallery, 'GALLERY REPORT');

  const [activeImage, setActiveImage] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false);
  const [picturesPopup, setPicturesPopup] = useState(false);
  const [isChangingOrder, setIsChangingOrder] = useState(false);
  // console.log(isChangingOrder, 'IS CHANGING ORDER');
  // console.log(gallery.length, 'GALLERY LENGTH');

  useEffect(() => {
    if (gallery?.data) {
      setActiveImage(gallery?.data?.[0]);
    }
  }, [gallery]);

  useEffect(() => {
    if (!gallery?.data?.length) {
      setIsChangingOrder(false);
    }
  }, [gallery]);

  return gallery?.loading ? (
    // <Skeleton height='700px' /> :
    <Spinner
      position="relative"
      paddingBottom="50px"
      height="400px"
      zIndex="0"
    />
  ) : (
    <div className={styles.container} id="gallery">
      <SectionTitle title={"Foto dell'immobile"} section={"pictures"} />
      <ActiveImageInfo
        activeImage={activeImage}
        reportId={reportId}
        setConfirmModalOpen={setConfirmModalOpen}
        setUploadImageModalOpen={setUploadImageModalOpen}
        gallery={gallery}
        picturesPopup={picturesPopup}
        setPicturesPopup={setPicturesPopup}
      />
      <GalleryList
        reportId={reportId}
        gallery={gallery?.data}
        activeImage={activeImage}
        setActiveImage={setActiveImage}
        isChangingOrder={isChangingOrder}
        setIsChangingOrder={setIsChangingOrder}
      />
      <ActiveImageActions
        activeImage={activeImage}
        setUploadImageModalOpen={setUploadImageModalOpen}
        gallery={gallery}
        setPicturesPopup={setPicturesPopup}
        setIsChangingOrder={setIsChangingOrder}
      />
      <ConfirmRemoveImageModal
        confirmModalOpen={confirmModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
        reportId={reportId}
        idImage={activeImage?.id}
        setIsChangingOrder={setIsChangingOrder}
      />
      <UploadImageModal
        reportId={reportId}
        isModalOpen={uploadImageModalOpen}
        setUploadImageModalOpen={setUploadImageModalOpen}
        gallery={gallery}
      />
    </div>
  );
};

export default Gallery;
