import styles from "./Spinner.module.scss";

const Spinner = ({
  position = "fixed",
  backgroundColor = "rgba(255, 255, 255, 0.7)",
  height,
  paddingBottom,
  zIndex,
}) => (
  <div
    className={styles.loader}
    style={{
      position,
      backgroundColor,
      height,
      paddingBottom,
      zIndex,
    }}
  >
    <div className={styles.spinner__wrapper}>
      <div className={styles.spinner} />
    </div>
  </div>
);

export default Spinner;
