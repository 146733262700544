import React from "react";
import styles from "./PricingSimulator.module.scss";
import clx from "classnames";

import { ReactComponent as TagIcon2mesi } from "../../../../assets/svg/tag/tag2mesi.svg";
import { ReactComponent as TagIcon6mesi } from "../../../../assets/svg/tag/tag6mesi.svg";
import { ReactComponent as TagIcon12mesi } from "../../../../assets/svg/tag/tag12mesi.svg";

const PricingSimulator = ({
  pdfSimulator,
  defPriceMin,
  defPrice,
  defPriceMax,
}) => {
  // const pdfSimulator = {
  //   classe_energetica: "D",
  //   riscaldamento: "Autonomo",
  //   stato: "Ristrutturato",
  //   price_min: "120000",
  //   price: "140000",
  //   price_max: "220000",
  // };

  // BEST SELLING CARDS
  const bestSelling = [
    {
      period: 2,
      stima_text: "Stima minima di vendita consigliata",
      price: Math.round(pdfSimulator?.price_min) || 0,
      defaultPrice: Math.round(defPriceMin) || 0,
    },
    {
      period: 6,
      stima_text: "Stima reale di vendita consigliata",
      price: Math.round(pdfSimulator?.price) || 0,
      defaultPrice: Math.round(defPrice) || 0,
    },
    {
      period: 12,
      stima_text: "Stima massima di vendita consigliata",
      price: Math.round(pdfSimulator?.price_max) || 0,
      defaultPrice: Math.round(defPriceMax) || 0,
    },
  ];

  return (
    <div className={styles.pricing_simulator}>
      <h3 className={styles.header__text}>Pricing simulator</h3>
      <p className={styles.secondary__text}>
        Di seguito come cambia il rapporto probabilità e prezzo di vendita, in
        base alle caratteristiche selezionate nel Pricing simulator.
      </p>
      <div className={styles.pricing_simulator__content}>
        <div className={styles.pricing_simulator__content__options}>
          <div className={styles.section_header}>
            Caratteristiche selezionate
          </div>
          <div className={styles.pricing_simulator__content__options__list}>
            <div
              className={styles.pricing_simulator__content__options__list__item}
            >
              <div className={styles.title}>Classe energetica</div>
              <div className={styles.content}>
                {pdfSimulator?.classe_energetica}
              </div>
            </div>
            <div
              className={styles.pricing_simulator__content__options__list__item}
            >
              <div className={styles.title}>Riscaldamento</div>
              <div className={styles.content}>
                {pdfSimulator?.riscaldamento}
              </div>
            </div>
            <div
              className={styles.pricing_simulator__content__options__list__item}
            >
              <div className={styles.title}>Stato immobile</div>
              <div className={styles.content}>{pdfSimulator?.stato}</div>
            </div>
          </div>
        </div>
        <div className={styles.pricing_simulator__content__cards}>
          <div className={styles.section_header}>Stime reali</div>
          {bestSelling.map((card, index) => (
            <div
              className={clx(styles.best__selling__card, {
                [styles.medium_card]: card.period === 6,
              })}
              key={`${index}-pricing-sim-card`}
            >
              <div className={styles.first__row}>
                {card.period === 2 && (
                  <div
                    className={clx(
                      styles.icon__wrapper,
                      styles.icon_two_months
                    )}
                  >
                    <TagIcon2mesi />
                  </div>
                )}
                {card.period === 6 && (
                  <div
                    className={clx(
                      styles.icon__wrapper,
                      styles.icon_six_months
                    )}
                  >
                    <TagIcon6mesi />
                  </div>
                )}
                {card.period === 12 && (
                  <div
                    className={clx(
                      styles.icon__wrapper,
                      styles.icon_twelve_months
                    )}
                  >
                    <TagIcon12mesi />
                  </div>
                )}
                <p className={styles.first__row__text}>{card.stima_text}</p>
                {/* If prices and default prices are different show tooltip with difference in percentage */}
                {100 -
                  ((Math.round(card.price / 1000) * 1000) /
                    (Math.round(card.defaultPrice / 1000) * 1000)) *
                    100 !=
                0.0 ? (
                  <div
                    className={
                      100 -
                        ((Math.round(card.price / 1000) * 1000) /
                          (Math.round(card.defaultPrice / 1000) * 1000)) *
                          100 <
                      0
                        ? styles.percent_box_positive
                        : styles.percent_box_negative
                    }
                  >
                    {100 -
                      ((Math.round(card.price / 1000) * 1000) /
                        (Math.round(card.defaultPrice / 1000) * 1000)) *
                        100 <
                    0
                      ? `+${(
                          ((Math.round(card.price / 1000) * 1000) /
                            (Math.round(card.defaultPrice / 1000) * 1000)) *
                            100 -
                          100
                        ).toFixed(2)}%`
                      : `${(
                          ((Math.round(card.price / 1000) * 1000) /
                            (Math.round(card.defaultPrice / 1000) * 1000)) *
                            100 -
                          100
                        ).toFixed(2)}%`}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <p className={styles.price}>
                {card.price != 0 ? (
                  <>
                    €{" "}
                    {`${Math.round(card.price / 1000) * 1000}`.replace(
                      /(?!^)(?=(?:\d{3})+(?:\.|$))/gm,
                      "."
                    )}
                  </>
                ) : (
                  <span>Nessun dato disponibile</span>
                )}
              </p>

              <p className={styles.second_row_test}>Configurazione base</p>
              <p className={styles.second_row_price}>
                €{" "}
                {`${Math.round(card.defaultPrice / 1000) * 1000}`.replace(
                  /(?!^)(?=(?:\d{3})+(?:\.|$))/gm,
                  "."
                )}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingSimulator;
