/* eslint-disable eqeqeq */
const hideElementsOnCopy = (copyValues, hideElement, sethideElement) => {
  let config = { ...hideElement };

  // console.log("copyValues", copyValues);

  if (copyValues.Tipologia == "Appartamento") {
    config = {
      ...config,
      Piano: false,
      PianiCasa: false,
      Ascensore: false,
      Cantina: false,
      SuperficieCantina: false,
      GiardinoPrivato: false,
      SuperficieGiardinoPrivato: false,
      GiardinoCondominiale: false,
      SuperficieGiardinoCondominiale: false,
      Soffitta: false,
      SuperficieSoffitta: false,
      Seminterrato: false,
      SuperficieSeminterrato: false,
    };
  }

  if (copyValues.Tipologia == "Attico") {
    // console.log("sono qui");
    config = {
      ...config,
      Piano: true,
      PianiCasa: false,
      Ascensore: false,
      Cantina: false,
      SuperficieCantina: false,
      // GiardinoPrivato: true,
      // SuperficieGiardinoPrivato: true,
      GiardinoPrivato: false,
      SuperficieGiardinoPrivato: false,
      GiardinoCondominiale: false,
      SuperficieGiardinoCondominiale: false,
      Soffitta: true,
      SuperficieSoffitta: true,
      Seminterrato: true,
      SuperficieSeminterrato: true,
    };
  }

  if (copyValues.Tipologia == "Villa" || copyValues.Tipologia == "Villetta") {
    config = {
      ...config,
      Piano: true,
      PianiCasa: true,
      Ascensore: true,
      Cantina: true,
      SuperficieCantina: true,
      GiardinoPrivato: false,
      SuperficieGiardinoPrivato: false,
      GiardinoCondominiale: true,
      SuperficieGiardinoCondominiale: true,
      Soffitta: false,
      SuperficieSoffitta: false,
      Seminterrato: false,
      SuperficieSeminterrato: false,
    };
  }

  // if (copyValues.Piano == 0 && copyValues.Tipologia == "Appartamento") {
  //   config = {
  //     ...config,
  //     GiardinoPrivato: false,
  //     SuperficieGiardinoPrivato: false,
  //   };
  // } else if (copyValues.Piano > 0 && copyValues.Tipologia == "Appartamento") {
  //   config = {
  //     ...config,
  //     GiardinoPrivato: true,
  //     SuperficieGiardinoPrivato: true,
  //   };
  // }

  if (copyValues.GiardinoPrivato == "No") {
    config = {
      ...config,
      SuperficieGiardinoPrivato: true,
    };
  }

  if (copyValues.GiardinoPrivato == "Si") {
    config = {
      ...config,
      SuperficieGiardinoPrivato: false,
    };
  }

  if (copyValues.GiardinoCondominiale == "No") {
    config = {
      ...config,
      SuperficieGiardinoCondominiale: true,
    };
  }

  if (copyValues.GiardinoCondominiale == "Si") {
    config = {
      ...config,
      SuperficieGiardinoCondominiale: false,
    };
  }

  if (copyValues.Balconi == "No") {
    config = {
      ...config,
      SuperficieBalconi: true,
    };
  }

  if (copyValues.Balconi == "Si") {
    config = {
      ...config,
      SuperficieBalconi: false,
    };
  }

  if (copyValues.Terrazzo == "No") {
    config = {
      ...config,
      SuperficieTerrazzo: true,
    };
  }

  if (copyValues.Terrazzo == "Si") {
    config = {
      ...config,
      SuperficieTerrazzo: false,
    };
  }

  if (copyValues.Soffitta == "No") {
    config = {
      ...config,
      SuperficieSoffitta: true,
    };
  }

  if (copyValues.Soffitta == "Si") {
    config = {
      ...config,
      SuperficieSoffitta: false,
    };
  }

  if (copyValues.Cantina == "No") {
    config = {
      ...config,
      SuperficieCantina: true,
    };
  }

  if (copyValues.Cantina == "Si") {
    config = {
      ...config,
      SuperficieCantina: false,
    };
  }

  if (copyValues.Seminterrato == "No") {
    config = {
      ...config,
      SuperficieSeminterrato: true,
    };
  }

  if (copyValues.Seminterrato == "Si") {
    config = {
      ...config,
      SuperficieSeminterrato: false,
    };
  }

  if (copyValues.PostoAuto?.includes("Nessuno")) {
    config = {
      ...config,
      SuperficiePostoAuto: true,
      SuperficiePostoAutoCondominiale: true,
      SuperficiePostoAutoGarage: true,
    };
  }

  if (copyValues.PostoAuto?.includes("Privato")) {
    config = {
      ...config,
      SuperficiePostoAuto: false,
    };
  }

  if (copyValues.PostoAuto?.includes("Condominiale")) {
    config = {
      ...config,
      SuperficiePostoAutoCondominiale: false,
    };
  }

  if (copyValues.PostoAuto?.includes("Garage")) {
    config = {
      ...config,
      SuperficiePostoAutoGarage: false,
    };
  }

  sethideElement(config);
};

export default hideElementsOnCopy;
