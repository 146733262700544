import clx from 'classnames';
import React, { useEffect } from 'react';

import styles from './WarningTooltip.module.scss';

const WarningTooltip = ({
	picturesPopup, setPicturesPopup, text
}) => {
	useEffect(() => {
		if (picturesPopup) {
			setTimeout(() => {
				setPicturesPopup(false);
			}, 3000);
		}
	}, [picturesPopup]);

	 return (
		<div className={clx(styles.no_pics_modal, { [styles.show_pics_modal]: picturesPopup })}>
			<div className={styles.no_pics_modal_stripe} />
			<div className={styles.no_pics_modal_text}>{text}</div>
		</div>
	)
	;
};

export default WarningTooltip;