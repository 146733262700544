import clx from "classnames";
import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getHiddenComparedItems } from "../../../Report/store/selectors";
import { actions } from "../../../Report/store/actions";

import SectionTitle from "../SectionTitle";
import ActionCheckboxButton from "../../../Common/Buttons/ActionCheckboxButton";
import ArrowNegative from "../../../../assets/svg/arrow-negative.svg";
import ArrowPositive from "../../../../assets/svg/arrow-positive.svg";
import formatToEuro from "../../../../utils/formatToEuro";
import styles from "./ComparedProperties.module.scss";
import ServicesMapModal from "./containers/ServicesMapModal";
import BuyCta from "../BuyCta";

const ComparedProperties = forwardRef(
  (
    {
      home,
      prezzoMq,
      prezzo,
      similar_properties,
      isMobile,
      privateUserHasReport,
      userIsPrivate,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { reportId } = useParams();
    const hiddenComparedItems = useSelector(getHiddenComparedItems());

    return (
      <div
        className={clx(styles.comparison__wrapper, {
          [styles.comparison__mbl]: isMobile,
        })}
        ref={ref}
      >
        <SectionTitle
          title={"Immobili comparabili"}
          subtitle={
            " Di seguito una selezione di alcuni immobili simili a quello analizzato e attualmente in vendita, al fine di confrontarne prezzi di vendita e caratteristiche"
          }
          section={"compared_properties"}
          marginBottom={"20px"}
        />

        {userIsPrivate && !privateUserHasReport ? (
          <BuyCta />
        ) : similar_properties?.length > 0 ? (
          <>
            <div
              className={clx({
                [styles["comparison__action-mobile"]]: isMobile,
              })}
            >
              <ServicesMapModal
                isMobile={isMobile}
                data={similar_properties}
                home={home}
              />
            </div>

            <div className={styles.comparison__table}>
              <table className={styles.table}>
                <thead>
                  <tr className={styles.head}>
                    {/* <th rowSpan="2" style={{ borderTopLeftRadius: 30 }}>
                Foto dell&apos;immobile
              </th> */}
                    <th rowSpan="2" style={{ borderTopLeftRadius: 30 }}>
                      Tipologia
                    </th>
                    <th
                      colSpan="1"
                      style={{
                        borderBottom: "1px solid #DAEBEE",
                        width: 200,
                      }}
                    >
                      Prezzo <br /> (€)
                    </th>
                    <th
                      colSpan="1"
                      style={{ borderBottom: "1px solid #DAEBEE" }}
                    >
                      Prezzo unitario <br /> (€/m<sup>2</sup>)
                    </th>
                    <th
                      rowSpan="2"
                      style={{ borderBottom: "1px solid #DAEBEE" }}
                    >
                      Classe <br /> energetica
                    </th>
                    <th
                      colSpan="1"
                      style={{ borderBottom: "1px solid #DAEBEE" }}
                    >
                      Superficie <br /> (m<sup>2</sup>)
                    </th>
                    <th
                      colSpan="1"
                      rowSpan="2"
                      style={{ borderBottom: "1px solid #DAEBEE" }}
                    >
                      Giorni sul <br /> mercato
                    </th>
                    <th
                      colSpan="1"
                      style={{
                        borderBottom: "1px solid #DAEBEE",
                      }}
                    >
                      Distanza <br /> (m)
                    </th>
                    <th
                      rowSpan="2"
                      style={{
                        borderTopRightRadius: 30,
                        borderBottom: "1px solid #DAEBEE",
                      }}
                    >
                      Includi <br /> nel PDF
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {similar_properties?.map((item, index, array) => (
                    <tr key={`similar_prop-${index}`}>
                      {/* IMMAGINE */}
                      {/* <td>
                  <img
                    src={checkHouseType(item?.tipology) || NotImg}
                    alt="home-icon"
                    style={{
                      borderBottomLeftRadius: index == array.length - 1 && 30,
                    }}
                  />
                </td> */}
                      {/* TIPOLOGIA */}
                      <td colSpan="1">{item?.typology}</td>
                      {/* PREZZO */}
                      <td colSpan="1">
                        <div className={styles.price_column}>
                          <b>€ {formatToEuro(item?.price)}</b>{" "}
                          <img
                            src={
                              item?.price <= prezzo
                                ? ArrowPositive
                                : ArrowNegative
                            }
                            alt="comparison-icon"
                            className={styles.arrow_img}
                          />
                        </div>
                      </td>
                      {/* PREZZO MQ */}
                      <td colSpan="1">
                        <div className={styles.price_column}>
                          <b>
                            €/m<sup>2</sup> {item?.price_mq?.toFixed(0)}
                          </b>{" "}
                          <img
                            src={
                              item?.price_mq <= prezzoMq
                                ? ArrowPositive
                                : ArrowNegative
                            }
                            alt="comparison-icon"
                            className={styles.arrow_img}
                          />
                        </div>
                      </td>
                      {/* CLASSE ENERGETICA */}
                      <td colSpan="1">{item?.energyClass}</td>
                      {/* SUPERFICIE */}
                      <td colSpan="1">
                        {item?.surface?.toFixed(0)} m<sup>2</sup>
                      </td>
                      {/* GSM */}
                      <td colSpan="1">{item?.GSM?.toFixed(0)}</td>
                      {/* DISTANZA */}
                      <td colSpan="1">{item?.dist?.toFixed(0)} m</td>
                      {/* PDF SELECTION */}
                      <td colSpan="1">
                        <div className={styles.flex_column}>
                          <ActionCheckboxButton
                            callback={() => {
                              if (reportId && item.id) {
                                dispatch(
                                  actions.UPDATE_HIDDEN_COMPARED_ITEMS.REQUEST(
                                    { reportId, property_id: item.id },
                                    () =>
                                      dispatch(
                                        actions.GET_HIDDEN_COMPARED_ITEMS.REQUEST(
                                          reportId
                                        )
                                      )
                                  )
                                );
                              } else {
                                throw new Error("missingReportId");
                              }
                            }}
                            checked={
                              !hiddenComparedItems
                                ?.map((property) => property.property_id)
                                .includes(String(item.id))
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className={styles.legend}>
              <div className={styles.legend__text}>
                *{" "}
                <img
                  src={ArrowPositive}
                  alt="comparison-icon"
                  className={styles.arrow_img}
                />{" "}
                Immobile con caratteristiche simili ma più economico
              </div>
              <div className={styles.legend__text}>
                *{" "}
                <img
                  src={ArrowNegative}
                  alt="comparison-icon"
                  className={styles.arrow_img}
                />{" "}
                Immobile con caratteristiche simili ma più costoso
              </div>
            </div>
          </>
        ) : (
          <div className={styles.no_data}>
            Non sono stati trovati immobili in vendita con caratteristiche
            simili.
          </div>
        )}
      </div>
    );
  }
);

export default ComparedProperties;
