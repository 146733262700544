import clx from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import homepal from "../../../../assets/images/partner/homepal.png";
import lazioInnova from "../../../../assets/images/partner/lazioInnova.png";
import luiss from "../../../../assets/images/partner/luiss.png";
import lventure from "../../../../assets/images/partner/lventure.png";
import Sagoma from "../../../../assets/svg/sagoma.svg";
import { ROUTES } from "../../../../routes/constants";
import useWindowSize from "../../../../utils/useWindowSize";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import { actions } from "../../store";
import ForgotPasswordForm from "./containers/ForgotPasswordForm";
import platformConditionals from "../../../../utils/platformConditionals";
import styles from "./ForgotPassword.module.scss";

export default () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [userEmail, setUserEmail] = useState("");
  const { isMobile, windowHeight } = useWindowSize();

  return (
    <div
      className={clx(styles.container__inner, {
        [styles["container__inner-mobile"]]: isMobile,
      })}
    >
      <div
        className={styles["forgot-pwd__container"]}
        style={{ background: "white" }}
      >
        <div
          // style={{ marginBottom: 200 }}
          className={styles["forgot-pwd__image"]}
        >
          <img
            src={platformConditionals()?.logo || ""}
            alt="logo"
            onClick={() => (window.location.href = "https://www.houseplus.it/")}
          />
        </div>
        {step === 1 ? (
          <ForgotPasswordForm setStep={setStep} setUserEmail={setUserEmail} />
        ) : step === 2 ? (
          <div
            className={clx(styles["forgot-pwd-form__wrapper"], {
              [styles["forgot-pwd-form__wrapper-mobile"]]: isMobile,
            })}
          >
            <h1>Recupero Password</h1>
            <div className={styles.text}>
              <p>Accedi alla tua casella email con la quale sei registrato:</p>
              <p className={styles.text__email}>{userEmail}</p>
              <p>Dopo la conferma potrai scegliere una nuova password</p>
            </div>
            <div className={styles["submit-btn"]}>
              <PrimaryButton
                width="100%"
                fontSize="18px"
                text="Non ho ricevuto nessuna email"
                onClick={() => setStep((prev) => prev + 1)}
              />
            </div>
          </div>
        ) : step === 3 ? (
          <div
            className={clx(styles["forgot-pwd-form__wrapper"], {
              [styles["forgot-pwd-form__wrapper-mobile"]]: isMobile,
            })}
          >
            <h1>Non hai ancora ricevuto l’email?</h1>
            <div className={styles.text}>
              <br />
              <ul>
                <li>- Attendi 5 minuti dalla richiesta</li>
                <li>- Controlla nello spam!</li>
                <li>- Controlla che la casella non sia piena</li>
              </ul>
              <p>
                Se hai fatto proprio tutto e pensi che ci sia stato un problema,
                puoi richedere una nuova email.
              </p>
            </div>
            <div className={styles["submit-btn"]}>
              <PrimaryButton
                width="100%"
                height="60px"
                fontSize="18px"
                background="#5E239D"
                text="Richiedi nuova email"
                onClick={() =>
                  dispatch(
                    actions.FORGOT_PASSWORD.REQUEST({ email: userEmail })
                  )
                }
              />
            </div>
            <div className={styles.inner__link}>
              <p>{"Hai già un Account?"}</p>
              <Link className={styles.link} to={`${ROUTES.SIGN_IN}`}>
                {"Accedi"}
              </Link>
            </div>
          </div>
        ) : null}
      </div>
      {!isMobile && (
        <div
          className={styles.right_div}
          style={{
            backgroundImage: "url(" + Sagoma + ")",
          }}
        >
          {process.env.REACT_APP_PLATFORM == "HOUSEPLUS" && (
            <div style={{ width: "100%" }}>
              <div className={styles.partner}>
                <img src={lventure} style={{ marginLeft: 20 }} />
                <img src={lazioInnova} style={{ marginLeft: 20 }} />
                <img src={homepal} style={{ marginLeft: 20 }} />
                <img src={luiss} style={{ marginLeft: 20 }} />
              </div>
              <div className={styles.trust_copy}>
                Trusted by Top-tier product campanies
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
