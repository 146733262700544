import PrimaryButton from '../Buttons/PrimaryButton';
import styles from './ConfirmModal.module.scss';

const ConfirmModal = ({
	confirmModal, setConfirmModalOpen, text, padding, textAlign, width, margin, rightIconSrc, iconHeight, callbackLeft, callbackRight, leftText, rightText, invertedColor = false
}) => <>
	<h2 className={styles['confirm-modal__title']} style={{
		width, margin, textAlign
	}}>{text || 'Sicuro di voler eliminare?'}</h2>
	<div className={styles['confirm-modal__actions']}>
		<PrimaryButton
			text={leftText || 'Elimina'}
			fontSize='18px'
			onClick={callbackLeft || confirmModal}
			height='25px'
			width='100px'
			padding='15px 20px'
			borderRadius='5px'
			rightIconSrc={rightIconSrc}
			iconHeight={iconHeight}
			background={!invertedColor && '#FC440F'}
		/>
		<PrimaryButton
			text={rightText || 'Indietro'}
			fontSize='18px'
			onClick={callbackRight || (() => setConfirmModalOpen(false))}
			height='25px'
			width='100px'
			padding='15px 20px'
			borderRadius='5px'
			background={invertedColor && '#FC440F'}
		/>
	</div>
</>;

export default ConfirmModal;
