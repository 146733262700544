// Hide elements conditionally on select change
// Also reset values of hidden elements
const handleElementSelected = (
  hideElement,
  sethideElement,
  nameOption,
  property,
  setFieldValue,
  values,
  valueInputNumber
) => {
  let config = { ...hideElement };
  // console.log(nameOption, "nameOption");

  if (property.fieldName == "Tipologia") {
    if (nameOption == "Appartamento") {
      config = {
        ...config,
        Piano: false,
        PianiCasa: false,
        Ascensore: false,
        Cantina: false,
        // KEEP HIDING THE RESPECTIVE SURFACE IF THE ELEMENT WAS NOT HIDDEN BEFORE AND THE VALUES IS "NO"
        SuperficieCantina: values.Cantina != "Si" ? true : false,
        GiardinoPrivato: false,
        SuperficieGiardinoPrivato:
          values.GiardinoPrivato != "Si" ? true : false,
        GiardinoCondominiale: false,
        SuperficieGiardinoCondominiale:
          values.GiardinoCondominiale != "Si" ? true : false,
        Soffitta: false,
        SuperficieSoffitta: values.Soffitta != "Si" ? true : false,
        Seminterrato: false,
        SuperficieSeminterrato: values.Seminterrato != "Si" ? true : false,
      };
      // ONLY IF THE ELEMENTS WERE ACTUALLY HIDDEN BEFORE NOW SHOWING THEM AGAIN, RESET ITS VALUES
      if (hideElement.Ascensore == true) {
        setFieldValue("Ascensore", "");
      }
      if (hideElement.Cantina == true) {
        setFieldValue("Cantina", "");
        setFieldValue("SuperficieCantina", "");
      }
      if (hideElement.GiardinoPrivato == true) {
        setFieldValue("GiardinoPrivato", "");
        setFieldValue("SuperficieGiardinoPrivato", "");
      }
      if (hideElement.GiardinoCondominiale == true) {
        setFieldValue("GiardinoCondominiale", "");
        setFieldValue("SuperficieGiardinoCondominiale", "");
      }
      if (hideElement.Soffitta == true) {
        setFieldValue("Soffitta", "");
        setFieldValue("SuperficieSoffitta", "");
      }
      if (hideElement.Seminterrato == true) {
        setFieldValue("Seminterrato", "");
        setFieldValue("SuperficieSeminterrato", "");
      }
    } else if (nameOption == "Attico") {
      config = {
        ...config,
        Piano: true,
        PianiCasa: false,
        Ascensore: false,
        Cantina: false,
        // KEEP HIDING THE RESPECTIVE SURFACE IF THE ELEMENT WAS NOT HIDDEN BEFORE AND THE VALUES IS "NO"
        SuperficieCantina: values.Cantina != "Si" ? true : false,
        GiardinoPrivato: false,
        SuperficieGiardinoPrivato:
          values.GiardinoPrivato != "Si" ? true : false,
        GiardinoCondominiale: false,
        SuperficieGiardinoCondominiale:
          values.GiardinoCondominiale != "Si" ? true : false,
        Soffitta: true,
        SuperficieSoffitta: true,
        Seminterrato: true,
        SuperficieSeminterrato: true,
      };
      // ONLY IF THE ELEMENTS WERE ACTUALLY HIDDEN BEFORE NOW SHOWING THEM AGAIN, RESET ITS VALUES
      setFieldValue("Piano", 0);
      if (hideElement.Ascensore == true) {
        setFieldValue("Ascensore", "");
      }
      if (hideElement.GiardinoPrivato == true) {
        setFieldValue("GiardinoPrivato", "");
        setFieldValue("SuperficieGiardinoPrivato", "");
      }
      setFieldValue("Soffitta", "No");
      setFieldValue("SuperficieSoffitta", "");
      setFieldValue("Seminterrato", "No");
      setFieldValue("SuperficieSeminterrato", "");
      if (hideElement.Cantina == true) {
        setFieldValue("Cantina", "");
        setFieldValue("SuperficieCantina", "");
      }
      if (hideElement.GiardinoCondominiale == true) {
        setFieldValue("GiardinoCondominiale", "");
        setFieldValue("SuperficieGiardinoCondominiale", "");
      }
    } else if (nameOption == "Villa" || nameOption == "Villetta") {
      config = {
        ...config,
        Piano: true,
        PianiCasa: true,
        Ascensore: true,
        Cantina: true,
        SuperficieCantina: true,
        GiardinoPrivato: false,
        // KEEP HIDING THE RESPECTIVE SURFACE IF THE ELEMENT WAS NOT HIDDEN BEFORE AND THE VALUES IS "NO"
        SuperficieGiardinoPrivato:
          values.GiardinoPrivato != "Si" ? true : false,
        GiardinoCondominiale: true,
        SuperficieGiardinoCondominiale: true,
        Soffitta: false,
        SuperficieSoffitta: values.Soffitta != "Si" ? true : false,
        Seminterrato: false,
        SuperficieSeminterrato: values.Seminterrato != "Si" ? true : false,
      };
      // ONLY IF THE ELEMENTS WERE ACTUALLY HIDDEN BEFORE NOW SHOWING THEM AGAIN, RESET ITS VALUES
      setFieldValue("Piano", 0);
      setFieldValue("PianiCasa", 0);
      setFieldValue("Ascensore", "No");
      setFieldValue("Cantina", "No");
      setFieldValue("SuperficieCantina", "");
      if (hideElement.GiardinoPrivato == true) {
        setFieldValue("GiardinoPrivato", "");
        setFieldValue("SuperficieGiardinoPrivato", "");
      }
      setFieldValue("GiardinoCondominiale", "No");
      setFieldValue("SuperficieGiardinoCondominiale", "");
      if (hideElement.Soffitta == true) {
        setFieldValue("Soffitta", "");
        setFieldValue("SuperficieSoffitta", "");
      }
      if (hideElement.Seminterrato == true) {
        setFieldValue("Seminterrato", "");
        setFieldValue("SuperficieSeminterrato", "");
      }
    }
  }

  // console.log(property.fieldName, "property.fieldName");
  // if (property.fieldName == "Piano" || property.fieldName == "TotalePiani") {
  //   // console.log("Piano");
  //   // console.log(valueInputNumber, "valueInputNumber");
  //   if (values.Tipologia == "Appartamento" && valueInputNumber == 0) {
  //     config = {
  //       ...config,
  //       GiardinoPrivato: false,
  //       SuperficieGiardinoPrivato: false,
  //     };
  //     setFieldValue("GiardinoPrivato", "");
  //     setFieldValue("SuperficieGiardinoPrivato", "");
  //   } else if (values.Tipologia == "Appartamento" && valueInputNumber != 0) {
  //     config = {
  //       ...config,
  //       GiardinoPrivato: true,
  //       SuperficieGiardinoPrivato: true,
  //     };
  //     setFieldValue("GiardinoPrivato", "No");
  //     setFieldValue("SuperficieGiardinoPrivato", "");
  //   }
  // }

  if (property.fieldName == "GiardinoPrivato") {
    if (nameOption == "No") {
      config = {
        ...config,
        SuperficieGiardinoPrivato: true,
      };
      setFieldValue("SuperficieGiardinoPrivato", "");
    }

    if (nameOption == "Si") {
      config = {
        ...config,
        SuperficieGiardinoPrivato: false,
      };
    }
  }

  if (property.fieldName == "GiardinoCondominiale") {
    if (nameOption == "No") {
      config = {
        ...config,
        SuperficieGiardinoCondominiale: true,
      };
      setFieldValue("SuperficieGiardinoCondominiale", "");
    }

    if (nameOption == "Si") {
      config = {
        ...config,
        SuperficieGiardinoCondominiale: false,
      };
    }
  }

  if (property.fieldName == "Balconi") {
    if (nameOption == "No") {
      config = {
        ...config,
        SuperficieBalconi: true,
      };
      setFieldValue("SuperficieBalconi", "");
    }

    if (nameOption == "Si") {
      config = {
        ...config,
        SuperficieBalconi: false,
      };
    }
  }

  if (property.fieldName == "Terrazzo") {
    if (nameOption == "No") {
      config = {
        ...config,
        SuperficieTerrazzo: true,
      };
      setFieldValue("SuperficieTerrazzo", "");
    }

    if (nameOption == "Si") {
      config = {
        ...config,
        SuperficieTerrazzo: false,
      };
    }
  }

  if (property.fieldName == "Soffitta") {
    if (nameOption == "No") {
      config = {
        ...config,
        SuperficieSoffitta: true,
      };
      setFieldValue("SuperficieSoffitta", "");
    }

    if (nameOption == "Si") {
      config = {
        ...config,
        SuperficieSoffitta: false,
      };
    }
  }

  if (property.fieldName == "Cantina") {
    if (nameOption == "No") {
      config = {
        ...config,
        SuperficieCantina: true,
      };
      setFieldValue("SuperficieCantina", "");
    }

    if (nameOption == "Si") {
      config = {
        ...config,
        SuperficieCantina: false,
      };
    }
  }

  if (property.fieldName == "Seminterrato") {
    if (nameOption == "No") {
      config = {
        ...config,
        SuperficieSeminterrato: true,
      };
      setFieldValue("SuperficieSeminterrato", "");
    }

    if (nameOption == "Si") {
      config = {
        ...config,
        SuperficieSeminterrato: false,
      };
    }
  }

  if (property.fieldName == "PostoAuto") {
    if (nameOption == "Nessuno") {
      config = {
        ...config,
        SuperficiePostoAuto: true,
        SuperficiePostoAutoCondominiale: true,
        SuperficiePostoAutoGarage: true,
      };
      setFieldValue("SuperficiePostoAuto", "");
      setFieldValue("SuperficiePostoAutoCondominiale", "");
      setFieldValue("SuperficiePostoAutoGarage", "");
    }

    if (nameOption == "Privato") {
      config = {
        ...config,
        SuperficiePostoAuto: values.PostoAuto.includes("Privato"),
      };
    }

    if (nameOption == "Condominiale") {
      config = {
        ...config,
        SuperficiePostoAutoCondominiale:
          values.PostoAuto.includes("Condominiale"),
      };
    }

    if (nameOption == "Garage") {
      config = {
        ...config,
        SuperficiePostoAutoGarage: values.PostoAuto.includes("Garage"),
      };
    }
  }

  sethideElement(config);
};

export default handleElementSelected;
