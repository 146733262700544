import React from "react";
import ReactToolTip from "../../../../../../../Common/ToolTip/ReactToolTip";
import { ReactComponent as LocExact } from "../../../../../../../../assets/svg/loc-exact.svg";
import formatToEuro from "../../../../../../../../utils/formatToEuro";

const PinMap = ({ key, customClass, data }) => (
  <>
    <ReactToolTip
      contenet={
        <div
          style={{
            color: "gray",
            minHeight: "max-content",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <h4>{data?.typology} </h4>
          <p>
            <span style={{ fontSize: 14, fontWeight: 600 }}>Prezzo:</span> €{" "}
            {formatToEuro(data?.price)}
          </p>
          <p>
            <span style={{ fontSize: 14, fontWeight: 600 }}>
              Classe energetica:
            </span>{" "}
            {data?.energyClass}
          </p>
          <p>
            <span style={{ fontSize: 14, fontWeight: 600 }}>Superficie:</span>{" "}
            {data?.surface}
          </p>
          <p>
            <span style={{ fontSize: 14, fontWeight: 600 }}>Distanza:</span>{" "}
            {data?.dist}m
          </p>
        </div>
      }
      containerClass="tooltipStyleChange"
      x={-87}
      y={70}
      width={289}
      height={"auto"}
      arrow={{
        x: -3,
        y: 74,
        rotate: "206deg",
      }}
    >
      <span className={customClass} key={key}>
        <LocExact />
      </span>
    </ReactToolTip>
  </>
);

export default PinMap;
