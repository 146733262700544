import clx from "classnames";
import { ReactComponent as LeftThumb } from "../../../../../../assets/svg/left-thumb.svg";
import { ReactComponent as OmiHome } from "../../../../../../assets/svg/omi-home.svg";
import { ReactComponent as RightThumb } from "../../../../../../assets/svg/right-thumb.svg";
import { ReactComponent as SliderRail } from "../../../../../../assets/svg/slider-rail.svg";
import { ReactComponent as SliderTrack } from "../../../../../../assets/svg/slider-track.svg";
import formatToEuro from "../../../../../../utils/formatToEuro";
import styles from "./RightSide.module.scss";

const RightSide = ({ min, max, minRent, maxRent, homePricem2, isMobile }) => {
  max = Math.round(max);
  min = Math.round(min);
  const priceAvg = Math.round((+min + +max) / 2);

  maxRent = Math.round(maxRent);
  minRent = Math.round(minRent);
  const priceAvgRent = Math.round((+minRent + +maxRent) / 2);

  // Calculate the x position of the home icon based on the value per square meter compared to min and max omi values
  const xPosition = ((homePricem2 - min) / (max - min)) * 100;
  // const xPosition = 113;

  // Home icon track styles, remove 18px to center the icon also hide it if its above 150% and out of the slider
  const homeIconTrackLeft = `calc(${xPosition}% - 24px)`;
  const homeIconTrackDisplay = (xPosition > 113 || xPosition < -13) && "none";

  // Home icon rail styles, visible if xPosition above 113% and positioned to the extremes of the rail
  const homeIconRailLeft = xPosition < -13 && 0;
  const homeIconRailRight = xPosition > 113 && 0;
  const homeIconRailDisplay = xPosition <= 113 && xPosition >= -13 && "none";

  return (
    <div
      className={clx(styles.right__side, {
        [styles.right__side__mbl]: isMobile,
      })}
    >
      {/* HEADER */}
      <p className={styles.header_text}>
        Prezzi per m<sup>2</sup>
      </p>
      {/* PRICES CONTAINER */}
      <div className={styles.prices__container}>
        {/* MINIMUM */}
        <div className={styles.price__container}>
          <p className={styles.subheader_text}>Minimo</p>
          <div>
            <p className={styles.text}>Vendita</p>
            {!isNaN(formatToEuro(min)) ? (
              <p className={styles.price}>
                <span className={styles.euro}>&euro;</span> {formatToEuro(min)}
              </p>
            ) : (
              <p className={styles.price} style={{ fontSize: 14 }}>
                Dato non <br /> disponibile
              </p>
            )}
          </div>

          <div>
            <p className={styles.text}>Affitto</p>
            {!isNaN(formatToEuro(minRent)) ? (
              <p className={styles.price}>
                <span className={styles.euro}>&euro;</span>{" "}
                {formatToEuro(minRent)}/mese
              </p>
            ) : (
              <p className={styles.price} style={{ fontSize: 14 }}>
                Dato non <br /> disponibile
              </p>
            )}
          </div>
        </div>
        {/* AVERAGE */}
        <div className={clx(styles.price__container, styles.avg__container)}>
          <p className={styles.subheader_text}>Medio</p>
          <div>
            <p className={styles.text}>Vendita</p>
            {!isNaN(formatToEuro(priceAvg)) ? (
              <p className={styles.price}>
                <span className={styles.euro}>&euro;</span>{" "}
                {formatToEuro(priceAvg)}
              </p>
            ) : (
              <p className={styles.price} style={{ fontSize: 14 }}>
                Dato non <br /> disponibile
              </p>
            )}
          </div>

          <div>
            <p className={styles.text}>Affitto</p>
            {!isNaN(formatToEuro(priceAvgRent)) ? (
              <p className={styles.price}>
                <span className={styles.euro}>&euro;</span>{" "}
                {formatToEuro(priceAvgRent)}/mese
              </p>
            ) : (
              <p className={styles.price} style={{ fontSize: 14 }}>
                Dato non <br /> disponibile
              </p>
            )}
          </div>
        </div>
        {/* MAXIMUM */}
        <div className={styles.price__container}>
          <p className={styles.subheader_text}>Massimo</p>
          <div>
            <p className={styles.text}>Vendita</p>
            {!isNaN(formatToEuro(max)) ? (
              <p className={styles.price}>
                <span className={styles.euro}>&euro;</span> {formatToEuro(max)}
              </p>
            ) : (
              <p className={styles.price} style={{ fontSize: 14 }}>
                Dato non <br /> disponibile
              </p>
            )}
          </div>

          <div>
            <p className={styles.text}>Affitto</p>
            {!isNaN(formatToEuro(maxRent)) ? (
              <p className={styles.price}>
                <span className={styles.euro}>&euro;</span>{" "}
                {formatToEuro(maxRent)}/mese
              </p>
            ) : (
              <p className={styles.price} style={{ fontSize: 14 }}>
                Dato non <br /> disponibile
              </p>
            )}
          </div>
        </div>
      </div>

      {/* SLIDER */}
      <div className={`${styles.photo__wrapper} slider`}>
        <span className={styles.slider_rail}>
          <SliderRail />
        </span>
        <div className={styles.slider_track}>
          <span className={styles.slider_track_track}>
            <SliderTrack />
          </span>
          <span className={styles.slider_left_thumb}>
            <LeftThumb />
          </span>
          <span className={styles.slider_right_thumb}>
            <RightThumb />
          </span>
          <p className={styles.slider_min}>Min</p>
          <p className={styles.slider_max}>Max</p>
          <span
            className={styles.slider_home}
            style={{ left: homeIconTrackLeft, display: homeIconTrackDisplay }}
          >
            <OmiHome />
          </span>
        </div>
        <span
          className={styles.slider_home_rail}
          style={{
            right: homeIconRailRight,
            left: homeIconRailLeft,
            display: homeIconRailDisplay,
          }}
        >
          <OmiHome />
        </span>
      </div>
    </div>
  );
};

export default RightSide;
