import styles from "./ProgressBar.module.scss";

const ProgressBar = ({
  percent = 0,
  rotateBarDeg = "-90",
  rotateTextDeg = 90,
  pointer = true,
  pointerText = "",
  scale = "1",
  top = "",
  left = "",
  display,
  position,
}) => (
  <div
    className={styles["progressbar"]}
    style={{
      "--percent-html": 315 - (315 * percent) / 100,
      transform: `rotate(${rotateBarDeg}deg) scale(${scale})`,
      top: `${top}`,
      left: `${left}`,
      display,
      position,
    }}
  >
    <svg className={styles["progressbar__svg"]}>
      <circle
        cx="50%"
        cy="50%"
        r="50"
        fill="white"
        strokeWidth="2"
        className={styles["progressbar__svg-stroke"]}
      ></circle>
      <circle
        cx="60"
        cy="60"
        r="50"
        className={styles["progressbar__svg-circle"]}
      ></circle>
    </svg>
    <span
      className={styles["progressbar__text"]}
      style={{
        transform: `translate(-50%, -50%) rotate(${rotateTextDeg}deg)`,
      }}
    >
      {Math.abs(percent)}%
    </span>
    {pointer && (
      <>
        <span
          className={styles["pointer__text"]}
          style={{
            transform: `rotate(${rotateTextDeg}deg)`,
          }}
        >
          {pointerText}
        </span>
      </>
    )}
  </div>
);

export default ProgressBar;
