import React from "react";

import AreaChart from "../../../../../Common/Charts/AreaChart";
import PdfCheckbox from "../../../PdfCheckbox";

const SafetyChange = ({
  data,
  minHeight,
  wrapperHeight,
  footerTop,
  locale,
  paddingTop,
  chartMarginTop,
  isPdf = false,
}) => {
  // console.log(data, "SAFETY CHANGE");
  const chartData = data?.data?.y?.map((item, index) => ({
    x: item,
    Denunce: data?.data?.x[index],
  }));
  // console.log(chartData);

  return (
    <div>
      {!isPdf && <PdfCheckbox section={"safety_change"} />}
      <AreaChart
        title={data?.title}
        description={data?.description}
        limit={data?.data?.ylimits}
        label={data?.data?.ytitle}
        dx={35}
        marginLeft={0}
        minHeight={minHeight}
        wrapperHeight={wrapperHeight}
        footerTop={footerTop}
        chartData={chartData}
        locale={locale}
        paddingTop={paddingTop}
        chartMarginTop={chartMarginTop}
      />
    </div>
  );
};

export default SafetyChange;
