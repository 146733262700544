import clx from "classnames";
import React from "react";

import NoChartsData from "../../../AlgorithmReport/containers/NoChartsData";
import GenderStatus from "../../../AlgorithmReport/containers/SocioDemographicArea/containers/GenderStatus";
import PopulationPyramid from "../../../AlgorithmReport/containers/SocioDemographicArea/containers/PopulationPyramid";
import styles from "./SeventhPg.module.scss";

const SeventhPg = ({ genderStatus, populationPyramid, town, pdfItems }) => {
  const highestCategoryAmount =
    genderStatus?.graph_data?.data?.displayed_values[0]?.map(
      (item, index) =>
        item + genderStatus?.graph_data?.data?.displayed_values[1][index]
    );
  const highestCategoryAmountIndex = highestCategoryAmount?.indexOf(
    Math.max(...highestCategoryAmount)
  );
  const highestCategory =
    genderStatus?.graph_data?.data?.x[highestCategoryAmountIndex];
  const highestCategoryMen =
    genderStatus?.graph_data?.data?.displayed_values[0]?.[
      highestCategoryAmountIndex
    ];
  const highestCategoryWomen =
    genderStatus?.graph_data?.data?.displayed_values[1]?.[
      highestCategoryAmountIndex
    ];
  // console.log(genderStatus);
  // console.log(highestCategoryAmount);
  // console.log(highestCategoryAmountIndex);

  const convertMenPyramid =
    populationPyramid?.graph_data?.data?.displayed_values[0]?.map((item) =>
      Number(item.replace("%", ""))
    );
  const convertWomenPyramid =
    populationPyramid?.graph_data?.data?.displayed_values[1]?.map((item) =>
      Number(item.replace("%", ""))
    );
  const highestPyramidMenIndex = convertMenPyramid?.indexOf(
    Math.max(...convertMenPyramid)
  );
  const highestPyramidWomenIndex = convertWomenPyramid?.indexOf(
    Math.max(...convertWomenPyramid)
  );
  const highestPyramidGender =
    convertMenPyramid?.[highestPyramidMenIndex] >
    convertWomenPyramid?.[highestPyramidWomenIndex]
      ? "uomini"
      : "donne";
  const highestGenderAge =
    populationPyramid?.graph_data?.data?.y?.[
      convertMenPyramid?.[highestPyramidMenIndex] >
      convertWomenPyramid?.[highestPyramidWomenIndex]
        ? highestPyramidMenIndex
        : highestPyramidWomenIndex
    ];
  // console.log(populationPyramid);
  // console.log(convertMenPyramid);
  // console.log(convertWomenPyramid);
  // console.log(highestPyramidMenIndex);
  // console.log(highestPyramidWomenIndex);
  // console.log(highestPyramidGender);

  return (
    <div className={clx(styles.area__services__wrapper)}>
      <h3 className={styles.header__text}>Informazioni socio-demografiche</h3>
      <div className={styles.area__services__main}>
        {!pdfItems?.["gender_status"] && (
          <div
            className={clx(styles.gender__status, styles.general__card)}
            style={{ position: "relative" }}
          >
            <div className={styles.chart_info}>
              Lo stato civile maggiormente presente è quello dei{" "}
              <b>{highestCategory}</b>, con rispettivamente il{" "}
              <b>{highestCategoryMen}%</b> di uomini e{" "}
              <b>{highestCategoryWomen}%</b> di donne
            </div>
            <div>
              {genderStatus?.graph_status == "OK" ? (
                <GenderStatus
                  data={genderStatus?.graph_data}
                  minHeight={340}
                  wrapperHeight={"auto"}
                  isPdf={true}
                />
              ) : (
                <NoChartsData title={"Stato civile"} />
              )}
            </div>
          </div>
        )}
        {!pdfItems?.["population_pyramid"] && (
          <div
            className={clx(styles.pop__pyramid, styles.general__card)}
            style={{ position: "relative" }}
          >
            <div className={styles.chart_info}>
              Il gruppo di popolazione più numeroso a <b>{town}</b> sono{" "}
              <b>{highestPyramidGender}</b> di <b>{highestGenderAge}</b> anni
            </div>
            <div>
              {populationPyramid?.graph_status == "OK" ? (
                <PopulationPyramid
                  data={populationPyramid?.graph_data}
                  minHeight={300}
                  wrapperHeight={"auto"}
                  chartWidth={"56%"}
                  tickX={-5.5}
                  isPdf={true}
                />
              ) : (
                <NoChartsData title={"Piramide della popolazione residente"} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SeventhPg;
