import { useDispatch } from "react-redux";
import styles from "./ContactUsModal.module.scss";

import { actions as userActions } from "../../../Report/store";

import Modal from "../../../Common/Modal";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import TextField from "../../../Common/TextField";
import alertToast from "../../../../shared/Error/AlertToast";

import { ReactComponent as Close } from "../../../../assets/svg/cross.svg";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";

const ContactUsModal = ({ isModalOpen, setIsModalOpen, userEmail }) => {
  const dispatch = useDispatch();

  const INITIAL_VALUES = {
    contactName: "",
    text: "",
  };

  const VALIDATION_SCHEMA = yup.object().shape({
    contactName: yup.string().required("Nome richiesto"),
    text: yup.string().required("Messaggio richiesto"),
  });

  return (
    <Modal isModalOpen={isModalOpen} width={"80%"} maxWidth={600}>
      <div className={styles.contact_us_modal}>
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={async (values, actions) => {
            if (!values.contactName || !values.text) {
              return alertToast(
                "error",
                "Compila tutti i campi",
                "Si prega di compilare tutti i campi richiesti"
              );
            }

            const formValues = {
              contactName: values.contactName,
              text: values.text,
              userEmail,
            };
            dispatch(
              userActions.DEMO_CONTACT.REQUEST(formValues, () => {
                setIsModalOpen(false);
                actions.resetForm();
                alertToast(
                  "success",
                  "Email inviata con successo!",
                  "Il team Houseplus ti contatterà entro 24 ore lavorative."
                );
              })
            );
          }}
        >
          {({ isSubmitting, errors, values }) => (
            // console.log(errors, "ERRORS"),
            <Form className={styles.form}>
              {/* FORM HEADER */}
              <h3>
                Inserisci il tuo nome e la ragione per cui vuoi contattarci
              </h3>
              {/* Name */}
              <Field
                name="contactName"
                id="contactName"
                label="Nome"
                component={TextField}
                height="35px"
                width="100%"
                labelHeight={"auto"}
              />
              {/* Message */}
              <Field
                name="text"
                id="text"
                label="Il tuo messaggio"
                component={TextField}
                textarea={true}
                width="100%"
                labelHeight={"auto"}
              />
              {/* Submit */}
              <PrimaryButton
                text="Invia il tuo messaggio"
                type="submit"
                width={240}
                height={50}
                background={"#078ea6"}
                className={styles.submit_btn}
                disabled={
                  // isSubmitting ||
                  !values.contactName ||
                  !values.text ||
                  Object.keys(errors).length > 0
                }
              />
            </Form>
          )}
        </Formik>

        {/* CLOSE BUTTON */}
        <div className={styles.close_btn}>
          <Close onClick={() => setIsModalOpen(false)} />
        </div>
      </div>
    </Modal>
  );
};

export default ContactUsModal;
