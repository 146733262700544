import clx from "classnames";
import {
  Field,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from "formik";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { animateScroll, scroller } from "react-scroll";
import { ReactComponent as MarkerIcon } from "../../assets/svg/marker.svg";
import { ROUTES } from "../../routes/constants";
import formatToEuro from "../../utils/formatToEuro";
import PrimaryButton from "../Common/Buttons/PrimaryButton";
import SecondaryButton from "../Common/Buttons/SecondaryButton";
import RadioButton from "../Common/Buttons/RadioButton";
import GoogleAutocomplete from "../Common/GoogleAutocomplete";
import MultiSelect from "../Common/MultiSelect";
import TextField from "../Common/TextField";
import SelectField from "../Common/SelectField";
import { actions } from "../Report/store";
import { startReportLoader } from "../../shared/Loader/store/actions";
import { getActiveHouse } from "../Report/store/selectors";
import {
  FORM_OPTIONS_STEP_2,
  FORM_OPTIONS_STEP_3,
  FORM_OPTIONS_STEP_4,
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from "./constants";
import Stepper from "./containers/Stepper";
import StepperMobile from "./containers/StepperMobile";
import NumberField from "./containers/NumberField";
import styles from "./CreateReport.module.scss";
import hideElementsOnCopy from "./utils/hideElementsOnCopy";
import hideSurfaces from "./utils/hideSurfaces";
import scrollToErrors from "./utils/scrollToErrors";
import handleElementSelected from "./utils/handleElementSelected";
import maxPiani from "./utils/maxPiani";
import handleInput from "./utils/handleInput";
import PreviewModal from "./containers/PreviewModal";
import platformConditionals from "../../utils/platformConditionals";

const MapOptions = {
  minZoom: 12,
  fullscreenControl: false,
  zoomControl: false,
  scrollwheel: false,
};

const CreateReport = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const submitFormRef = useRef();
  const formWrapperRef = useRef();
  // Ref to be assigned to input with error
  const elRef = useRef();
  const [hideElement, sethideElement] = useState({});
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [reportData, setReportData] = useState({});

  const activeHouse = useSelector(getActiveHouse());
  // console.log(activeHouse, "activeHouse");
  // console.log(Object.keys(activeHouse).length > 0, "activeHouse");
  // const omi_Info = useSelector(getOmiInfo());

  const [renderForm, setRenderForm] = useState(false);
  // console.log(location, "location");

  // COPY LOGIC
  // If user reloads the page on copy, it wont have anymore the activeHouse in the store,
  // so remove copy from location state and thereforrender initial values
  useEffect(() => {
    if (Object.keys(activeHouse).length <= 0 && location?.state?.copy) {
      navigate(ROUTES.CREATE_REPORT, { replace: true, state: {} });
      // Set flag to render form to account for location.state change delay
      setRenderForm(true);
    } else {
      setRenderForm(true);
    }
  }, [activeHouse, location?.state, navigate]);

  // Hide elements on copy
  useEffect(() => {
    if (location?.state?.copy) {
      hideElementsOnCopy(COPY_VALUES, hideElement, sethideElement);
    } else {
      hideSurfaces(hideElement, sethideElement);
    }
  }, [location]);

  // Format multi select values from backend for copy, since they come back in object key-value pairs rather than an array
  const additionalFieldsFromBackendValues = {
    ImmobileDiLusso: activeHouse?.ImmobileDiLusso,
    Portineria: activeHouse?.Portineria,
    ImmobileAZeroEmissioni: activeHouse?.ImmobileAZeroEmissioni,
    CampoTennis: activeHouse?.CampoTennis,
    Piscina: activeHouse?.Piscina,
    Jacuzzi: activeHouse?.Jacuzzi,
    PompaDiCalore: activeHouse?.PompaDiCalore,
    // AriaCondizionata: activeHouse?.AriaCondizionata,
    EsposizioneInterna: activeHouse?.EsposizioneInterna,
    EsposizioneEsterna: activeHouse?.EsposizioneEsterna,
    Lusso: activeHouse?.Lusso,
    Camino: activeHouse?.Camino,
  };
  const formattedFieldsFromBackend = Object.keys(
    additionalFieldsFromBackendValues
  ).filter((key) => additionalFieldsFromBackendValues[key] == "Si");
  // console.log(formattedFieldsFromBackend);

  // Copy values from report to prefill form if user comes from copy button
  const COPY_VALUES = {
    Titolo: activeHouse?.Titolo,
    Indirizzo: {
      country: activeHouse?.country,
      regione: activeHouse?.regione,
      provincia: activeHouse?.provincia,
      city: activeHouse?.city,
      buildingNumber: activeHouse?.buildingNumber,
      cap: activeHouse?.cap,
      street: activeHouse?.street,
      Lat: activeHouse?.Lat,
      Lng: activeHouse?.Lng,
    },
    Tipologia: activeHouse?.Tipologia,
    TotalePiani: +activeHouse?.TotalePiani,
    Piano: +activeHouse?.Piano,
    PianiCasa: +activeHouse?.PianiCasa,
    Superficie: activeHouse?.Superficie,
    Bagni: +activeHouse?.Bagni,
    Cucina: activeHouse?.Cucina,
    Locali: +activeHouse?.Locali,
    GiardinoPrivato: activeHouse?.GiardinoPrivato,
    SuperficieGiardinoPrivato: activeHouse?.SuperficieGiardinoPrivato,
    GiardinoCondominiale: activeHouse?.GiardinoCondominiale,
    SuperficieGiardinoCondominiale: activeHouse?.SuperficieGiardinoCondominiale,
    Balconi: activeHouse?.Balconi,
    SuperficieBalconi: activeHouse?.SuperficieBalconi,
    Terrazzo: activeHouse?.Terrazzo,
    SuperficieTerrazzo: activeHouse?.SuperficieTerrazzo,
    Soffitta: activeHouse?.Soffitta,
    SuperficieSoffitta: activeHouse?.SuperficieSoffitta,
    Cantina: activeHouse?.Cantina,
    SuperficieCantina: activeHouse?.SuperficieCantina,
    Seminterrato: activeHouse?.Seminterrato,
    SuperficieSeminterrato: activeHouse?.SuperficieSeminterrato,
    Ascensore: activeHouse?.Ascensore,
    PostoAuto: activeHouse?.PostoAuto,
    SuperficiePostoAuto: activeHouse?.SuperficiePostoAuto,
    SuperficiePostoAutoCondominiale:
      activeHouse?.SuperficiePostoAutoCondominiale || 0,
    SuperficiePostoAutoGarage: activeHouse?.SuperficiePostoAutoGarage || 0,
    Riscaldamento: activeHouse?.Riscaldamento,
    TipologiaRiscaldamento: activeHouse?.TipologiaRiscaldamento,
    AlimentazioneRiscaldamento: activeHouse?.AlimentazioneRiscaldamento,
    SistemaAriaCondizionata: activeHouse?.SistemaAriaCondizionata,
    StatoInterni: activeHouse?.StatoInterni,
    ClasseEnergetica: activeHouse?.ClasseEnergetica,
    Arredamento: activeHouse?.Arredamento,
    AnnoCostruzione: activeHouse?.AnnoCostruzione,
    CostiProprieta:
      activeHouse?.CostiProprieta == 0
        ? ""
        : formatToEuro(activeHouse?.CostiProprieta),
    CampiAddizionali: formattedFieldsFromBackend, // Ensure formattedFieldsFromBackend is defined elsewhere
    Prezzo:
      activeHouse?.CostiProprieta == 0 ? "" : formatToEuro(activeHouse?.Prezzo),
  };

  // This function wraps the values from the stepper for preview, adding the stepId.
  const wrappDataStepperForPreview = (values) => {
    const wrappedValues = [
      ...FORM_OPTIONS_STEP_2,
      ...FORM_OPTIONS_STEP_3,
      ...FORM_OPTIONS_STEP_4,
    ].reduce(
      (acc, item) => ({
        ...acc,
        [item.fieldName]: {
          value: values?.[item.fieldName],
          stepId: item.stepId,
        },
      }),
      {}
    );
    // console.log(wrappedValues, "wrappedValues");

    // Load data before opening the confirmation modal.
    setReportData(wrappedValues);

    // If the current step is 4, open the preview modal.
    if (step == 4) setIsPreviewModalOpen(true);
  };

  // Controllo  se posso andare indietro
  const backStep = (stepClick) => {
    setIsPreviewModalOpen(false);
    if (stepClick > 0 && stepClick < step) setStep(stepClick);
  };

  // FORM COMPONENTS
  const FormFields = ({
    fieldKey,
    option,
    touched,
    setFieldValue,
    values,
    errors,
  }) => {
    // console.log(errors, "errors");
    // Textfield
    if (option.isTextField) {
      return (
        <div
          key={`${fieldKey}-text`}
          className={styles.field__wrapper}
          style={{
            display: hideElement[option.fieldName] == true ? "none" : "",
          }}
        >
          {/* <p>{formatToEuro(10000)}</p> */}
          <Field
            name={option.fieldName}
            id={option.fieldName}
            label={
              option.fieldName == "AnnoCostruzione" &&
              (values["Tipologia"] == "Villa" ||
                values["Tipologia"] == "Villetta")
                ? "Anno costruzione villa"
                : option.labelProperty
            }
            component={TextField}
            optional={option.optionalField}
            onKeyUp={(e) => {
              handleInput(e, option, setFieldValue);
            }}
            labelHeight={"auto"}
          />
        </div>
      );
      // Numberfield
    } else if (option.isNumberField) {
      return (
        <div
          key={`${fieldKey}-num`}
          className={styles.field__wrapper}
          style={{
            display: hideElement[option.fieldName] == true ? "none" : "",
          }}
        >
          <Field
            name={option.fieldName}
            id={option.fieldName}
            label={
              option.fieldName == "TotalePiani" &&
              (values["Tipologia"] == "Villa" ||
                values["Tipologia"] == "Villetta")
                ? "Piani della villa"
                : option.labelProperty
            }
            handleSelect={(valueNum) => {
              // console.log("FEERERVREV");
              handleElementSelected(
                hideElement,
                sethideElement,
                "",
                option,
                setFieldValue,
                values,
                valueNum
              );
            }}
            component={NumberField}
            min={option.min}
            max={maxPiani(
              option.fieldName,
              values.TotalePiani,
              values.Piano,
              values.PianiCasa
            )}
          />
        </div>
      );
      // Multiselect field
    } else if (option.isMultiSelect) {
      return (
        <div
          key={`${fieldKey}-multiselect`}
          className={styles.field__wrapper}
          style={{
            display: hideElement[option.fieldName] == true ? "none" : "",
          }}
        >
          <Field
            name={option.fieldName}
            id={option.fieldName}
            label={option.labelProperty}
            component={MultiSelect}
            options={option.acceptValues}
            optional={option.optionalField}
            placeholder="Scegli caratteristiche addizionali"
          />
        </div>
      );
      // Multiselect radio (checkbox) field
    } else if (option.isMulti) {
      return (
        <div
          key={`${fieldKey}-multi`}
          className={styles["radio-group__wrapper"]}
          style={{
            display: hideElement[option.fieldName] == true ? "none" : "",
          }}
        >
          <p className={styles["radio-group__label"]}>
            {option.labelProperty} <span>(opzione multipla)</span>
          </p>
          <div className={styles["radio-group"]}>
            {option.acceptValues.map((optionValue) => (
              <Field
                key={`${optionValue.nameOption}${fieldKey}-btnmulti`}
                name={option.fieldName}
                onClick={(e) => {
                  handleElementSelected(
                    hideElement,
                    sethideElement,
                    e.target.value,
                    option,
                    setFieldValue,
                    values
                  );
                }}
                id={optionValue.nameOption}
                label={optionValue.nameOption}
                component={RadioButton}
                option={option}
                type="checkbox"
              />
            ))}
            {touched[option.fieldName] && errors[option.fieldName] && (
              <div className={styles.error}>{errors[option.fieldName]}</div>
            )}
          </div>
        </div>
      );
      // Select field
    } else if (option.isSelectField) {
      return (
        <div
          className={styles["radio-group__wrapper"]}
          style={{
            display: hideElement[option.fieldName] == true ? "none" : "",
          }}
        >
          <p className={styles["radio-group__label"]}>{option.labelProperty}</p>
          <Field
            name={option.fieldName}
            id={option.fieldName}
            options={option.acceptValues}
            component={SelectField}
            width="100%"
            borderRadius="10px"
            labelHeight={"auto"}
            labelStyle={{ marginBottom: "0px" }}
            marginBottomInputGroup={"0px"}
            classNameInherit={styles.sold_form__formik__field}
            key={`${option.fieldName}-select-sold`}
          />
        </div>
      );
      // Radio field
    } else {
      return (
        <div
          key={`${fieldKey}-radio`}
          className={styles["radio-group__wrapper"]}
          style={{
            display: hideElement[option.fieldName] == true ? "none" : "",
          }}
        >
          {option.optionalField && (
            <p className={styles.optional_select}>Opzionale</p>
          )}
          <p className={styles["radio-group__label"]}>{option.labelProperty}</p>
          <div className={styles["radio-group"]}>
            {option.acceptValues.map((optionValue) => (
              <Field
                key={`${optionValue.nameOption}${fieldKey}-btnradio`}
                name={option.fieldName}
                id={optionValue.nameOption}
                onClick={(e) => {
                  handleElementSelected(
                    hideElement,
                    sethideElement,
                    e.target.value,
                    option,
                    setFieldValue,
                    values
                  );
                }}
                label={
                  optionValue.nameOption == "Villetta"
                    ? "Villetta a schiera"
                    : option.fieldName == "Arredamento" &&
                      optionValue.nameOption == "Nessuno"
                    ? "Vuoto"
                    : optionValue.nameOption
                }
                component={RadioButton}
              />
            ))}
          </div>
          {touched[option.fieldName] && errors[option.fieldName] && (
            <div className={styles.error}>{errors[option.fieldName]}</div>
          )}
        </div>
      );
    }
  };

  const handleScroll = () => {
    scroller.scrollTo("form-wrapper", {
      smooth: "easeOutQuad",
      containerId: "form-wrapper",
      duration: 600,
      isDynamic: true,
    });
    // const options = {
    //   duration: 100,
    //   smooth: true,
    //   smooth: "easeOutQuad",
    // };

    // animateScroll.scrollToTop(options);
  };

  // BUTTON COMPONENTS
  const NextStepBtn = ({
    optionsStep = "",
    errors,
    setFieldTouched,
    step,
    values,
  }) => {
    return (
      <div className={styles.submit_btns}>
        {step != 1 && (
          <SecondaryButton
            height="50px"
            width="30%"
            fontSize="22px"
            text="Indietro"
            // isReactIcon={true}
            // leftIconSrc={BackIcon}
            onClick={step != 1 ? () => setStep(step - 1) : null}
            classNameExternal={styles.submit_btns__back}
          />
        )}
        <PrimaryButton
          width={step == 1 ? "100%" : "30%"}
          height="50px"
          fontSize="22px"
          text="Avanti"
          type="button"
          // Define an asynchronous onClick event handler
          onClick={async () => {
            // Determine if there are errors in the current step
            const hasError =
              step == 1
                ? errors["Titolo"] !== undefined ||
                  (!values.Indirizzo.Lat && !values.Indirizzo.Lng)
                : optionsStep.some(
                    (option) => errors[option.fieldName] !== undefined
                  );

            // Mark all relevant fields as touched
            await Promise.all(
              step == 1
                ? [
                    setFieldTouched("Titolo", true, true),
                    setFieldTouched("Indirizzo", true, true),
                  ]
                : optionsStep.map((option) =>
                    setFieldTouched(option.fieldName, true, true)
                  )
            );

            // If there are errors, scroll to the first error after a delay
            if (hasError) {
              setTimeout(() => {
                scrollToErrors(optionsStep, errors, elRef);
              }, 200);
              return;
            }

            // Special case handling for step 4
            if (step === 4) {
              wrappDataStepperForPreview(values); // Open a confirmation modal
              return;
            }

            // Increment the step and scroll to the top of the form
            setStep(step + 1);
            // scroller.scrollTo(scrollId, scrollToTopOptions);
            handleScroll();
          }}
        />
      </div>
    );
  };

  // FORM
  return (
    // <>
    //   {isLoader ? (
    //     <CustomLoader />
    //   ) : (
    renderForm && (
      <>
        {/* FORM COMPONENT */}
        <section className={styles.create_report} id="form-wrapper">
          {/* STEPPER DESKTOP */}
          <Stepper
            setIsPreviewModalOpen={setIsPreviewModalOpen}
            step={step}
            setStep={setStep}
          />
          {/* FORMIK FORM */}
          <div
            className={styles.create_report__form}
            id="create-report__wrapper"
          >
            {/* HEADER */}
            <Link to={ROUTES.REPORT} className={styles.logo}>
              <img src={platformConditionals()?.logo || ""} alt="logo" />
            </Link>
            <div className={styles.create_report__form__header}>
              <h2>
                Inserisci le caratteristiche dell&apos;immobile che vuoi
                analizzare
              </h2>

              {/* STEPPER MOBILE */}
              <StepperMobile
                setIsPreviewModalOpen={setIsPreviewModalOpen}
                step={step}
                setStep={setStep}
              />
            </div>
            {/* FORM */}
            <div
              className={styles.create_report__form__formik}
              ref={formWrapperRef}
              id="form-wrappert"
            >
              <Formik
                innerRef={submitFormRef}
                initialValues={
                  location?.state?.copy ? COPY_VALUES : INITIAL_VALUES
                }
                // Custom yup validate function in order to pass context to it
                validate={(values) => {
                  try {
                    validateYupSchema(values, VALIDATION_SCHEMA, true, {
                      hideElement,
                      values,
                    }); // Context
                  } catch (err) {
                    return yupToFormErrors(err);
                  }

                  return {};
                }}
                // Submit handler
                onSubmit={(values) => {
                  // console.log(values, "values submit pre conversion");
                  dispatch(startReportLoader());

                  let submitValues = values; // Create a copy of values for manipulation

                  if (
                    submitValues.Tipologia == "Villa" ||
                    submitValues.Tipologia == "Villetta"
                  ) {
                    submitValues.PianiCasa = submitValues.TotalePiani;
                  }
                  if (submitValues.Tipologia == "Attico") {
                    submitValues.Piano = submitValues.TotalePiani;
                  }

                  submitValues.PostoAuto = JSON.stringify(
                    submitValues.PostoAuto
                  ); // Stringify the PostoAuto field

                  // Remove non-numeric characters from the Prezzo field
                  submitValues.CostiProprieta =
                    submitValues.CostiProprieta.replace(/\D/g, "");
                  submitValues.Prezzo = submitValues.Prezzo.replace(/\D/g, "");

                  if (submitValues.ClasseEnergetica == "Sconosciuta") {
                    submitValues.ClasseEnergetica = "G";
                  }

                  // Convert each numeric key to a number
                  [
                    "Superficie",
                    "SuperficieGiardinoPrivato",
                    "SuperficieGiardinoCondominiale",
                    "SuperficieBalconi",
                    "SuperficieTerrazzo",
                    "SuperficieSoffitta",
                    "SuperficieCantina",
                    "SuperficieSeminterrato",
                    "SuperficiePostoAuto",
                    "SuperficiePostoAutoCondominiale",
                    "SuperficiePostoAutoGarage",
                    "CostiProprieta",
                    "Prezzo",
                    "AnnoCostruzione",
                  ].forEach((key) => {
                    submitValues[key] =
                      submitValues[key] == "" ? 0 : +submitValues[key];
                  });

                  // Iterate over the keys in 'Indirizzo' and assign them to 'submitValues'
                  Object.keys(submitValues.Indirizzo).forEach((key) => {
                    submitValues[key] = submitValues.Indirizzo[key];
                  });

                  // Iterate over a predefined list of keys
                  [
                    "ImmobileDiLusso",
                    "Portineria",
                    "ImmobileAZeroEmissioni",
                    "CampoTennis",
                    "Piscina",
                    "Jacuzzi",
                    "PompaDiCalore",
                    // "AriaCondizionata",
                    "EsposizioneInterna",
                    "EsposizioneEsterna",
                    "Camino",
                  ].forEach((key) => {
                    // Set each key in 'submitValues' to a boolean indicating
                    // if 'CampiAddizionali' includes the current key
                    submitValues[key] = submitValues.CampiAddizionali.includes(
                      key
                    )
                      ? "Si"
                      : "No";
                  });

                  // Delete 'Indirizzo' and 'CampiAddizionali' from 'submitValues' as they are no longer needed
                  delete submitValues.Indirizzo;
                  delete submitValues.CampiAddizionali;

                  // Log the modified values for submission
                  // console.log(submitValues, "SUBMIT VALUES POST CONVERSION");

                  // Dispatch an action with the modified values
                  dispatch(
                    actions.ADD_HOUSE.REQUEST(submitValues, (insertId) => {
                      navigate(`${ROUTES.REPORT}/${insertId}`); // Navigate to a new route with the insertId
                    })
                  );
                }}
              >
                {({
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  values,
                }) => (
                  // console.log(values, "values stepper"),
                  // console.log(errors, "errors stepper"),
                  <Form className={styles["create-report__form"]}>
                    {/* STEP 1 */}
                    {step === 1 ? (
                      <>
                        <div className={styles.field__wrapper}>
                          <Field
                            name="Titolo"
                            id="Titolo"
                            label="Nome Agente / Nome Immobile"
                            component={TextField}
                            labelHeight={"auto"}
                          />
                        </div>
                        <div
                          className={clx(
                            styles.field__wrapper,
                            styles["form__map-step"]
                          )}
                        >
                          <Field
                            name="Indirizzo"
                            id="Indirizzo"
                            label="Indirizzo dell’immobile"
                            placeholder="Inserisci l’indirizzo dell’immobile"
                            defaultValue={
                              location?.state?.copy
                                ? COPY_VALUES.Indirizzo.street +
                                  ", " +
                                  COPY_VALUES.Indirizzo.buildingNumber +
                                  ", " +
                                  COPY_VALUES.Indirizzo.city
                                : ""
                            }
                            component={GoogleAutocomplete}
                            borderRadius="5px"
                          />
                          {values.Indirizzo.Lat != "" && (
                            <div
                              style={{
                                height: 400,
                                width: "100%",
                              }}
                            >
                              <GoogleMapReact
                                options={MapOptions}
                                bootstrapURLKeys={{
                                  key: "AIzaSyDhU_uaWxC4hWjLecwUvBW7RzqauLXfpVw",
                                }}
                                center={{
                                  lat: values.Indirizzo.Lat,
                                  lng: values.Indirizzo.Lng,
                                }}
                                defaultZoom={15}
                                key={"reportIds"}
                                yesIWantToUseGoogleMapApiInternals
                              >
                                {values.Indirizzo.Lat != "" && (
                                  <div
                                    className={styles.map_icon}
                                    lat={values?.Indirizzo?.Lat}
                                    lng={values?.Indirizzo?.Lng}
                                  >
                                    <MarkerIcon />
                                  </div>
                                )}
                              </GoogleMapReact>
                            </div>
                          )}
                          <NextStepBtn
                            errors={errors}
                            setFieldTouched={setFieldTouched}
                            step={1}
                            values={values}
                          />
                        </div>
                      </>
                    ) : // STEP 2
                    step === 2 ? (
                      <>
                        {FORM_OPTIONS_STEP_2?.map((option) => (
                          <FormFields
                            fieldKey={`${option.fieldName}-step2`}
                            option={option}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={errors}
                          />
                        ))}
                        <NextStepBtn
                          optionsStep={FORM_OPTIONS_STEP_2}
                          errors={errors}
                          setFieldTouched={setFieldTouched}
                          step={2}
                          values={values}
                        />
                      </>
                    ) : // STEP 3
                    step === 3 ? (
                      <>
                        {FORM_OPTIONS_STEP_3?.map((option) => (
                          <FormFields
                            fieldKey={`${option.fieldName}-step3`}
                            option={option}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={errors}
                          />
                        ))}
                        <NextStepBtn
                          optionsStep={FORM_OPTIONS_STEP_3}
                          errors={errors}
                          setFieldTouched={setFieldTouched}
                          step={3}
                          values={values}
                        />
                      </>
                    ) : // STEP 4
                    step === 4 ? (
                      <>
                        {FORM_OPTIONS_STEP_4?.map((option) => (
                          <FormFields
                            fieldKey={`${option.fieldName}-step4`}
                            option={option}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={errors}
                          />
                        ))}
                        <NextStepBtn
                          optionsStep={FORM_OPTIONS_STEP_4}
                          errors={errors}
                          setFieldTouched={setFieldTouched}
                          step={4}
                          values={values}
                        />
                      </>
                    ) : null}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>

        {/* PREVIEW MODAL */}
        <PreviewModal
          isPreviewModalOpen={isPreviewModalOpen}
          setIsPreviewModalOpen={setIsPreviewModalOpen}
          reportData={reportData}
          backStep={backStep}
          submitFormRef={submitFormRef}
        />
      </>
    )
  );
};
//     </>
//   );
// };

export default CreateReport;
