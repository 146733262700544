import clx from "classnames";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import homepal from "../../../../assets/images/partner/homepal.png";
import lazioInnova from "../../../../assets/images/partner/lazioInnova.png";
import luiss from "../../../../assets/images/partner/luiss.png";
import lventure from "../../../../assets/images/partner/lventure.png";
import Sagoma from "../../../../assets/svg/sagoma.svg";
import { ROUTES } from "../../../../routes/constants";
import { setError } from "../../../../shared/Error/store/actions";
import useWindowSize from "../../../../utils/useWindowSize";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import TextField from "../../../Common/TextField";
import { actions } from "../../store";
import styles from "./ResetPassword.module.scss";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const { isMobile, windowHeight } = useWindowSize();

  const [isPasswordSaved, setIsPasswordSaved] = useState(false);

  const initialValues = {
    passwordConfirmation: "",
    newPassword: "",
  };

  const validationSchema = {
    newPassword: yup
      .string()
      .min(3, "La password è troppe breve")
      .max(100, "La password è troppo lunga!")
      .required("Required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Le password devono coincidere")
      .min(3, "La password è troppe breve")
      .max(100, "La password è troppo lunga!"),
  };

  return (
    <div
      className={clx(styles.container__inner, {
        [styles["container__inner-mobile"]]: isMobile,
      })}
    >
      <div
        className={styles["reset-pwd__container"]}
        style={{ backgroundColor: "white" }}
      >
        {isPasswordSaved ? (
          <div className={styles["pwd-saved"]}>
            <h1 className={styles.title}>
              Ben fatto!
              <br />
              Hai creato una nuova Password
            </h1>
            <p className={styles.text}>
              Potrai accedere nuovamente ad HousePlus.
            </p>
            <PrimaryButton
              width="100%"
              height="60px"
              text="Accedi di nuovo"
              onClick={() => navigate(ROUTES.SIGN_IN)}
            />
          </div>
        ) : (
          <div
            className={clx(styles["reset-pwd-form__wrapper"], {
              [styles["reset-pwd-form__wrapper-mobile"]]: isMobile,
            })}
          >
            <h1>Recupero Password</h1>
            <p style={{ lineHeight: 1.6 }}>
              {" "}
              La nuova password deve contenere almeno 8 caratteri, usa lettere e
              numeri.
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={yup.object().shape(validationSchema)}
              onSubmit={async (values) => {
                if (token) {
                  localStorage.setItem("jwtToken", token);
                  dispatch(
                    actions.RESET_PASSWORD.REQUEST(
                      {
                        newPassword: values.newPassword,
                      },
                      () => {
                        setIsPasswordSaved(true);
                        localStorage.removeItem("jwtToken");
                      }
                    )
                  );
                } else {
                  dispatch(setError("Empty token"));
                }
              }}
            >
              {() => (
                <Form className={styles["reset-pwd__form"]}>
                  <Field
                    name="newPassword"
                    id="newPassword"
                    label="Nuova Password"
                    type="password"
                    placeholder="Password"
                    component={TextField}
                    showPassword={true}
                  />
                  <Field
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    label="Riscrivi la Password scelta"
                    type="password"
                    placeholder="Password"
                    component={TextField}
                    showPassword={true}
                  />
                  <PrimaryButton
                    width="100%"
                    height="60px"
                    text="Conferma Password"
                  />
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
      {!isMobile && (
        <div
          className={styles.right_div}
          style={{
            backgroundImage: "url(" + Sagoma + ")",
          }}
        >
          {process.env.REACT_APP_PLATFORM == "HOUSEPLUS" && (
            <div style={{ width: "100%" }}>
              <div className={styles.partner}>
                <img src={lventure} style={{ marginLeft: 20 }} />
                <img src={lazioInnova} style={{ marginLeft: 20 }} />
                <img src={homepal} style={{ marginLeft: 20 }} />
                <img src={luiss} style={{ marginLeft: 20 }} />
              </div>
              <div className={styles.trust_copy}>
                Trusted by Top-tier product campanies
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
