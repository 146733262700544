import "./BarChartHorizontal.scss";
import clx from "classnames";

import React from "react";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import chartStyles from "../Charts.module.scss";
import tooltipStyles from "../ChartsTooltip/ChartsTooltip.module.scss";
import ChartsLegend from "../ChartsLegend";

const Index = ({
  title,
  description,
  limit,
  legendTitle,
  firstRow,
  secondRow,
  minHeight,
  wrapperHeight,
  legendHeight,
  legendTop,
  marginTop,
  barSize,
  legendLeft,
  minWidth,
  customLabelTop,
  paddingTop,
  chartMarginTop,
}) => {
  const CustomNameLabel = ({ x, y, value }) => (
    <Text x={x} y={y} dy={customLabelTop || -10} dx={0} textAnchor="left">
      {value}
    </Text>
  );

  const CustomCursor = (props) => {
    const { x, y, width } = props;
    return <Rectangle x={x} y={y + 11.5} width={width} height={35} />;
  };

  const HorizontalBarTooltip = ({ active, payload, label, type }) => {
    // console.log(payload);
    if (active && payload && payload.length) {
      return (
        <div className={tooltipStyles.custom_tooltip}>
          <b className={tooltipStyles.custom_tooltip__title}>{label}</b>
          <div className={tooltipStyles.custom_tooltip__content}>
            {payload.map((item, index) => (
              <React.Fragment key={index}>
                <div>
                  <b>{item.value.toLocaleString("it-IT")}</b> denunce per{" "}
                  <b>100.000</b> abitanti
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={clx(chartStyles.chart__wrapper, "bar-chart-horizontal")}
      style={{ minHeight: wrapperHeight, paddingTop }}
    >
      <b>{title}</b>
      <div
        className={chartStyles.chart__container}
        style={{ display: "flex", marginTop: chartMarginTop }}
      >
        <ResponsiveContainer
          minHeight={minHeight || 500}
          minWidth={minWidth || 300}
        >
          <BarChart
            data={firstRow}
            margin={{ top: 40 }}
            layout="vertical"
            barSize={barSize || 25}
          >
            <XAxis type="number" hide={true} domain={[0, limit]} />
            <YAxis
              type="category"
              dataKey={firstRow && Object.keys(firstRow[0])[0]}
              hide={true}
            />
            <Tooltip
              cursor={<CustomCursor />}
              wrapperStyle={{ outline: "none" }}
              content={<HorizontalBarTooltip />}
            />
            <Legend
              verticalAlign="top"
              align="right"
              height={legendHeight || 50}
              wrapperStyle={{ top: legendTop || 40, left: legendLeft }}
              payload={[
                {
                  value: "",
                  type: "line",
                },
              ]}
            />
            <Bar
              isAnimationActive={false}
              dataKey={firstRow && Object.keys(firstRow[0])[1]}
              radius={[0, 5, 5, 0]}
              className="strong-bar"
            >
              <LabelList
                dataKey={firstRow && Object.keys(firstRow[0])[0]}
                content={<CustomNameLabel />}
              />
              <LabelList
                dataKey={firstRow && Object.keys(firstRow[0])[1]}
                position="right"
                fill="#9D9D9D"
                formatter={(value) => value.toLocaleString("it-IT")}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer
          minHeight={minHeight || 500}
          minWidth={minWidth || 325}
        >
          <BarChart
            data={secondRow}
            margin={{ top: 40 }}
            layout="vertical"
            barSize={barSize || 25}
          >
            <XAxis type="number" hide={true} domain={[0, limit]} />
            <YAxis
              type="category"
              dataKey={secondRow && Object.keys(secondRow[0] || {})[0]}
              hide={true}
            />
            <Tooltip
              cursor={<CustomCursor />}
              wrapperStyle={{ outline: "none" }}
              content={<HorizontalBarTooltip />}
            />
            <Legend
              verticalAlign="top"
              align="right"
              height={legendHeight || 50}
              wrapperStyle={{ top: legendTop || 40, left: legendLeft }}
              // iconType="rect"
              // formatter={() => <span>{legendTitle}</span>}
              content={
                <ChartsLegend
                  typeFirst={"Denunce/100.000 abitanti"}
                  colorFirst={"light"}
                  position="7px"
                  isSingle={true}
                />
              }
            />
            <Bar
              isAnimationActive={false}
              dataKey={secondRow && Object.keys(secondRow[0])[1]}
              radius={[0, 5, 5, 0]}
              className="strong-bar"
            >
              <LabelList
                dataKey={secondRow && Object.keys(secondRow[0])[0]}
                content={<CustomNameLabel />}
              />
              <LabelList
                dataKey={secondRow && Object.keys(secondRow[0])[1]}
                position="right"
                fill="#9D9D9D"
                formatter={(value) => value.toLocaleString("it-IT")}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <p className={chartStyles.chart__source} style={{ marginTop }}>
        {description}
      </p>
    </div>
  );
};

export default Index;
