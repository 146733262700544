import clx from "classnames";
import { Link } from "react-router-dom";

import BannerLogin from "../../../assets/images/banner-login.png";
import BannerSignUp from "../../../assets/images/banner-signup.png";
import platformConditionals from "../../../utils/platformConditionals";
import { ROUTES } from "../../../routes/constants";
import styles from "./Banner.module.scss";

const Banner = ({ isAuth, isLogin, isPayment }) => (
  <div className={clx(styles.banner__wrapper)}>
    <div className={styles["image__wrapper"]}>
      <img
        src={isLogin ? BannerLogin : BannerSignUp}
        alt="banner"
        className={styles.banner__img}
      />
    </div>
    <div className={styles.banner__inner}>
      {!isAuth ? (
        <img
          src={platformConditionals()?.logo || ""}
          alt="logo"
          className={styles.logo}
        />
      ) : (
        <div />
      )}
      <div className={styles.inner__text}>
        <h2>Benvenuto in {platformConditionals()?.bannerText || ""}.</h2>
        <p>
          Da ora puoi facilitare le tue decisioni immobiliari prima di acquisire
          un nuovo immobile.{" "}
        </p>
        <div
          style={{
            marginTop: 40,
            textAlign: "left",
            marginLeft: 35,
          }}
        >
          <ul>
            <li>Carica i dati dell’immobile che vuoi acquisire.</li>
            <br />
            <li>
              Calcola il valore economico che ti permette di venderlo entro 90
              giorni.
            </li>
            <br />
            <li>Scarica il tuo report di vendibilità.</li>
          </ul>
        </div>
      </div>
      {isPayment || isAuth ? (
        <div className={styles.inner__link} />
      ) : (
        <div className={styles.inner__link}>
          <p>{!isLogin ? "Non hai un Account?" : "Hai già un Account?"}</p>
          <Link
            className={styles.link}
            to={!isLogin ? `${ROUTES.SIGN_UP}` : `${ROUTES.SIGN_IN}`}
          >
            {!isLogin ? "Registrati" : "Accedi"}
          </Link>
        </div>
      )}
    </div>
  </div>
);

export default Banner;
