import styles from "./SeventeenthPg.module.scss";

const SeventeenthPg = () => (
  <div className={styles.page__container}>
    <h3 className={styles.header__text}>Informazioni tecniche</h3>

    <p className={styles.subheader__text}>Metodologia</p>
    <p className={styles.description}>
      Il presente report è stato redatto mediante Plus predictive AI modello
      proprietario di Houseplus Innovation s.r.l.{" "}
      {process.env.REACT_APP_PLATFORM === "MONDORED" &&
        "concesso in licenza a MondoRed Spa per la piattaforma Red Estate."}
    </p>
    <p className={styles.subheader__text}>Input dell’algoritmo PLUS</p>
    <p className={styles.description}>
      L’algoritmo Plus usa come input le caratteristiche dell’immobile e la
      posizione dell’immobile inserite dall’utente per calcolare le probabilità
      di vendita a diversi prezzi.
    </p>
    <br />
    <p
      className={styles.description}
      style={{ textDecoration: "underline", fontSize: 15 }}
    >
      Processamento dati
    </p>
    <p className={styles.description}>
      Il primo step è quello dell’arricchimento e processamento dei dati.
    </p>
    <ol
      type="n"
      style={{
        marginLeft: "15px",
        marginTop: 6,
        fontSize: 12,
      }}
    >
      <li>
        <p
          style={{
            marginLeft: "10px",
            marginBottom: 2,
            fontSize: 12,
          }}
        >
          Si generano tutte le configurazioni dell’immobile possibili (classe
          energetica, stato degli interni, riscaldamento, ascensore) per una
          selezione di prezzi.
        </p>
      </li>
      <li>
        <p
          style={{
            marginLeft: "10px",
            marginBottom: 2,
            fontSize: 12,
          }}
        >
          Dal database geografico di Houseplus, costruito con dati Open e a
          pagamento, si estraggono tutte le informazioni utili sulla demografia
          e economia della zona.
        </p>
      </li>
      <li>
        <p
          style={{
            marginLeft: "10px",
            marginBottom: 2,
            fontSize: 12,
          }}
        >
          Le API di terzi sono utilizzate per trovare i servizi in zona.
        </p>
      </li>
      <li>
        <p
          style={{
            marginLeft: "10px",
            marginBottom: 2,
            fontSize: 12,
          }}
        >
          Tutti i dati sono processati perché diventino l’input dei modelli di
          Machine Learning.
        </p>
      </li>
    </ol>
    <br />
    <p
      className={styles.description}
      style={{ textDecoration: "underline", fontSize: 15 }}
    >
      Predizioni da modelli intelligenti
    </p>
    <p className={styles.description}>
      {" "}
      Nel secondo step, i nostri modelli di Machine Learning fanno le predizioni
      del tempo di vendita per ognuna delle configurazioni dell’immobile e
      prezzi considerati.{" "}
    </p>
    <ul style={{ marginLeft: "15px", marginTop: 6 }}>
      <li>
        <p
          style={{
            marginLeft: "10px",
            marginBottom: 2,
            fontSize: 12,
          }}
        >
          <b style={{ fontSize: 15, marginBottom: 4 }}>
            Addestramento preliminare dei nostri modelli.
          </b>
          <br />
          <br /> I nostri modelli di Machine Learning sono stati previamente
          addestrati con un dataset estenso dataset di annunci pubblicati in
          diverse piattaforme, modifiche degli annunci, e offerte ricevute
          durante il periodo.
        </p>
      </li>
    </ul>
    <p className={styles.subheader__text}>Output</p>
    <p className={styles.description}>
      Otteniamo le curve prezzo - probabilità per ogni orizzonte temporale e per
      ogni configurazione possibile dell’immobile. Da queste curve si ottengono
      i prezzi raccomandati come quel prezzo che dà una probabilità di vendita
      del 60 % per ogni orizzonte temporale e configurazione possibile. Anche si
      ottengono le probabilità di vendita entro ogni orizzonte temporale per il
      prezzo di valutazione dell’utente e i prezzi raccomandati per la
      configurazione iniziale inserita dall’utente.
    </p>

    <p className={styles.subheader__text}>Avvertenze</p>
    <p className={styles.description}>
      La valutazione svolta deve considerarsi come una pura stima ed il Report
      non ha dunque alcun valore legale.{" "}
    </p>
  </div>
);

export default SeventeenthPg;
