import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getActiveHouse,
  getAgentCharacteristics,
} from "../../../../../Report/store/selectors";

const useSelectsSetter = () => {
  const houseInfo = useSelector(getActiveHouse());
  // console.log("houseInfo", houseInfo);
  const agentCharacteristics = useSelector(getAgentCharacteristics());
  // console.log("agentCharacteristics", agentCharacteristics);
  const [selectOptions, setSelectOptions] = useState([]);
  let filtersArray = [];
  const [characteristicsValues, setCharacteristicsValues] = useState([]);
  // console.log(characteristicsValues, "characteristicsValues");

  // SET TOTAL SELECT OPTIONS BASED ON HOUSE INFO
  useEffect(() => {
    const statoType =
      houseInfo?.StatoInterni === "Ristrutturato" ||
      houseInfo?.StatoInterni === "Nuovo"
        ? "positivo"
        : houseInfo?.StatoInterni === "Buono, abitabile"
        ? "neutro"
        : "negativo";
    filtersArray.push({
      charId: `single_select_${statoType}_houseInfo?.StatoInterni`,
      value: `Stato degli interni ${houseInfo?.StatoInterni}`,
      label: `Stato degli interni ${houseInfo?.StatoInterni}`,
      type: statoType,
    });

    const classeListPositive = ["A4", "A3", "A2", "A1", "B", "C"];
    const classeListNeutre = ["D", "E"];
    const classeType = classeListPositive.includes(houseInfo?.ClasseEnergetica)
      ? "positivo"
      : classeListNeutre.includes(houseInfo?.ClasseEnergetica)
      ? "neutro"
      : "negativo";
    filtersArray.push({
      charId: `single_select_${classeType}_houseInfo?.ClasseEnergetica`,
      value: `Classe energetica ${houseInfo?.ClasseEnergetica}`,
      label: `Classe energetica ${houseInfo?.ClasseEnergetica}`,
      type: classeType,
    });

    const today = new Date();
    const year = today.getFullYear();
    const annoType =
      year - houseInfo?.AnnoCostruzione <= 20
        ? "positivo"
        : houseInfo?.AnnoCostruzione > 20 && houseInfo?.AnnoCostruzione < 50
        ? "neutro"
        : "negativo";
    filtersArray.push({
      charId: `single_select_${annoType}_houseInfo?.AnnoCostruzione`,
      value: `Età del palazzo`,
      label: `Età del palazzo`,
      type: annoType,
    });

    const ascensoreType =
      houseInfo?.Ascensore === "Si"
        ? "positivo"
        : houseInfo?.Ascensore === "No" && houseInfo?.Piano >= 2
        ? "negativo"
        : "negativo";
    filtersArray.push({
      charId: `single_select_${ascensoreType}_houseInfo?.Ascensore`,
      value: "Ascensore",
      label: ascensoreType == "negativo" ? "Nessun ascensore" : "Ascensore",
      type: ascensoreType,
    });

    const ariaType =
      houseInfo?.SistemaAriaCondizionata === "Autonomo" ||
      houseInfo?.SistemaAriaCondizionata === "Centralizzato"
        ? "positivo"
        : houseInfo?.SistemaAriaCondizionata === "Predisposizione"
        ? "neutro"
        : "negativo";
    filtersArray.push({
      charId: `single_select_${ariaType}_houseInfo?.SistemaAriaCondizionata`,
      value: `Sistema aria condizionata ${houseInfo?.SistemaAriaCondizionata}`,
      label: `Sistema aria condizionata ${houseInfo?.SistemaAriaCondizionata}`,
      type: ariaType,
    });

    const riscaType =
      houseInfo?.Riscaldamento === "Autonomo" ? "positivo" : "negativo";
    filtersArray.push({
      charId: `single_select_${riscaType}_houseInfo?.Riscaldamento`,
      value: `Riscaldamento ${houseInfo?.Riscaldamento}`,
      label: `Riscaldamento ${houseInfo?.Riscaldamento}`,
      type: riscaType,
    });

    const postoType = !houseInfo?.PostoAuto?.includes("Nessuno")
      ? "positivo"
      : "negativo";
    filtersArray.push({
      charId: `single_select_${postoType}_houseInfo?.PostoAuto`,
      value: `Posto auto ${houseInfo?.PostoAuto?.join(", ")}`,
      label:
        postoType == "negativo"
          ? "Nessun posto auto"
          : `Posto auto ${houseInfo?.PostoAuto?.join(", ")}`,
      type: postoType,
    });

    if (
      houseInfo?.GiardinoPrivato === "Si" ||
      houseInfo?.GiardinoCondominiale === "Si"
    ) {
      filtersArray.push({
        charId: `single_select_positivo_houseInfo?.GiardinoPrivato`,
        value: `Giardino`,
        label: `Giardino`,
        type: "positivo",
      });
    } else {
      filtersArray.push({
        charId: `single_select_negativo_noverde`,
        value: `Nessun spazio verde`,
        label: `Nessun spazio verde`,
        type: "negativo",
      });
    }

    if (houseInfo?.Balconi === "Si" || houseInfo?.Terrazzo === "Si") {
      filtersArray.push({
        charId: `single_select_positivo_Balconi`,
        value: `Balconi/terrazzi`,
        label: `Balconi / terrazzi`,
        type: "positivo",
      });
    } else {
      filtersArray.push({
        charId: `single_select_negativo_nobalcone`,
        value: `Nessun terrazzo o balcone`,
        label: `Nessun terrazzo o balcone`,
        type: "negativo",
      });
    }

    if (houseInfo?.EsposizioneEsterna === "No") {
      filtersArray.push({
        charId: `single_select_negativo_EsposizioneEsterna`,
        value: `Nessuna esposizione esterna`,
        label: `Nessuna esposizione esterna`,
        type: "negativo",
      });
    }

    setSelectOptions(filtersArray);
  }, [houseInfo]);

  // SET AVAILABLE SELECT OPTIONS (CHARACTERISTIC VALUES) BASED ON TOTAL SELECT OPTIONS MINUS AGENT CHARACTERISTICS FROM DATABASE
  useEffect(() => {
    setCharacteristicsValues(
      selectOptions.filter(
        (option) =>
          !agentCharacteristics
            ?.map((item) => item.characteristic)
            ?.includes(option.value)
      )
    );
  }, [selectOptions, agentCharacteristics]);

  // SET SELECTED OPTIONS (CARDS) BASED ON TOTAL SELECT OPTIONS MINUS AVAILABLE SELECT OPTIONS
  const cardsValues = selectOptions
    .filter(
      (option) =>
        !new Set(characteristicsValues.map((item) => item.charId)).has(
          option.charId
        )
    )
    .sort((a, b) => a.type.localeCompare(b.type));
  // console.log(cardsValues, "cardsValues");

  return { characteristicsValues, setCharacteristicsValues, cardsValues };
};

export default useSelectsSetter;
