import actionCreator from "../../../utils/actionCreator";

const adminActionTypes = [
  "GET_USERS_BY_EMAIL_OR_NAME",
  "GET_REPORTS_BY_ADDRESS",
  "GET_USER_REPORTS",
  "GET_USER_TRANSACTIONS",
  "GET_ALL_REPORTS",
  "MODIFY_USER_CREDITS",
  "MODIFY_SUBSCRIPTION_DATE",
  "MODIFY_SUBSCRIPTION_TYPE",
  "END_SUBSCRIPTION",
];

export const setPlatform = (platform) => ({
  type: "SET_PLATFORM",
  payload: platform,
});
export const setTypeSearch = (typeSearch) => ({
  type: "SET_TYPE_SEARCH",
  payload: typeSearch,
});

export const setFormValue = (formValue) => ({
  type: "SET_FORM_VALUE",
  payload: formValue,
});

export const { types, actions } = actionCreator(adminActionTypes);
