import styles from "./CheckboxField.module.scss";

const CheckboxField = ({
  field,
  id,
  label,
  labelStyle,
  form,
  paddingBottom,
  padding,
  gap,
  width,
  ...props
}) => {
  const isError = form.touched[field.name] && form.errors[field.name];

  return (
    <>
      <label
        className={styles["checkbox__wrapper"]}
        style={{ padding, gap, width }}
      >
        <input
          {...field}
          id={id}
          type="checkbox"
          value={id}
          className={styles["checkbox__field"]}
          {...props}
        />
        <span style={labelStyle}>{label}</span>
      </label>
      {isError && <div className={styles.error}>{form.errors[field.name]}</div>}
    </>
  );
};

export default CheckboxField;
