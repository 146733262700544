import React from "react";
import styles from "./ChartsTooltip.module.scss";

const ChartsTooltip = ({
  active,
  payload,
  label,
  type,
  labelAdditionalTitle,
  labelAdditionalContent,
  isPyramid,
}) => {
  // console.log(payload, "payload");
  if (active && payload && payload.length) {
    return (
      <div className={styles.custom_tooltip}>
        <b className={styles.custom_tooltip__title}>
          {label} {labelAdditionalTitle}
        </b>
        <div className={styles.custom_tooltip__content}>
          {payload?.map((item, index) => (
            <React.Fragment key={index}>
              <div>
                <span
                  className={
                    item?.className == "weak-bar"
                      ? styles.weak_bar
                      : item?.className == "strong-bar"
                      ? styles.strong_bar
                      : ""
                  }
                  style={{
                    backgroundColor:
                      type == "areaChart" ? item.stroke : item.fill,
                  }}
                />{" "}
                {item.dataKey} :{" "}
                <b>
                  {item.value.toLocaleString("it-IT")}
                  {labelAdditionalContent}
                </b>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default ChartsTooltip;
