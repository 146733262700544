import React from "react";
import formatToEuro from "../../../../utils/formatToEuro";
import ProgressBar from "../../../Common/ProgressBar";
import styles from "./UserPrice.module.scss";

const UserPrice = ({ prezzo_utente_gpt, prezzo_utente }) => {
  const probVenditaCopy = () => {
    if (prezzo_utente_gpt?.proba_180 > 0.84) {
      return "molto alta";
    } else if (
      prezzo_utente_gpt?.proba_180 > 0.59 &&
      prezzo_utente_gpt?.proba_180 < 0.85
    ) {
      return "accettabile";
    } else if (
      prezzo_utente_gpt?.proba_180 > 0.39 &&
      prezzo_utente_gpt?.proba_180 < 0.6
    ) {
      return "moderata";
    } else if (
      prezzo_utente_gpt?.proba_180 > 0.19 &&
      prezzo_utente_gpt?.proba_180 < 0.4
    ) {
      return "bassa";
    } else if (prezzo_utente_gpt?.proba_180 < 0.2) {
      return "molto bassa";
    }
    return "moderata";
  };

  return (
    <div className={styles.user_price}>
      <h3 className={styles.user_price__header}>
        Analisi della tua valutazione
      </h3>
      {/* PRICE */}
      <div className={styles.user_price__price}>
        <div className={styles.user_price__price__number}>
          <p className={styles.value}>€{formatToEuro(prezzo_utente)}</p>
          <p className={styles.probability}>
            Hai una probabilità <b>{probVenditaCopy()} </b>
            <br />
            di vendere entro
            <b> 6 mesi</b>
          </p>
        </div>
        <div className={styles.user_price__price__probability}>
          <ProgressBar
            percent={Math.round(prezzo_utente_gpt?.proba_180 * 100) || "0"}
            rotateBarDeg={90}
            rotateTextDeg={-90}
            pointerText=""
            scale={0.9}
            // left={"-87px"}
            display={"inline-block"}
            // position={"absolute"}
          />
        </div>
      </div>

      {/* TEXT */}
      <div className={styles.user_price__text}>
        {prezzo_utente_gpt?.description}
      </div>
    </div>
  );
};

export default UserPrice;
