import styles from "./ActionCheckboxButton.module.scss";

const CheckboxButton = ({ id, zIndex, top, right, callback, checked }) => {
  // console.log(checked, "checked");
  return (
    <label
      className={styles["checkbox__wrapper"]}
      style={{
        zIndex,
        top,
        right,
      }}
    >
      <input
        id={id}
        type="checkbox"
        value={id}
        className={styles["checkbox__field"]}
        checked={checked}
        onClick={callback ? callback : null}
      />
    </label>
  );
};

export default CheckboxButton;
