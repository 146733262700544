import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as EyeIcon } from "../../../../../assets/svg/eye.svg";
import { ReactComponent as ReportIcon } from "../../../../../assets/svg/report.svg";
import { appendFilterListToColumns } from "../../../../../helpers";
import { getLoader } from "../../../../../shared/Loader/store/selectors";
import useWindowSize from "../../../../../utils/useWindowSize";
import { DeleteReportModal } from "../../../../CreateReport/containers/CreateReportModals";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import DataTableFooter from "../DataTableFooter";
import OptionsTableHeadCell from "../OptionsTableHeadCell";
import styles from "./DataTableCustomizedAdmin.module.scss";
import useQueryCallback from "../../../../Admin/containers/ResultTable/utils/useQueryCallback";
import { actions } from "../../../../Admin/store/actions";
import alertToast from "../../../../../shared/Error/AlertToast";

const DataTableCustomizedAdmin = ({
  tableData,
  tableColumns,
  flag,
  rowsPerPageNum = 10,
  uniqueKey,
  filterOption = true,
  selectableRowsOption = false,
  paginationOption = true,
  noMatchOption = true,
}) => {
  // console.log(tableData, "tableData");
  const dispatch = useDispatch();
  const loader = useSelector(getLoader());
  const { deleteReport, endSubscription, deleteReportFromUserReports } =
    useQueryCallback();
  const [isDeleteReportModalOpen, setIsDeleteReportModalOpen] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [userId, setUserId] = useState(null);
  // console.log(userId, "userId");
  const [isFiltersOpen, setIsFilterOpen] = useState(false);
  const [filterList, setFilterList] = useState(
    JSON.parse(localStorage.getItem(`filtersListAdmin${uniqueKey}`))
  );
  const [sortingOptions, setSortingOptions] = useState(
    JSON.parse(localStorage.getItem(`sortingOptionsAdmin${uniqueKey}`))
  );
  const [isReset, setReset] = useState(false);
  const { isMobile } = useWindowSize();

  const columns = [
    ...tableColumns?.map((column) => ({
      name: column?.name,
      label: column?.label,
      options: {
        filter: column?.filter,
        sort: column?.sort,
        setCellHeaderProps: () =>
          column?.centered && { className: "centeredHeader" },
        setCellProps: () =>
          column?.centered && !isMobile && { className: "centeredColumn" },
        customBodyRender: (data) =>
          column?.customBodyRender ? column?.customBodyRender(data) : data,
      },
    })),
    {
      name: "options",
      label: "",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: "centeredHeader" }),
        setCellProps: () => !isMobile && { className: "centeredColumn" },
        customHeadRender: () => (
          <OptionsTableHeadCell
            key={tableColumns?.length}
            filterOption={filterOption}
            setIsFilterOpen={setIsFilterOpen}
            resetOptions={() => setReset(true)}
          />
        ),
      },
    },
  ];

  useEffect(() => {
    if (JSON.parse(localStorage.getItem(`searchTextAdmin${uniqueKey}`))) {
      setIsFilterOpen(true);
    }

    if (uniqueKey) {
      setFilterList(
        JSON.parse(localStorage.getItem(`filtersListAdmin${uniqueKey}`))
      );
      setSortingOptions(
        JSON.parse(localStorage.getItem(`sortingOptionsAdmin${uniqueKey}`))
      );
    }
  }, [uniqueKey]);

  const options = {
    filterType: "checkbox",
    filter: filterOption,
    sort: true,
    sortOrder: sortingOptions || {},
    searchOpen: true,
    responsive: "vertical",
    selectableRows: selectableRowsOption ? "multiple" : "none",
    tableBodyMaxHeight: "auto",
    rowsPerPage: rowsPerPageNum,
    rowsPerPageOptions: [],
    searchText: JSON.parse(localStorage.getItem(`searchTextAdmin${uniqueKey}`))
      ? JSON.parse(localStorage.getItem(`searchTextAdmin${uniqueKey}`))
      : "",
    pagination: paginationOption,
    onRowClick: (e) => {
      (flag == "reports" || flag == "userReports") &&
        window.open(e[tableColumns.length].props.to, "_self");
    },
    onTableChange: (action, tableState) => {
      if (action === "propsUpdate") {
        if (isReset) {
          setFilterList(false);
          setSortingOptions(false);
          localStorage.removeItem(`searchTextAdmin${uniqueKey}`);
          localStorage.removeItem(`filtersListAdmin${uniqueKey}`);
          localStorage.removeItem(`sortingOptionsAdmin${uniqueKey}`);
          tableState.filterList = tableState.filterList.map((list) => []);
          tableState.sortOrder = {};
          setReset(false);
        }
      }
      if (action === "filterChange") {
        setFilterList(tableState.filterList);
        localStorage.setItem(
          `filtersListAdmin${uniqueKey}`,
          JSON.stringify(tableState.filterList)
        );
        return;
      }
      if (action === "search") {
        localStorage.setItem(
          `searchTextAdmin${uniqueKey}`,
          JSON.stringify(tableState.searchText)
        );
        return;
      }
      if (action === "sort") {
        const fieldName = columns[tableState.activeColumn].name;
        const direction = tableState.announceText.includes("descending")
          ? "desc"
          : "asc";
        const option = {
          name: fieldName,
          direction,
        };

        localStorage.setItem(
          `sortingOptionsAdmin${uniqueKey}`,
          JSON.stringify(option)
        );
        setSortingOptions(option);
        return;
      }
    },
    customSort: (data, colIndex, client) =>
      data.sort((a, b) => {
        const aVal = a.data[colIndex];
        const bVal = b.data[colIndex];

        if (aVal < bVal) {
          return -1 * (client === "desc" ? -1 : 1);
        } else if (aVal > bVal) {
          return 1 * (client === "desc" ? -1 : 1);
        } else {
          return 0;
        }
      }),
    textLabels: {
      body: {
        noMatch: !loader && noMatchOption && (
          <div className={styles.empty_table}>
            <h2 key="empty-table-1">Nessun risultato trovato</h2>
          </div>
        ),
      },
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) =>
      paginationOption ? (
        <DataTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      ) : (
        false
      ),
  };

  const handleDeleteButtonClick = (item) => {
    // console.log(item, "item");
    setIsDeleteReportModalOpen(true);
    setItemId(item.id);
    flag == "userReports" && setUserId(item.user_id);
  };

  return (
    <div>
      {tableData && (
        <MUIDataTable
          className={isFiltersOpen ? "withFilters" : "noFilters"}
          key={uniqueKey}
          data={
            tableData?.length > 0
              ? tableData.map((item, index) => {
                  // console.log(item, "item");
                  return {
                    ...item,
                    options: (
                      <div
                        style={{
                          display: "flex",
                          columnGap: 10,
                        }}
                        className={styles.table_data_options}
                        key={`tabledata${index}`}
                      >
                        {flag == "reports" || flag == "userReports" ? (
                          <Link to={`/report/${item?.id}`}>
                            <EyeIcon className="eye-icon" />
                          </Link>
                        ) : flag == "users" ? (
                          <ReportIcon
                            className="eye-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(
                                actions.GET_USER_REPORTS.REQUEST(
                                  item.id,
                                  (data) => {
                                    if (!data.length) {
                                      alertToast(
                                        "warning",
                                        "Attenzione",
                                        "Nessun report trovato per l'utente selezionato"
                                      );
                                    }
                                  }
                                )
                              )
                            }
                          />
                        ) : null}
                        <DeleteOutlineIcon
                          style={{ fontSize: "18px" }}
                          viewBox="1.5 2.5 24 24"
                          onClick={() => handleDeleteButtonClick(item)}
                          className={styles.copy}
                        />
                      </div>
                    ),
                  };
                })
              : []
          }
          columns={appendFilterListToColumns(columns, filterList)}
          options={options}
        />
      )}
      <DeleteReportModal
        isDeleteReportModalOpen={isDeleteReportModalOpen}
        setIsDeleteReportModalOpen={setIsDeleteReportModalOpen}
        itemId={itemId}
        flag={flag}
        callback={() => {
          flag == "users"
            ? endSubscription({ userId: itemId })
            : flag == "reports"
            ? deleteReport(itemId)
            : flag == "userReports"
            ? deleteReportFromUserReports(itemId, userId)
            : null;
        }}
      />
    </div>
  );
};

export default DataTableCustomizedAdmin;
