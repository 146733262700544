import React from "react";
import styles from "./FirstPg.module.scss";
import buildAddressString from "../../../../utils/buildAddress";
import platformConditionals from "../../../../utils/platformConditionals";

const FirstPg = ({ houseInfo, creationDate }) => {
  const houseAddress = buildAddressString(houseInfo);
  const reversedDate = creationDate?.split("-")?.reverse()?.join("-");

  return (
    <div className={styles.first_page_wrapper}>
      <div
        className={
          styles[platformConditionals()?.bannerPdfClass || "first_page_img"]
        }
      />
      {process.env.REACT_APP_PLATFORM === "BAKECA" && (
        <img
          src={platformConditionals()?.logo}
          alt="logo"
          className={styles.first_page_bakeca_logo}
        />
      )}
      {houseAddress && (
        <div className={styles.first_page_header}>
          <div className={styles.title}>Stima immobiliare</div>
          <div className={styles.address}>
            <div>{houseInfo?.Tipologia}</div>
            <div className={styles.hr} />
            <div>{houseAddress}</div>
          </div>
          <div className={styles.subtitle}>
            Stima realizzata il {reversedDate}
          </div>
        </div>
      )}
    </div>
  );
};

export default FirstPg;
