import styles from "./LeftSide.module.scss";

const LeftSide = ({ omi }) => {
  return (
    <div className={styles.left__side}>
      <div className={styles.item}>
        <p className={styles.title}>Regione</p>
        <p className={styles.text}>
          {omi?.regione || "Comune non disponibile"}
        </p>
      </div>
      <div className={styles.item}>
        <p className={styles.title}>Zona OMI</p>
        <p className={styles.text}>{omi?.omi || "Dati omi non disponibili"}</p>
      </div>
      <div className={styles.item}>
        <p className={styles.title}>Tipologia immobile</p>
        <p className={styles.text}>{omi?.tipologia}</p>
      </div>
    </div>
  );
};

export default LeftSide;
