import { useEffect } from "react";

const useRemoveScroll = (el, cond) => {
  useEffect(() => {
    if (cond != 0) {
      el.style.overflow = "hidden";
    } else {
      el.style.overflow = "auto";
    }

    return () => {
      el.style.overflow = "auto";
    };
  }, [cond]);
};

export default useRemoveScroll;
