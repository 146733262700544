import clx from "classnames";
import React from "react";

import InflationChange from "../../../AlgorithmReport/containers/Economics/containers/InflationChange";
import NoChartsData from "../../../AlgorithmReport/containers/NoChartsData";
import CommonCard from "../../../AlgorithmReport/containers/SocioDemographicArea/containers/CommonCard";
import styles from "./NinthPg.module.scss";

const NinthPg = ({ inflation, inflationChange, bce, pdfItems }) => {
  // console.log(inflationChange);
  const firstYear = inflationChange?.graph_data?.data?.y[0];
  const lastYear =
    inflationChange?.graph_data?.data?.y[
      inflationChange?.graph_data?.data?.y.length - 1
    ];
  const firstYearAmount = inflationChange?.graph_data?.data?.x?.[0];
  const lastYearAmount =
    inflationChange?.graph_data?.data?.x?.[
      inflationChange?.graph_data?.data?.y.length - 1
    ];
  const difference = lastYearAmount - firstYearAmount;
  const differencePercent = (difference / firstYearAmount) * 100;
  const changeType =
    (difference / firstYearAmount) * 100 >= 0 ? "incremento" : "decremento";

  const extractMonthName = (dateString) => {
    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];
    const numericMonth = Number(dateString?.split("-")?.[1]);
    const year = dateString?.split("-")?.[0];

    return `${months[numericMonth - 1]} ${year}`;
  };

  return (
    <div className={clx(styles.area__services__wrapper)}>
      <h3 className={styles.header__text}>Informazioni economiche</h3>
      <p className={styles.secondary__text}>
        Esplora l&apos;andamento economico tramite grafici intuitivi che
        evidenziano informazioni chiave.
      </p>
      <div className={styles.area__services__main}>
        {!pdfItems?.["inflation"] && (
          <div className={clx(styles.inflation, styles.general__card)}>
            {inflation?.graph_status == "OK" ? (
              <CommonCard
                header={inflation?.graph_data?.title}
                description={inflation?.graph_data?.description}
                content={inflation?.graph_data?.data?.displayed_value}
                isPdf={true}
              />
            ) : (
              <NoChartsData
                title={"Inflazione"}
                fontSize={"12px"}
                padding={"15px 25px"}
                minHeight={"105px"}
                marginTop={"5px"}
              />
            )}
          </div>
        )}
        {!pdfItems?.["bce"] && (
          <div className={clx(styles.bce, styles.general__card)}>
            {bce?.graph_status == "OK" ? (
              <CommonCard
                header={bce?.graph_data?.title}
                description={bce?.graph_data?.description}
                content={bce?.graph_data?.data?.displayed_value}
                isPdf={true}
              />
            ) : (
              <NoChartsData
                title={"Tasso BCE"}
                fontSize={"12px"}
                padding={"15px 25px"}
                minHeight={"105px"}
                marginTop={"5px"}
              />
            )}
          </div>
        )}
        {!pdfItems?.["inflation_change"] && (
          <div
            className={clx(styles.inflation_change, styles.general__card)}
            style={{ position: "relative" }}
          >
            <div className={styles.chart_info}>
              L’inflazione a <b>{firstYear}</b> era del{" "}
              <b>{firstYearAmount}%</b>, rispetto al <b>{lastYearAmount}%</b> di{" "}
              <b>{lastYear}</b>, con un <b>{changeType}</b> del{" "}
              <b>{difference.toFixed(2)}%</b>
            </div>
            <div>
              {inflationChange?.graph_status == "OK" ? (
                <InflationChange
                  data={inflationChange?.graph_data}
                  minHeight={300}
                  wrapperHeight={"450px"}
                  paddingTop={"30px"}
                  chartMarginTop={"50px"}
                  isPdf={true}
                />
              ) : (
                <NoChartsData title={"Andamento Inflazione"} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default NinthPg;
