import React, { forwardRef } from "react";
import styles from "./SoldProperties.module.scss";
import SectionTitle from "../SectionTitle";

import Form from "./Form";
import Table from "./Table";
import BuyCta from "../BuyCta";

const SoldProperties = forwardRef(
  (
    {
      prezzo,
      prezzoMq,
      privateUserHasReport,
      userIsPrivate,
      agencyUserHasSubscription,
    },
    ref
  ) => {
    return (
      <div className={styles.sold} ref={ref}>
        <SectionTitle
          title={"Immobili similari venduti"}
          subtitle={
            "Di seguito una selezione di alcuni immobili simili a quello analizzato venduti in precedenza"
          }
          section={"sold_properties"}
          isDisabled={!userIsPrivate && !agencyUserHasSubscription}
        />

        {(userIsPrivate && !privateUserHasReport) ||
        (!userIsPrivate && !agencyUserHasSubscription) ? (
          <BuyCta />
        ) : (
          <>
            {/* HERE PUT TABLE WITH INSERTED PROPERTIES */}
            <Table prezzo={prezzo} prezzoMq={prezzoMq} />

            {/* HERE OPEN FORMS */}
            <Form />
          </>
        )}
      </div>
    );
  }
);

export default SoldProperties;
