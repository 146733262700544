import React from "react";
import styles from "./Selects.module.scss";
import ReactToolTip from "../../../../Common/ToolTip/ReactToolTip";
import SingleSelect from "../../../../Common/SingleSelect";
import { ReactComponent as InfoIcon } from "../../../../../assets/svg/info.svg";

const Selects = ({
  characteristicsValues,
  setCharacteristicsValues,
  isChangingCharacteristics,
  setIsChangingCharacteristics,
}) => {
  return (
    <>
      <div className={styles.selects_tooltip}>
        <ReactToolTip
          contenet={
            <div style={{ color: "gray" }}>
              Le caratteristiche selezionate appariranno nel PDF{" "}
            </div>
          }
          containerClass="tooltipStyleChange"
          x={-87}
          y={70}
          width={289}
          height={77}
          arrow={{
            x: -3,
            y: 74,
            rotate: "206deg",
          }}
        >
          <InfoIcon />
        </ReactToolTip>
        <p className={styles.selects_tooltip__text}>
          Inserisci le caratteristiche rilevanti
        </p>
      </div>
      {/* SELECTS */}
      <div className={styles.selects} style={{ zIndex: 1000 }}>
        {/* CARATTERISTICHE POSITIVE */}
        <SingleSelect
          label="Caratteristiche positive"
          options={characteristicsValues?.filter(
            (item) => item.type === "positivo"
          )}
          value=""
          placeholder="Aggiungi al PDF"
          hideBaseConfiguration={true}
          positive={true}
          width={"calc(100% / 3)"}
          onChange={(data) => {
            setCharacteristicsValues(
              characteristicsValues.filter((item) => item !== data)
            );
            if (!isChangingCharacteristics) {
              setIsChangingCharacteristics(true);
            }
          }}
          isDisabled={
            !characteristicsValues?.filter((item) => item.type === "positivo")
              .length
          }
        />
        {/* CARATTERISTICHE NEUTRE */}
        <SingleSelect
          label="Caratteristiche neutre"
          options={characteristicsValues?.filter(
            (item) => item.type === "neutro"
          )}
          value=""
          placeholder="Aggiungi al PDF"
          hideBaseConfiguration={true}
          neutral={true}
          width={"calc(100% / 3)"}
          onChange={(data) => {
            setCharacteristicsValues(
              characteristicsValues.filter((item) => item !== data)
            );
            if (!isChangingCharacteristics) {
              setIsChangingCharacteristics(true);
            }
          }}
          isDisabled={
            !characteristicsValues?.filter((item) => item.type === "neutro")
              .length
          }
        />
        {/* CARATTERISTICHE NEGATIVE */}
        <SingleSelect
          label="Caratteristiche negative"
          options={characteristicsValues?.filter(
            (item) => item.type === "negativo"
          )}
          value=""
          placeholder="Aggiungi al PDF"
          hideBaseConfiguration={true}
          negative={true}
          width={"calc(100% / 3)"}
          onChange={(data) => {
            setCharacteristicsValues(
              characteristicsValues.filter((item) => item !== data)
            );
            if (!isChangingCharacteristics) {
              setIsChangingCharacteristics(true);
            }
          }}
          isDisabled={
            !characteristicsValues?.filter((item) => item.type === "negativo")
              .length
          }
        />
      </div>
    </>
  );
};

export default Selects;
