import actionCreator from "../../../utils/actionCreator";

const ReportActionTypes = [
  "GET_ALL_USER_HOUSES",
  "GET_HOUSE_BY_ID",
  "GET_REPORT_NEW",
  "ADD_HOUSE",
  "UPDATE_PDF_ITEMS",
  "GET_PDF_ITEMS",
  "GET_HIDDEN_COMPARED_ITEMS",
  "UPDATE_HIDDEN_COMPARED_ITEMS",
  "ADD_SOLD_PROPERTY",
  "GET_SOLD_PROPERTIES",
  "DELETE_SOLD_PROPERTY",
  "SET_PDF_SIMULATOR",
  "DELETE_PDF_SIMULATOR",
  "GET_PDF_SIMULATOR",
  "SET_AGENT_CHARACTERISTICS",
  "GET_AGENT_CHARACTERISTICS",
  "GET_GALLERY",
  "DELETE_IMAGE",
  "EDIT_IMAGE_FIELDS",
  "FEATURED_IMAGE",
  "UPLOAD_IMAGE",
  "CHANGE_ORDER",
  "CHECK_USER_CREDITS",
  "GET_REPORT_DOWNLOAD",
  "DELETE_HOUSE",
  "DEMO_CONTACT",
  "CHECK_BOUGHT_REPORT",
  // "UPDATE_HOUSE",
  // "OMI_INFO",
];

export const { types, actions } = actionCreator(ReportActionTypes);
