import { all } from "redux-saga/effects";

import { authSaga } from "../Containers/Auth/store/";
import { paymentsAndCreditsSaga } from "../Containers/PaymentsAndCredits/store";
import { reportSaga } from "../Containers/Report/store";
import { adminSaga } from "../Containers/Admin/store";

export default function* rootSaga() {
  yield all([authSaga(), reportSaga(), paymentsAndCreditsSaga(), adminSaga()]);
}
