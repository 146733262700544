import React, { useState, forwardRef } from "react";
import SectionTitle from "../SectionTitle";
import CopyIcon from "../../../../assets/svg/copy.svg";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import styles from "./Dante.module.scss";
import BuyCta from "../BuyCta";

const Dante = forwardRef(
  (
    { dante, privateUserHasReport, userIsPrivate, agencyUserHasSubscription },
    ref
  ) => {
    const [tooltip, setTooltip] = useState("Clicca per copiare");

    const copy = async (text) => {
      setTooltip("Copiato!");
      setTimeout(() => {
        setTooltip("Clicca per copiare");
      }, 1000);
      await navigator.clipboard.writeText(text);
    };

    return (
      <div className={styles["container-inner"]} ref={ref}>
        {/* HEADER */}
        <SectionTitle
          title={"Dante AI"}
          subtitle={
            "La nostra Intelligenza Artificiale DANTE ha creato il miglior annuncio di vendita per questo immobile in meno di 0.4 secondi. Copia e incolla l'annuncio sui portali immobiliari, così da aumentare l'efficacia di vendita e risparmiando il 90% di tempo nella creazione di annuncio."
          }
          section={"dante"}
          isDisabled={true}
          isChecked={false}
          toolText={"Questo campo non è disponibile nel PDF."}
        />
        {/* DANTE TEXTS */}
        {(userIsPrivate && !privateUserHasReport) ||
        (!userIsPrivate && !agencyUserHasSubscription) ? (
          <BuyCta />
        ) : (
          <div className={styles.copy_wrapper}>
            {Object.keys(dante || {})?.map((key, index) => (
              <div className={styles.copy} key={`dante-${index}`}>
                <p className={styles.copy__body}>{dante?.[key]}</p>
                <div className={styles.copy__footer}>
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext} id="myTooltip">
                      {tooltip}
                    </span>
                    <PrimaryButton
                      width="250px"
                      height="60px"
                      leftIconSrc={CopyIcon}
                      text="Copia descrizione"
                      onClick={() => copy(dante?.[key])}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
);

export default Dante;
