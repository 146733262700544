import actionCreator from "../../../utils/actionCreator";

const SignInActionTypes = [
  "SIGN_UP",
  "SIGN_IN",
  "FETCH_USER",
  "FETCH_PUPPETEER_USER",
  "LOG_OUT",
  "FORGOT_PASSWORD",
  "RESET_PASSWORD",
  "EDIT_ACCOUNT",
  "CHANGE_PASSWORD",
  "RESET_PASSWORD",
  "ADD_LOGO_AGENCY",
];

export const { types, actions } = actionCreator(SignInActionTypes);
