const platformCheck = (owner) => {
  switch (owner) {
    case 1:
      return "HOUSEPLUS";
    case 2:
      return "MONDORED";
    case 3:
      return "BAKECA";
    default:
      return "";
  }
};

export default platformCheck;
