import React from "react";
import { ROUTES } from "../../../../../../routes/constants";
import styles from "./Price.module.scss";
import { useNavigate } from "react-router-dom";
import CrossIcon from "../../../../../../assets/svg/cross.svg";
import formatToEuro from "../../../../../../utils/formatToEuro";
import ProgressBar from "../../../../../Common/ProgressBar";

const Price = ({ model }) => {
  const navigate = useNavigate();

  const probVenditaCopy = () => {
    if (model?.proba_180 > 0.84) {
      return "molto alta";
    } else if (model?.proba_180 > 0.59 && model?.proba_180 < 0.85) {
      return "accettabile";
    } else if (model?.proba_180 > 0.39 && model?.proba_180 < 0.6) {
      return "moderata";
    } else if (model?.proba_180 > 0.19 && model?.proba_180 < 0.4) {
      return "bassa";
    } else if (model?.proba_180 < 0.2) {
      return "molto bassa";
    }
    return "moderata";
  };

  return (
    <div className={styles.content}>
      {/* CLOSE REPORT BTN */}
      <div
        className={styles.cross__wrapper}
        onClick={() => navigate(ROUTES.REPORT)}
      >
        <img src={CrossIcon} alt="cross-icon" />
      </div>
      {/* PRICE ESTIMATION */}
      <div className={styles.assumptions}>
        {/* HEADER */}
        <div className={styles.assumptions__header}>
          {/* TITLE */}
          <p className={styles.assumptions__header__title}>
            Stima reale dell&apos;immobile
          </p>
          {/* MAIN PRICE */}
          <div className={styles.assumptions__header__price}>
            {/* MAIN PRICE PRICES */}
            <div className={styles.assumptions__header__price__values}>
              {/* MAIN */}
              <p className={styles.assumptions__header__price__values__main}>
                € {formatToEuro(model?.price || 0)}
              </p>
              <div
                className={styles.assumptions__header__price__values__div_head}
              />
              {/* SQUARE METER */}
              <p className={styles.assumptions__header__price__values__square}>
                {/* € */}€<span>/m</span>
                <sup>2</sup> {formatToEuro(model?.price_m2 || 0)}
              </p>
            </div>
            {/* MAIN PRICES TEXT */}
            <div className={styles.assumptions__header__price__text}>
              Hai una probabilità <b>{probVenditaCopy()}</b> di vendita entro{" "}
              <b>6 mesi</b>
            </div>
          </div>
        </div>
        {/* PRICES */}
        <div className={styles.assumptions__stima}>
          {[
            {
              price: model?.price_min,
              priceM2: model?.price_min_m2,
              pct: model?.proba_60,
              title: "Stima minima",
              prob_text: "alla stima minima",
            },
            {
              price: model?.price_max,
              priceM2: model?.price_max_m2,
              pct: model?.proba_360,
              title: "Stima massima",
              prob_text: "alla stima massima",
            },
            {
              title: "Permanenza media sul mercato",
              prob_text: "giorni sul mercato",
              avg_gsm: model?.avg_gsm,
            },
          ].map((item, index, array) => (
            <div className={styles.price_data} key={`proba-cards-${index}`}>
              <div className={styles.price_data__div_head} />
              <div className={styles.price_data__title}>{item.title}</div>
              {index != array.length - 1 ? (
                <div className={styles.price_data__cards}>
                  <div className={styles.price_data__cards__consigliato}>
                    <p className={styles.price_data__cards__consigliato__price}>
                      € {formatToEuro(item?.price || 0)}
                    </p>

                    <div
                      className={
                        styles.price_data__cards__consigliato__div_head
                      }
                    />

                    <p
                      className={styles.price_data__cards__consigliato__priceM2}
                    >
                      €/<span>m</span>
                      <sup>2</sup>{" "}
                      {formatToEuro(item?.priceM2?.toFixed(0) || 0)}
                    </p>
                  </div>
                  <div className={styles.price_data__cards__probabilita}>
                    <p>
                      Probabilità di vendita <br /> {item.prob_text}
                    </p>
                    <div
                      className={styles.price_data__cards__probabilita__card}
                    >
                      <ProgressBar
                        percent={Math.round(item.pct * 100) || "0"}
                        rotateBarDeg={90}
                        rotateTextDeg={-90}
                        pointerText=""
                        scale={0.5}
                        left={"-87px"}
                        display={"inline-block"}
                        position={"absolute"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.price_data__gsm}>
                  {model?.avg_gsm} {item.prob_text}
                </div>
              )}
            </div>
          ))}
        </div>
        {/* TEXT */}
        <div className={styles.assumptions__text}>{model?.description}</div>
      </div>
    </div>
  );
};

export default Price;
