import { createSelector } from "reselect";

const selectReportState = (state) => state.reportReducer;

export const getUserHouses = () =>
  createSelector(selectReportState, (state) => state.userHouses);
export const getActiveHouse = () =>
  createSelector(selectReportState, (state) => state.activeHouse);
export const getReportNew = () =>
  createSelector(selectReportState, (state) => state.reportNew || {});
export const getReportRadarChart = () =>
  createSelector(
    selectReportState,
    (state) => state.reportNew?.radar_chart || {}
  );
export const getReportOmi = () =>
  createSelector(selectReportState, (state) => state.reportNew?.omi_info || {});
export const getReportCharts = () =>
  createSelector(selectReportState, (state) => state.reportNew?.charts || {});
export const getReportServices = () =>
  createSelector(selectReportState, (state) => state.reportNew?.services || {});
export const getProbabilityNew = () =>
  createSelector(
    selectReportState,
    (state) => state.reportNew?.configuratore || {}
  );
export const getPdfItems = () =>
  createSelector(selectReportState, (state) => state.pdfItemsNew);
export const getHiddenComparedItems = () =>
  createSelector(selectReportState, (state) => state.hiddenComparedItems);
export const getSoldProperties = () =>
  createSelector(selectReportState, (state) => state.soldProperties);
export const getPdfSimulator = () =>
  createSelector(selectReportState, (state) => state.pdfSimulator);
export const getAgentCharacteristics = () =>
  createSelector(
    selectReportState,
    (state) => state.agentCharacteristics || []
  );
export const getGallery = () =>
  createSelector(selectReportState, (state) => state.gallery);
export const getBoughtReport = () =>
  createSelector(selectReportState, (state) => state.boughtReport);
// export const getOmiInfo = () =>
//   createSelector(selectReportState, (state) => state.omiInfo);
