import React from "react";
import styles from "../SearchBar.module.scss";
import clx from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { setTypeSearch, setPlatform } from "../../../store/actions";
import { getPlatform, getTypeSearch } from "../../../store/selectors";

const Filters = () => {
  const dispatch = useDispatch();
  const platform = useSelector(getPlatform());
  const typeSearch = useSelector(getTypeSearch());
  // console.log(platform, "platform");
  // console.log(typeSearch, "typeSearch");

  return (
    <>
      <div className={clx(styles.admin_search_bar__filter, styles.platforms)}>
        <div
          onClick={() => dispatch(setPlatform(platform === 1 ? 0 : 1))}
          className={clx(
            styles.admin_search_bar__filter__btn,
            styles.houseplus,
            { [styles.active]: platform === 1 }
          )}
        >
          Houseplus
        </div>
        <div
          onClick={() => dispatch(setPlatform(platform === 2 ? 0 : 2))}
          className={clx(
            styles.admin_search_bar__filter__btn,
            styles.mondored,
            { [styles.active]: platform === 2 }
          )}
        >
          Mondored
        </div>
        <div
          onClick={() => dispatch(setPlatform(platform === 3 ? 0 : 3))}
          className={clx(styles.admin_search_bar__filter__btn, styles.bakeca, {
            [styles.active]: platform === 3,
          })}
        >
          Bakeca
        </div>
      </div>
      <div className={styles.admin_search_bar__filter}>
        <div
          onClick={() => dispatch(setTypeSearch("user"))}
          className={clx(
            styles.admin_search_bar__filter__btn,
            styles.type_search,
            { [styles.active]: typeSearch === "user" }
          )}
        >
          Cerca utente
        </div>
        <div
          onClick={() => dispatch(setTypeSearch("report"))}
          className={clx(
            styles.admin_search_bar__filter__btn,
            styles.type_search,
            { [styles.active]: typeSearch === "report" }
          )}
        >
          Cerca report
        </div>
      </div>
    </>
  );
};

export default Filters;
