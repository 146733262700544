import clx from "classnames";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import chartStyles from "../../../../../Common/Charts/Charts.module.scss";
import styles from "./ProbabilitySaleChart.module.scss";
import platformConditionals from "../../../../../../utils/platformConditionals";
import "./ProbaChart.scss";

const ProbabilitySaleChart = ({ selected_curves, base_curve, isMobile }) => {
  const [linesToShow, setLinesToShow] = useState();
  const lines = [
    { name: "Probabilità molto alta", color: "#E7FDE7" },
    { name: "Probabilità accettabile", color: "#F1FDE7" },
    { name: "Probabilità moderata", color: "#FDFAE7" },
    { name: "Probabilità bassa", color: "#FDF2E8" },
    { name: "Probabilità molto bassa", color: "#FCE8EA" },
  ];

  const [options, setOptions] = useState({
    series: [],
    tooltip: {
      enabled: true,
      y: {
        formatter: (value, opts) => value + "%",
      },
    },
    chart: {
      zoom: {
        enabled: true,
        type: "x",
        autoScaleXaxis: true,
      },
      toolbar: {
        show: false,
      },
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillTo: "end",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "ligth",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.4,
        opacityTo: 0.1,
        stops: [0, 40, 100],
        colorStops: [],
      },
    },
    noData: {
      text: "L'AI sta caricando le tue probabilità di vendita",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    markers: {
      size: 5,
      strokeColors: "#fff",
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: platformConditionals()?.colorsProbaChart || [],
    legend: {
      show: true,
    },
    xaxis: {
      axisBorder: {
        show: true,
        color: "#78909C",
        height: 0.2,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "dotted",
        color: "#78909C",
        height: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    grid: {
      show: true,
      borderColor: "#90A4AE55",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    yaxis: {
      min: (min) => {
        const intervals = [80, 60, 40, 20, 0];
        const lines = intervals.find((el) => el <= min);
        if (min <= 100)
          setLinesToShow(intervals.findIndex((el) => el === lines));
        return lines;
      },
      max: 100,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          cssClass: "apexcharts-yaxis-label",
        },
        offsetX: -10,
        offsetY: 0,
        rotate: 0,
        formatter: (val) => val + "%",
      },
    },
  });

  useEffect(() => {
    let values = {
      series: [{ data: [] }, { data: [] }],
      xaxis: {
        type: "numeric",
        categories: [],
        labels: {},
      },
    };

    base_curve?.forEach((value) => {
      values.series[0].data.push(value[1] * 100);
      //values.xaxis.categories.push(value[0]);
    });

    // Add more curves to base with a value of 0, if array length shorter than selected curves
    const curvesLengthDifference = selected_curves?.length - base_curve?.length;
    if (curvesLengthDifference > 0) {
      for (let i = 0; i < curvesLengthDifference; i++) {
        values.series[0].data.push(0);
      }
    }

    selected_curves?.forEach((value) => {
      values.series[1].data.push(value[1] * 100);
      values.xaxis.categories.push(value[0]);
    });
    // console.log(values.series[1].data, "values.series[1].data");
    // console.log(selected_curves, "selected_curves");
    // console.log(base_curve, "base_curve");

    values = {
      series: [
        {
          name: "Probabilità di vendita in base alla stima reale",
          data: [...values.series[0].data],
        },
        {
          name: "Probabilità di vendita in base alle opzioni selezionate",
          data: [...values.series[1].data],
        },
      ],
      xaxis: {
        ...values.xaxis,
        categories: [...values.xaxis.categories, ""],
        crosshairs: {
          show: true,
          width: 1,
          position: "back",
        },
        // tickAmount: 10,
        labels: {
          show: true,
          rotate: 0,
          style: {
            cssClass: "apexcharts-xaxis-label",
          },
          formatter: (value) => {
            return `€ ${value
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
          },
          tickPlacement: "on",
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (value, opts) => value + "%",
        },
        custom({ series, seriesIndex, dataPointIndex, w }) {
          // console.log(series[1][dataPointIndex], "series[1][dataPointIndex]");
          let htmlContent =
            '<div class="chart_menu_box">' +
            '<span class="title_head">Valutazione di<br/></span>' +
            '<span  class="val_head">' +
            w.globals.categoryLabels[dataPointIndex] +
            "<br/></span>" +
            '<span  class="val_head">';

          // Check if series[0][dataPointIndex] is present
          if (series[0][dataPointIndex] !== undefined) {
            htmlContent +=
              '<div class="val_serie_box  mdodici">' +
              '<span class="title_head">Probabilità di vendita in base alla stima reale<br/></span>' +
              "<span>" +
              series[0][dataPointIndex]?.toFixed(0) +
              "%</span>" +
              "</div>";
          }

          if (series[1][dataPointIndex] !== undefined) {
            htmlContent +=
              '<div class="val_serie_box msei">' +
              '<span class="title_head">Probabilità di vendita in base alle opzioni selezionate<br/></span>' +
              "<span>" +
              series[1][dataPointIndex]?.toFixed(0) +
              "%</span>" +
              "</div>";
          }

          return htmlContent + "</div>";
        },
      },
    };

    if (values && values.series[0].data[0])
      setOptions((option) => ({
        ...option,
        ...values,
      }));
  }, [setOptions, selected_curves, base_curve]);

  return (
    <div className={`${chartStyles.chart__wrapper} ${styles.chart__wrapper}`}>
      <div
        className={chartStyles.chart__titles_head}
        style={{ marginBottom: "40px" }}
      >
        <b>Analisi Prezzo - Probabilità di vendita</b>
        <p style={{ marginTop: "5px" }}>
          Osserva come variano i prezzi e le probabilità di vendita.
        </p>
      </div>
      <div className={styles.chart__box}>
        <div
          className={clx(styles.chart__probabilities, {
            [styles.chart__probabilities__mbl]: isMobile,
          })}
        >
          {linesToShow !== undefined &&
            lines.slice(0, linesToShow + 1).map(({ name, color }, index) => (
              <div key={index} className={styles.probability__item}>
                <span
                  className={styles.probability__color}
                  style={{ backgroundColor: color }}
                />
                <div className={styles.probability__text}>
                  <span>{name}</span>
                </div>
              </div>
            ))}
        </div>
        <div className={clx(styles.chart, "proba_chart")}>
          <Chart
            options={options}
            series={options.series || []}
            width="100%"
            type="area"
            height="400px"
            style={{ minWidth: 2000 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProbabilitySaleChart;

//valutazione dell'utente mark
// let _flagMyPrice = [
//   {
//     x: "",
//     borderColor: "",
//     label: {
//       style: {
//         color: "",
//       },
//       text: "",
//     },
//   },
// ];

// var _point = [
//   {
//     x: "",
//     y: 60,
//     seriesIndex: 2,
//     label: {
//       borderColor: "",
//       offsetY: 0,
//       style: {
//         color: "",
//         background: "",
//       },
//       text: "",
//     },
//   },
// ];

// _point = [];

// if (value.show_point) {

// console.log(values, "values proba chart pre conversion");
// }

// if (value.proba_60) {
//   _point.push({
//     x: `€ ${Math.round(value.prezzo)
//       ?.toString()
//       .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`,
//     y: Math.round(value.proba_60 * 100),
//     seriesIndex: 1,
//     label: {
//       borderColor: "#66ACBE",
//       offsetX: 0,
//       style: {
//         color: "#F3FDFF",
//         background: "#66ACBE",
//       },
//       text: "",
//     },
//     marker: {
//       size: 0,
//       fillColor: "#fff",
//       strokeColor: "#333",
//       strokeWidth: 0,
//       shape: "circle",
//       radius: 0,
//       OffsetX: 0,
//       OffsetY: 0,
//       cssClass: "",
//     },
//     image: {
//       path: "https://assets-web-hp.s3.eu-south-1.amazonaws.com/tag2mesi.svg",
//       width: 20,
//       height: 20,
//       offsetX: -12,
//       offsetY: 12,
//     },
//   });
// }

// if (value.proba_180) {
//   _point.push({
//     x: `€ ${Math.round(value.prezzo)
//       ?.toString()
//       .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`,
//     y: Math.round(value.proba_180 * 100),
//     seriesIndex: 1,
//     offsetX: -30,
//     label: {
//       borderColor: "#66ACBE",
//       offsetX: 0,
//       style: {
//         color: "#F3FDFF",
//         background: "#66ACBE",
//       },
//       text: "",
//     },
//     marker: {
//       size: 0,
//       fillColor: "#fff",
//       strokeColor: "#333",
//       strokeWidth: 0,
//       shape: "circle",
//       radius: 0,
//       OffsetX: 0,
//       OffsetY: 0,
//       cssClass: "",
//     },
//     image: {
//       path: "https://assets-web-hp.s3.eu-south-1.amazonaws.com/tag6mesi.svg",
//       width: 20,
//       height: 20,
//       offsetX: -14,
//       offsetY: 12,
//     },
//   });
// }

// if (value.proba_360) {
//   _point.push({
//     x: `€ ${Math.round(value.prezzo)
//       ?.toString()
//       .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`,
//     y: Math.round(value.proba_360 * 100),
//     seriesIndex: 1,
//     offsetX: -30,
//     label: {
//       borderColor: "#66ACBE",
//       offsetX: 0,
//       style: {
//         color: "#F3FDFF",
//         background: "#66ACBE",
//       },
//       text: "",
//     },
//     marker: {
//       size: 0,
//       fillColor: "#fff",
//       strokeColor: "#333",
//       strokeWidth: 0,
//       shape: "circle",
//       radius: 0,
//       OffsetX: 0,
//       OffsetY: 0,
//       cssClass: "",
//     },
//     image: {
//       path: "https://assets-web-hp.s3.eu-south-1.amazonaws.com/tag12mesi.svg",
//       width: 20,
//       height: 20,
//       offsetX: -14,
//       offsetY: 12,
//     },
//   });
// }

// console.log(values.annotations.points, "values.annotations.points");
// console.log(
//   w.globals.categoryLabels[dataPointIndex],
//   "w.globals.categoryLabels[dataPointIndex]"
// );
// console.log(series[1][dataPointIndex], "series[1][dataPointIndex]");
// console.log(series[2][dataPointIndex], "series[2][dataPointIndex]");
// console.log(series, "series");
// var consigliato = "";
// if (
//   values.annotations.points[0].x ==
//   w.globals.categoryLabels[dataPointIndex]
// ) {
//   consigliato =
//     '<div class="consigliato_box">Prezzo consigliato per vendere <br/>entro 2 mesi</div>';
// } else if (
//   values.annotations.points[1].x ==
//   w.globals.categoryLabels[dataPointIndex]
// ) {
//   consigliato =
//     '<div class="consigliato_box">Prezzo consigliato per vendere <br/>entro 6 mesi</div>';
// } else if (
//   values.annotations.points[2].x ==
//   w.globals.categoryLabels[dataPointIndex]
// ) {
//   consigliato =
//     '<div class="consigliato_box">Prezzo consigliato per vendere <br/>entro 12 mesi</div>';
// }

// annotations: {
//   points: _point,
//   xaxis: _flagMyPrice,
// },
