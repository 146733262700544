import { types } from "./actions";

const initialState = {
  userHouses: [],
  activeHouse: {},
  reportNew: {},
  pdfItemsNew: {},
  hiddenComparedItems: [],
  soldProperties: [],
  pdfSimulator: {},
  agentCharacteristics: [],
  gallery: {
    loading: false,
    data: [],
  },
  boughtReport: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_USER_HOUSES.SUCCESS:
      return {
        ...state,
        userHouses: action.payload,
      };
    case types.GET_HOUSE_BY_ID.SUCCESS:
      return {
        ...state,
        activeHouse: action.payload,
      };
    case types.GET_HOUSE_BY_ID.CLEAR:
      return initialState;
    case types.GET_REPORT_NEW.SUCCESS:
      return {
        ...state,
        reportNew: action.payload,
      };
    case types.GET_PDF_ITEMS.SUCCESS:
      return {
        ...state,
        pdfItemsNew: action.payload,
      };
    case types.GET_HIDDEN_COMPARED_ITEMS.SUCCESS:
      return {
        ...state,
        hiddenComparedItems: action.payload,
      };
    case types.GET_SOLD_PROPERTIES.SUCCESS:
      return {
        ...state,
        soldProperties: action.payload,
      };
    case types.GET_PDF_SIMULATOR.SUCCESS:
      return {
        ...state,
        pdfSimulator: action.payload,
      };
    case types.GET_AGENT_CHARACTERISTICS.SUCCESS:
      return {
        ...state,
        agentCharacteristics: action.payload,
      };
    case types.GET_GALLERY.LOADING:
    case types.FEATURED_IMAGE.LOADING:
    case types.EDIT_IMAGE_FIELDS.LOADING:
    case types.DELETE_IMAGE.LOADING:
    case types.UPLOAD_IMAGE.LOADING:
    case types.CHANGE_ORDER.LOADING:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          loading: true,
        },
      };
    case types.GET_GALLERY.FAILED:
    case types.FEATURED_IMAGE.FAILED:
    case types.EDIT_IMAGE_FIELDS.FAILED:
    case types.DELETE_IMAGE.FAILED:
    case types.UPLOAD_IMAGE.FAILED:
    case types.CHANGE_ORDER.FAILED:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          loading: false,
        },
      };
    case types.DELETE_IMAGE.SUCCESS:
      return {
        ...state,
        gallery: {
          loading: false,
          data: state.gallery.data.filter((i) => i.id !== action.payload),
        },
      };
    case types.CHANGE_ORDER.SUCCESS:
      return {
        ...state,
        gallery: {
          loading: false,
          data: action.payload,
        },
      };
    case types.UPLOAD_IMAGE.SUCCESS:
      return {
        ...state,
        gallery: {
          loading: false,
          data: [...state.gallery.data, action.payload],
        },
      };
    case types.GET_GALLERY.SUCCESS:
      return {
        ...state,
        gallery: {
          loading: false,
          data: action.payload,
        },
      };
    case types.EDIT_IMAGE_FIELDS.SUCCESS:
      return {
        ...state,
        gallery: {
          loading: false,
          data: state.gallery.data.map((i) =>
            i.id !== action.payload[0].id ? i : action.payload[0]
          ),
        },
      };
    case types.FEATURED_IMAGE.SUCCESS:
      return {
        ...state,
        gallery: {
          loading: false,
          data: [
            action.payload[0],
            ...state.gallery.data.filter((i) => i.id !== action.payload[0].id),
          ],
        },
      };
    case types.CHECK_BOUGHT_REPORT.SUCCESS:
      return {
        ...state,
        boughtReport: action.payload,
      };
    default:
      return { ...state };
  }
};
