import clx from "classnames";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import homepal from "../../../../assets/images/partner/homepal.png";
import lazioInnova from "../../../../assets/images/partner/lazioInnova.png";
import luiss from "../../../../assets/images/partner/luiss.png";
import lventure from "../../../../assets/images/partner/lventure.png";
import Sagoma from "../../../../assets/svg/sagoma.svg";
import { ROUTES } from "../../../../routes/constants";
import useWindowSize from "../../../../utils/useWindowSize";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import TextField from "../../../Common/TextField";
import { actions } from "../../store";
import styles from "./SignIn.module.scss";
import platformConditionals from "../../../../utils/platformConditionals";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile, windowHeight } = useWindowSize();

  const initialValues = {
    email: "",
    pwd: "",
  };

  const validationSchema = {
    email: yup
      .string("Inserisci la tua email")
      .email("Inserisci un email valida")
      .required("Email richiesta"),
    pwd: yup
      .string()
      .min(3, "Password troppo corta")
      .max(100, "Password troppo lunga")
      .required("Password richiesta"),
  };
  return (
    <div
      className={clx(styles.container__inner, {
        [styles["container__inner-mobile"]]: isMobile,
      })}
    >
      <div
        className={clx(styles["login__container"], {
          [styles["login__container-mobile"]]: isMobile,
        })}
        style={{ backgroundColor: "white" }}
      >
        <div className={styles["login-form__image"]}>
          <img
            src={platformConditionals()?.logo || ""}
            alt="logo"
            onClick={() => (window.location.href = "https://www.houseplus.it/")}
          />
        </div>
        <div className={styles["login-form__wrapper"]}>
          <h1>Accedi</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={yup.object().shape(validationSchema)}
            onSubmit={async (values) => {
              dispatch(
                actions.SIGN_IN.REQUEST(values, () => navigate(ROUTES.REPORT))
              );
            }}
          >
            {({ errors, touched }) => (
              <Form className={styles["login__form"]}>
                <Field
                  name="email"
                  id="email"
                  label="Email"
                  placeholder="Email"
                  component={TextField}
                  type="email"
                />
                <Field
                  name="pwd"
                  id="pwd"
                  label="Password"
                  placeholder="Password"
                  showPassword={true}
                  component={TextField}
                  type="password"
                />
                <Link
                  to={`${ROUTES.FORGOT_PASSWORD}`}
                  className={styles["forgot-pwd__link"]}
                >
                  Hai dimenticato la password?
                </Link>
                <PrimaryButton width="100%" height="60px" text="Accedi" />
              </Form>
            )}
          </Formik>
        </div>
        <div className={styles.inner__link}>
          <p>{"Non hai un Account?"}</p>
          <Link className={styles.link} to={`${ROUTES.SIGN_UP}`}>
            {"Registrati"}
          </Link>
        </div>
      </div>

      {!isMobile && (
        <div
          className={styles.right_div}
          style={{
            backgroundImage: "url(" + Sagoma + ")",
          }}
        >
          {/* <img src={Sagoma} /> */}
          {process.env.REACT_APP_PLATFORM == "HOUSEPLUS" && (
            <div style={{ width: "100%" }}>
              <div className={styles.partner}>
                <img src={lventure} style={{ marginLeft: 20 }} />
                <img src={lazioInnova} style={{ marginLeft: 20 }} />
                <img src={homepal} style={{ marginLeft: 20 }} />
                <img src={luiss} style={{ marginLeft: 20 }} />
              </div>
              <div className={styles.trust_copy}>
                Trusted by Top-tier product campanies
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
