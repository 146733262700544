import React from "react";
import styles from "./ResultTable.module.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getUsers, getReports, getUserReports } from "../../store/selectors";
import { TABLE_KEYS } from "../../../Common/DataTables/constants";
import DataTableCustomizedAdmin from "../../../Common/DataTables/containers/DataTableCustomizedAdmin";
import {
  tableColumnsReports,
  tableColumnsUser,
  userData,
  reportData,
} from "./utils/useTableSpecs";
import useQueryCallback from "./utils/useQueryCallback";

const ResultTable = () => {
  const { pathname } = useLocation();
  const users = useSelector(getUsers());
  const reports = useSelector(getReports());
  const userReports = useSelector(getUserReports());
  // console.log(users, "users");
  // console.log(reports, "reports");
  // console.log(userReports, "userReports");
  const { modifySubscription, modifySubscriptionType } = useQueryCallback();

  if (!users.length && !reports.length && !userReports.length) return null;

  return (
    <div className={styles.result_table}>
      {(users.length || reports.length) > 0 && (
        <DataTableCustomizedAdmin
          rowsPerPageNum={8}
          tableData={
            users.length
              ? userData(users, modifySubscription, modifySubscriptionType)
              : reports.length
              ? reportData(reports)
              : []
          }
          uniqueKey={`${pathname}/ ${TABLE_KEYS.ADMIN}`}
          tableColumns={
            users.length
              ? tableColumnsUser
              : reports.length
              ? tableColumnsReports
              : []
          }
          flag={users.length ? "users" : reports.length ? "reports" : ""}
        />
      )}
      {userReports.length > 0 && (
        <div className={styles.result_table}>
          <h1 style={{ marginBottom: "10px" }}>
            Reports per {userReports?.[0]?.email}
          </h1>
          <DataTableCustomizedAdmin
            rowsPerPageNum={8}
            tableData={reportData(userReports)}
            uniqueKey={`${pathname}/ ${TABLE_KEYS.ADMIN}`}
            tableColumns={tableColumnsReports}
            flag={"userReports"}
          />
        </div>
      )}
    </div>
  );
};

export default ResultTable;
