import { createSelector } from "reselect";

const paymentsAndCreditsState = (state) => state.paymentsAndCreditsReducer;

export const getUserPortal = () =>
  createSelector(paymentsAndCreditsState, (state) => state.userPortal);
export const getTransactions = () =>
  createSelector(paymentsAndCreditsState, (state) => state.transactions);
export const getTransactionsNew = () =>
  createSelector(paymentsAndCreditsState, (state) => state.transactionsNew);
export const getUserCredits = () =>
  createSelector(paymentsAndCreditsState, (state) => state.credits);
export const getUserBoughtReports = () =>
  createSelector(paymentsAndCreditsState, (state) => state.reportAcquistati);
export const getUserSubscriptionHouseplus = () =>
  createSelector(
    paymentsAndCreditsState,
    (state) => state.isSubscribedHouseplus
  );
export const getUserSubscriptionMondo = () =>
  createSelector(paymentsAndCreditsState, (state) => state.isSubscribedMondo);
// export const getProducts = () => createSelector(paymentsAndCreditsState, state => state.products);
