import React, { useState } from "react";
import styles from "./Form.module.scss";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { INITIAL_VALUES, VALIDATION_SCHEMA, FORM_FIELDS } from "../constants";
import { actions } from "../../../../Report/store";

import { ReactComponent as AddIcon } from "../../../../../assets/svg/add.svg";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../../Common/Buttons/SecondaryButton";
import IconButton from "../../../../Common/Buttons/IconButton";
import TextField from "../../../../Common/TextField";
import SelectField from "../../../../Common/SelectField";
import handleInput from "../../../../CreateReport/utils/handleInput";

const SoldForm = () => {
  const dispatch = useDispatch();
  const { reportId } = useParams();
  // console.log(reportId, "id sold form");
  const [isForm, setIsForm] = useState(false);

  return (
    <div className={styles.sold_form}>
      {isForm ? (
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={(values) => {
            // console.log(values, "values sold form");
            // Create a copy of values for manipulation
            let submitValues = values;
            // Remove non-numeric characters from the Prezzo field
            submitValues.Prezzo = submitValues.Prezzo.replace(/\D/g, "");
            submitValues.PrezzoMq = submitValues.PrezzoMq.replace(/\D/g, "");
            submitValues.Distanza = submitValues.Distanza.replace(/\D/g, "");
            submitValues.Superficie = submitValues.Superficie.replace(
              /\D/g,
              ""
            );
            if (submitValues.ClasseEnergetica == "Sconosciuta") {
              submitValues.ClasseEnergetica = "G";
            }
            // console.log(submitValues, "submitValues sold form");
            // Dispatch an action with the modified values
            dispatch(
              actions.ADD_SOLD_PROPERTY.REQUEST(
                { submitValues, reportId },
                () => {
                  dispatch(
                    actions.GET_SOLD_PROPERTIES.REQUEST(reportId, () => {
                      setIsForm(false);
                    })
                  );
                }
              )
            );
          }}
        >
          {({ errors, values, setFieldValue }) => (
            // console.log(errors, "errors sold form"),
            // console.log(values, "values sold form"),
            <Form className={styles.sold_form__formik}>
              <div className={styles.sold_form__formik__fields}>
                {FORM_FIELDS?.map((option) => {
                  if (option.isTextField) {
                    return (
                      <Field
                        name={option.fieldName}
                        id={option.fieldName}
                        label={option.labelProperty}
                        component={TextField}
                        borderRadius="5px"
                        onKeyUp={(e) => {
                          handleInput(e, option, setFieldValue);
                        }}
                        labelHeight={"auto"}
                        labelStyle={{ marginBottom: "0px" }}
                        marginBottomInputGroup={"0px"}
                        // height={"40px"}
                        classNameInherit={styles.sold_form__formik__field}
                        key={`${option.fieldName}-text-sold`}
                      />
                    );
                  } else if (option.isSelectField) {
                    return (
                      <Field
                        name={option.fieldName}
                        id={option.fieldName}
                        label={option.labelProperty}
                        options={option.acceptValues}
                        component={SelectField}
                        borderRadius="5px"
                        labelHeight={"auto"}
                        labelStyle={{ marginBottom: "0px" }}
                        marginBottomInputGroup={"0px"}
                        classNameInherit={styles.sold_form__formik__field}
                        key={`${option.fieldName}-select-sold`}
                      />
                    );
                  }
                })}
              </div>
              <div className={styles.sold_form__formik__actions}>
                <SecondaryButton
                  width="100%"
                  height="50px"
                  fontSize="20px"
                  text="Cancella"
                  onClick={() => setIsForm(false)}
                />
                <PrimaryButton
                  width="100%"
                  height="50px"
                  fontSize="20px"
                  text="Aggiungi"
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className={styles.sold_form__add}>
          <IconButton
            height="75px"
            width="75px"
            padding="25px"
            borderRadius="50%"
            onClick={() => setIsForm(true)}
            iconSrc={AddIcon}
          />
        </div>
      )}
    </div>
  );
};

export default SoldForm;
