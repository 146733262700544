import clx from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import menu from "../../../assets/svg/menu.svg";
import useOutsideClick from "../../../utils/useOutsideClick";
import useWindowSize from "../../../utils/useWindowSize";
import { actions } from "../../Auth/store";
import { getUser } from "../../Auth/store/selectors";
import SideBar from "../../SideBar";
import Footer from "../Footer";
import MobileHeader from "../MobileHeader";
import styles from "./PageWrapper.module.scss";

const PageWrapper = ({ children, flexDirection }) => {
  const params = useParams();
  const { pathname } = useLocation();
  const user = useSelector(getUser());
  const [isMobileSideBarOpen, setIsMobileSideBarOpen] = useState(false);
  const { isMobile } = useWindowSize();
  const sideBarRef = useRef();

  useOutsideClick(sideBarRef, () => {
    if (isMobileSideBarOpen) setIsMobileSideBarOpen(false);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.FETCH_USER.REQUEST());
  }, [dispatch]);

  useEffect(() => {
    if (isMobileSideBarOpen) setIsMobileSideBarOpen(false);
  }, [children]);

  return (
    <>
      <div
        className={clx(styles["page-wrapper"], {
          [styles["page-wrapper-mobile"]]: isMobile,
        })}
        style={{ flexDirection }}
      >
        {/* {!params?.reportId && !isMobile && pathname !== '/report/demo' && <div ref={sideBarRef}><SideBar isMobile={isMobile} user={user} isMobileSideBarOpen={isMobileSideBarOpen} /></div>} */}
        {/* {!params?.reportId && pathname !== '/report/demo' && <div ref={sideBarRef}> */}
        <SideBar
          isMobile={isMobile}
          user={user}
          isMobileSideBarOpen={isMobileSideBarOpen}
        />
        {/* </div>} */}
        {/* {isMobile && <MobileHeader setIsMobileSideBarOpen={setIsMobileSideBarOpen} />} */}
        {/* {
					isMobile &&
						<div ref={sideBarRef}>
							<SideBar isMobile={isMobile} user={user} isMobileSideBarOpen={isMobileSideBarOpen} />
						</div>
				} */}
        <div className={styles.section__wrapper}>
          <section>{children}</section>
        </div>
      </div>
    </>
  );
};

export default PageWrapper;
