import { createTheme } from "@material-ui/core/styles";
import platformConditionals from "../utils/platformConditionals";

const muiTheme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: "none",
        backgroundColor: platformConditionals()?.reportTableWhite,

        "&.noFilters": {
          "& .MuiToolbar-root": {
            display: "none",
          },
          "& .MuiTableHead-root tr": {
            "& th:first-child": {
              borderRadius: "8px 0 0 0",
            },
            "& th:last-child": {
              borderRadius: "0 8px 0 0",
            },
          },
        },
        "&.withFilters": {
          "& .MuiTableHead-root": {
            display: "table-header-group",
          },
        },
      },
      elevation4: {
        boxShadow: "none",
      },
      rounded: {
        borderRadius: "0",
      },
    },
    MuiTableCell: {
      root: {
        "&.centeredHeader": {
          "& span": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& div": {
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          },
        },
        "&.centeredColumn": {
          textAlign: "center",
          whiteSpace: "nowrap",
        },
      },
      footer: {
        display: "flex",
        justifyContent: "center",
        borderRadius: "0 0 8px 8px",
        borderBottom: "none",
      },
    },
    MUIDataTableHeadCell: {
      root: {
        "& > div": {
          color: platformConditionals()?.reportTableColor,
        },
      },
      data: {
        fontWeight: "600",
        fontSize: "15px",
        color: platformConditionals()?.reportTableColor,
      },
      fixedHeader: {
        backgroundColor: platformConditionals()?.reportTableWhite,

        "& > div": {
          fontWeight: "600",
          fontSize: "17px",
          color: platformConditionals()?.reportTableColor,
        },
      },
      sortActive: {
        color: platformConditionals()?.reportTableColor,
      },
      toolButton: {
        "&:hover": {
          backgroundColor: platformConditionals()?.reportTableColorAction,
          color: platformConditionals()?.reportTableColor,

          "& *": {
            color: platformConditionals()?.reportTableColor,
          },
        },
      },
      sortAction: {
        display: "flex",
        alignItems: "center",

        "& > div": {
          fontWeight: "600",
          fontSize: "17px",
          color: platformConditionals()?.reportTableColor,
        },

        "& svg path": {
          color: platformConditionals()?.reportTableColor,
        },
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: platformConditionals()?.reportTableWhite,

        "& svg path": {
          color: platformConditionals()?.reportTableColor,
        },
      },
    },
    MUIDataTableBodyCell: {
      root: {
        fontSize: "14px",
      },
    },
    MuiTableRow: {
      hover: {
        "&:hover": {
          backgroundColor: `${
            platformConditionals()?.reportTableColorAction
          } !important`,
        },
      },
    },
    MUIDataTableToolbar: {
      root: {
        backgroundColor: platformConditionals()?.reportTableWhite,
        borderRadius: "8px 8px 0 0",
      },
      filterPaper: {
        maxWidth: "20%",
        "& > div": {
          paddingTop: "48px",
        },
      },
      actions: {
        "& svg path": {
          color: platformConditionals()?.reportTableColor,
        },
      },
      icon: {
        "&:hover": {
          backgroundColor: `${
            platformConditionals()?.reportTableColorAction
          } !important`,
        },
      },
      iconActive: {
        "&:hover": {
          backgroundColor: `${
            platformConditionals()?.reportTableColorAction
          } !important`,
        },
      },
      titleText: {
        fontSize: "24px",
        fontWeight: "600",
      },
    },
    MUIDataTableSearch: {
      main: {
        display: "flex",
        alignItems: "center",

        "& *": {
          color: platformConditionals()?.reportTableColor,
        },
      },
      searchIcon: {
        marginTop: "0",
      },
      clearIcon: {
        "& *": {
          color: platformConditionals()?.reportTableColor,
        },
        "&:hover": {
          backgroundColor: `${
            platformConditionals()?.reportTableColorAction
          } !important`,
        },
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: `2px solid ${platformConditionals()?.reportTableColor}`,
        },
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        backgroundColor: platformConditionals()?.reportTableWhite,

        "& *": {
          color: platformConditionals()?.reportTableColor,
        },
      },
    },
    MUIDataTableFilterList: {
      root: {
        backgroundColor: platformConditionals()?.reportTableWhite,
        margin: "0",
        padding: "0 16px",

        "& svg path": {
          fill: platformConditionals()?.reportTableColor,
        },
      },
      chip: {
        backgroundColor: platformConditionals()?.reportTableColorAction,

        "& span": {
          color: platformConditionals()?.reportTableColor,
          fontSize: "15px",
        },
      },
    },
    MUIDataTableFilter: {
      root: {
        backgroundColor: platformConditionals()?.reportTableWhite,

        "& .MuiGrid-root": {
          maxWidth: "none",
          padding: "0",
          marginTop: "10px",
        },
      },
      title: {
        color: platformConditionals()?.reportTableColor,
      },
      checkboxListTitle: {
        color: platformConditionals()?.reportTableColor,
        fontWeight: "600",
      },
      checkboxFormControlLabel: {
        color: platformConditionals()?.reportTableColor,
      },
      resetLink: {
        "& span": {
          fontWeight: "600",
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: `${
            platformConditionals()?.reportTableColorAction
          } !important`,
        },
      },
    },
    MUIDataTableViewCol: {
      title: {
        color: platformConditionals()?.reportTableColor,
        fontWeight: "600",
        marginBottom: "10px",
      },
    },
  },
});

export default muiTheme;
