import clx from "classnames";
import React from "react";

import AverageEarnings from "../../../AlgorithmReport/containers/Economics/containers/AverageEarnings";
import BceChange from "../../../AlgorithmReport/containers/Economics/containers/BceChange";
import Employment from "../../../AlgorithmReport/containers/Economics/containers/Employment";
import NoChartsData from "../../../AlgorithmReport/containers/NoChartsData";
import styles from "./TenthPg.module.scss";

const TenthPg = ({ bceChange, avgEarnings, employment, pdfItems }) => {
  // console.log(bceChange);
  const firstYear = bceChange?.graph_data?.data?.x[0];
  const lastYear =
    bceChange?.graph_data?.data?.x?.[
      bceChange?.graph_data?.data?.x?.length - 1
    ];
  const firstYearAmount = bceChange?.graph_data?.data?.y?.[0];
  const lastYearAmount =
    bceChange?.graph_data?.data?.y?.[
      bceChange?.graph_data?.data?.y?.length - 1
    ];
  const difference = lastYearAmount - firstYearAmount;
  const differencePercent = (difference / firstYearAmount) * 100;
  const changeType =
    (difference / firstYearAmount) * 100 >= 0 ? "incremento" : "decremento";

  const extractMonthName = (dateString) => {
    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];
    const numericMonth = Number(dateString?.split("-")?.[1]);
    const year = dateString?.split("-")?.[0];
    const day = dateString?.split("-")?.[2];

    return `${months[numericMonth - 1]} ${year}`;
  };

  return (
    <div className={clx(styles.area__services__wrapper)}>
      <h3 className={styles.header__text}>Informazioni economiche</h3>
      <div className={styles.area__services__main}>
        {!pdfItems?.["bce_change"] && (
          <div
            className={clx(styles.bce_change, styles.general__card)}
            style={{ position: "relative" }}
          >
            <div className={styles.chart_info}>
              Il tasso della Banca Centrale Europea a{" "}
              <b>{extractMonthName(lastYear)}</b> è del <b>{lastYearAmount}%</b>{" "}
              con un <b>{changeType}</b> del <b>{difference.toFixed(2)}%</b>{" "}
              rispetto a <b>{extractMonthName(firstYear)}</b>
            </div>
            <div>
              {bceChange?.graph_status == "OK" ? (
                <BceChange
                  data={bceChange?.graph_data}
                  minHeight={300}
                  wrapperHeight={"450px"}
                  paddingTop={"30px"}
                  chartMarginTop={"50px"}
                  isPdf={true}
                />
              ) : (
                <NoChartsData title={"Andamento del tasso BCE"} />
              )}
            </div>
          </div>
        )}
        {!pdfItems?.["avg_earnings"] && (
          <div className={clx(styles.avg_earnings, styles.general__card)}>
            {avgEarnings?.graph_status == "OK" ? (
              <AverageEarnings
                data={avgEarnings?.graph_data}
                marginTopValue={"80px"}
                minHeight={"425px"}
                isPdf={true}
              />
            ) : (
              <NoChartsData title={"Reddito medio"} />
            )}
          </div>
        )}
        {!pdfItems?.["employment"] && (
          <div className={clx(styles.employment, styles.general__card)}>
            {employment?.graph_status == "OK" ? (
              <Employment
                data={employment?.graph_data}
                marginTopValue={"80px"}
                minHeight={"425px"}
                isPdf={true}
              />
            ) : (
              <NoChartsData title={"Tasso di occupazione"} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TenthPg;
