import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../Containers/Auth/store";
import { getUser } from "../Containers/Auth/store/selectors";
import platformCheck from "./platformCheck";

function useAuth() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { type } = useSelector(getUser());

  useEffect(() => {
    dispatch(
      actions.FETCH_USER.REQUEST("", (data) => {
        if (
          data.message != "tokenMissing" &&
          platformCheck(data.data.owner) == process.env.REACT_APP_PLATFORM
        ) {
          dispatch(actions.SIGN_IN.SUCCESS());
        } else {
          setIsLoading(false);
          dispatch(actions.LOG_OUT.SUCCESS());
        }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (type !== null) {
      setIsLoading(false);
    }
  }, [type]);

  return { isLoading };
}

export default useAuth;

// }
// const token = localStorage.getItem("jwtToken");
// if (token) {
//   const decode = jwtDecode(token);
//   const date = new Date().getTime();
//   // change to decode.exp < date
//   if (decode.exp > date) {
//     ocalStorage.removeItem("jwtToken");
//     dispatch(actions.LOG_OUT.SUCCESS());
//     setIsLoading(false);
//   } else {
//   localStorage.setItem("jwtToken", token);
//   dispatch(actions.SIGN_IN.SUCCESS(token));
//   dispatch(actions.FETCH_USER.REQUEST());
//   setIsLoading(false);
//   // }
// } else {
//   setIsLoading(false);
//   dispatch(actions.LOG_OUT.SUCCESS());
// }
