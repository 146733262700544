import clx from "classnames";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import CheckIcon from "../../assets/svg/check.svg";
import CloseIcon from "../../assets/svg/close.svg";
import PencilIcon from "../../assets/svg/pencil.svg";
import useWindowSize from "../../utils/useWindowSize";
import { actions } from "../Auth/store";
import { getUser } from "../Auth/store/selectors";
import PrimaryButton from "../Common/Buttons/PrimaryButton";
import GoogleAutocomplete from "../Common/GoogleAutocomplete";
import TextField from "../Common/TextField";
import TextFieldMockup from "../Common/TextFieldMockup";
import styles from "./Account.module.scss";
import UploadAvatarModal from "./UploadAvatarModal/UploadAvatarModal";

const Account = () => {
  const dispatch = useDispatch();
  const {
    is_private,
    user_name,
    agency_name,
    email,
    addresses,
    piva,
    business_name,
    pec,
    profileImg,
  } = useSelector(getUser());
  const user = useSelector(getUser());
  console.log(user, "USER");
  const [isModifyModeOn, setIsModifyModeOn] = useState(false);
  const { isMobile, windowHeight } = useWindowSize();
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
  const initialValues = {
    agency_name: agency_name || "",
    user_name: user_name || "",
    address: {
      city: addresses?.[0]?.city || "",
      street: addresses?.[0]?.street || "",
      number: addresses?.[0]?.number || "",
      province: addresses?.[0]?.province || "",
      postal_code: addresses?.[0]?.postal_code || "",
      region: addresses?.[0]?.region || "",
      country: addresses?.[0]?.country || "",
      lat: addresses?.[0]?.lat,
      lng: addresses?.[0]?.lng,
    },
    piva: piva || "",
    business_name: business_name || "",
    pec: pec || "",
  };

  const accountFields = [
    {
      label: "Tipo di account",
      value: is_private == 1 ? "Privato" : "Agenzia",
      fieldName: "is_private",
    },
    {
      label: "Nome Account",
      value: (is_private == 1 ? user_name : agency_name) || "",
      fieldName: is_private == 1 ? "user_name" : "agency_name",
      validationSchema: yup
        .string("Inserisce il nome dell'agenzia")
        .required("Il nome dell'agenzia è richiesto"),
    },
    {
      label: "Password",
      value: "",
      fieldName: "oldPassword",
    },
    {
      label: "Email",
      value: email,
      fieldName: "email",
    },
    {
      label: "Indirizzo di fatturazione",
      value:
        `${addresses?.[0]?.street || " - "} ${addresses?.[0]?.number || ""}` +
        ` - ${addresses?.[0]?.city} (${addresses?.[0]?.region || ""}) - ${
          addresses?.[0]?.postal_code || ""
        } `,
      fieldName: "address",
    },
    {
      label: "Partita IVA",
      value: piva || "",
      fieldName: "piva",
      validationSchema: yup
        .string("Enter P.IVA agency")
        .required("P.IVA dell'agenzia è richiesto"),
    },
    {
      label: "Ragione sociale",
      value: business_name || "",
      fieldName: "business_name",
      validationSchema: yup
        .string("Enter Ragione sociale")
        .required("Ragione sociale è richiesto"),
    },
    {
      label: "PEC",
      value: pec || "",
      fieldName: "pec",
      validationSchema: yup.string("Enter PEC").required("PEC è richiesto"),
    },
  ];

  if (is_private == 1) {
    accountFields.splice(4);
  }

  const changePasswordValidationSchema = {
    oldPassword: yup
      .string()
      .min(3, "Password is too short - should be 3 chars minimum")
      .max(100, "Your password is very big!")
      .required("Attenzione! La password è errata"),
    newPassword: yup
      .string()
      .min(3, "Password is too short - should be 3 chars minimum")
      .max(100, "Your password is very big!")
      .required("Attenzione! La password è errata"),
  };

  return (
    <div
      className={clx(styles.account__wrapper, {
        [styles["account__wrapper-mobile"]]: isMobile,
      })}
    >
      <div className={clx(styles.account__wrapper, styles["account-image"])}>
        <div
          className={styles["account-image__img"]}
          onClick={() => setIsUploadFileModalOpen(true)}
        >
          <img
            src={
              profileImg ||
              "https://houseplus-s3-store-prod.s3.eu-south-1.amazonaws.com/profilePic/cd245260-6fca-11ed-bb8e-ddc5cf43a955.png"
            }
            className={styles.profile_pic}
          />
          <div className={clx(styles.modify_btn, styles.edit_pic)}>
            <img src={PencilIcon} alt="edit-pic" />
          </div>
        </div>
        <UploadAvatarModal
          isUploadFileModalOpen={isUploadFileModalOpen}
          setIsUploadFileModalOpen={setIsUploadFileModalOpen}
        />
      </div>
      {accountFields.map((field, idx) => (
        <div key={idx} className={styles.form__wrapper}>
          {isModifyModeOn === field.fieldName ? (
            <Formik
              initialValues={
                field.fieldName === "oldPassword"
                  ? {
                      oldPassword: "",
                      newPassword: "",
                    }
                  : initialValues
              }
              validationSchema={yup.object().shape(
                field.fieldName === "oldPassword"
                  ? changePasswordValidationSchema
                  : {
                      [field.fieldName]: field.validationSchema,
                    }
              )}
              onSubmit={(values) => {
                if (field.fieldName === "oldPassword") {
                  dispatch(
                    actions.CHANGE_PASSWORD.REQUEST(values, () =>
                      setIsModifyModeOn(false)
                    )
                  );
                } else {
                  dispatch(
                    actions.EDIT_ACCOUNT.REQUEST(values, () => {
                      dispatch(actions.FETCH_USER.REQUEST());
                      setIsModifyModeOn(false);
                    })
                  );
                }
              }}
            >
              {() => (
                <Form className={styles["edit-account__form"]}>
                  {field.fieldName === "oldPassword" ? (
                    <div>
                      <Field
                        name={field.fieldName}
                        id={field.fieldName}
                        label={"Inserisci la password attuale"}
                        border="1px solid #078EA6"
                        borderRadius="5px"
                        fontSize="20px"
                        component={TextField}
                        type="password"
                        showPassword={true}
                      />
                      <Field
                        name="newPassword"
                        id="newPassword"
                        type="password"
                        label="Inserisci la nuova password desiderata"
                        component={TextField}
                        border="1px solid #078EA6"
                        borderRadius="5px"
                        fontSize="20px"
                        showPassword={true}
                      />
                    </div>
                  ) : (
                    <Field
                      name={field.fieldName}
                      id={field.fieldName}
                      label={field.label}
                      border="1px solid #078EA6"
                      borderRadius="5px"
                      fontSize="20px"
                      component={
                        field.fieldName === "address"
                          ? GoogleAutocomplete
                          : TextField
                      }
                    />
                  )}
                  <div
                    className={styles.form__actions}
                    style={{
                      marginBottom: field.fieldName === "oldPassword" && 10,
                    }}
                  >
                    <button className={styles.modify_btn} type="submit">
                      <img src={CheckIcon} alt="edit-field" />
                    </button>
                    <div
                      className={styles.modify_btn}
                      style={{ background: "rgba(3, 67, 73, 0.5)" }}
                      onClick={() => setIsModifyModeOn(false)}
                    >
                      <img src={CloseIcon} alt="edit-field" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className={styles.field__wrapper}>
              <TextFieldMockup
                label={field.label}
                value={
                  field.fieldName === "oldPassword"
                    ? "********"
                    : field.value || "-"
                }
                height="auto"
                bgColor="transparent"
                labelSize={isMobile ? "13px" : "18px"}
              />
              {field.fieldName !== "email" && field.fieldName !== "is_private" && (
                <div
                  className={styles.modify_btn}
                  onClick={() => setIsModifyModeOn(field.fieldName)}
                >
                  <img src={PencilIcon} alt="edit-field" />
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      <PrimaryButton
        background="transparent"
        fontSize="13px"
        padding="15px"
        border="2px solid #FC440F"
        color="#FC440F"
        text="Esci da questo Account"
        margin="0 0 0 auto"
        onClick={() => dispatch(actions.LOG_OUT.REQUEST())}
      />
    </div>
  );
};

export default Account;
