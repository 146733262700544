import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actions as actionUser } from "../Auth/store";
import { getUser } from "../Auth/store/selectors";
import { actions } from "../Report/store";
import {
  getActiveHouse,
  getGallery,
  getPdfItems,
  getReportNew,
  getSoldProperties,
  getPdfSimulator,
  getAgentCharacteristics,
} from "../Report/store/selectors";
import CompareSell from "./containers/ComparePg/CompareSell";
import AgentInputs from "./containers/AgentInputs";
import PricingSimulator from "./containers/PricingSimulator";
import EightPg from "./containers/EightPg";
import EleventhPg from "./containers/EleventhPg";
import FifteenthPg from "./containers/FifteenthPg";
import FifthPg from "./containers/FifthPg";
import OmiTable from "./containers/FifthPg/OmiTable";
import FirstPg from "./containers/FirstPg";
import FourteenthPg from "./containers/FourteenthPg";
import NinthPg from "./containers/NinthPg";
import PageHOC from "./containers/PageHOC";
import SecondPg from "./containers/SecondPg";
import SeventeenthPg from "./containers/SeventeenthPg";
import SeventhPg from "./containers/SeventhPg";
import SixteenthPg from "./containers/SixteenthPg";
import SixthPg from "./containers/SixthPg";
import TenthPg from "./containers/TenthPg";
import ThirdPg from "./containers/ThirdPg";
import ThirteenthPg from "./containers/ThirteenthPg";
import TwelfthPg from "./containers/TwelfthPg";
import UserPrice from "./containers/UserPrice";
import styles from "./Download.module.scss";

const Download = () => {
  const dispatch = useDispatch();
  const { reportId } = useParams();
  // console.log(reportId, "REPORT ID");

  const houseInfo = useSelector(getActiveHouse());
  // console.log("houseInfo PDF", houseInfo);
  const report = useSelector(getReportNew());
  // console.log("model", report?.model);
  // console.log("report PDF", report);
  const pdfItems = useSelector(getPdfItems())?.[0];
  // console.log(pdfItems, "DOWNLOAD PDF ITEMS");
  const gallery = useSelector(getGallery());
  // console.log(gallery, "GALLERY PDF");
  const soldProperties = useSelector(getSoldProperties());
  // console.log(soldProperties, "SOLD PROPERTIES PDF");
  const pdfSimulator = useSelector(getPdfSimulator());
  // console.log(pdfSimulator, "PRICING SELECTION PDF");
  const agentCharacteristics = useSelector(getAgentCharacteristics());
  // console.log("agentCharacteristics", agentCharacteristics);

  const {
    agency_name,
    email,
    addresses,
    piva,
    business_name,
    pec,
    profileImg,
    is_private,
  } = useSelector(getUser());

  useEffect(() => {
    dispatch(actionUser.FETCH_USER.REQUEST());
    dispatch(actions.GET_HOUSE_BY_ID.CLEAR());
    dispatch(
      actions.GET_HOUSE_BY_ID.REQUEST(reportId, (data) => {
        dispatch(actions.GET_REPORT_NEW.REQUEST(data));
        dispatch(actions.GET_GALLERY.REQUEST(reportId));
        dispatch(actions.GET_PDF_ITEMS.REQUEST(reportId));
        dispatch(actions.GET_HIDDEN_COMPARED_ITEMS.REQUEST(reportId));
        dispatch(actions.GET_SOLD_PROPERTIES.REQUEST(reportId));
        dispatch(actions.GET_PDF_SIMULATOR.REQUEST(reportId));
        dispatch(actions.GET_AGENT_CHARACTERISTICS.REQUEST(reportId));
      })
    );
    // }
  }, [dispatch]);

  // CREATION DATE FOR ALL PAGES
  const creationDate = houseInfo?.created_at?.split("T")[0];

  // LENGTH OMI AND SERVICES DATAS
  const omiPricesLength = report?.omi_info?.prices.length > 14;
  const services = report?.services;
  const availableServices =
    Object.keys(services ?? {}).filter(
      (key) => services[key]?.services_found?.length
    ).length > 8;
  // console.log(availableServices, "availableServices");
  // console.log(report?.services, "report?.services");

  // ADD PAGE NUMBER DYNAMICALLY TO ALL COMPONENTS THAT ARENT HIDDEN
  const componentRefs = useRef([]);
  useEffect(() => {
    const visibleRefs = componentRefs.current.filter(
      (ref) => ref && !ref.classList.contains(styles.hide)
    );
    visibleRefs?.forEach((ref, index) => {
      if (ref)
        ref.querySelector(`.${styles.footer__number}`).innerText = index + 2;
    });
  });

  // Items to be checked to hide page conditionally
  const pageSixDisplayCheck = [
    "population_change",
    "gender_distribution",
    "population_density",
  ];
  const pageSevenDisplayCheck = ["gender_status", "population_pyramid"];
  const pageEightDisplayCheck = [
    "residents",
    "surface",
    "family",
    "education",
    "foreigners",
  ];
  const pageNineDisplayCheck = ["inflation", "bce", "inflation_change"];
  const pageTenDisplayCheck = ["employment", "bce_change", "avg_earnings"];
  const pageElevenDisplayCheck = ["safety_change", "safety_detail"];

  return (
    <div className={styles.book}>
      {/* FIRST PAGE */}
      <div className={styles.page} style={{ height: "296.80mm" }}>
        <FirstPg houseInfo={houseInfo} creationDate={creationDate} />
      </div>

      {/* SECOND PAGE - PROPERTY CHARACTERISTICS */}
      <PageHOC
        ref={(ref) => (componentRefs.current[0] = ref)}
        date={creationDate}
      >
        <SecondPg
          houseInfo={houseInfo}
          completeSurface={report?.model?.commercial_surface}
        />
      </PageHOC>

      {/* THIRD PAGE - PRICE ANALYSIS */}
      <PageHOC
        ref={(ref) => (componentRefs.current[1] = ref)}
        date={creationDate}
        height="296.70mm"
      >
        <ThirdPg model={report?.model} />
      </PageHOC>

      {/* USER PRICE ANALYSIS */}
      <PageHOC
        ref={(ref) => (componentRefs.current[2] = ref)}
        visibleClass={
          (houseInfo?.Prezzo <= 0 || pdfItems?.["agent_assessment"]) &&
          styles.hide
        }
        date={creationDate}
        height="296.70mm"
      >
        <UserPrice
          prezzo_utente_gpt={report?.prezzo_utente}
          prezzo_utente={houseInfo?.Prezzo}
        />
      </PageHOC>

      {/* COMPARED PROPERTIES */}
      <PageHOC
        ref={(ref) => (componentRefs.current[3] = ref)}
        visibleClass={pdfItems?.["compared_properties"] && styles.hide}
        date={creationDate}
        height="296.80mm"
      >
        <CompareSell
          similar_properties={report?.similar_properties}
          prezzoMq={report?.model?.price_m2}
          prezzo={report?.model?.price}
          lat={houseInfo?.Lat}
          lng={houseInfo?.Lng}
        />
      </PageHOC>

      {/* AGENT INPUTS FIELDS */}
      <PageHOC
        ref={(ref) => (componentRefs.current[4] = ref)}
        visibleClass={
          (pdfItems?.["sold_properties"] || !soldProperties?.length) &&
          (pdfItems?.["agent_characteristics"] ||
            !agentCharacteristics?.length) &&
          !pdfItems?.["pricing_simulator"] &&
          styles.hide
        }
        date={creationDate}
        height="296.80mm"
      >
        <AgentInputs
          pdfItems={pdfItems}
          soldProperties={soldProperties}
          prezzoMq={report?.model?.price_m2}
          prezzo={report?.model?.price}
          pdfSimulator={pdfSimulator}
          defPriceMin={report?.model?.price_min}
          defPrice={report?.model?.price}
          defPriceMax={report?.model?.price_max}
          agentCharacteristics={agentCharacteristics}
          isPrivate={is_private}
        />
      </PageHOC>

      {/* PRICING SIMULATOR */}
      <PageHOC
        ref={(ref) => (componentRefs.current[5] = ref)}
        visibleClass={
          (!Object.keys(pdfSimulator).length ||
            !pdfItems?.["pricing_simulator"] ||
            soldProperties.length <= 3 ||
            pdfItems?.["sold_properties"] ||
            (soldProperties.length <= 7 &&
              pdfItems?.["agent_characteristics"])) &&
          styles.hide
        }
        date={creationDate}
        height="296.80mm"
      >
        <PricingSimulator
          pdfSimulator={pdfSimulator}
          defPriceMin={report?.model?.price_min}
          defPrice={report?.model?.price}
          defPriceMax={report?.model?.price_max}
        />
      </PageHOC>

      {/* FIFTH PAGE - OMI */}
      <PageHOC
        ref={(ref) => (componentRefs.current[6] = ref)}
        visibleClass={pdfItems?.["omi"] && styles.hide}
        date={creationDate}
        height="296.80mm"
      >
        <FifthPg homePricem2={report?.model?.price_m2} omi={report?.omi_info} />
      </PageHOC>

      {/* FIFTH PAGE - ADDITIONAL OMI DATA IF PRESENT */}
      <PageHOC
        ref={(ref) => (componentRefs.current[7] = ref)}
        visibleClass={(!omiPricesLength || pdfItems?.["omi"]) && styles.hide}
        date={creationDate}
      >
        <div style={{ padding: "0px 60px" }}>
          <OmiTable omiPrices={report?.omi_info?.prices.slice(14)} />
        </div>
      </PageHOC>

      {/* SIXTH PAGE - SOCIODEMOGRAPHICS */}
      <PageHOC
        ref={(ref) => (componentRefs.current[8] = ref)}
        visibleClass={
          (pageSixDisplayCheck.every((item) => pdfItems?.[item]) ||
            pdfItems?.["all_socio_charts"]) &&
          styles.hide
        }
        date={creationDate}
        height="296.80mm"
      >
        <SixthPg
          populationChange={report?.charts?.[2]}
          genderDistributionArea={report?.charts?.[3]}
          populationDensityArea={report?.charts?.[7]}
          town={houseInfo?.city}
          pdfItems={pdfItems}
        />
      </PageHOC>

      {/* SEVENTH PAGE - SOCIODEMOGRAPHHICS */}
      <PageHOC
        ref={(ref) => (componentRefs.current[9] = ref)}
        visibleClass={
          (pageSevenDisplayCheck.every((item) => pdfItems?.[item]) ||
            pdfItems?.["all_socio_charts"]) &&
          styles.hide
        }
        date={creationDate}
      >
        <SeventhPg
          genderStatus={report?.charts?.[4]}
          populationPyramid={report?.charts?.[0]}
          town={houseInfo?.city}
          pdfItems={pdfItems}
        />
      </PageHOC>

      {/* EIGHT PAGE - SOCIODEMOGRAPHHICS */}
      <PageHOC
        ref={(ref) => (componentRefs.current[10] = ref)}
        visibleClass={
          (pageEightDisplayCheck.every((item) => pdfItems?.[item]) ||
            pdfItems?.["all_socio_charts"]) &&
          styles.hide
        }
        date={creationDate}
        height="296.80mm"
      >
        <EightPg
          residents={report?.charts?.[5]}
          surface={report?.charts?.[6]}
          family={report?.charts?.[9]}
          education={report?.charts?.[8]}
          foreigners={report?.charts?.[1]}
          pdfItems={pdfItems}
        />
      </PageHOC>

      {/* NINTH PAGE- ECONOMICS */}
      <PageHOC
        ref={(ref) => (componentRefs.current[11] = ref)}
        visibleClass={
          (pageNineDisplayCheck.every((item) => pdfItems?.[item]) ||
            pdfItems?.["all_eco_charts"]) &&
          styles.hide
        }
        date={creationDate}
        height="296.80mm"
      >
        <NinthPg
          inflation={report?.charts?.[15]}
          bce={report?.charts?.[17]}
          inflationChange={report?.charts?.[14]}
          pdfItems={pdfItems}
        />
      </PageHOC>

      {/* TENTH PAGE - ECONOMICS */}
      <PageHOC
        ref={(ref) => (componentRefs.current[12] = ref)}
        visibleClass={
          (pageTenDisplayCheck.every((item) => pdfItems?.[item]) ||
            pdfItems?.["all_eco_charts"]) &&
          styles.hide
        }
        date={creationDate}
      >
        <TenthPg
          bceChange={report?.charts?.[16]}
          avgEarnings={report?.charts?.[10]}
          employment={report?.charts?.[11]}
          pdfItems={pdfItems}
        />
      </PageHOC>

      {/* ELEVENTH PAGE - SAFETY */}
      <PageHOC
        ref={(ref) => (componentRefs.current[13] = ref)}
        visibleClass={
          (pageElevenDisplayCheck.every((item) => pdfItems?.[item]) ||
            pdfItems?.["all_safety_charts"]) &&
          styles.hide
        }
        date={creationDate}
        height="296.80mm"
      >
        <EleventhPg
          safetyChange={report?.charts?.[12]}
          safetyDetail={report?.charts?.[13]}
          pdfItems={pdfItems}
        />
      </PageHOC>

      {/* TWELFTH PAGE - SERVICES */}
      <PageHOC
        ref={(ref) => (componentRefs.current[14] = ref)}
        visibleClass={pdfItems?.["area_services"] && styles.hide}
        date={creationDate}
        height="296.80mm"
      >
        <TwelfthPg
          data={report?.services}
          lat={houseInfo?.Lat}
          lng={houseInfo?.Lng}
        />
      </PageHOC>

      {/* THIRTEENTH PAGE - SERVICES PART 2 */}
      <PageHOC
        ref={(ref) => (componentRefs.current[15] = ref)}
        visibleClass={
          (!availableServices || pdfItems?.["area_services"]) && styles.hide
        }
        date={creationDate}
        height="296.80mm"
      >
        <ThirteenthPg data={report?.services} />
      </PageHOC>

      {/* FOURTEENTH PAGE - PICTURES */}
      <PageHOC
        ref={(ref) => (componentRefs.current[16] = ref)}
        visibleClass={
          (gallery?.data?.length <= 0 || pdfItems?.["pictures"]) && styles.hide
        }
        date={creationDate}
        height="296.80mm"
      >
        <FourteenthPg gallery={gallery} />
      </PageHOC>

      {/* FIFTEENTH PAGE - POTENTIAL CUSTOMER */}
      <PageHOC
        ref={(ref) => (componentRefs.current[17] = ref)}
        visibleClass={pdfItems?.["potential_customer"] && styles.hide}
        date={creationDate}
        height="296.80mm"
      >
        <FifteenthPg report={report?.radar_chart} />
      </PageHOC>

      {/* SIXTEENTH PAGE - AGENT */}
      <PageHOC
        ref={(ref) => (componentRefs.current[18] = ref)}
        date={creationDate}
        height="296.80mm"
      >
        <SixteenthPg
          addresses={addresses}
          agency_name={agency_name}
          email={email}
          piva={piva}
          business_name={business_name}
          pec={pec}
          profileImg={profileImg}
        />
      </PageHOC>

      {/* SEVENTEENTH PAGE - TECH */}
      <PageHOC
        ref={(ref) => (componentRefs.current[19] = ref)}
        date={creationDate}
        height="296.35mm"
      >
        <SeventeenthPg />
      </PageHOC>
    </div>
  );
};

export default Download;
