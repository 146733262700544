const hideSurfaces = (hideElement, sethideElement) => {
  sethideElement({
    ...hideElement,
    SuperficieCantina: true,
    SuperficieGiardinoPrivato: true,
    SuperficieGiardinoCondominiale: true,
    SuperficieSoffitta: true,
    SuperficieSeminterrato: true,
    SuperficieBalconi: true,
    SuperficieTerrazzo: true,
    SuperficiePostoAuto: true,
    SuperficiePostoAutoCondominiale: true,
    SuperficiePostoAutoGarage: true,
  });
};

export default hideSurfaces;
