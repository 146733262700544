import React from "react";
import styles from "./Admin.module.scss";
import Nav from "./containers/Nav";
import SearchBar from "./containers/SearchBar";
import ResultTable from "./containers/ResultTable";
import { ROUTES } from "../../routes/constants";
import Helmet from "../../shared/Helmet/Helmet";

const Admin = () => {
  return (
    <div className={styles.admin}>
      <Helmet title="Admin" route={ROUTES.ADMIN} />
      <Nav />
      <SearchBar />
      <ResultTable />
    </div>
  );
};

export default Admin;
