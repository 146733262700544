import { useState, useEffect } from "react";

const useIntersectionObserver = (refs) => {
  // console.log(refs, "refs");
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    try {
      const observer = new IntersectionObserver(
        ([entry]) => {
          try {
            // If the section intersects with the viewport, set activeSection to true
            if (entry.isIntersecting) {
              //   console.log(entry.target, "entry targets");
              setActiveSection(entry.target);
            }
          } catch (error) {
            console.error("Error in IntersectionObserver callback:", error);
          }
        },
        {
          // Set rootMargin to trigger intersection earlier or later if desired
          // rootMargin: '0px',
          threshold: 0.1, // Trigger when 50% of the section is visible
        }
      );

      if (refs) {
        refs.forEach((item) => observer.observe(item));
      }

      // Cleanup function to disconnect the observer
      return () => {
        if (refs) {
          refs.forEach((item) => observer.unobserve(item));
        }
      };
    } catch (error) {
      console.error("Error creating IntersectionObserver:", error);
    }
  }, [refs]);

  return { activeSection };
};

export default useIntersectionObserver;
