import React from "react";
import clx from "classnames";
import styles from "./SectionTitle.module.scss";
import PdfCheckbox from "../PdfCheckbox";

const SectionTitle = ({
  title,
  subtitle,
  section,
  className,
  isDisabled = false,
  toolText,
  isChecked,
  isOpposite,
  isGeneral,
  marginBottom,
}) => {
  return (
    <div
      className={clx(styles.section__title, className)}
      style={{ marginBottom }}
    >
      <div className={styles.section__title__title}>
        <p className={styles.section__title__title__text}>{title}</p>
        <div className={styles.section__title__title__action}>
          <PdfCheckbox
            section={section}
            className={"tooltipStyle"}
            isDisabled={isDisabled}
            isChecked={isChecked}
            isOpposite={isOpposite}
            isGeneral={isGeneral}
            toolText={toolText}
          />
        </div>
      </div>
      {subtitle && (
        <p className={styles.section__title__subtitle}>{subtitle}</p>
      )}
    </div>
  );
};

export default SectionTitle;
