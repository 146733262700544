import DataTableDefault from "../../../Common/DataTables/containers/DataTableDefault";
import styles from "../../PaymentsAndCredits.module.scss";

const PaymentsTable = ({ transactions, uniqueKey }) => {
  const tableColumns = [
    {
      name: "id",
      label: "Id transazione",
      sort: true,
      centered: true,
    },
    {
      name: "type",
      label: "Tipo transazione",
      sort: true,
      filter: true,
    },
    {
      name: "date",
      label: "Data transazione",
      filter: false,
      sort: true,
      centered: true,
    },
  ];

  return (
    <div className={styles.table__wrapper}>
      <DataTableDefault
        title="Transazioni"
        tableData={transactions?.map((transaction) => {
          const date = new Date(transaction?.createdAt).toLocaleDateString(
            "it-IT",
            {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }
          );

          return {
            id: transaction.id,
            type: transaction.type,
            // type: transaction.type === 'withdrawal' ? 'Acquisto Report' : 'Ricarca Reports',
            // nCredits: transaction.nCredits,
            date,
          };
        })}
        uniqueKey={uniqueKey}
        tableColumns={tableColumns}
        noMatchOption={false}
        rowsPerPageNum={8}
      />
    </div>
  );
};

export default PaymentsTable;

// {
// 	name: 'nCredits',
// 	label: 'Report',
// 	sort: true,
// },
