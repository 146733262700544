import { ReactComponent as AddIcon } from "../../../../../../assets/svg/add.svg";
import { ReactComponent as GridIcon } from "../../../../../../assets/svg/grid.svg";
import IconButton from "../../../../../Common/Buttons/IconButton";
import styles from "./ActiveImageActions.module.scss";

const ActiveImageActions = ({
  activeImage,
  setUploadImageModalOpen,
  gallery,
  setPicturesPopup,
  setIsChangingOrder,
}) => {
  const AddImageButton = () => (
    <div className={styles["add-image-btn__wrapper"]}>
      <IconButton
        height="75px"
        width="75px"
        padding="25px"
        borderRadius="50%"
        onClick={() => {
          // if (gallery?.data?.length < 4) {
          setUploadImageModalOpen(true);
          // } else {
          // 	setPicturesPopup(true);
          // }
        }}
        // iconSrc={CrossIcon}
        iconSrc={AddIcon}
      />
    </div>
  );

  return (
    <>
      {activeImage && (
        <div className={styles.actions_container}>
          {/* {gallery?.length ?
					( */}
          <div className={styles["change-order-btn__wrapper"]}>
            <IconButton
              padding="14px"
              height="75px"
              width="75px"
              borderRadius="50%"
              onClick={() => setIsChangingOrder(true)}
              iconSrc={GridIcon}
              className={styles.icon_color}
              backgroundColor="#FFF7FA"
            />
          </div>
          {/* ) :
					null} */}
          <AddImageButton />
        </div>
      )}
    </>
  );
};

export default ActiveImageActions;
