const scrollInToView = (el) => {
  const elementRect = el?.getBoundingClientRect();
  const absoluteElementTop = elementRect.top + window.pageYOffset;

  // Specify the margin you want to add
  const marginTop = 40; // You can adjust this value

  // Scroll to the position with margin
  window.scrollTo({
    top: absoluteElementTop - marginTop,
    behavior: "smooth", // Optionally, you can add smooth scrolling behavior
  });
};

export default scrollInToView;

// el?.scrollIntoView({
//   behavior: "smooth",
//   block: "start",
//   inline: "nearest",
// });
