import React from 'react';

import styles from './NoChartsData.module.scss';

const index = ({
	title, fontSize, padding, minHeight, marginTop
}) => (
	<div className={styles.chart__wrapper} style={{ minHeight, padding }}>
		<b>{title}</b>
		<b className={styles.content} style={{ fontSize, marginTop }}>
			Non sempre si trovano tutti i dati. In questo caso non sono presenti, ma quando lo saranno, noi te li mostreremo!
		</b>
	</div>
);

export default index;