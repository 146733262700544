import { call, put, takeLatest } from "redux-saga/effects";
import { Api } from "../../../entries/ApiTransport";
import sagaAssessor from "../../../utils/sagaAssessor";
import { actions, types } from "./actions";

const api = Api.getInstance();

const getUsers = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { value, platform } = payload;
        const { data } = yield call(() =>
          api.get(`/admin/users_by_email_or_name/${value}/${platform}`)
        );
        yield put(actions.GET_USERS_BY_EMAIL_OR_NAME.SUCCESS(data.data));
        callback && typeof callback === "function" && callback(data.data);
      },
    (err) => actions.GET_USERS_BY_EMAIL_OR_NAME.FAILED(err)
  );

const getReports = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { value, platform } = payload;
        const { data } = yield call(() =>
          api.get(`/admin/reports_by_address/${value}/${platform}`)
        );
        yield put(actions.GET_REPORTS_BY_ADDRESS.SUCCESS(data.data));
        callback && typeof callback === "function" && callback(data.data);
      },
    (err) => actions.GET_REPORTS_BY_ADDRESS.FAILED(err)
  );

const getUserReports = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`/admin/reports/${payload}`));
        yield put(actions.GET_USER_REPORTS.SUCCESS(data.data));
        callback && typeof callback === "function" && callback(data.data);
      },
    (err) => actions.GET_USER_REPORTS.FAILED(err)
  );

const getUserTransactions = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get(`/admin/transactions/${payload}`)
        );
        yield put(actions.GET_USER_TRANSACTIONS.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_USER_TRANSACTIONS.FAILED(err)
  );

const getAllReports = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get(`/admin/all_reports/${payload}`)
        );
        yield put(actions.GET_ALL_REPORTS.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_ALL_REPORTS.FAILED(err)
  );

const modifyUserCredits = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.put("/admin/modify_credits", payload)
        );
        yield put(actions.MODIFY_USER_CREDITS.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.MODIFY_USER_CREDITS.FAILED(err)
  );

const modifySubscriptionDate = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.put("/admin/modify_subscription_date", payload)
        );
        yield put(actions.MODIFY_SUBSCRIPTION_DATE.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.MODIFY_SUBSCRIPTION_DATE.FAILED(err)
  );

const modifySubscriptionType = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.put("/admin/modify_subscription_type", payload)
        );
        yield put(actions.MODIFY_SUBSCRIPTION_TYPE.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.MODIFY_SUBSCRIPTION_TYPE.FAILED(err)
  );

const endSubscription = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.put("/admin/end_user_subscription", payload)
        );
        yield put(actions.END_SUBSCRIPTION.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.END_SUBSCRIPTION.FAILED(err)
  );

export default function* () {
  yield takeLatest(types.GET_USERS_BY_EMAIL_OR_NAME.REQUEST, getUsers);
  yield takeLatest(types.GET_REPORTS_BY_ADDRESS.REQUEST, getReports);
  yield takeLatest(types.GET_USER_REPORTS.REQUEST, getUserReports);
  yield takeLatest(types.GET_USER_TRANSACTIONS.REQUEST, getUserTransactions);
  yield takeLatest(types.GET_ALL_REPORTS.REQUEST, getAllReports);
  yield takeLatest(types.MODIFY_USER_CREDITS.REQUEST, modifyUserCredits);
  yield takeLatest(
    types.MODIFY_SUBSCRIPTION_DATE.REQUEST,
    modifySubscriptionDate
  );
  yield takeLatest(
    types.MODIFY_SUBSCRIPTION_TYPE.REQUEST,
    modifySubscriptionType
  );
  yield takeLatest(types.END_SUBSCRIPTION.REQUEST, endSubscription);
}
