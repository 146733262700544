import React, { forwardRef } from "react";

import SectionTitle from "../SectionTitle";
import formatToEuro from "../../../../utils/formatToEuro";
import ProgressBar from "../../../Common/ProgressBar";
import styles from "./AgentAssessment.module.scss";

const AgentAssessment = forwardRef(
  ({ prezzo_utente, prezzo_utente_gpt }, ref) => {
    // console.log(prezzo_utente_gpt, "prezzo_utente_gpt");
    const probVenditaCopy = () => {
      if (prezzo_utente_gpt?.proba_180 > 0.84) {
        return "molto alta";
      } else if (
        prezzo_utente_gpt?.proba_180 > 0.59 &&
        prezzo_utente_gpt?.proba_180 < 0.85
      ) {
        return "accettabile";
      } else if (
        prezzo_utente_gpt?.proba_180 > 0.39 &&
        prezzo_utente_gpt?.proba_180 < 0.6
      ) {
        return "moderata";
      } else if (
        prezzo_utente_gpt?.proba_180 > 0.19 &&
        prezzo_utente_gpt?.proba_180 < 0.4
      ) {
        return "bassa";
      } else if (prezzo_utente_gpt?.proba_180 < 0.2) {
        return "molto bassa";
      }
      return "moderata";
    };

    return (
      <div className={styles.assessment__wrapper} ref={ref}>
        {/* HEADER */}
        <SectionTitle
          title={"Analisi della tua valutazione"}
          section={"agent_assessment"}
        />
        <div className={styles.assessment}>
          {/* HEADER */}
          <div className={styles.assessment__result}>
            <div className={styles.assessment__result__price}>
              <p className={styles.value}>€ {formatToEuro(prezzo_utente)}</p>
              <p className={styles.probability}>
                Hai una probabilità <b>{probVenditaCopy()} </b>
                <br />
                di vendere entro
                <b> 6 mesi</b>
              </p>
            </div>
            <div className={styles.assessment__result__probability}>
              <ProgressBar
                percent={Math.round(prezzo_utente_gpt?.proba_180 * 100) || "0"}
                rotateBarDeg={90}
                rotateTextDeg={-90}
                pointerText=""
                scale={0.8}
                display={"inline-block"}
              />
            </div>
          </div>
          {/* CARD */}
          {/* <div className={styles.probabilita_card}>
            <p>
              Probabilità di vendita
              <br />
              al prezzo inserito
            </p>
            <div className={styles.probabilita_card_barWrapper}>
              <ProgressBar
                percent={Math.round(prezzo_utente_gpt?.proba_180 * 100) || "0"}
                rotateBarDeg={90}
                rotateTextDeg={-90}
                pointerText=""
                scale={0.8}
                left={"-107px"}
                display={"inline-block"}
                position={"absolute"}
              />
            </div>
          </div> */}
          {/* TEXT */}
          <div className={styles.assessment__text}>
            {prezzo_utente_gpt?.description}
          </div>
        </div>
      </div>
    );
  }
);

export default AgentAssessment;
