import { createSelector } from "reselect";

const selectAdminState = (state) => state.adminReducer;

export const getUsers = () =>
  createSelector(selectAdminState, (state) => state.users);
export const getReports = () =>
  createSelector(selectAdminState, (state) => state.reports);
export const getUserReports = () =>
  createSelector(selectAdminState, (state) => state.userReports);
export const getUserTransactions = () =>
  createSelector(selectAdminState, (state) => state.userTransactions);
export const getAllReports = () =>
  createSelector(selectAdminState, (state) => state.allReports);
export const getPlatform = () =>
  createSelector(selectAdminState, (state) => state.platform);
export const getTypeSearch = () =>
  createSelector(selectAdminState, (state) => state.typeSearch);
export const getFormValue = () =>
  createSelector(selectAdminState, (state) => state.formValue);
