import React from "react";

import PyramidChart from "../../../../../Common/Charts/PyramidChart";
import PdfCheckbox from "../../../PdfCheckbox";

const PopulationPyramid = ({
  data,
  minHeight,
  wrapperHeight,
  chartWidth,
  tickX,
  chartMarginTop,
  isPdf = false,
}) => {
  // console.log(data, 'PYRAMID DATA');

  const chartData = data?.data?.y
    ?.map((item, index, array) => ({
      y: index == array.length - 1 ? ">100" : item,
      Uomini: data?.data?.X[0][index],
      Donne: data?.data?.X[1][index],
      percentUomini: data?.data?.displayed_values[0][index],
      percentDonne: data?.data?.displayed_values[1][index],
    }))
    .reverse();

  return (
    <div>
      {!isPdf && <PdfCheckbox section={"population_pyramid"} />}
      <PyramidChart
        title={data?.title}
        description={data?.description}
        label={"Anni"}
        limit={data?.data?.xlimit}
        labelAdditionalTitle={"anni"}
        chartData={chartData}
        minHeight={minHeight}
        wrapperHeight={wrapperHeight}
        chartWidth={chartWidth}
        chartMarginTop={chartMarginTop}
        tickX={tickX}
      />
    </div>
  );
};

export default PopulationPyramid;
