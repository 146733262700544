import clx from "classnames";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/constants";

import { ReactComponent as RocketIcon } from "../../assets/svg/rocket.svg";
import { generateHouse, generateSeconds } from "../../utils/countResult";
import { formatNumber } from "../../utils/formatNumber";
import useWindowSize from "../../utils/useWindowSize";
import { actions } from "../Report/store";
import {
  getActiveHouse,
  getReportNew,
  getBoughtReport,
} from "../Report/store/selectors";
import { getUser } from "../Auth/store/selectors";
import styles from "./AlgorithmReport.module.scss";
import ReportNav from "./containers/ReportNav";
import SectionTitle from "./containers/SectionTitle";
import AreaServices from "./containers/AreaServices";
import SoldProperties from "./containers/SoldProperties";
import AgentCharacteristics from "./containers/AgentCharacteristics";
import ComparedProperties from "./containers/ComparedProperties";
import AgentAssessment from "./containers/AgentAssessment";
import Dante from "./containers/Dante";
import Economics from "./containers/Economics";
import MapSection from "./containers/MapSection";
import OMIData from "./containers/OMIData";
import PotentialCustomer from "./containers/PotentialCustomer";
import ProbabilitySale from "./containers/ProbabilitySale";
import PropertyCharacteristics from "./containers/PropertyCharacteristics";
import Safety from "./containers/Safety";
import SocioDemographicArea from "./containers/SocioDemographicArea";
import DownloadIcon from "../../assets/svg/download-pdf.svg";
import Helmet from "../../shared/Helmet/Helmet";

const AlgorithmReport = () => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reportId } = useParams();

  const houseInfo = useSelector(getActiveHouse());
  // console.log("houseInfo", houseInfo);
  const report = useSelector(getReportNew());
  // console.log("report", report);
  const user = useSelector(getUser());
  // console.log("user", user);
  const boughtReport = useSelector(getBoughtReport());
  console.log("boughtReport", boughtReport);
  const privateUserHasReport = boughtReport?.house_id == reportId;
  const agencyUserHasSubscription =
    user?.subscriptionExpiry && new Date(user.subscriptionExpiry) > new Date();
  console.log("user?.subscriptionExpiry", user?.subscriptionExpiry);
  console.log("agencyUserHasSubscription", agencyUserHasSubscription);

  useEffect(() => {
    dispatch(actions.GET_HOUSE_BY_ID.CLEAR());
    dispatch(
      actions.GET_HOUSE_BY_ID.REQUEST(
        reportId,
        (data) => {
          dispatch(actions.GET_REPORT_NEW.REQUEST(data));
          dispatch(actions.GET_GALLERY.REQUEST(reportId));
          dispatch(actions.GET_PDF_ITEMS.REQUEST(reportId));
          dispatch(actions.GET_HIDDEN_COMPARED_ITEMS.REQUEST(reportId));
          dispatch(actions.GET_SOLD_PROPERTIES.REQUEST(reportId));
          dispatch(actions.GET_AGENT_CHARACTERISTICS.REQUEST(reportId));
          if (user?.is_private == 1) {
            dispatch(actions.CHECK_BOUGHT_REPORT.REQUEST(reportId));
          }
        },
        () => {
          navigate(ROUTES.REPORT);
        }
      )
    );
  }, [dispatch]);

  const goToDownload = () => {
    dispatch(
      actions.SET_PDF_SIMULATOR.REQUEST(
        {
          reportId,
          ...(({ ascensore, curves, ...rest }) => ({ submitValues: rest }))(
            simulatoRef.current?.configuredData
          ),
        },
        () => {
          dispatch(actions.GET_REPORT_DOWNLOAD.REQUEST(reportId));
        }
      )
    );
  };

  // Section refs for navigation
  const algoComponentRefs = useRef([]);
  const simulatoRef = useRef(null);
  // console.log(simulatoRef.current, "simulatoRef.current");

  return (
    <div
      className={clx(styles.report__algorithm__section__wrapper, {
        [styles.report__algorithm__section__mbl]: isMobile,
      })}
      id="algo"
    >
      <Helmet title={`Report ${reportId}`} route={ROUTES.REPORT} />
      {!isMobile && (
        <ReportNav
          algoComponentRefs={algoComponentRefs?.current?.filter(
            (ref) => ref != null
          )}
          hasAgentAssessment={houseInfo?.Prezzo != 0}
          isPrivate={user?.is_private}
        />
      )}
      <section className={styles.report__algorithm__section}>
        <MapSection
          houseInfo={houseInfo}
          model={report?.model}
          ref={(el) => (algoComponentRefs.current[0] = el)}
        />
        {houseInfo?.Prezzo != 0 && (
          <AgentAssessment
            prezzo_utente_gpt={report?.prezzo_utente}
            prezzo_utente={houseInfo?.Prezzo}
            ref={(el) => (algoComponentRefs.current[1] = el)}
          />
        )}
        <div className={styles.count__results}>
          <div className={styles.count__results__icon}>
            <RocketIcon />
          </div>
          <p className={styles.count__results__text}>
            Abbiamo analizzato più di {formatNumber(generateHouse(reportId))}
            &nbsp; immobili in meno di 0.
            {
              parseFloat(generateSeconds(reportId)).toFixed(2).split(".")[1]
            }{" "}
            secondi
          </p>
        </div>
        <ComparedProperties
          home={{
            lat: houseInfo?.Lat,
            lng: houseInfo?.Lng,
          }}
          prezzoMq={report?.model?.price_m2}
          prezzo={report?.model?.price}
          similar_properties={report?.similar_properties}
          isMobile={isMobile}
          ref={(el) => (algoComponentRefs.current[2] = el)}
          privateUserHasReport={privateUserHasReport}
          userIsPrivate={user?.is_private}
        />
        {user?.is_private != 1 && (
          <SoldProperties
            prezzoMq={report?.model?.price_m2}
            prezzo={report?.model?.price}
            isMobile={isMobile}
            privateUserHasReport={privateUserHasReport}
            userIsPrivate={user?.is_private}
            agencyUserHasSubscription={agencyUserHasSubscription}
            ref={(el) => (algoComponentRefs.current[3] = el)}
          />
        )}
        <AgentCharacteristics
          privateUserHasReport={privateUserHasReport}
          userIsPrivate={user?.is_private}
          ref={(el) => (algoComponentRefs.current[4] = el)}
        />
        <ProbabilitySale
          isMobile={isMobile}
          houseInfo={houseInfo}
          defPrice={report?.model}
          privateUserHasReport={privateUserHasReport}
          userIsPrivate={user?.is_private}
          agencyUserHasSubscription={agencyUserHasSubscription}
          ref={[
            (el) => (simulatoRef.current = el),
            (el) => {
              algoComponentRefs.current[5] = el;
            },
          ]}
        />
        <PotentialCustomer
          isMobile={isMobile}
          privateUserHasReport={privateUserHasReport}
          userIsPrivate={user?.is_private}
          ref={(el) => (algoComponentRefs.current[6] = el)}
        />
        <OMIData
          homePricem2={report?.model?.price_m2}
          isMobile={isMobile}
          privateUserHasReport={privateUserHasReport}
          userIsPrivate={user?.is_private}
          agencyUserHasSubscription={agencyUserHasSubscription}
          ref={(el) => (algoComponentRefs.current[7] = el)}
        />
        <SectionTitle
          title={"Scenario intorno all’immobile"}
          subtitle={
            "Plus ti mostra le caratteristiche economiche e socio - demografiche intorno all’immobile che stai valutando. Dati forniti dal comune di appartenenza."
          }
          section={"all_charts"}
          className={styles.section_title_wrapper}
          isDisabled={true}
          toolText={
            "Questo campo non è opzionale. Se desideri omettere le charts, clicca nel bottone dell'apposita sezione o quello allegato alla singola chart"
          }
        />
        <SocioDemographicArea
          isMobile={isMobile}
          town={houseInfo?.city}
          privateUserHasReport={privateUserHasReport}
          userIsPrivate={user?.is_private}
          ref={(el) => (algoComponentRefs.current[8] = el)}
        />
        <Economics
          privateUserHasReport={privateUserHasReport}
          userIsPrivate={user?.is_private}
          ref={(el) => (algoComponentRefs.current[9] = el)}
        />
        <Safety
          privateUserHasReport={privateUserHasReport}
          userIsPrivate={user?.is_private}
          agencyUserHasSubscription={agencyUserHasSubscription}
          ref={(el) => (algoComponentRefs.current[10] = el)}
        />
        <AreaServices
          isMobile={isMobile}
          home={{
            lat: houseInfo?.Lat,
            lng: houseInfo?.Lng,
          }}
          privateUserHasReport={privateUserHasReport}
          userIsPrivate={user?.is_private}
          agencyUserHasSubscription={agencyUserHasSubscription}
          ref={(el) => (algoComponentRefs.current[11] = el)}
        />
        <Dante
          dante={report?.dante}
          privateUserHasReport={privateUserHasReport}
          userIsPrivate={user?.is_private}
          agencyUserHasSubscription={agencyUserHasSubscription}
          ref={(el) => (algoComponentRefs.current[12] = el)}
        />
        <PropertyCharacteristics
          data={houseInfo}
          isMobile={isMobile}
          goToDownload={goToDownload}
          reportId={reportId}
          minPrice={report?.model?.price_min || 0}
          exactPrice={report?.model?.price || 0}
          maxPrice={report?.model?.price_max || 0}
          completeSurface={report?.model?.commercial_surface || 0}
          ref={(el) => (algoComponentRefs.current[13] = el)}
        />
      </section>

      {/* DOWNLOAD BTN */}
      <div
        className={clx(styles.download, {
          [styles.disabled]:
            (user?.is_private == 1 && !privateUserHasReport) ||
            (user?.is_private != 1 && !agencyUserHasSubscription),
        })}
        onClick={() => {
          if (
            (user?.is_private == 1 && !privateUserHasReport) ||
            (user?.is_private != 1 && !agencyUserHasSubscription)
          ) {
            return;
          } else {
            goToDownload();
          }
        }}
      >
        <img src={DownloadIcon} alt="print-icon" />
        <p>Scarica</p>
      </div>
    </div>
  );
};

export default AlgorithmReport;
