import React from 'react';

import AreaChart from '../../../../../Common/Charts/AreaChart';
import PdfCheckbox from '../../../PdfCheckbox';

const BceChange = ({
	data, minHeight, wrapperHeight, paddingTop, chartMarginTop, sourcePosition, sourceBottom, sourceRight, isPdf = false,
}) => {
	// console.log(data);
	const chartData = data?.data?.x?.map((item, index) => ({ 'x': item.split('-').slice(0, 2).join('-'), 'Tasso BCE': data?.data?.y[index] }));
	// console.log(chartData);

	return (
		<div>
			{!isPdf && <PdfCheckbox section={'bce_change'} />}
			<AreaChart title={data?.title} description={data?.description}
				label={`${data?.data?.ytitle}%`} dx={40} marginLeft={-25}
				minHeight={minHeight}
				wrapperHeight={wrapperHeight}
				chartData={chartData} labelAdditionalContent={'%'}
				paddingTop={paddingTop}
				chartMarginTop={chartMarginTop}
				sourcePosition={sourcePosition}
				sourceRight={sourceRight}
				sourceBottom={sourceBottom} />
		</div>
	);
};

export default BceChange;