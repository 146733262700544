import { types } from "./actions";

const initialState = {
  userPortal: "",
  transactions: [],
  transactionsNew: [],
  credits: [],
  reportAcquistati: 0,
  isSubscribedHouseplus: "",
  isSubscribedMondo: "",
  // products: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_PORTAL.SUCCESS:
      return {
        ...state,
        userPortal: action.payload,
      };
    case types.GET_TRANSACTIONS.SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      };
    case types.GET_TRANSACTIONS_NEW.SUCCESS:
      return {
        ...state,
        transactionsNew: action.payload,
      };
    case types.GET_PAYMENT_URL_SUBSCRIPTION_MONDO.SUCCESS:
      return {
        ...state,
        isSubscribedMondo: action.payload,
      };
    case types.CHECK_USER_CREDITS.SUCCESS:
      return {
        ...state,
        credits: action.payload.credits,
        reportAcquistati: action.payload.reportAcquistati,
        isSubscribedHouseplus: action.payload.subscribed,
      };
    // case types.GET_PRODUCTS.SUCCESS:
    // 	return {
    // 		...state,
    // 		products: action.payload,
    // 	};
    default:
      return { ...state };
  }
};
