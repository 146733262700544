import React, { useReducer } from "react";
import { Navigate } from "react-router-dom";

import { ROUTES } from "../../../../routes/constants";
import Banner from "../../../Common/Banner";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import styles from "./PaymentError.module.scss";
import platformConditionals from "../../../../utils/platformConditionals";

export default () => {
  const [redirect, setRedirect] = useReducer((a) => !a, false);

  return (
    <div className={styles.container__inner}>
      <Banner isLogin isPayment />
      <div className={styles.payment_container}>
        <div className={styles.info_container}>
          <h1>Oops!</h1>
          <h1>Il pagamento non è andato a buon fine 😞</h1>
          <p>
            Puoi contattare il nostro <b>Customer Care!</b>
          </p>
        </div>
        <PrimaryButton
          height="auto"
          fontSize="2rem"
          onClick={() => setRedirect()}
          text={`Ritorna a ${
            platformConditionals()?.bannerText || "Houseplus"
          }`}
        />
        {redirect ? <Navigate to={`${ROUTES.REPORT}`} /> : null}
      </div>
    </div>
  );
};
