import { types } from "./actions";

const initialState = {
  users: [],
  reports: [],
  userReports: [],
  userTransactions: [],
  allReports: [],
  platform: 0,
  typeSearch: "user",
  formValue: "",
};

export default (state = initialState, action) => {
  // console.log(action, "action");
  switch (action.type) {
    case types.GET_USERS_BY_EMAIL_OR_NAME.SUCCESS:
      return {
        ...state,
        users: action.payload,
        reports: [],
        userReports: [],
        userTransactions: [],
        allReports: [],
      };
    case types.GET_REPORTS_BY_ADDRESS.SUCCESS:
      return {
        ...state,
        reports: action.payload,
        users: [],
        userReports: [],
        userTransactions: [],
        allReports: [],
      };
    case types.GET_USER_REPORTS.SUCCESS:
      return {
        ...state,
        userReports: action.payload,
      };
    case types.GET_USER_TRANSACTIONS.SUCCESS:
      return {
        ...state,
        userTransactions: action.payload,
      };
    case types.GET_ALL_REPORTS.SUCCESS:
      return {
        ...state,
        allReports: action.payload,
        users: [],
        reports: [],
        userReports: [],
        userTransactions: [],
      };
    case "SET_PLATFORM":
      return {
        ...state,
        platform: action.payload,
      };
    case "SET_TYPE_SEARCH":
      return {
        ...state,
        typeSearch: action.payload,
      };
    case "SET_FORM_VALUE":
      return {
        ...state,
        formValue: action.payload,
      };
    default:
      return { ...state };
  }
};
