import { call, put, takeLatest } from "redux-saga/effects";
import { Api } from "../../../entries/ApiTransport";
import sagaAssessor from "../../../utils/sagaAssessor";
import { actions, types } from "./actions";
import platformConditionals from "../../../utils/platformConditionals";
const api = Api.getInstance();

// Handler functions getting called by the actions in the watcher saga
const getUserPortal = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get("/user/getPortal"));
        yield put(actions.GET_USER_PORTAL.SUCCESS(data.url));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_USER_PORTAL.FAILED(err)
  );

// ONLY FOR MONDORED
const getTransactions = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get("/transactions/getTransactions")
        );
        yield put(actions.GET_TRANSACTIONS.SUCCESS(data.transactions));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_TRANSACTIONS.FAILED(err)
  );

const getTransactionsNew = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get("/transactions/getTransactionsNew")
        );
        yield put(actions.GET_TRANSACTIONS_NEW.SUCCESS(data.transactions));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_TRANSACTIONS_NEW.FAILED(err)
  );

// ONLY FOR MONDORED
const checkUserCredits = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get("/user/checkCredits"));
        yield put(
          actions.CHECK_USER_CREDITS.SUCCESS({
            credits: data.credits,
            subscribed: data.subscribed,
            reportAcquistati: data.reportAcquistati,
          })
        );
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.CHECK_USER_CREDITS.FAILED(err)
  );

const getPaymentUrlPackage = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post(platformConditionals()?.packageUrl, payload)
        );
        yield put(actions.GET_PAYMENT_URL_PACKAGE.SUCCESS(data));
        callback && typeof callback === "function" && callback(data.url);
      },
    (err) => actions.GET_PAYMENT_URL_PACKAGE.FAILED(err)
  );

const getPaymentUrlPackagePrivate = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post("/getPaymentUrlPrivate", payload)
        );
        yield put(actions.GET_PAYMENT_URL_PACKAGE_PRIVATE.SUCCESS(data));
        callback && typeof callback === "function" && callback(data.url);
      },
    (err) => actions.GET_PAYMENT_URL_PACKAGE_PRIVATE.FAILED(err)
  );

// const getPaymentUrlSubscriptionHouseplus = ({ payload, callback }) =>
//   sagaAssessor(
//     () =>
//       function* () {
//         const { data } = yield call(() =>
//           api.post("/getPaymentUrlSubscriptions", payload)
//         );
//         yield put(actions.GET_PAYMENT_URL_SUBSCRIPTION_HOUSEPLUS.SUCCESS(data));
//         callback && typeof callback === "function" && callback(data.url);
//       },
//     (err) => actions.GET_PAYMENT_URL_SUBSCRIPTION_HOUSEPLUS.FAILED(err)
//   );

const getPaymentUrlSubscriptionMondo = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get("/whiteLabel/admin/get-client/subscription")
        );
        yield put(
          actions.GET_PAYMENT_URL_SUBSCRIPTION_MONDO.SUCCESS(data.subscribed)
        );
        callback && typeof callback === "function" && callback(data.url);
      },
    (err) => actions.GET_PAYMENT_URL_SUBSCRIPTION_MONDO.FAILED(err)
  );

//   const getProducts = ({ callback }) => sagaAssessor(
// 	() =>
// 		function* () {
// 			const { data } = yield call(() => api.get('/getProducts'));
// 			yield put(actions.GET_PRODUCTS.SUCCESS(data.products));
// 			callback && typeof callback === 'function' && callback();
// 		},
// 	err => actions.GET_PRODUCTS.FAILED(err),
// );

// Watcher saga - It will watch for the below actions to dispatch in order to call the handler functions associated with it
export default function* () {
  yield takeLatest(types.GET_USER_PORTAL.REQUEST, getUserPortal);
  yield takeLatest(types.GET_TRANSACTIONS.REQUEST, getTransactions);
  yield takeLatest(types.GET_TRANSACTIONS_NEW.REQUEST, getTransactionsNew);
  yield takeLatest(types.CHECK_USER_CREDITS.REQUEST, checkUserCredits);
  yield takeLatest(types.GET_PAYMENT_URL_PACKAGE.REQUEST, getPaymentUrlPackage);
  yield takeLatest(
    types.GET_PAYMENT_URL_PACKAGE_PRIVATE.REQUEST,
    getPaymentUrlPackagePrivate
  );
  // yield takeLatest(
  //   types.GET_PAYMENT_URL_SUBSCRIPTION_HOUSEPLUS.REQUEST,
  //   getPaymentUrlSubscriptionHouseplus
  // );
  yield takeLatest(
    types.GET_PAYMENT_URL_SUBSCRIPTION_MONDO.REQUEST,
    getPaymentUrlSubscriptionMondo
  );
  //   yield takeLatest(types.GET_PRODUCTS.REQUEST, getProducts);
}
