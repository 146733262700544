import Select from "react-select";
import styles from "./SingleSelect.module.scss";
import selectConditionals from "../../../utils/selectConditionals";

const colourStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: selectConditionals()?.singleSecondaryColor,
    border: `1px solid ${selectConditionals()?.singleMainColor}`,
    boxShadow: isFocused
      ? `0 0 0 1px ${selectConditionals()?.mainColor}`
      : "none",
    "&:hover": {
      boxShadow: `0 0 0 1px ${selectConditionals()?.mainColor}`,
    },
  }),
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      borderRadius: "0px 5px 5px 0px",
      width: "5px",
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: "0px 5px 5px 0px",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? selectConditionals()?.singleMainColor
      : isFocused
      ? selectConditionals()?.singleSecondaryColor
      : undefined,
    color: isDisabled ? "#ccc" : isSelected ? "white" : data.color,
    ":active": {
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? selectConditionals()?.singleMainColor
        : isFocused
        ? selectConditionals()?.singleSecondaryColor
        : undefined,
    },
  }),
  input: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
};

const negativeColourStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? "#ccc" : "#ffd1d2",
    border: isDisabled ? `1px solid #939293` : `1px solid #927677`,
    boxShadow: isFocused ? `0 0 0 1px #927677` : "none",
    "&:hover": {
      boxShadow: `0 0 0 1px #927677`,
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? "#ffd1d2"
      : isFocused
      ? "#ffd1d2"
      : undefined,
    color: isDisabled ? "#ccc" : isSelected ? "white" : "#735a5a",
    ":active": {
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#ffd1d2"
        : isFocused
        ? "#ffd1d2"
        : undefined,
    },
  }),
  input: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "white" : "#735a5a",
  }),
};

const positiveColourStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? "#ccc" : "#d2ffd5",
    border: isDisabled ? `1px solid #939293` : `1px solid #7b9b79`,
    boxShadow: isFocused ? `0 0 0 1px #7b9b79` : "none",
    "&:hover": {
      boxShadow: `0 0 0 1px #7b9b79`,
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? "#d2ffd5"
      : isFocused
      ? "#d2ffd5"
      : undefined,
    color: isDisabled ? "#ccc" : isSelected ? "white" : "#6d876d",
    ":active": {
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#d2ffd5"
        : isFocused
        ? "#d2ffd5"
        : undefined,
    },
  }),
  input: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "white" : "#6d876d",
  }),
};

const neutralColourStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? "#ccc" : "#dcdbdc",
    border: `1px solid #939293`,
    boxShadow: isFocused ? `0 0 0 1px #939293` : "none",
    "&:hover": {
      boxShadow: `0 0 0 1px #939293`,
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? "#dcdbdc"
      : isFocused
      ? "#dcdbdc"
      : undefined,
    color: isDisabled ? "#ccc" : isSelected ? "white" : "#6b6a6d",
    ":active": {
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#dcdbdc"
        : isFocused
        ? "#dcdbdc"
        : undefined,
    },
  }),
  input: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "white" : "#6b6a6d",
  }),
};

export default (props) => (
  <div style={{ width: props.width }}>
    {props.label ? (
      <div className={styles.select__label}>{props.label}</div>
    ) : null}
    <Select
      {...props}
      styles={
        props.negative
          ? negativeColourStyles
          : props.positive
          ? positiveColourStyles
          : props.neutral
          ? neutralColourStyles
          : colourStyles
      }
    />
    {!props.hideBaseConfiguration && (
      <p style={{ fontSize: 12, marginTop: 8 }}>
        Configurazione base:{" "}
        <span
          style={{
            fontSize: 14,
            fontWeight: 700,
            color: selectConditionals()?.spanSecondaryColor,
          }}
        >
          {props.defOption}
        </span>
      </p>
    )}
  </div>
);
