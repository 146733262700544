import React from "react";
import styles from "../SearchBar.module.scss";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import TextField from "../../../../Common/TextField";
import { Field, Form as FormikForm, Formik } from "formik";
import * as yup from "yup";
import { actions } from "../../../store/actions";
import alertToast from "../../../../../shared/Error/AlertToast";
import { useSelector, useDispatch } from "react-redux";
import { setFormValue } from "../../../store/actions";
import { getPlatform, getTypeSearch } from "../../../store/selectors";

const Form = () => {
  const dispatch = useDispatch();
  const platform = useSelector(getPlatform());
  const typeSearch = useSelector(getTypeSearch());
  // console.log(platform, "platform");
  // console.log(typeSearch, "typeSearch");

  return (
    <Formik
      initialValues={{
        value: "",
      }}
      validationSchema={yup.object().shape({
        value: yup.string("Inserisci un valore").required("Stringa richiesta"),
      })}
      onSubmit={async (values) => {
        dispatch(setFormValue(values.value));
        dispatch(
          actions[
            typeSearch == "user"
              ? "GET_USERS_BY_EMAIL_OR_NAME"
              : "GET_REPORTS_BY_ADDRESS"
          ].REQUEST(
            {
              value: values.value,
              platform,
            },
            (data) => {
              if (!data.length) {
                alertToast("warning", "Attenzione", "Nessun risultato trovato");
              }
            }
          )
        );
      }}
    >
      {({ values, isSubmitting }) => (
        // console.log(values),
        // console.log(isSubmitting, "isSubmitting"),
        <FormikForm className={styles.admin_search_bar__bar}>
          <Field
            name={"value"}
            id={"value"}
            fontSize="20px"
            border="2px solid #034349"
            borderRadius="10px"
            component={TextField}
            height="60px"
            placeholder={
              typeSearch == "user"
                ? "Cerca utente per nome o email"
                : "Cerca report per indirizzo"
            }
            classNameInherit={styles.admin_search_bar__bar__input}
          />
          <div className={styles.admin_search_bar__bar__btn}>
            <PrimaryButton
              text="Cerca"
              width="100%"
              height="100%"
              background="#034349"
              // type="submit"
              disabled={!values.value || isSubmitting}
            />
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
