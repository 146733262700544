export const TYPE_ROOM = [
	{
		id: 'type_room_1', value: 'cucina', label: 'Cucina'
	},
	{
		id: 'type_room_2', value: 'bagno', label: 'Bagno'
	},
	{
		id: 'type_room_3', value: 'salone', label: 'Salone'
	},
	{
		id: 'type_room_4', value: 'giardino', label: 'Giardino'
	},
	{
		id: 'type_room_5', value: 'garage', label: 'Garage'
	},
	{
		id: 'type_room_6', value: 'posto auto', label: 'Posto auto'
	},
	{
		id: 'type_room_7', value: 'camera', label: 'Camera'
	},
	{
		id: 'type_room_8', value: 'cantina', label: 'Cantina'
	},
];
