import styles from "./CircleProgressBar.module.scss";
import { Label } from "./containers/Label";

const cleanPercentage = (percentage) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Circle = ({ colour, percentage, radius, className }) => {
  const circ = 2 * Math.PI * radius;
  const strokePct = ((100 - percentage) * circ) / 100;
  return (
    <circle
      r={radius}
      cx={100}
      cy={100}
      fill="transparent"
      strokeWidth={"2rem"}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
      className={className}
    />
  );
};

const CircleProgressBar = ({
  percentage,
  colour,
  disableColour,
  icon,
  label = false,
  radius,
}) => {
  const pct = cleanPercentage(percentage);
  const r = radius || 70;

  return (
    <div className={styles.circle__bar__wrapper}>
      <svg width={250} height={250}>
        <g transform={`rotate(-90 ${"125 100"})`}>
          <Circle
            radius={r}
            colour={disableColour}
            className={styles.full_circle}
          />
          <Circle
            radius={r}
            colour={colour}
            percentage={pct}
            className={styles.empty_circle}
          />
        </g>
      </svg>
      {label && (
        <div className={styles.circle__bar__labels}>
          <Label radius={r} label={label[0]} value={100 - pct} disabled />
          <Label radius={r} label={label[1]} value={pct} />
        </div>
      )}
      {!label && (
        <div className={styles.icon__wrapper}>
          <img src={icon} alt="icon" />
        </div>
      )}
    </div>
  );
};

export default CircleProgressBar;
