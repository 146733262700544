import clx from "classnames";
import { Field, Form, Formik } from "formik";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { ReactComponent as AddIcon } from "../../../../../../assets/svg/add.svg";
import CheckIcon from "../../../../../../assets/svg/check-icon.svg";
import { ReactComponent as CrossIcon } from "../../../../../../assets/svg/close.svg";
import { ReactComponent as CloseIcon } from "../../../../../../assets/svg/cross-icon.svg";
import { ReactComponent as GridIcon } from "../../../../../../assets/svg/grid.svg";
import { ReactComponent as MenuIcon } from "../../../../../../assets/svg/menu-icon.svg";
import { ReactComponent as PencilImg } from "../../../../../../assets/svg/pencil.svg";
import StarIcon from "../../../../../../assets/svg/star.svg";
import useOutsideClick from "../../../../../../utils/useOutsideClick";
import IconButton from "../../../../../Common/Buttons/IconButton";
import PrimaryButton from "../../../../../Common/Buttons/PrimaryButton";
import ConfirmModal from "../../../../../Common/ConfirmModal";
import Modal from "../../../../../Common/Modal";
import SingleSelect from "../../../../../Common/SingleSelect";
import TextField from "../../../../../Common/TextField";
import WarningTooltip from "../../../../../Common/WarningTooltip";
import { actions } from "../../../../../Report/store";
import { TYPE_ROOM } from "../../constants";
import styles from "./ActiveImageInfo.module.scss";

const ActiveImageInfo = ({
  activeImage,
  setConfirmModalOpen,
  setUploadImageModalOpen,
  reportId,
  gallery,
  picturesPopup,
  setPicturesPopup,
}) => {
  const dispatch = useDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [editImageInfoFormOpen, setEditImageInfoFormOpen] = useState(false);
  const [openModalImage, setOpenModalImage] = useState(false);

  const [changeCoverImageModalOpen, isChangeCoverImageModalOpen] =
    useState(false);

  const menuRef = useRef();
  const submitFormRef = useRef();

  useOutsideClick(menuRef, () => {
    if (isMenuOpen) setIsMenuOpen(false);
  });

  const initialValues = {
    title: activeImage?.title || "",
    description: activeImage?.description || "",
    type_room: activeImage?.type_room || "",
  };

  const validationSchema = yup.object().shape({
    type_room: yup.string("Scegli il tipo di camera"),
  });

  const AddImageButton = () => (
    <div className={styles["add-image-btn__wrapper"]}>
      <IconButton
        height="75px"
        width="75px"
        padding="25px"
        borderRadius="50%"
        onClick={() => {
          if (gallery?.data?.length < 4) {
            setUploadImageModalOpen(true);
          } else {
            setPicturesPopup(true);
          }
        }}
        // iconSrc={CrossIcon}
        iconSrc={AddIcon}
      />
    </div>
  );

  const ModalImage = () => (
    <Modal
      isModalOpen={openModalImage}
      setIsModalOpen={setOpenModalImage}
      height="80vh"
      width="80vw"
      padding="0"
      backgroundColor="rgba(0, 0, 0, 0.8)"
      showCloseBtn={() => setOpenModalImage(false)}
    >
      <div className={styles["modal-image__wrapper"]}>
        <img
          src={activeImage?.path}
          alt="house"
          className={styles["modal-image"]}
        />
      </div>
    </Modal>
  );

  return (
    <>
      {activeImage ? (
        <div className={styles.image__main}>
          <ModalImage />
          <div className={styles.image__wrapper}>
            {activeImage?.id === reportId ? (
              <div className={clx(styles.cover__image, "cover-image-tooltip")}>
                <img src={StarIcon} alt="cover" />
              </div>
            ) : null}
            <img
              src={activeImage?.path}
              alt="house"
              className={styles["house-image"]}
              onClick={() => setOpenModalImage(true)}
            />
            {/* <IconButton
								onClick={() => setIsMenuOpen(true)}
								iconSrc={MenuIcon}
							/> */}

            <div>
              <MenuIcon
                onClick={() => setIsMenuOpen(true)}
                className={styles.image__wrapper_edit}
              />
            </div>

            {isMenuOpen && (
              <div ref={menuRef} className={styles.dropdown__menu}>
                <span
                  className={styles.menu__item}
                  onClick={() => isChangeCoverImageModalOpen(true)}
                >
                  Imposta nuova copertina
                </span>
                <span
                  className={clx(styles.menu__item, styles["remove-item-btn"])}
                  onClick={() => setConfirmModalOpen(true)}
                >
                  Elimina Foto
                </span>
              </div>
            )}
          </div>
          <div className={styles.image__info}>
            <div className={styles.actions__wrapper}>
              {editImageInfoFormOpen ? (
                <>
                  <PrimaryButton
                    text="Salva modifica"
                    leftIconSrc={CheckIcon}
                    onClick={() => submitFormRef.current.handleSubmit()}
                    fontSize="10px"
                    height="25px"
                    padding="6px 10px"
                    borderRadius="5px"
                  />
                  <IconButton
                    padding="0"
                    onClick={() => {
                      submitFormRef.current.resetForm();
                      setEditImageInfoFormOpen(false);
                    }}
                    iconSrc={CloseIcon}
                  />
                </>
              ) : (
                <IconButton
                  height="30px"
                  padding="4px"
                  borderRadius="6px"
                  width="35px"
                  onClick={() => setEditImageInfoFormOpen(true)}
                  backgroundColor="#5E239D"
                  iconSrc={PencilImg}
                />
              )}
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                dispatch(
                  actions.EDIT_IMAGE_FIELDS.REQUEST({
                    ...values,
                    reportId,
                    idImage: activeImage?.id,
                  })
                );
                setConfirmModalOpen(false);
                setEditImageInfoFormOpen(false);
              }}
              innerRef={submitFormRef}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form
                  className={clx(styles["edit-image__form"], {
                    [styles["form__disabled"]]: !editImageInfoFormOpen,
                  })}
                >
                  <div className={styles.input__label}>Titolo</div>
                  <Field
                    name="title"
                    id="title"
                    component={TextField}
                    type="text"
                    color={"red"}
                    borderRadius="5px"
                    colorFontInput="rgb(16 96 109)"
                    disabled={!editImageInfoFormOpen}
                    placeholder="Titolo"
                  />
                  <div className={styles.input__label}>Descrizione</div>
                  <Field
                    name="description"
                    id="description"
                    component={TextField}
                    type="text"
                    borderRadius="5px"
                    disabled={!editImageInfoFormOpen}
                    placeholder="Descrizione"
                    colorFontTextarea="rgb(16 96 109)"
                    textarea={true}
                  />
                  <SingleSelect
                    isDisabled={!editImageInfoFormOpen}
                    label="Tipologia stanza"
                    options={TYPE_ROOM}
                    hideBaseConfiguration={true}
                    defOption={
                      TYPE_ROOM.find((i) => i.value === values.type_room)?.label
                    }
                    value={TYPE_ROOM.find((i) => i.value === values.type_room)}
                    onChange={(data) => setFieldValue("type_room", data.value)}
                  />
                </Form>
              )}
            </Formik>
            {/* <AddImageButton /> */}
          </div>
        </div>
      ) : (
        <div className={styles["empty-gallery"]}>
          <b>Non ci sono foto</b>
          <AddImageButton />
        </div>
      )}
      <Modal
        isModalOpen={changeCoverImageModalOpen}
        setIsModalOpen={() => isChangeCoverImageModalOpen(false)}
        modalJustifyContent="center"
        padding="40px 0px"
        gap={"20px"}
      >
        <ConfirmModal
          text="Sei sicuro dei cambiamenti?"
          textAlign="center"
          width="60%"
          margin="0 auto"
          iconHeight="14px"
          callbackLeft={() => {
            dispatch(
              actions.FEATURED_IMAGE.REQUEST({
                reportId,
                idImage: activeImage?.id,
              })
            );
            isChangeCoverImageModalOpen(false);
          }}
          callbackRight={() => isChangeCoverImageModalOpen(false)}
          leftText="Cambia"
          rightText="Indietro"
          invertedColor="true"
        />
      </Modal>
      <WarningTooltip
        picturesPopup={picturesPopup}
        setPicturesPopup={setPicturesPopup}
        text={
          "E' possibile caricare massimo 4 foto. Per assistenza o feedback contatta il team Houseplus :)"
        }
      />
    </>
  );
};

export default ActiveImageInfo;
