import clx from "classnames";
import React from "react";

import { formatNumber } from "../../../../utils/formatNumber";
import NoChartsData from "../../../AlgorithmReport/containers/NoChartsData";
import SafetyChange from "../../../AlgorithmReport/containers/Safety/containers/SafetyChange";
import SafetyDetail from "../../../AlgorithmReport/containers/Safety/containers/SafetyDetail";
import styles from "./EleventhPg.module.scss";

const EleventhPg = ({ safetyChange, safetyDetail, pdfItems }) => {
  // console.log(safetyChange);
  const firstYear = safetyChange?.graph_data?.data?.x?.[0];
  const lastYear =
    safetyChange?.graph_data?.data?.x?.[
      safetyChange?.graph_data?.data?.x?.length - 1
    ];
  const difference = lastYear - firstYear;
  const differencePercent = (difference / firstYear) * 100;
  const changeType =
    (difference / firstYear) * 100 >= 0 ? "incremento" : "decremento";

  // console.log(safetyDetail);
  const crimeAmounts = safetyDetail?.graph_data?.data?.x;
  const highestCrimeAmountIndex = crimeAmounts?.indexOf(
    Math.max(...crimeAmounts)
  );
  const highestCrimeType =
    safetyDetail?.graph_data?.data?.y?.[highestCrimeAmountIndex];
  const highestCrimeAmount =
    crimeAmounts?.[crimeAmounts?.indexOf(Math.max(...crimeAmounts))];

  return (
    <div className={clx(styles.area__services__wrapper)}>
      <h3 className={styles.header__text}>Informazioni sulla sicurezza</h3>
      <p className={styles.secondary__text}>
        Visualizza in modo chiaro e preciso i dati sulla sicurezza locale, per
        approfondire la conoscenza del contesto dell’immobile.
      </p>
      <div className={styles.area__services__main}>
        {!pdfItems?.["safety_change"] && (
          <div
            className={clx(styles.safety_change, styles.general__card)}
            style={{ position: "relative" }}
          >
            <div className={styles.chart_info}>
              Le denunce totali nel <b>2022</b> sono state{" "}
              <b>{formatNumber(lastYear)}</b>, con un <b>{changeType}</b> del{" "}
              <b>{differencePercent.toFixed(2)}%</b> rispetto al <b>2019</b>
            </div>
            <div>
              {safetyChange?.graph_status == "OK" ? (
                <SafetyChange
                  data={safetyChange?.graph_data}
                  minHeight={280}
                  wrapperHeight={"425px"}
                  footerTop={"10px"}
                  locale={false}
                  paddingTop={"30px"}
                  chartMarginTop={"50px"}
                  isPdf={true}
                />
              ) : (
                <NoChartsData title={"Andamento delle denunce"} />
              )}
            </div>
          </div>
        )}
        {!pdfItems?.["safety_detail"] && (
          <div
            className={clx(styles.safety_detail, styles.general__card)}
            style={{ position: "relative" }}
          >
            <div className={styles.chart_info}>
              Nel 2022 i reati maggiormente frequenti sono i{" "}
              <b>{highestCrimeType}</b>, con{" "}
              <b>{formatNumber(highestCrimeAmount)}</b> denunce ogni 100.000
              abitanti
            </div>
            <div>
              {safetyDetail?.graph_status == "OK" ? (
                <SafetyDetail
                  data={safetyDetail?.graph_data}
                  minHeight={325}
                  wrapperHeight={"430px"}
                  marginTop={"0px"}
                  legendHeight={"0px"}
                  legendTop={"2.5px"}
                  legendLeft={0}
                  barSize={15}
                  minWidth={300}
                  customLabelTop={-6.5}
                  paddingTop={"30px"}
                  chartMarginTop={"30px"}
                  isPdf={true}
                />
              ) : (
                <NoChartsData title={"Dettaglio dei delitti"} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EleventhPg;
