import clx from "classnames";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { getReportCharts } from "../../../Report/store/selectors";
import { formatNumber } from "../../../../utils/formatNumber";
import Skeleton from "../../../Common/Skeleton";
import NoChartsData from "../NoChartsData";
import SafetyChange from "./containers/SafetyChange";
import SafetyDetail from "./containers/SafetyDetail";
import styles from "./Safety.module.scss";
import SectionTitle from "../SectionTitle";
import BuyCta from "../BuyCta";

const Safety = forwardRef(
  ({ privateUserHasReport, userIsPrivate, agencyUserHasSubscription }, ref) => {
    const charts = useSelector(getReportCharts());

    // SAFETY CHANGE DYNAMIC STRING DATA
    const safetyChange = charts?.[12];
    const firstYear = safetyChange?.graph_data?.data?.x?.[0];
    const lastYear =
      safetyChange?.graph_data?.data?.x?.[
        safetyChange?.graph_data?.data?.x?.length - 1
      ];
    const difference = lastYear - firstYear;
    const differencePercent = (difference / firstYear) * 100;
    const changeType =
      (difference / firstYear) * 100 >= 0 ? "incremento" : "decremento";

    // SAFETY DETAIL DYNAMIC STRING DATA
    const safetyDetail = charts?.[13];
    const crimeAmounts = safetyDetail?.graph_data?.data?.x;
    const highestCrimeAmountIndex = crimeAmounts?.indexOf(
      Math.max(...crimeAmounts)
    );
    const highestCrimeType =
      safetyDetail?.graph_data?.data?.y?.[highestCrimeAmountIndex];
    const highestCrimeAmount =
      crimeAmounts?.[crimeAmounts?.indexOf(Math.max(...crimeAmounts))];

    return charts?.loading ? (
      <Skeleton />
    ) : (
      <div className={clx(styles.area__services__wrapper)} ref={ref}>
        <SectionTitle
          title={"Informazioni sulla sicurezza"}
          section={"all_safety_charts"}
          className={styles.header__text}
          isGeneral={true}
          isDisabled={!userIsPrivate && !agencyUserHasSubscription}
        />
        {/* <p className={styles.secondary__text}>Dati forniti dal comune di appartenenza</p> */}

        {(userIsPrivate && !privateUserHasReport) ||
        (!userIsPrivate && !agencyUserHasSubscription) ? (
          <BuyCta />
        ) : (
          <div className={styles.area__services__main}>
            <div className={clx(styles.safety_change, styles.general__card)}>
              {charts?.[12]?.graph_status == "OK" ? (
                <div className={styles.general__card_container}>
                  <div className={styles.chart_info}>
                    Le denunce totali nel <b>2022</b> sono state{" "}
                    <b>{formatNumber(lastYear)}</b>, con un <b>{changeType}</b>{" "}
                    del <b>{differencePercent.toFixed(2)}%</b> rispetto al{" "}
                    <b>2019</b>
                  </div>
                  <SafetyChange
                    data={charts?.[12]?.graph_data}
                    chartMarginTop={"90px"}
                    locale={false}
                  />
                </div>
              ) : (
                <NoChartsData title={"Andamento delle denunce"} />
              )}
            </div>
            <div className={clx(styles.safety, styles.general__card)}>
              {charts?.[13]?.graph_status == "OK" ? (
                <div className={styles.general__card_container}>
                  <div className={styles.chart_info}>
                    Nel 2022 i reati maggiormente frequenti sono i{" "}
                    <b>{highestCrimeType}</b>, con{" "}
                    <b>{formatNumber(highestCrimeAmount)}</b> denunce ogni
                    100.000 abitanti
                  </div>
                  <SafetyDetail data={charts?.[13]?.graph_data} />
                </div>
              ) : (
                <NoChartsData title={"Dettaglio dei delitti"} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default Safety;
