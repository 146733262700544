import GaugeChart from "../../../../../Common/Charts/GaugeChart";
import PdfCheckbox from "../../../PdfCheckbox";

const AverageEarnings = ({
  data,
  marginTopValue,
  minHeight,
  isPdf = false,
}) => {
  // console.log(data);
  const translateValue = (value) => {
    if (value < 1) {
      return { label: "basso", value: 25 };
    }
    if (value < 2) {
      return { label: "medio", value: 50 };
    }
    return { label: "alto", value: 100 };
  };

  const translateDisplayValue = (displayVal) => {
    // const numericVal = displayVal?.split(' ')[0];
    // const stringVal = displayVal?.split(' ')[1];
    // console.log(numericVal?.toLocaleString('it-IT'));

    // console.log(displayVal);
    const str = displayVal;
    // console.log(str);
    const regex = /(\d+(\.\d+)?)/;
    const match = str?.match(regex);
    // console.log(match);
    const number = match ? Number(match[0])?.toLocaleString("it-IT") : null;
    // console.log(number, 'NUMBER');

    return (
      <>
        Comune con un reddito medio annuale di €{" "}
        <b>{number?.toLocaleString("it-IT")}</b>
      </>
    );
  };

  return (
    <div style={{ position: "relative", height: "100%" }}>
      {!isPdf && <PdfCheckbox zIndex={1} section={"avg_earnings"} />}
      <GaugeChart
        title={data?.title}
        description={data?.description}
        displayValue={translateDisplayValue(data?.data?.displayed_value)}
        levelLabel={translateValue(data?.data?.level)?.label}
        value={translateValue(data?.data?.level)?.value}
        minHeight={minHeight}
        marginTopValue={marginTopValue}
      />
    </div>
  );
};

export default AverageEarnings;
