import React from 'react';

import styles from './FourteenthPg.module.scss';

const FourteenthPg = ({ gallery }) => {
	const firstFour = gallery?.data?.slice(0, 3);
	// console.log(firstFour, 'FIRST FOUR PICTURES DOWNLOAD SECTION');

	 return (
		!gallery.loading &&
		<div className={styles.section__wrapper}>
			<h3 className={styles.header__text}>Immagini dell&apos;immobile</h3>
			<div className={styles.gallery__list}>
				{firstFour?.map((image, idx) => <div
					key={idx}
					className={styles['list-item__wrapper']}
				>
					<img src={image.path} alt="match"
						className={styles.list__img}/>
					<b>{image.type_room.toUpperCase()}</b>
				</div>
				)}
			</div>
		</div>
	)
	;
};

export default FourteenthPg;