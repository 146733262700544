import { forwardRef } from "react";

import PrimaryButton from "../../../../../Common/Buttons/PrimaryButton";
import { STEPS } from "../../constants";
import styles from "./SubmitButton.module.scss";

const SubmitButton = forwardRef(({ step, setStep, isPrivateState }, ref) => {
  const changeStep = (formRef) => {
    const activeStep = STEPS.find((stepInfo) => +step === stepInfo.step);

    const requiredFields = isPrivateState
      ? activeStep.requiredFields.filter(
          (item) => item != "agency_name" && item != "piva"
        )
      : activeStep.requiredFields;
    // console.log(requiredFields, "REQUIRED FIELDS");
    // console.log(activeStep.requiredFields, "activeStep REQUIRED FIELDS");
    // console.log(formRef.values, "formRef.values");

    const formErrors = requiredFields.map((rField) => {
      formRef.setFieldTouched(rField, true, true);

      if (!formRef.values[rField]) {
        return "isError";
      }
    });

    console.log(formErrors, "FORM ERRORS");

    if (!formErrors.includes("isError")) {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <div className={styles["submit-btn"]}>
      {step === 1 ? (
        <PrimaryButton
          width="100%"
          height="60px"
          text="Continua"
          onClick={() => {
            changeStep(ref.current);
          }}
        />
      ) : (
        <PrimaryButton
          width="100%"
          height="60px"
          marginTop="10px"
          text="Completa Registrazione"
          onClick={() => ref.current.handleSubmit()}
        />
      )}
    </div>
  );
});

export default SubmitButton;
