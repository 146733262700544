import { styled } from "@material-ui/core/styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import clx from "classnames";
import GoogleMapReact from "google-map-react";
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import CloseIcon from "../../../../../../assets/svg/cross-white.svg";
import MapIcon from "../../../../../../assets/svg/empty-map-violet.svg";
import centriEsteticiMaker from "../../../../../../assets/svg/map/centriEstetici.svg";
import DentistMarker from "../../../../../../assets/svg/map/dent.svg";
import dottoreMaker from "../../../../../../assets/svg/map/dottore.svg";
import farmaciayMaker from "../../../../../../assets/svg/map/farmacia.svg";
import GreenSpace from "../../../../../../assets/svg/map/green.svg";
import HospitalMarker from "../../../../../../assets/svg/map/hospital.svg";
import ParkingMarker from "../../../../../../assets/svg/map/parching.svg";
import SchoolMarker from "../../../../../../assets/svg/map/school.svg";
import GroceryStoreMarker from "../../../../../../assets/svg/map/shop-food.svg";
import TransportMarker from "../../../../../../assets/svg/map/transport.svg";
import TransportMarkerTrain from "../../../../../../assets/svg/map/transportTrain.svg";
import UniversityMaker from "../../../../../../assets/svg/map/univerity.svg";
import { ReactComponent as MarkerIcon } from "../../../../../../assets/svg/marker.svg";
import PrimaryButton from "../../../../../Common/Buttons/PrimaryButton";
import Modal from "../../../../../Common/Modal";
import ReactToolTip from "../../../../../Common/ToolTip/ReactToolTip";
import PinMap from "./containers/PinMap";
import styles from "./ServicesMapModal.module.scss";

const mapServices = [
  {
    id: 1,
    key: "supermarket",
    name: "Supermercati",
    img: GroceryStoreMarker,
    is_active: true,
  },
  {
    id: 2,
    key: "parking",
    name: "Parcheggi",
    img: ParkingMarker,
    is_active: true,
  },
  {
    id: 3,
    key: "school",
    name: "Scuole",
    img: SchoolMarker,
    is_active: true,
  },
  {
    id: 4,
    key: "hospital",
    name: "Ospedali",
    img: HospitalMarker,
    is_active: true,
  },
  {
    id: 5,
    key: "metro",
    name: "Metro",
    img: TransportMarker,
    is_active: true,
  },
  {
    id: 6,
    key: "train",
    name: "Treni",
    img: TransportMarkerTrain,
    is_active: true,
  },
  {
    id: 7,
    key: "dentist",
    name: "Dentisti",
    img: DentistMarker,
    is_active: true,
  },
  {
    id: 8,
    key: "green_outdoors",
    name: "Spazi green",
    img: GreenSpace,
    is_active: true,
  },
  {
    id: 10,
    key: "university",
    name: "Università",
    img: UniversityMaker,
    is_active: true,
  },
  {
    id: 11,
    key: "pharmacy",
    name: "Farmacie",
    img: farmaciayMaker,
    is_active: true,
  },
  {
    id: 12,
    key: "medical_centers",
    name: "Centri medici",
    img: dottoreMaker,
    is_active: true,
  },
  {
    id: 17,
    key: "beauty",
    name: "Centri estetici",
    img: centriEsteticiMaker,
    is_active: true,
  },
];

const distanceFiltering = [
  {
    id: 1,
    name: "Tutto",
    value: null,
    is_active: true,
  },
  {
    id: 2,
    name: "Entro 500 m",
    value: 500,
    is_active: false,
  },
  {
    id: 3,
    name: "Entro 1 km",
    value: 1000,
    is_active: false,
  },
  {
    id: 4,
    name: "Entro 2 km",
    value: 2000,
    is_active: false,
  },
];

const HomePin = () => (
  <>
    <ReactToolTip
      containerClass="tooltipStyleChange"
      x={-87}
      y={70}
      width={289}
      height={"auto"}
    >
      <span className={clx(styles["map__point"], styles["map__point-active"])}>
        <MarkerIcon />
      </span>
      {/* <img
        src={MarkerIcon}
        className={clx(styles["map__point"], styles["map__point-active"])}
        alt="point"
      /> */}
    </ReactToolTip>
  </>
);

const MapOptions = {
  minZoom: 12,
  fullscreenControl: false,
  zoomControl: true,
};

// Material UI button icons styles
const PrevIcon = styled(NavigateBeforeIcon)({
  color: "white",
  fontSize: "1.2rem",
});
const NextIcon = styled(NavigateNextIcon)({
  color: "white",
  fontSize: "1.2rem",
});

const ServicesMapModal = ({ isMobile, data, home: { lat, lng } }) => {
  // Carousel setting
  const sliderRef = useRef(null);
  const carouselOptions = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    arrows: false,
  };

  const [isServicesMapModalOpen, setIsServicesMapModalOpen] = useState(false);
  const [activeMapServices, setActiveMapServices] = useState(mapServices);
  const [activeDistanceFilter, setActiveDistanceFilter] =
    useState(distanceFiltering);
  const [activeService, setActiveService] = useState(null);

  const setActivePointGroup = (service_id) => {
    setActiveMapServices((activeService) =>
      activeService.map((item) =>
        item.id === service_id
          ? { ...item, is_active: true }
          : { ...item, is_active: false }
      )
    );
  };

  const resetActivePointGroup = () => {
    setActiveMapServices((activeService) =>
      activeService.map((item) => ({ ...item, is_active: true }))
    );
  };

  return (
    <>
      <div
        className={clx(styles.area__services__action, {
          [styles["area__services__action-mobile"]]: isMobile,
        })}
      >
        <PrimaryButton
          height="60px"
          text="Visualizza sulla Mappa"
          onClick={() => setIsServicesMapModalOpen(true)}
          color={"#ffffff"}
          leftIconSrc={MapIcon}
        />
      </div>
      <Modal
        padding="0px"
        width={isMobile ? "100%" : "80%"}
        height={isMobile ? "100vh" : "80vh"}
        isModalOpen={isServicesMapModalOpen}
        zIndex="10000000000"
      >
        <span className={clx(styles["corner-background"])} />
        <span
          onClick={() => setIsServicesMapModalOpen(false)}
          className={styles["corner-value"]}
        >
          <img style={{ width: 20 }} src={CloseIcon} alt="" />
        </span>
        <div className={styles["map-actions"]}>
          <Slider {...carouselOptions} ref={sliderRef}>
            {activeMapServices.map((service) => (
              <span
                className={clx(styles["map-label"], {
                  [styles["map-label__active"]]: service.is_active,
                  [styles["map-label__stroke"]]:
                    activeService?.id === service.id,
                })}
                onClick={() =>
                  setActiveService(
                    activeService?.id === service?.id ? null : service
                  )
                }
                onMouseEnter={() => setActivePointGroup(service.id)}
                onMouseLeave={resetActivePointGroup}
                key={service.id}
              >
                <img src={service.img} alt="" />
                <span>{service.name}</span>
              </span>
            ))}
          </Slider>
          {/* Slider custom buttons */}
          <div className={styles.buttons}>
            <div
              className={`${styles.buttons_next} next`}
              onClick={() => sliderRef?.current?.slickNext()}
            >
              <NextIcon />
            </div>
            <div
              className={`${styles.buttons_prev} prev`}
              onClick={() => sliderRef?.current?.slickPrev()}
            >
              <PrevIcon />
            </div>
          </div>
        </div>
        <div className={styles["distance-filter"]}>
          {activeDistanceFilter.map((filter) => (
            <span
              className={clx(styles["filter-item"], {
                [styles["active-filter"]]: filter.is_active,
              })}
              onClick={() =>
                setActiveDistanceFilter(
                  activeDistanceFilter.map((i) => ({
                    ...i,
                    is_active: i.id === filter.id,
                  }))
                )
              }
              key={filter.id}
            >
              {filter.name}
            </span>
          ))}
        </div>
        <div className={styles["map-modal__content"]}>
          <GoogleMapReact
            options={MapOptions}
            bootstrapURLKeys={{
              key: "AIzaSyDhU_uaWxC4hWjLecwUvBW7RzqauLXfpVw",
            }}
            center={{ lat: +lat, lng: +lng }}
            defaultZoom={15}
            key={"servicesMapId"}
            yesIWantToUseGoogleMapApiInternals
          >
            {activeMapServices.map((service) => {
              const value = data?.[service.key];

              return value?.services_found
                ?.filter((props) => {
                  const activeFilter = activeDistanceFilter.find(
                    (filter) => filter.is_active
                  );
                  return activeFilter.value
                    ? activeFilter.value >= props.distance
                    : true;
                })
                .filter(() =>
                  Object.values(activeService || {}).length
                    ? activeService?.key === service.key
                    : true
                )
                .map(({ lat, lng, ...props }, index) => (
                  <PinMap
                    setActivePointGroup={setActivePointGroup}
                    resetActivePointGroup={resetActivePointGroup}
                    customClass={clx(styles["map__point"], {
                      [styles["map__point-active"]]: service.is_active,
                    })}
                    data={props}
                    service={service}
                    key={`${service.id}${index}`}
                    lat={lat}
                    lng={lng}
                  />
                ));
            })}
            <HomePin lat={+lat} lng={+lng} />
          </GoogleMapReact>
        </div>
      </Modal>
    </>
  );
};

export default ServicesMapModal;
