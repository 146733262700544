import { useSelector } from "react-redux";
import { getPdfItems } from "../../Report/store/selectors";

// export const options1 = [
//   {
//     id: "single_select_1_1",
//     value: "No",
//     label: "Senza Ascensore",
//   },
//   {
//     id: "single_select_1_2",
//     value: "Si",
//     label: "Con Ascensore",
//   },
// ];

export const options2 = [
  {
    id: "single_select_2_8",
    value: "Sconosciuta",
    label: "Sconosciuta",
  },
  {
    id: "single_select_2_7",
    value: "G",
    label: "G",
  },
  {
    id: "single_select_2_7",
    value: "F",
    label: "F",
  },
  {
    id: "single_select_2_6",
    value: "E",
    label: "E",
  },
  {
    id: "single_select_2_5",
    value: "D",
    label: "D",
  },
  {
    id: "single_select_2_4",
    value: "C",
    label: "C",
  },
  {
    id: "single_select_2_3",
    value: "B",
    label: "B",
  },
  {
    id: "single_select_2_1",
    value: "A1",
    label: "A1",
  },
  {
    id: "single_select_2_9",
    value: "A2",
    label: "A2",
  },
  {
    id: "single_select_2_10",
    value: "A3",
    label: "A3",
  },
  {
    id: "single_select_2_11",
    value: "A4",
    label: "A4",
  },
];

export const options3 = [
  {
    id: "single_select_3_2",
    value: "Centralizzato",
    label: "Centralizzato",
  },
  {
    id: "single_select_3_3",
    value: "Autonomo",
    label: "Autonomo",
  },
];

export const options4 = [
  {
    id: "single_select_4_1",
    value: "Da ristrutturare",
    label: "Da ristrutturare",
  },
  {
    id: "single_select_4_4",
    value: "Buono, abitabile",
    label: "Buono, abitabile",
  },
  {
    id: "single_select_4_2",
    value: "Ristrutturato",
    label: "Ristrutturato",
  },
  {
    id: "single_select_4_3",
    value: "Nuovo",
    label: "Nuovo",
  },
];

export const useCheckboxTooltipText = (
  section,
  isOpposite = false,
  isGeneral
) => {
  const pdfItems = useSelector(getPdfItems());

  return (
    isOpposite ? pdfItems?.[0]?.[section] == 0 : pdfItems?.[0]?.[section] == 1
  )
    ? isGeneral
      ? "Seleziona questo campo se vuoi che questa sezione venga inclusa nel PDF"
      : "Seleziona questo campo se vuoi che venga incluso nel PDF"
    : isGeneral
    ? "Deseleziona questo campo se non vuoi che questa sezione venga inclusa nel PDF"
    : "Deseleziona questo campo se non vuoi che venga incluso nel PDF";
};
