import styles from "./Footer.module.scss";

const Footer = () => (
  <footer className={styles.footer}>
    <p>
      <a
        className={styles.link}
        href="https://www.iubenda.com/privacy-policy/24329777"
      >
        Privacy Policy
      </a>
      &nbsp;
      <span className={styles.link}>|</span>&nbsp;&nbsp;
      <a
        className={styles.link}
        href="https://www.houseplus.it/wp-content/uploads/2022/11/TERMINI-E-CONDIZIONI-HOUSEPLUS-1.pdf"
      >
        Termini e Condizioni
      </a>
    </p>
  </footer>
);

export default Footer;
