import clx from 'classnames';
import {
	Field,
	Form, Formik
} from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import useWindowSize from '../../../../../../utils/useWindowSize';
import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import TextField from '../../../../../Common/TextField';
import { actions } from '../../../../store';
import styles from '../../ForgotPassword.module.scss';

const ForgotPasswordForm = ({
	setStep, setUserEmail
}) => {
	const dispatch = useDispatch();
	const { isMobile, windowHeight } = useWindowSize();

	const initialValues = {
		email: '',
	};

	const validationSchema = {
		email: yup
			.string('Inserisci la tua email')
			.email('Inserisci un email valida')
			.required('Email richiesta'),
	};

	return (
		<div
			className={
				clx(styles['forgot-pwd-form__wrapper'],
					{ [styles['forgot-pwd-form__wrapper-mobile']]: isMobile})
			}>			<h1>Recupero Password</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={yup.object().shape(validationSchema)}
				onSubmit={values =>
					dispatch(actions.FORGOT_PASSWORD.REQUEST(values, () => {
						setUserEmail(values.email);
						setStep(prev => prev + 1);
					}))
				}
			>
				{() => (
					<Form className={styles['forgot-pwd__form']}>
						<Field name='email' id='email'
							label='Scrivi l’Email con cui ti sei registrato.'
							placeholder='Email'
							component={TextField}
							type='email'
						/>
						<PrimaryButton
							width='100%'
							height='60px'
							text='Avanti' />
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default ForgotPasswordForm;
