import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../routes/constants";
import platformConditionals from "../../../../utils/platformConditionals";
import styles from "./Stepper.module.scss";
import clx from "classnames";

const Stepper = ({ step, setStep }) => {
  const steps = [
    "Informazioni di partenza",
    "Caratteristiche dell'immobile",
    "Pertinenze",
    "Ulteriori dettagli dell'immobile",
  ];
  return (
    <div className={styles.stepper}>
      <div className={styles.stepper__wrapper}>
        <Link to={ROUTES.REPORT} className={styles.logo}>
          <img src={platformConditionals()?.logo || ""} alt="logo" />
        </Link>
        <div className={styles.steps}>
          {steps.map((item, index) => (
            <div
              key={`${item}-stepper-index`}
              className={clx(styles.steps__step, {
                [styles.active]: index <= step - 1,
              })}
              onClick={step > index + 1 ? () => setStep(index + 1) : null}
              style={{
                cursor: step > index + 1 ? "pointer" : "default",
                transitionDelay: step > index && "0.15s",
              }}
            >
              <div className={styles.number}>{index + 1}</div>
              <span className={styles.text}>{item}</span>
            </div>
          ))}
          <div className={styles.steps__line}>
            <div
              className={styles.steps__line__fill}
              style={{ height: `${(step - 1) * 33.33}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
